import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {FormatosService} from "../../../../services/FormatosService/FormatosService";
import {$cInfo, $cSuccess, EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {AttachMoney, MonetizationOn, Money, SpeakerNotes, Description, InsertDriveFile, PostAdd, Mail} from "@material-ui/icons";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import {PusherSubscribe, PusherUnSubscribe} from "../../../../settings/Pusher/Pusher";
import Fab from "@material-ui/core/Fab";
import ModalImportarArchivo from "./ModalImportarArchivo";


const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el formato con id ${item.id_formato}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				FormatosService.Eliminar(item.id_formato).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	is_cancel = (item) => {
		return item.activo === 0;
	};
	
	ver_root = () => {
		return this.Usr.id_usuario === 1;
	};
	
	verDocumento = (path) => {
		console.log("PATH ::: ", path);
		DropboxApi.Open(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
	};
	
	sendMail = (id_importe_neto) => {
		FormatosService.SendMail(id_importe_neto).then((response) => {
			this.props.RefreshList();
			this.props.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-acresco'}>
					<TableHead>
						<TableRow>
							{/*<TableCell component="th" align={'left'} className={'padding-10-L'}>N.P.</TableCell>*/}
							<TableCell component="th" align={'left'}>UUID</TableCell>
							<TableCell component="th" align={'left'}>Estado Sat</TableCell>
							<TableCell component="th" align={'left'}>Versión</TableCell>
							<TableCell component="th" align={'left'}>Tipo</TableCell>
							<TableCell component="th" align={'left'}>Fecha Emision</TableCell>
							<TableCell component="th" align={'left'}>Folio</TableCell>
							<TableCell component="th" align={'left'}>RFC Emisor</TableCell>
							<TableCell component="th" align={'left'}>Nombre Emisor</TableCell>
							<TableCell component="th" align={'left'}>RFC Receptor</TableCell>
							<TableCell component="th" align={'left'}>Nombre Receptor</TableCell>
							<TableCell component="th" align={'left'}>UsoCFDI</TableCell>
							<TableCell component="th" align={'left'}>Subtotal</TableCell>
							<TableCell component="th" align={'left'}>Descuento</TableCell>
							<TableCell component="th" align={'left'}>Total IEPS</TableCell>
							<TableCell component="th" align={'left'}>IVA 16%</TableCell>
							<TableCell component="th" align={'left'}>Total</TableCell>
							<TableCell component="th" align={'left'}>Forma Pago</TableCell>
							<TableCell component="th" align={'left'}>Metodo Pago</TableCell>
							<TableCell component="th" align={'left'}>Conceptos</TableCell>
							{/*<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>*/}
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lista.map((item, key) => {
								return (
									<Fragment key={key}>
										<TableRow>
											{/*<TableCell align={'left'} className={'padding-10-L'}>{item.id_formato}</TableCell>*/}
											<TableCell align={'left'}>{item.uuid}</TableCell>
											<TableCell align={'left'}>{item.estado_sat}</TableCell>
											<TableCell align={'left'}>{item.version}</TableCell>
											<TableCell align={'left'}>{item.tipo}</TableCell>
											<TableCell align={'left'}>{item.fecha_emision}</TableCell>
											<TableCell align={'left'}>{item.folio}</TableCell>
											<TableCell align={'left'}>{item.rfc_emisor}</TableCell>
											<TableCell align={'left'}>{item.nombre_emisor}</TableCell>
											<TableCell align={'left'}>{item.rfc_receptor}</TableCell>
											<TableCell align={'left'}>{item.nombre_receptor}</TableCell>
											<TableCell align={'left'}>{item.usocfdi}</TableCell>
											<TableCell align={'left'}>{item.subtotal}</TableCell>
											<TableCell align={'left'}>{item.descuento}</TableCell>
											<TableCell align={'left'}>{item.total_ieps}</TableCell>
											<TableCell align={'left'}>{item.iva}</TableCell>
											<TableCell align={'left'}>{item.total}</TableCell>
											<TableCell align={'left'}>{item.forma_pago}</TableCell>
											<TableCell align={'left'}>{item.metodo_pago}</TableCell>
											<TableCell align={'left'}>{item.conceptos}</TableCell>
											{/*<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
												<div className={'w-auto vertical-inline'}>
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Enviar correo electrónico">
															<IconButton aria-label="Enviar correo electrónico" onClick={() => this.sendMail(item.id_importe_neto)}><Mail style={{color: item.estatus === 2 ? 'rgb(0, 99, 0)':'rgba(0, 0, 0, 0.54)'}}/></IconButton>
														</Tooltip>
													</div>
													
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
															<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
														</Tooltip>
													</div>
												</div>
											</TableCell>*/}
										</TableRow>
									</Fragment>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	lista_empresa: PropTypes.array.isRequired,
	ListEmpresa: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
