import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {CancelPresentation, CloudDownload, Description, Email, LocalAtm, Message} from "@material-ui/icons";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import ModalPagoCFDI from "./ModalPagoCFDI";
import ModalCancelarCFDI from "./ModalCancelarCFDI";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import ModalObservacionCFDI from "./ModalObservacionCFDI";
import SubListaTabla from "./SubListaTabla";
import {FieldsJs} from "../../../../settings/General/General";
import ModalEnviarEmailXMLPDF from "./ModalEnviarEmailXMLPDF";
import Fab from "@material-ui/core/Fab";
import ModalDescargarDoc from "./ModalDescargarDoc";
import {CONFIG} from "../../../../settings/Config/Config";

class ListaTabla extends Component {
	
	Usr = {};
	
	RefreshList = this.props.RefreshList;
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	
	descargarZip = (path, zip) => {
		
		try {
			
			if (!FieldsJs.Field(path)) {
				throw Object({
					status: false, mensaje: 'No se encuentra el archivo registrado.'
				})
			} else {
				
				if (zip) {
					console.log("ZIPPP:::", zip);
					let ruta = '';
					let route = path.split('/');
					for (let i = 0; i < route.length; i++) {
						if (i < route.length - 1) {
							ruta = ruta + route[i] + (i < route.length - 2 ? '/':'');
						}
					}
					path = ruta;
				}
				
				console.log("PATH ::: ", path, zip);
				
				DropboxApi.GenerateZip(path).then(function (data) {
					console.log('Descarga con éxito!');
				}).catch(function (error) {
					console.error("ERROR::: ", error);
				});
			}
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
		
	};
	
	exportar = (item, tipo) => {
		
		if (item.is_local) {
			let path = tipo ? item.archivo_pdf : item.archivo;
			path = CONFIG.src + path;
			window.open(path, '', 'width=900, height=600');
		} else {
			let path = tipo ? item.archivo_pdf : item.archivo;
			
			DropboxApi.Open(path).then(function (data) {
				console.log('Descarga con éxito!');
			}).catch(function (error) {
				console.error(error);
			});
		}
	};
	
	
	ver_root = () => {
		return this.Usr.id_usuario === 1;
	};
	
	render() {
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		
		const {lista} = this.props;
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'}> </TableCell>
							<TableCell component="th" align={'left'}>Plaza</TableCell>
							<TableCell component="th" align={'left'}>RFC Emisor</TableCell>
							<TableCell component="th" align={'left'}>Empresa Facturadora</TableCell>
							{/*<TableCell component="th" align={'left'}>RFC Receptor</TableCell>*/}
							<TableCell component="th" align={'left'}>Cliente</TableCell>
							<TableCell component="th" align={'left'}>Serie</TableCell>
							<TableCell component="th" align={'left'}>Folio</TableCell>
							<TableCell component="th" align={'left'}>Estatus Sat</TableCell>
							<TableCell component="th" align={'left'}>Fecha Emisión</TableCell>
							<TableCell component="th" align={'left'}>Estatus Pago</TableCell>
							<TableCell component="th" align={'left'}>Fecha Pago</TableCell>
							<TableCell component="th" align={'left'}>SubTotal</TableCell>
							<TableCell component="th" align={'left'}>Iva</TableCell>
							<TableCell component="th" align={'left'}>Total Facturado</TableCell>
							<TableCell component="th" align={'left'}>Concepto de Facturación</TableCell>
							{/*<TableCell component="th" align={'left'}>Clave Unidad</TableCell>*/}
							{/*<TableCell component="th" align={'left'}>Clave Concepto</TableCell>*/}
							<TableCell component="th" align={'center'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<Fragment key={key}>
								<TableRow>
									<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
										{item.parcialidades.length > 0 ? (
											<div className={'w-100'} align={'center'}>
												<div style={!!item.expandir ? bg_menos : bg_mas}
												     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
												     onClick={() => this.props.SubTabla(item, key)}
												/>
											</div>
										) : '...'}
									</TableCell>
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>{item.plaza}</TableCell>
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>{item.rfc_emisor}</TableCell>
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>{item.empresa_facturadora}</TableCell>
									{/*<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>{item.rfc_receptor}</TableCell>*/}
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>{item.cliente ?? item.cliente_externo ?? 'Sin registrar'}</TableCell>
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>{item.serie}</TableCell>
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>{item.folio}</TableCell>
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>{item.facturacion_status_sat}</TableCell>
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>{item.fecha ? DateFormat.getFechaText(item.fecha, '/') : 'Sin registrar'}</TableCell>
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>{item.facturacion_status_pago}</TableCell>
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>{item.fecha_pago ? DateFormat.getFechaText(item.fecha_pago, '/') : 'Sin registrar'}</TableCell>
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>${FieldsJs.Currency(item.subtotal)}</TableCell>
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>${FieldsJs.Currency(item.iva)}</TableCell>
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>${FieldsJs.Currency(item.total)}</TableCell>
									<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado':''}>{item.concepto}</TableCell>
									{/*<TableCell align={'left'}>{item.clave_unidad}</TableCell>*/}
									{/*<TableCell align={'left'}>{item.clave_concepto}</TableCell>*/}
									<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
										<div className={'w-auto vertical-inline'}>
											
											<ModalPagoCFDI
												tipo={'add'}
												item={item}
												componente={
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Pagar">
														<IconButton aria-label="Pagar" onClick={() => {
														}}>
															<LocalAtm style={{color: 'green'}}/>
														</IconButton>
													</Tooltip>
												}
												listDropbox={this.props.listDropbox}
												RefreshList={this.RefreshList}
												showSnackBars={this.showSnackBars}
											/>
											
											<ModalCancelarCFDI
												tipo={'add'}
												item={item}
												componente={
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Cancelar">
														<IconButton aria-label="Cancelar" onClick={() => {
														}}>
															<CancelPresentation style={{color: 'red'}}/>
														</IconButton>
													</Tooltip>
												}
												RefreshList={this.RefreshList}
												showSnackBars={this.showSnackBars}
											/>
											
											<ModalDescargarDoc
												tipo={'add'}
												item={item}
												componente={
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Descargar Archivos">
														<IconButton aria-label="Descargar Archivos" color="default">
															<CloudDownload/>
														</IconButton>
													</Tooltip>
												}
												showSnackBars={this.props.showSnackBars}
												RefreshList={this.props.RefreshList}
												descargarZip={this.descargarZip}
											/>
											
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver XML">
												<IconButton aria-label="Ver XML" onClick={() => this.exportar(item, 0)}>
													<Description style={{color: '#e18c02'}}/>
												</IconButton>
											</Tooltip>
											
											{item.archivo_pdf ? (
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver PDF">
													<IconButton aria-label="Ver PDF" onClick={() => this.exportar(item, 1)}>
														<PictureAsPdf style={{color: '#8E1E32'}}/>
													</IconButton>
												</Tooltip>
											) : (
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver PDF">
													<IconButton aria-label="Ver PDF" onClick={() =>
														this.showSnackBars('error', "No se ha agregado el PDF correspondiente ó el CFDI no cuenta con representación impresa.")}>
														<PictureAsPdf style={{color: 'rgba(0, 0, 0, 0.54)'}}/>
													</IconButton>
												</Tooltip>
											)
												/*<ModalPdfCFDI
													tipo={'add'}
													item={item}
													componente={
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver PDF">
															<IconButton aria-label="Ver PDF" onClick={() => {
															}}>
																<PictureAsPdf style={{color: '#8E1E32'}}/>
															</IconButton>
														</Tooltip>
													}
													RefreshList={this.RefreshList}
													showSnackBars={this.showSnackBars}
												/>*/
											}
											
											{FieldsJs.inArray([1, 2, 3], this.Usr.id_cat_tipo_usuario) ? (
												<ModalEnviarEmailXMLPDF
													tipo={'add'}
													item={item}
													componente={
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Enviar correo">
															<IconButton aria-label="Enviar correo">
																<Email style={{color: item.is_enviado ? '#3f51b5' : 'rgba(0, 0, 0, 0.54)'}}/>
															</IconButton>
														</Tooltip>
													}
													RefreshList={this.RefreshList}
													showSnackBars={this.showSnackBars}
												/>
											) : null}
											
											<ModalObservacionCFDI
												tipo={'add'}
												item={item}
												componente={
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Observación">
														<IconButton aria-label="Observación">
															<Message style={{color: item.observacion ? '#3f51b5' : 'rgba(0, 0, 0, 0.54)'}}/>
														</IconButton>
													</Tooltip>
												}
												RefreshList={this.RefreshList}
												showSnackBars={this.showSnackBars}
											/>
											{/*<Fab variant="extended" size="small" color="default" aria-label="XML" className={'margin-10-L'} onClick={() => this.exportar(item.url_xml_factura)}>
											<PictureAsXml className={'margin-5-R px-14'}/>
											XML
										</Fab>
										
										<Fab variant="extended" size="small" color="default" aria-label="PDF" className={'margin-10-L'} onClick={() => this.exportar(item.url_pdf_factura)}>
											<PictureAsPdf className={'margin-5-R px-14'}/>
											PDF
										</Fab>*/}
										
										</div>
									</TableCell>
								</TableRow>
								{(
									<Fragment>
										{!!item.expandir ? (
											<TableRow>
												{/*<TableCell align={'center'}
												           className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
												           colSpan={this.ver_root() ? 13 : 13}
												>*/}
												<TableCell align={'center'}
												           className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
												           colSpan={this.ver_root() ? 19 : 19}
												>
													
													<SubListaTabla
														tipo_vista={'add'}
														item={item}
														showSnackBars={this.props.showSnackBars}
														RefreshList={this.props.RefreshList}
													/>
												
												</TableCell>
											</TableRow>
										) : null}
									</Fragment>
								)}
							</Fragment>
						
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	listDropbox: PropTypes.array,
	showSnackBars: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
};

export default ListaTabla;
