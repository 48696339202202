import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import {AttachFile, AttachMoney, Business, DeleteOutlined, Description, EditOutlined, HdrStrong, HdrWeakOutlined, HomeWork, House, SearchOutlined} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import EmpresaFacturacionService from "../../../../services/EmpresaFacturacionService/EmpresaFacturacionService";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import {CONFIG} from "../../../../settings/Config/Config";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import empresa_default from "../../../../assets/img/icons/business.svg";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

class ModalEmpresaClaveProducto extends Component {
	
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open_modal: false,
			scroll: 'paper',
			id_empresa: props.id_empresa || '',
			tipo: props.tipo,
			
			id_cat_clave_producto_servicio: (props.item || '').id_cat_clave_producto_servicio,
			clave_producto_servicio: (props.item || '').descripcion ? (props.item || '').clave_producto_servicio + ' - ' + (props.item || '').descripcion : (props.item || '').clave_producto_servicio,
			concepto: '',
			precio_unitario: '',
			tipo_descuento: '1',
			descuento: '',
			id_empresa_clave_unidad: '',
			numero_identificacion: '',
			id_empresa_clave_producto: '',
			
			cat_clave_unidad: [],
			sucursales: [],
		};
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open_modal: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open_modal: false});
	};
	
	claveUnidad = () => {
		return new Promise((resolve, reject) => {
			let params = {
				id_empresa: this.state.id_empresa,
				valuetab: '2'
			};
			EmpresaFacturacionExtService.Catalogos(params).then((response) => {
				this.setState({
					cat_clave_unidad: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					cat_clave_unidad: []
				});
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	save = () => {
		
		try {
			if (this.state.id_empresa_clave_producto > 0) {
				EmpresaFacturacionExtService.ModificarProducto(this.state).then((response) => {
					this.props.RefreshList();
					hideSpinner('spinner', 500);
					this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			} else {
				EmpresaFacturacionExtService.AgregarProducto(this.state).then((response) => {
					this.props.RefreshList();
					hideSpinner('spinner', 500);
					this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		this.claveUnidad();
		
		console.log(this.props);
		const {id_empresa, item} = this.props;
		
		this.setState({
			id_empresa: id_empresa || '',
			id_empresa_clave_producto: '',
			id_cat_clave_producto_servicio: item.id_cat_clave_producto_servicio,
			clave_producto_servicio: item.descripcion ? item.clave_producto_servicio + ' - ' + item.descripcion : item.clave_producto_servicio,
			concepto: '',
			precio_unitario: '',
			tipo_descuento: '1',
			descuento: '',
			id_empresa_clave_unidad: '',
			numero_identificacion: '',
			activo: true,
		});
		
		this.openModal();
		
	};
	
	view = () => {
		
		const {id_empresa, item} = this.props;
		
		this.claveUnidad().then((response) => {
			this.setState({
				id_empresa_clave_unidad: item.id_empresa_clave_unidad || ''
			});
		});
		
		this.setState({
			id_empresa: id_empresa || '',
			id_empresa_clave_producto: item.id_empresa_clave_producto,
			id_cat_clave_producto_servicio: item.id_cat_clave_producto_servicio,
			clave_producto_servicio: item.clave_producto_servicio,
			concepto: item.concepto || '',
			precio_unitario: item.precio_unitario || '',
			tipo_descuento: item.tipo_descuento ? item.tipo_descuento.toString() : '1',
			descuento: item.descuento || '',
			id_empresa_clave_unidad: item.id_empresa_clave_unidad || '',
			numero_identificacion: item.numero_identificacion || '',
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_cat_clave_producto_servicio > 0) {
			if (this.state.tipo === 'add') {
				BTN_ACTION = (
					<Button color="primary" size={'small'} variant="contained" onClick={() => this.add()}>
						{'Agregar'}
					</Button>
				);
			} else if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Button color="primary" size={'small'} variant="contained" onClick={() => this.add()}>
					{'Agregar'}
				</Button>
				/*<Tooltip TransitionComponent={Zoom} placement={"top"} title="Sucursales">
					<IconButton aria-label="Sucursales" onClick={() => this.add()}><Business/></IconButton>
				</Tooltip>*/
			);
		}
		return BTN_ACTION;
	};
	
	edit = (value, key) => {
		console.log(this.props);
		const {id_empresa, item} = this.props;
		
		this.claveUnidad().then((response) => {
			this.setState({
				id_empresa_clave_unidad: item.id_empresa_clave_unidad || ''
			});
		});
		
			this.setState({
				id_empresa: id_empresa || '',
				id_empresa_clave_producto: item.id_empresa_clave_producto || '',
				id_cat_clave_producto_servicio: item.id_cat_clave_producto_servicio,
				clave_producto_servicio: item.descripcion ? item.clave_producto_servicio + ' - ' + item.descripcion : item.clave_producto_servicio,
				concepto: item.concepto || '',
				precio_unitario: item.precio_unitario || '',
				tipo_descuento: item.tipo_descuento ? item.tipo_descuento.toString() : '1',
				descuento: item.descuento || '',
				numero_identificacion: item.numero_identificacion || '',
				activo: Number(item.activo) ? 1:0,
			});
		
		this.openModal();
		
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open_modal}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{'Producto o Servicio'}</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											required
											name="clave_producto_servicio"
											label="Producto o Servicio"
											type="text"
											fullWidth
											value={this.state.clave_producto_servicio}
											onChange={this.handleChange}
											disabled
										/>
									
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										
										<TextField
											required
											name="concepto"
											label="Concepto"
											type="text"
											fullWidth
											value={this.state.concepto}
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
										/>
									
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										
										<TextField
											required
											name="precio_unitario"
											label="Precio Unitario"
											type="text"
											fullWidth
											value={this.state.precio_unitario}
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											onKeyPress={EnteroSolo}
										/>
									
									</Grid>
									
									<Grid item className={'text-left ticketfrecuencia'} xs={12} sm={12} md={8} lg={8} xl={8}>
										<RadioGroup
											row aria-label="position"
											name="tipo_descuento"
											value={this.state.tipo_descuento}
											onChange={this.handleChange}>
											
											<FormControlLabel
												style={{color: 'gray', fontSize: '13px'}}
												labelPlacement="end"
												control={<Radio color="primary"/>}
												value={"1"}
												label={"Sin descuento"}
												disabled={this.props.tipo === 'view'}
											/>
											<FormControlLabel
												style={{color: 'gray', fontSize: '13px'}}
												labelPlacement="end"
												control={<Radio color="primary"/>}
												value={"2"}
												label={"Porcentaje"}
												disabled={this.props.tipo === 'view'}
											/>
											<FormControlLabel
												style={{color: 'gray', fontSize: '13px'}}
												labelPlacement="end"
												control={<Radio color="primary"/>}
												value={"3"}
												label={"Monto"}
												disabled={this.props.tipo === 'view'}
											/>
										</RadioGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
										
										<TextField
											required={this.state.tipo_descuento === '2' || this.state.tipo_descuento === '3'}
											name="descuento"
											label="Descuento"
											type="text"
											fullWidth
											value={this.state.descuento}
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											onKeyPress={EnteroSolo}
										/>
									
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<FormControl fullWidth required>
											<InputLabel>Unidad de Medida</InputLabel>
											<Select
												native
												value={this.state.id_empresa_clave_unidad}
												onChange={this.handleChange}
												name="id_empresa_clave_unidad"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_clave_unidad.map(option => (
													<option key={option.id_empresa_clave_unidad}
													        value={option.id_empresa_clave_unidad}>
														{option.concepto ? option.clave_unidad + ' - ' + option.concepto : option.clave_unidad}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										
										<TextField
											name="numero_identificacion"
											label="No. de Identificación"
											type="text"
											fullWidth
											value={this.state.numero_identificacion}
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
										/>
									
									</Grid>
								
								</Grid>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.tipo !== 'edit' ? 'Agregar':'Modificar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEmpresaClaveProducto.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	id_empresa: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalEmpresaClaveProducto);
