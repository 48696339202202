import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {FacturacionService} from "../../../../services/FacturacionService/FacturacionService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import Avatar from "@material-ui/core/Avatar";
import {Icono} from "../../../Include/Icono/Icono";
import ModalImage from "../../../Include/ModalImage/ModalImage";
import Box from "@material-ui/core/Box";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";

import lineas_de_texto_svg from '../../../../assets/img/icons/lineas-de-texto.svg'
import {ImageAspectRatioOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {TimbradoFacturacionExtService} from "../../../../services/TimbradoFacturacionExtService/TimbradoFacturacionExtService";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";


class ModalConsultaSat extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			uuid: '',
			rfc_emisor: '',
			rfc_receptor: '',
			total: '',
			estatus: '',
			es_cancelable: '',
			estado: '',
			estatus_cancelacion: ''
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	
	add = () => {
		const {item} = this.props;
		let uuid = '';
		if (FieldsJs.Field(item)) {
			uuid = item.uuid;
		}
		
		this.setState({
			uuid: uuid,
			rfc_receptor: '',
			rfc_emisor: '',
			total: '',
			estatus: '',
			es_cancelable: '',
			estado: '',
			estatus_cancelacion: ''
		}, () => {
			if (FieldsJs.Field(item)) {
				this.ConsultaCFDIProyecto().then((response) => {
					console.log("CONSULTACFDIPROYECTO::: ", response);
					
				}).catch((error) => {
				
				});
			}
		});
		
		this.open();
		
	};
	
	
	ConsultaCFDIProyecto = () => {
		return new Promise((resolve, reject) => {
			TimbradoFacturacionExtService.ConsultaCFDI(this.state).then((res) => {
				console.log("ConsultaCFDI:::::", res);
				let data = res.data;
				this.setState({
					rfc_receptor: data.rfc_receptor,
					rfc_emisor: data.rfc_emisor,
					total: data.total,
				}, () => {
					TimbradoFacturacionExtService.ConsultaSat(this.state).then((response) => {
						console.log("ConsultaSat:::::", response);
						let res = response.data;
						this.setState({
							estatus: res.CodigoEstatus,
							es_cancelable: res.EsCancelable,
							estado: res.Estado,
							estatus_cancelacion: res.EstatusCancelacion
						})
						resolve(response);
					}).catch((error) => {
						
						this.setState({
							estatus: '',
							es_cancelable: '',
							estado: '',
							estatus_cancelacion: ''
						});
						
						this.props.showSnackBars('error', error.mensaje);
						
					});
				})
				//this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.setState({listar_facturacion: []});
				//this.props.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	consultaSat = () => {
		TimbradoFacturacionExtService.ConsultaSat(this.state).then((response) => {
			
			console.log(response);
			let res = response.data;
			this.setState({
				estatus: res.CodigoEstatus,
				es_cancelable: res.EsCancelable,
				estado: res.Estado,
				estatus_cancelacion: res.EstatusCancelacion
			})
			
			if (response.status) {
				TimbradoFacturacionExtService.ConsultaCFDI(this.state).then((response) => {
					
					console.log(response);
					
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		}).catch((error) => {
			
			this.setState({
				estatus: '',
				es_cancelable: '',
				estado: '',
				estatus_cancelacion: ''
			});
			
			this.props.showSnackBars('error', error.mensaje);
			
		});
	}
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'add':
				funcion = this.add;
				break;
			
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
				</Fragment>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Consulta SAT
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={"center"}>
								<Box
									display="flex"
									flexWrap="wrap"
									alignContent="flex-start"
									justifyContent="center"
									alignItems="center"
									p={1}
									m={0}
									css={{
										background: "rgba(194, 236, 255, 0.4)",
										borderRadius: "10px",
										borderStyle: "dashed",
										borderWidth: "1px !important",
										borderColor: "#3f51b5",
									}}
								>
									
									<Grid container spacing={1} alignItems={"flex-end"}>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField
												type={'text'}
												fullWidth
												name="uuid"
												onChange={this.handleChange}
												label="UUID"
												autoComplete={'off'}
												value={this.state.uuid}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
											<TextField
												type={'text'}
												fullWidth
												name="rfc_emisor"
												onChange={this.handleChange}
												label="RFC Emisor" autoComplete={'off'}
												value={this.state.rfc_emisor}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
											<TextField
												type={'text'}
												fullWidth
												name="rfc_receptor"
												onChange={this.handleChange}
												label="RFC Receptor" autoComplete={'off'}
												value={this.state.rfc_receptor}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
											<TextField
												type={'text'}
												fullWidth
												name="total"
												onChange={this.handleChange}
												label="Total" autoComplete={'off'}
												//inputProps={{maxLength: 5}}
												onKeyPress={EnteroSolo}
												value={this.state.total}
											/>
										</Grid>
									
									</Grid>
								</Box>
								
								<Table className={' desing-mark'}>
									<TableHead>
										<TableRow>
											<TableCell component="th" align={'left'}>Estatus</TableCell>
											<TableCell component="th" align={'left'}>Es Cancelable</TableCell>
											<TableCell component="th" align={'left'}>Estado</TableCell>
											<TableCell component="th" align={'left'}>Estatus Cancelacion</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell align={'left'}>{this.state.estatus}</TableCell>
											<TableCell align={'left'}>{this.state.es_cancelable}</TableCell>
											<TableCell align={'left'}>{this.state.estado}</TableCell>
											<TableCell align={'left'}>{this.state.estatus_cancelacion}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
								
								
								<VistaVacia
									numero={null}
									mensaje={'Consulta tu factura directamente ante el SAT'}
									image={lineas_de_texto_svg}
									padding={'10px'}
									paddingText={'10px'}
									height={'50px'}
									width={'50px'}
								/>
							
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							{!FieldsJs.Field(this.props.item) ? (
								<Grid item xs={6} align={'right'}>
									<Button onClick={() => this.consultaSat()} color="primary" variant={"contained"}>
										Consultar
									</Button>
								</Grid>
							) : null}
						
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ModalConsultaSat.propTypes = {
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	componente: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalConsultaSat;
