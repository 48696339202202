import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const ModuloService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				id_cat_proyecto: filtro.id_cat_proyecto || null,
				id_cat_proyecto_modulo: filtro.id_cat_proyecto_modulo || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Proyecto_Modulo_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cat_proyecto_modulo: form.id_cat_proyecto_modulo,
			id_cat_proyecto: form.id_cat_proyecto,
			proyecto_modulo: form.proyecto_modulo,
			descripcion: form.descripcion,
			abreviatura: form.abreviatura,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Proyecto_Modulo_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_proyecto_modulo: form.id_cat_proyecto_modulo,
			id_cat_proyecto: form.id_cat_proyecto,
			proyecto_modulo: form.proyecto_modulo,
			descripcion: form.descripcion,
			abreviatura: form.abreviatura,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Proyecto_Modulo_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_proyecto_modulo) => {
		let params = {
			id_cat_proyecto_modulo: id_cat_proyecto_modulo
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Proyecto_Modulo_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
