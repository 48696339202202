import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

class EmpresaFacturacionService {
	static Listar = (filtro, paginacion) => {
		let params = {
			filtro: {
				nombre_comercial: filtro.nombre_comercial || null,
				razon_social: filtro.razon_social || null,
				rfc: filtro.rfc || null,
				correo_electronico: filtro.correo_electronico || null,
				estatus: filtro.estatus || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Cliente_Receptora_Pago_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Agregar = (form) => {
		let params = {
			id_empresa: null,
			id_cliente: form.id_cliente,
			nombre_comercial: form.nombre_comercial,
			razon_social: form.razon_social,
			rfc: form.rfc,
			logo_archivo: form.logo_archivo,
			logo_formato: form.logo_formato,
			correo_electronico: form.correo_electronico,
			telefono: form.telefono,
			pagina_web: form.pagina_web,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Cliente_Receptora_Pago_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Modificar = (form) => {
		let params = {
			id_empresa: form.id_empresa,
			id_cliente: form.id_cliente,
			nombre_comercial: form.nombre_comercial,
			razon_social: form.razon_social,
			rfc: form.rfc,
			logo_archivo: form.logo_archivo,
			logo_formato: form.logo_formato,
			correo_electronico: form.correo_electronico,
			telefono: form.telefono,
			pagina_web: form.pagina_web,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Cliente_Receptora_Pago_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Eliminar = (id_empresa) => {
		let params = {
			id_empresa: id_empresa
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Cliente_Receptora_Pago_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Archivo = (form) => {
		let fecha_emision = form.fecha_emision ? DateFormat.FormatSql(form.fecha_emision) : null;
		let fecha_apertura = form.fecha_apertura ? DateFormat.FormatSql(form.fecha_apertura) : null;
		
		let params = {
			tipo_archivo: form.tipo_archivo,
			id_empresa: form.id_empresa,
			fecha_emision: fecha_emision,
			fecha_apertura: fecha_apertura,
			observaciones: form.observaciones,
			archivos: form.archivos
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Cliente_Receptora_Pago_Archivo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
}

export default EmpresaFacturacionService;
