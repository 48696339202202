import React, {Component, Fragment} from 'react';

import Typography from "@material-ui/core/Typography/index";
import Sync from '@material-ui/icons/Sync';

import {TimbradoJsonService} from '../../../services/TimbradoJsonService/TimbradoJsonService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {EnteroSolo, FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Zoom from '@material-ui/core/Zoom/index';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab/index";
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import carrito_de_compra_1 from "../../../assets/img/icons/facturacion.svg";
import Paginacion from "../../Include/Paginacion/Paginacion";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {CONFIG} from "../../../settings/Config/Config";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import {AddOutlined, BarChart, DeleteOutlined, Edit, PlaylistAddOutlined, Visibility} from "@material-ui/icons";
import {DropboxApi} from "../../../settings/Dropbox/DropboxApi";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import Grid from "@material-ui/core/Grid";
import TabContext from "@material-ui/lab/TabContext";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import {TabPanel} from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import carrito_de_compra_3 from "../../../assets/img/icons/facturacion.svg";
import {Card} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import icon_mas from "../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../assets/img/icons/expandir-menos.svg";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {array} from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DropzoneArea} from "material-ui-dropzone";
import {FileBase64} from "../../../settings/FileBase64/FileBase64";

class TimbradoJson extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		let ids_expandido = ReactLocalStorageService.get('ids_expandido');
		
		this.state = {
			id_cat_clave_producto_servicio: null,
			id_cat_tipo_comprobante: '',
			
			numero_identificacion: '',
			descripcion_concepto: '',
			cantidad: 1.0,
			precio_unitario: '',
			total: '',
			iva_incluido: false,
			iva_aplicado: false,
			
			listar_cliente: [],
			listar_empresa: [],
			listar_empresa_sucursal: [],
			listar_empresa_regimen_fiscal: [],
			
			listar_forma_pago: [],
			listar_clave_producto_servicio: [],
			listar_clave_unidad_medida: [],
			listar_tipo_comprobante: [],
			
			listar_metodo_pago: [],
			listar_periodo_pago: [],
			listar_cat_impuesto: [],
			listar_cat_tasa: [],
			listar_concepto: [],
			listar_unidad_medida: [],
			
			listar_cat_impuestos: [],
			listar_cat_impuestos_retenidos: [],
			listar_cat_impuestos_trasladados: [],
			listar_cat_tipo_factor: [],
			listar_cat_tipo_cfdi: [],
			listar_cat_uso_cfdi: [],
			listar_cat_metodo_pago: [],
			listar_cat_forma_pago: [],
			listar_cat_moneda: [],
			listar_cat_objeto_impuesto: [],
			listar_empresa_sucursal_serie: [],
			
			
			id_empresa: '',
			nombre_razon_social: '',
			id_cliente: '',
			id_empresa_sucursal: '',
			id_cat_regimen_fiscal: '',
			lugar_expedicion: '',
			cliente_regimen_fiscal: '',
			cliente_numero_registro_if: '',
			cliente_codigo_postal: '',
			id_empresa_regimen_fiscal: '',
			id_cat_uso_cfdi: 3,
			
			id_cat_tipo_cfdi: '',
			id_cat_metodo_pago: 1,
			id_cat_forma_pago: '',
			id_cat_moneda: '',
			fecha: moment(new Date()).format('DD/MM/YYYY hh:mm:ss a').toString(),
			folio: '',
			condiciones_pago: '',
			clave_confirmacion: '',
			tipo_cambio: '1',
			id_empresa_sucursal_serie: '1',
			serie: '',
			
			id_cat_objeto_impuesto: '',
			
			concepto: '',
			clave_concepto: '',
			tipo_descuento: '1',
			descuento: '',
			id_empresa_clave_unidad: '',
			numero_pedimento: '',
			numero_predial: '',
			
			id_cat_impuesto_trasladado: '',
			id_cat_tipo_factor_trasladado: '',
			id_cat_impuesto: '',
			impuestos_traladados: [],
			
			id_cat_impuesto_retenido: '',
			id_cat_tipo_factor_retenido: '',
			tasa_cuota_retenido: '',
			impuestos_retenidos: [],
			
			partidas: [],
			
			cat_regimen_fiscal: [],
			valuetab: '1',
			
			desglose: '',
			archivo_xml: '',
			open_modal_xml: false,
			
			rfc_emisor: '',
			sucursal_emisor: '',
			certificado_emisor: '',
			observaciones_emisor: '',
			enviar_a: '',
			enviar_cc: '',
			enviar_cco: '',
			
			produccion: false,
			autocomplete_concepto: {},
			
			files: [],
			base64Tipo: '',
			base64: '',
			archivo: '',
			formato: '',
			
		};
		
		this.RefreshList();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		//this.Listar();
	};
	
	Listar = () => {
		TimbradoJsonService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			let listar_facturacion = response.data;
			
			this.setState({
				listar_facturacion: listar_facturacion,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
		}).catch((error) => {
			
			this.setState({listar_facturacion: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	timbradoFacturacion = () => {
		try {
			if (!this.state.json) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Json.'
				})
			}
			
			if (!this.state.enviar_a) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Enviar a.'
				})
			}
			TimbradoJsonService.Guardar(this.state).then((response) => {
				let msg = `${response.data.code} - ${response.data.message}`;
				PopupService.Confirm(['Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
					if (r.button === 'Aceptar') {
					
					}
				});
				//this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.setState({listar_facturacion: []});
				this.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			console.error(e);
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	timbradoXmlPdf = () => {
		try {
			if (!FieldsJs.Field(this.state.files)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Json.'
				})
			}
			
			TimbradoJsonService.XmlPdf(this.state).then((response) => {
				this.exportar(response.data, 'PDF');
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			console.error(e);
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	timbradoNominExcel = () => {
		try {
			if (!FieldsJs.Field(this.state.files)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Json.'
				})
			}
			
			TimbradoJsonService.ExcelNomina(this.state).then((response) => {
				this.exportar(response.data, 'PDF');
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			console.error(e);
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
		}
	};
	
	save = () => {
		try {
			if (!this.state.json) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Json.'
				})
			}
			
			if (!this.state.enviar_a) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Enviar a.'
				})
			}
			
			try {
				TimbradoJsonService.Guardar(this.state).then((response) => {
					this.closeModal();
					this.props.RefreshList();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			} catch (e) {
				this.showSnackBars('error', e.mensaje);
			}
		} catch (e) {
			console.error(e);
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		let formatos = ["text/xml", ".csv", "text/csv", "application/xlsx", "application/xls", "application/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
		let fil = {files};
		FileBase64.Base64(fil, formatos, this.props.MegaByte || null, true).then((response) => {
			this.handleChange({
				target: {
					name: 'base64',
					value: response.base64
				}
			});
			this.handleChange({
				target: {
					name: 'base64Tipo',
					value: response.base64Tipo
				}
			});
			this.handleChange({
				target: {
					name: 'archivo',
					value: response.archivo
				}
			});
			this.handleChange({
				target: {
					name: 'formato',
					value: response.formato
				}
			});
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	exportar = (link, tipo) => {
		if (tipo === 'PDF') {
			try {
				let params = {
					archivo: tipo,
					archivo_xml: link
				};
				HttpRequest.exportFacturacion('xmltopdfn', ['xmltopdfn'], params, false, false, false, true);
			} catch (e) {
				this.showSnackBars('error', e.mensaje);
			}
		} else {
			let url = CONFIG.src_facturacion + link;
			window.open(url, '', 'width=900, height=600');
		}
	};
	
	
	render() {
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		
		return (
			<div className='Catalogos ContaineViewComponet timbrado_facturacion'>
				<div className={'row-flex margin-30-T margin-30-B'}>
					<div className={'w-100-250-px'}>
						<Typography variant={'h5'} className={'text-left'}>
							Timbrado Json
						</Typography>
					</div>
					<div className={'w-250-px text-right vertical-inline'}>
						{/*{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
							<FormGroup row className={'margin-3-L'}>
								<FormControlLabel
									control={
										<Checkbox type="checkbox" name='produccion'
										          checked={this.state.produccion}
										          onChange={this.handleChange} value="produccion"
										          color="primary"
										          disabled={this.props.tipo === 'view'}/>
									}
									label={'Para Producción:'}
								/>
							</FormGroup>
						) : null}*/}
					</div>
				</div>
				
				<div className={'form margin-20'}>
					<div className={'w-100'} align={'center'}>
						
						<Card style={{maxWidth: '1400px', width: '100%'}} className={'padding-15'}>
							
							<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									
									<Grid container spacing={2} alignContent={"flex-end"}>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
											<Typography className={'text-center px-16'} style={{opacity: 0.8, color: '#3f51b5', fontWeight: 'bold'}}>
												Ingresa el Json que deseas Timbrar:
											</Typography>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											
											<TextField
												className={'jsontext text-left'}
												multiline
												name="json"
												type="text"
												fullWidth
												rows={20}
												value={this.state.json}
												onChange={this.handleChange}
											/>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											
											<fieldset className={'borde-punteado-gray-2 text-left'}>
												<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Dactos de envío</legend>
												
												<Grid container spacing={2}>
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														
														<TextField
															multiline
															name="observaciones"
															label="Observaciones"
															type="text"
															fullWidth
															rows={3}
															value={this.state.observaciones}
															onChange={this.handleChange}
														/>
													
													</Grid>
													
													<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
														<TextField
															required
															name="enviar_a"
															label="Enviar a: "
															type="text"
															fullWidth
															value={this.state.enviar_a}
															onChange={this.handleChange}
														/>
													</Grid>
													
													<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
														<TextField
															name="enviar_cc"
															label="Cc:"
															type="text"
															fullWidth
															value={this.state.enviar_cc}
															onChange={this.handleChange}
														/>
													
													</Grid>
													
													<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
														<TextField
															name="enviar_cco"
															label="Cco:"
															type="text"
															fullWidth
															value={this.state.enviar_cco}
															onChange={this.handleChange}
														/>
													
													</Grid>
												
												</Grid>
											
											</fieldset>
										
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
											<div className={'text-right'}>
												<div className={'vertical-inline'}>
													<Button className={'v-center'} color="primary" size={'small'} variant="contained" onClick={() => this.timbradoFacturacion()}>
														{'Generar Timbrado Json'}
													</Button>
												</div>
											</div>
											<Divider style={{marginTop: "10px", marginBottom: "15px"}}/>
										</Grid>
										
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											
											<DropzoneArea
												onChange={this.handleChangeDropzoneArea.bind(this)}
												open={true}
												filesLimit={1}
												dropzoneText={'Arrastre y suelte un archivo XML aquí o haga clic'}
												acceptedFiles={["text/xml", ".csv", "text/csv", "application/xlsx", "application/xls", "application/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"]}
												showPreviews={false}
												maxFileSize={5000000}
												showAlerts={false}
												showFileNames={true}
											/>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6} align={'center'}>
											<div className={'text-left'}>
												<div className={'vertical-inline'}>
													<Button className={'v-center'} color="primary" size={'small'} variant="contained" onClick={() => this.timbradoNominExcel()}>
														{'Subir Excel Nomina'}
													</Button>
												</div>
											</div>
										</Grid>
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6} align={'center'}>
											<div className={'text-right'}>
												<div className={'vertical-inline'}>
													<Button className={'v-center'} color="primary" size={'small'} variant="contained" onClick={() => this.timbradoXmlPdf()}>
														{'Generar PDF'}
													</Button>
												</div>
											</div>
										</Grid>
									
									</Grid>
								</Grid>
							</Grid>
						</Card>
					</div>
				</div>
			
			</div>
		);
	}
}

TimbradoJson.propTypes = {};

export default IntegrationNotistack(TimbradoJson);
