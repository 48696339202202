import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, BotonAccionFooterModal} from "../../../Include/MiniComponents/GlobalComponent";
import {DropzoneArea} from 'material-ui-dropzone';
import {CONFIG} from "../../../../settings/Config/Config";
import {Dropbox} from 'dropbox';
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {CargaCFDIService} from "../../../../services/CargaCFDIService/CargaCFDIService";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import utf8 from "utf8";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";


class ModalPagoCFDI extends Component {
	
	state = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			id_facturacion_cfdi: (props.item || {}).id_facturacion_cfdi,
			uuid: (props.item || {}).uuid,
			files: [],
			id_cliente: '',
			archivos: [],
			listar_cat_cliente: [],
			jsonXML: '',
			
			tipo_pago: false,
			fecha_pago: null,
		};
	}
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
		
		let xmls = [];
		
		
		if (files.length > 0) {
			
			for (let i = 0; i < files.length; i++) {
				if (files[i].type.split("/")[1] === 'xml') {
					FileBase64.Base64XmlToJson(files[i]).then((response) => {
						console.log(this.searchXmlToJson(response, 'pago10:DoctoRelacionado', 'IdDocumento'), 'STATE::: ', this.state.uuid,
							this.searchXmlToJson(response, 'pago10:DoctoRelacionado', 'IdDocumento') === this.state.uuid);
						
						try {
							if (this.searchXmlToJson(response, 'pago10:DoctoRelacionado', 'IdDocumento') !== this.state.uuid) {
								throw Object({
									status: false, mensaje: 'No existe relación entre el CFDI tipo ingreso y el REP.'
								})
							}
							
							let xmljson = {
								rfc_emisor: this.searchXmlToJson(response, 'cfdi:Emisor', 'Rfc') || '',
								id_documento: this.searchXmlToJson(response, 'pago10:DoctoRelacionado', 'IdDocumento' || ''),
								empresa_facturadora: this.searchXmlToJson(response, 'cfdi:Emisor', 'Nombre' || ''),
								rfc_receptor: this.searchXmlToJson(response, 'cfdi:Receptor', 'Rfc' || ''),
								cliente: this.searchXmlToJson(response, 'cfdi:Receptor', 'Nombre' || ''),
								iva: this.searchXmlToJson(response, 'cfdi:Impuestos', 'TotalImpuestosTrasladados' || ''),
								total_facturado: this.searchXmlToJson(response, 'cfdi:Emisor', 'Rfc' || ''),
								concepto_facturacion: this.searchXmlToJson(response, 'cfdi:Concepto', 'Descripcion' || ''),
								clave_unidad: this.searchXmlToJson(response, 'cfdi:Concepto', 'ClaveUnidad' || ''),
								clave_concepto: this.searchXmlToJson(response, 'cfdi:Concepto', 'ClaveProdServ' || ''),
								
								version: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Version' || ''),
								serie: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Serie' || ''),
								folio: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Folio' || ''),
								fecha: this.searchXmlToJson(response, 'pago10:Pago', 'FechaPago') ? DateFormat.FormatSql(moment(this.searchXmlToJson(response, 'pago10:Pago', 'FechaPago'))) : '',
								sello: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Sello' || ''),
								forma_pago: this.searchXmlToJson(response, 'pago10:Pago', 'FormaDePagoP' || ''),
								numero_certificado: this.searchXmlToJson(response, 'cfdi:Comprobante', 'NoCertificado' || ''),
								certificado: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Certificado' || ''),
								subtotal: this.searchXmlToJson(response, 'cfdi:Comprobante', 'SubTotal' || ''),
								moneda: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Moneda' || ''),
								tipo_cambio: this.searchXmlToJson(response, 'cfdi:Comprobante', 'TipoCambio' || ''),
								total: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Total' || ''),
								tipo_comprobante: this.searchXmlToJson(response, 'cfdi:Comprobante', 'TipoDeComprobante' || ''),
								metodo_pago: this.searchXmlToJson(response, 'pago10:DoctoRelacionado', 'MetodoDePagoDR' || ''),
								lugar_expedicion: this.searchXmlToJson(response, 'cfdi:Comprobante', 'LugarExpedicion' || ''),
								
								numero_parcialidad: this.searchXmlToJson(response, 'pago10:DoctoRelacionado', 'NumParcialidad' || ''),
								importe_pagado: this.searchXmlToJson(response, 'pago10:DoctoRelacionado', 'ImpPagado' || ''),
								importe_saldo_insoluto: this.searchXmlToJson(response, 'pago10:DoctoRelacionado', 'ImpSaldoInsoluto' || ''),
								importe_saldo_anterior: this.searchXmlToJson(response, 'pago10:DoctoRelacionado', 'ImpSaldoAnt' || ''),
								banco: this.searchXmlToJson(response, 'pago10:Pago', 'NomBancoOrdExt' || ''),
								uuid: this.searchXmlToJson(response, 'tfd:TimbreFiscalDigital', 'UUID') || '',
								nombre_archivo: files[i].name,
							};
							
							xmls.push(xmljson);
							
							console.log("JSONXML :::: ", xmls);
							
							
							this.setState({jsonXML: xmls});
						} catch (e) {
							console.error(e);
							
							this.setState({
								files: '',
								jsonXML: ''
							});
							
							this.props.showSnackBars('error', e.mensaje);
						}
					}).catch((error) => {
						this.setState({jsonXML: ''});
					})
				}
			}
			
		} else {
			this.setState({jsonXML: ''});
		}
		
	};
	
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		const item = this.props.item;
		
		this.setState({
			id_facturacion_cfdi: item.id_facturacion_cfdi || '',
			uuid: item.uuid || ''
		});
		
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	existCompany = (company, listcompany) => {
		
		let comp = false;
		listcompany.map((item, key) => {
			let name = item.name.split('-');
			console.log(name);
			if (company === name[0]) {
				comp = item.name
			}
		});
		
		return comp;
	};
	
	guardarDocTimbradoDbx = () => {
		let ftar = this.state.files;
		console.log("1 FTAR::: ", ftar);
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		let files;
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i]) === true) {
					band++;
				}
			}
			//band = this.state.jsonXML.length;
			
			let archives = [];
			
			let years_fechaxml = moment(this.state.jsonXML[0].fecha).get('years');
			let month_fechaxml = moment(this.state.jsonXML[0].fecha).get('month');
			month_fechaxml = month_fechaxml + 1;
			if (month_fechaxml < 10) {
				month_fechaxml = '0' + month_fechaxml;
			}
			
			
			for (let i = 0; i < ftar.length; i++) {
				console.log("2 FTAR::: ", ftar);
				files = ftar[i];
				
				let empresa = this.existCompany(this.state.jsonXML[0].rfc_emisor, this.props.listDropbox);
				let ruta = '';
				if (!empresa) {
					ruta = '/' + this.state.jsonXML[0].rfc_emisor + '-' + this.state.jsonXML[0].empresa_facturadora
						+ '/facturacion/' + years_fechaxml + '/' + month_fechaxml + '/' +
						files.name;
				} else {
					ruta = '/' + empresa + '/facturacion/' + years_fechaxml + '/' + month_fechaxml + '/' +
						files.name;
				}
				
				DropboxApi.Upload(ruta, files).then(function (response) {
					archives.push(response.path_display);
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	
	searchXmlToJson = (json, name, elemento) => {
		let valor = '';
		if (json.hasOwnProperty('elements')) {
			for (let a = 0; a < json.elements.length; a++) {
				if (json.elements[a].hasOwnProperty('name') && json.elements[a].hasOwnProperty('attributes')) {
					if (json.elements[a].name === name) {
						valor = json.elements[a].attributes[elemento];
					}
				}
				if (json.elements[a].hasOwnProperty('elements')) {
					for (let b = 0; b < json.elements[a].elements.length; b++) {
						if (json.elements[a].elements[b].hasOwnProperty('name') && json.elements[a].elements[b].hasOwnProperty('attributes')) {
							if (json.elements[a].elements[b].name === name) {
								valor = json.elements[a].elements[b].attributes[elemento];
							}
						}
						if (json.elements[a].elements[b].hasOwnProperty('elements')) {
							for (let c = 0; c < json.elements[a].elements[b].elements.length; c++) {
								if (json.elements[a].elements[b].elements[c].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].hasOwnProperty('attributes')) {
									if (json.elements[a].elements[b].elements[c].name === name) {
										valor = json.elements[a].elements[b].elements[c].attributes[elemento];
									}
								}
								if (json.elements[a].elements[b].elements[c].hasOwnProperty('elements')) {
									for (let d = 0; d < json.elements[a].elements[b].elements[c].elements.length; d++) {
										if (json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('attributes')) {
											if (json.elements[a].elements[b].elements[c].elements[d].name === name) {
												valor = json.elements[a].elements[b].elements[c].elements[d].attributes[elemento];
											}
										}
										if (json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('elements')) {
											for (let f = 0; f < json.elements[a].elements[b].elements[c].elements[d].elements.length; f++) {
												if (json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('attributes')) {
													if (json.elements[a].elements[b].elements[c].elements[d].elements[f].name === name) {
														valor = json.elements[a].elements[b].elements[c].elements[d].elements[f].attributes[elemento];
													}
												}
												if (json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('elements')) {
													for (let g = 0; g < json.elements[a].elements[b].elements[c].elements[d].elements[f].elements.length; g++) {
														if (json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].hasOwnProperty('attributes')) {
															if (json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].name === name) {
																valor = json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].attributes[elemento];
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		
		if (name === 'cfdi:Emisor' && elemento === 'Nombre' ||
			name === 'cfdi:Receptor' && elemento === 'Nombre')
			valor = utf8.decode(valor);
		
		return valor;
	};
	
	
	save = () => {
		console.log("THIS.STATE: ", this.state);
		
		try {
			
			if (!this.state.tipo_pago && this.state.files.length <= 0) {
				throw Object({
					status: false, mensaje: 'Agrega un archivo.'
				})
			}
			
			if (!this.state.tipo_pago) {
				let guardar = new Promise((resolve, reject) => {
					this.guardarDocTimbradoDbx().then(response => {
						console.log("GUARDARFICHATECNICAARCHIVOELABORACIONPROPUESTADBX::: ", response);
						this.setState({
							archivos: response
						});
						resolve(response);
						console.log("DROPBOX UPLOAD: ", this.state);
						
					}).catch(error => {
						reject(error);
					})
				});
				
				guardar.then(response => {
					CargaCFDIService.Pagar(this.state).then((response) => {
						this.closeModal();
						this.props.RefreshList();
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				})
			} else {
				CargaCFDIService.Pagar(this.state).then((response) => {
					this.closeModal();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	add = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_facturacion_cfdi: item.id_facturacion_cfdi || '',
			activo: true,
			jsonXML: '',
			uuid: item.uuid || '',
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			//abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	getBase64 = (e, dropzone) => {
		return new Promise((resolve) => {
			const accessToken = CONFIG.DROPBOX_TOKEN;
			let files;
			if (dropzone) {
				files = e;
			} else {
				console.log(e.target);
				files = e.target.files;
			}
			
			const dbx = new Dropbox({
				accessToken,
				fetch
			});
			console.log("DROPBOX: ", dbx, files[0], files[0].name);
			dbx.filesUpload({path: '/' + files[0].name, contents: files[0]})
				.then(function (response) {
					console.log("DROPBOX UPLOAD: ", response);
					resolve(true);
				})
				.catch(function (error) {
					console.error(error);
				});
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	render() {
		
		const filterOptions = createFilterOptions({
			matchFrom: 'start',
			stringify: option => option.rfc,
		});
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						{/*<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Carga de CFDI´s'}
						/>*/} Pagar CFDI
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'margin-15-B'} style={{color: 'gray', opacity: '0.8'}}>
									Selecciona el archivo XML de los CFDI.
								</Typography>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'margin-15-B'}>
									<Button variant="contained" style={{marginRight: '20px', padding: '10px 25px', color: !this.state.tipo_pago ? 'white' : 'black', backgroundColor: !this.state.tipo_pago ? '#3f51b5' : '#e0e0e0'}} onClick={() => {
										this.setState({
											tipo_pago: false
										})
									}}>
										REP
									</Button>
									<Button variant="contained" style={{padding: '10px 25px', color: this.state.tipo_pago ? 'white' : 'black', backgroundColor: this.state.tipo_pago ? '#3f51b5' : '#e0e0e0'}} onClick={() => {
										this.setState({
											tipo_pago: true
										})
									}}>
										PUE
									</Button>
								</div>
							</Grid>
							
							{this.state.tipo_pago ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<KeyboardDateTimePicker
										format="yyyy/MM/dd hh:mm a"
										fullWidth
										required
										clearable
										inputProps={{readOnly: true}}
										KeyboardButtonProps={{
											'aria-label': 'Fecha de pago',
										}}
										label="Fecha de pago"
										value={this.state.fecha_pago}
										onChange={(date) => {
											this.handleChange(null, null, null, date, 'fecha_pago');
										}}
										// minDate={moment(new Date())}
										clearLabel={'Limpiar'}
										okLabel={'Aceptar'}
										cancelLabel={'Cancelar'}
										onError={console.log}
										
										disabled={this.is_view()}
									/>
								
								</Grid>
							) : (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<DropzoneArea
										onChange={this.handleChangeDropzoneArea.bind(this)}
										open={this.state.open}
										filesLimit={2}
										dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
										acceptedFiles={['text/xml', 'application/pdf']}
										showPreviews={false}
										maxFileSize={5000000}
										showAlerts={false}
										showFileNames={true}
										showFileNamesInPreview={true}
									/>
								
								</Grid>
							)}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<br/>
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_sexo}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPagoCFDI.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	listDropbox: PropTypes.array,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalPagoCFDI;
