import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {DropzoneArea} from 'material-ui-dropzone';
import {CONFIG} from "../../../../settings/Config/Config";
import {Dropbox} from 'dropbox';
import {ClienteService} from "../../../../services/ClienteService/ClienteService";
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {CargaCFDINominaService} from "../../../../services/CargaCFDINominaService/CargaCFDINominaService";
import DivSvg from "../../../Include/MiniComponents/DivSvg";
import Typography from "@material-ui/core/Typography";
import img_lista_vacia from '../../../../assets/img/logo_acresco.svg'
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import moment from "moment";
import utf8 from "utf8";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";


class ModalCargaCFDINominaView extends Component {
	
	state = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			files: [],
			patch: '/prueba1/',
			select_cliente: '',
			
			archivos: [],
			
			listar_cat_cliente: [],
			
			jsonXml: ''
		};
	}
	
	
	handleChange(files) {
		
		this.setState({
			files: files[0]
		});
	}
	
	
	searchXmlToJson = (json, name, elemento) => {
		let valor = '';
		let percepciones = [];
		let deducciones = [];
		if (json.hasOwnProperty('elements')) {
			for (let a = 0; a < json.elements.length; a++) {
				if (json.elements[a].hasOwnProperty('name') && json.elements[a].hasOwnProperty('attributes')) {
					if (json.elements[a].name === name) {
						valor = json.elements[a].attributes[elemento];
					}
				}
				if (json.elements[a].hasOwnProperty('elements')) {
					for (let b = 0; b < json.elements[a].elements.length; b++) {
						if (json.elements[a].elements[b].hasOwnProperty('name') && json.elements[a].elements[b].hasOwnProperty('attributes')) {
							if (json.elements[a].elements[b].name === name) {
								valor = json.elements[a].elements[b].attributes[elemento];
							}
						}
						if (json.elements[a].elements[b].hasOwnProperty('elements')) {
							for (let c = 0; c < json.elements[a].elements[b].elements.length; c++) {
								if (json.elements[a].elements[b].elements[c].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].hasOwnProperty('attributes')) {
									if (json.elements[a].elements[b].elements[c].name === name) {
										valor = json.elements[a].elements[b].elements[c].attributes[elemento];
									}
								}
								if (json.elements[a].elements[b].elements[c].hasOwnProperty('elements')) {
									for (let d = 0; d < json.elements[a].elements[b].elements[c].elements.length; d++) {
										if (json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('attributes')) {
											if (json.elements[a].elements[b].elements[c].elements[d].name === name) {
												valor = json.elements[a].elements[b].elements[c].elements[d].attributes[elemento];
											}
										}
										if (json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('elements')) {
											for (let f = 0; f < json.elements[a].elements[b].elements[c].elements[d].elements.length; f++) {
												if (json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('attributes')) {
													if (json.elements[a].elements[b].elements[c].elements[d].elements[f].name === name) {
														valor = json.elements[a].elements[b].elements[c].elements[d].elements[f].attributes[elemento];
														if (name === "nomina12:Percepcion" && elemento === "TipoPercepcion") {
															percepciones.push(json.elements[a].elements[b].elements[c].elements[d].elements[f].attributes);
															console.log("HOLA MUNDOOOOOOOOOOO");
															valor = percepciones;
														}
														if (name === "nomina12:Deduccion" && elemento === "TipoDeduccion") {
															deducciones.push(json.elements[a].elements[b].elements[c].elements[d].elements[f].attributes);
															console.log("HOLA MUNDOOOOOOOOOOO");
															valor = deducciones;
														}
													}
												}
												if (json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('elements')) {
													for (let g = 0; g < json.elements[a].elements[b].elements[c].elements[d].elements[f].elements.length; g++) {
														if (json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].hasOwnProperty('attributes')) {
															if (json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].name === name) {
																valor = json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].attributes[elemento];
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		
		//if (typeof valor != 'string') throw new TypeError('parameter ‘valor’ is not a string');
		/*valor = valor.replace(
			/[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g, function (c) {
				var cc = ((c.charCodeAt(0) & 0x0f) << 12) | ((c.charCodeAt(1) & 0x3f) << 6) | (c.charCodeAt(2) & 0x3f);
				return String.fromCharCode(cc);
			}
		).replace(
			/[\u00c0-\u00df][\u0080-\u00bf]/g, function (c) {
				var cc = (c.charCodeAt(0) & 0x1f) << 6 | c.charCodeAt(1) & 0x3f;
				return String.fromCharCode(cc);
			}
		);*/
		
		/*if (/^[\x00-\x7F]*$/.test(valor)) {
			valor = decodeURIComponent(valor);
		} else {
			valor = decodeURIComponent(escape(valor));
		}*/
		
		
		if (name === 'cfdi:Emisor' && elemento === 'Nombre' ||
			name === 'cfdi:Receptor' && elemento === 'Nombre')
			valor = utf8.decode(valor);
		
		return valor;
	};
	
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
		
		let xmls = [];
		
		if (files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				if (files[i].type.split("/")[1] === 'xml') {
					FileBase64.Base64XmlToJson(files[i], true).then((response) => {
						
						let xmljson = {
							emisor_rfc: this.searchXmlToJson(response, 'cfdi:Emisor', 'Rfc' || ''),
							emisor_nombre: this.searchXmlToJson(response, 'cfdi:Emisor', 'Nombre' || ''),
							receptor_rfc: this.searchXmlToJson(response, 'cfdi:Receptor', 'Rfc' || ''),
							receptor_nombre: this.searchXmlToJson(response, 'cfdi:Receptor', 'Nombre' || ''),
							nomina_fecha_pago: this.searchXmlToJson(response, 'nomina12:Nomina', 'FechaPago' || ''),
							nomina_fecha_inicial_pago: this.searchXmlToJson(response, 'nomina12:Nomina', 'FechaInicialPago' || ''),
							nomina_fecha_final_pago: this.searchXmlToJson(response, 'nomina12:Nomina', 'FechaFinalPago' || ''),
							nomina_version: this.searchXmlToJson(response, 'nomina12:Nomina', 'Version' || ''),
							percepcion_total_sueldos: this.searchXmlToJson(response, 'nomina12:Percepciones', 'TotalSueldos' || ''),
							percepcion_total_gravado: this.searchXmlToJson(response, 'nomina12:Percepciones', 'TotalGravado' || ''),
							percepcion_total_exento: this.searchXmlToJson(response, 'nomina12:Percepciones', 'TotalExento' || ''),

							percepciones_total_sueldos: this.searchXmlToJson(response, 'nomina12:Percepciones', 'TotalSueldos' || ''),
							percepciones_total_gravado: this.searchXmlToJson(response, 'nomina12:Percepciones', 'TotalGravado' || ''),
							percepciones_total_exento: this.searchXmlToJson(response, 'nomina12:Percepciones', 'TotalExento' || ''),
							percepciones: this.searchXmlToJson(response, 'nomina12:Percepcion', 'TipoPercepcion' || ''),

							deducciones_total_otras_deducciones: this.searchXmlToJson(response, 'nomina12:Deducciones', 'TotalOtrasDeducciones' || ''),
							deducciones_total_impuestos_retenidos: this.searchXmlToJson(response, 'nomina12:Deducciones', 'TotalImpuestosRetenidos' || ''),
							deducciones: this.searchXmlToJson(response, 'nomina12:Deduccion', 'TipoDeduccion' || ''),
							
							uuid: this.searchXmlToJson(response, 'tfd:TimbreFiscalDigital', 'UUID') || '',
							nombre_archivo: files[i].name
						};
						
						xmls.push(xmljson);
						
						console.log("JSONXML :::: ", xmls);
						
						this.setState({jsonXML: xmls});
						
						
					}).catch((error) => {
						this.setState({jsonXML: ''});
					})
				}
			}
		} else {
			this.setState({jsonXML: ''});
		}
	};
	
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		console.log("OPEN ::: ", this.props.listDropbox);
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			this.ListaClientes();
			resolve(true);
		});
	};
	
	ListaClientes = () => {
		return new Promise((resolve, reject) => {
			ClienteService.ListarTodo().then((response) => {
				this.setState({
					listar_cat_cliente: response.data,
				});
				resolve(response.data);
			}).catch((error) => {
				this.setState({
					listar_cat_cliente: []
				});
				reject(false);
			});
		});
	};
	
	existCompany = (company, listcompany) => {
		
		let comp = false;
		listcompany.map((item, key) => {
			let name = item.name.split('-');
			console.log(name);
			if (company === name[0]) {
				comp = item.name
			}
		});
		
		return comp;
	};
	
	
	guardarDocTimbradoDbx = () => {
		let ftar = this.state.files;
		console.log("1 FTAR::: ", ftar);
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		let files;
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i]) === true) {
					band++;
				}
			}
			let archives = [];
			let years_fechaxml = moment(this.state.jsonXML[0].fecha).get('years');
			let month_fechaxml = moment(this.state.jsonXML[0].fecha).get('month');
			month_fechaxml = month_fechaxml + 1;
			if (month_fechaxml < 10) {
				month_fechaxml = '0' + month_fechaxml;
			}
			
			
			for (let i = 0; i < ftar.length; i++) {
				console.log("2 FTAR::: ", ftar);
				files = ftar[i];
				
				let empresa = this.existCompany(this.state.jsonXML[0].rfc_emisor , this.props.listDropbox);
				let ruta = '';
				if (!empresa) {
					ruta = '/' + this.state.jsonXML[0].rfc_emisor + '-' + this.state.jsonXML[0].empresa_facturadora
						+ '/facturacion/' + years_fechaxml + '/' + month_fechaxml + '/' +
						files.name;
				} else {
					ruta = '/' + empresa + '/facturacion/' + years_fechaxml + '/' + month_fechaxml + '/' +
						files.name;
				}
				
				DropboxApi.Upload(ruta, files).then(function (response) {
					archives.push(response.path_display);
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	
	save = () => {
		console.log("THIS.STATE: ", this.state);
		
		try {
			
			if (this.state.files.length <= 0) {
				throw Object({
					status: false, mensaje: 'Agrega un archivo.'
				})
			}
			
			if (this.state.jsonXML[0].tipo_comprobante !== 'N') {
				throw Object({
					status: false, mensaje: 'XML incorrecto.'
				})
			}
			
			let guardar = new Promise((resolve, reject) => {
				this.guardarDocTimbradoDbx().then(response => {
					console.log("GUARDARFICHATECNICAARCHIVOELABORACIONPROPUESTADBX::: ", response);
					this.setState({
						archivos: response
					});
					resolve(response);
					console.log("DROPBOX UPLOAD: ", this.state);
					
				}).catch(error => {
					reject(error);
				})
			});
			
			guardar.then(response => {
				CargaCFDINominaService.GuardarX(this.state).then((response) => {
					this.closeModal();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			})
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			activo: true,
			jsonXML: ''
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			//abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	getBase64 = (e, dropzone) => {
		return new Promise((resolve) => {
			const accessToken = CONFIG.DROPBOX_TOKEN;
			let files;
			if (dropzone) {
				files = e;
			} else {
				console.log(e.target);
				files = e.target.files;
			}
			
			const dbx = new Dropbox({
				accessToken,
				fetch
			});
			console.log("DROPBOX: ", dbx, files[0], files[0].name);
			dbx.filesUpload({path: '/' + files[0].name, contents: files[0]})
				.then(function (response) {
					console.log("DROPBOX UPLOAD: ", response);
					resolve(true);
				})
				.catch(function (error) {
					console.error(error);
				});
		});
	};
	
	render() {
		
		let image = img_lista_vacia;
		
		const filterOptions = createFilterOptions({
			matchFrom: 'start',
			stringify: option => option.rfc,
		});
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Carga de CFDI´s Nomina'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'margin-15-B'} style={{color: 'gray', opacity: '0.8'}}>
									Solo puedes seleccionar un archivo XML, para visualizar el contenido y poder subirlo posteriormente.
								</Typography>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100'}>
									<DropzoneArea
										onChange={this.handleChangeDropzoneArea.bind(this)}
										open={this.state.open}
										filesLimit={2}
										dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
										acceptedFiles={['text/xml', 'application/pdf']}
										showPreviews={false}
										maxFileSize={5000000}
										showAlerts={false}
										showFileNames={true}
										showFileNamesInPreview={true}
									/>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{this.state.jsonXML ?
									(
										<Grid container spacing={1}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'borde-punteado-gray-2 margin-20-T margin-20-B padding-20'}>
												<Grid container spacing={1}>
													
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Uuid</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].uuid}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>RFC Emisor</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].emisor_rfc}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Nombre Emisor</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].emisor_nombre}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>RFC Receptor</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].receptor_rfc}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Nombre Receptor</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].receptor_nombre}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Fecha Pago</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].nomina_fecha_pago}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Fecha Inicial Pago</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].nomina_fecha_inicial_pago}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Fecha Final Pago</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].nomina_fecha_final_pago}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Versión</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].nomina_version}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Percepcion Total Sueldos</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].percepcion_total_sueldos}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Percepcion Total Gravado</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].percepcion_total_gravado}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Percepcion Total Exento</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].percepcion_total_exento}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Total Otras Ddeducciones</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].deducciones_total_otras_deducciones}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Deducciones Total Impuestos Retenidos</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].deducciones_total_impuestos_retenidos}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Percepciones</h5>
														{this.state.jsonXML[0].percepciones.map((item) => (
															<Fragment>
																<p style={{margin: '0px'}}>{item.TipoPercepcion}</p>
																<p style={{margin: '0px'}}>{item.Clave}</p>
																<p style={{margin: '0px'}}>{item.Concepto}</p>
																<p style={{margin: '0px'}}>{item.ImporteGravado}</p>
																<p style={{margin: '0px'}}>{item.ImporteExento}</p>
															</Fragment>
														))}
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Deducciones</h5>
														{this.state.jsonXML[0].deducciones.map((item) => (
															<Fragment>
																<p style={{margin: '0px'}}>{item.TipoDeduccion}</p>
																<p style={{margin: '0px'}}>{item.Clave}</p>
																<p style={{margin: '0px'}}>{item.Concepto}</p>
																<p style={{margin: '0px'}}>{item.Importe}</p>
															</Fragment>
														))}
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									) : (
										<Fragment>
											<div className={'w-auto'} align={'center'} style={{padding: '20px'}}>
												<DivSvg img={image} height={'100px'} width={'100px'} opacity={true}/>
												<Typography className={'text-center px-16'} style={{opacity: 0.5, padding: "20px", color: 'gray'}}>
													{'No se encontraron datos.'}
												</Typography>
											</div>
										</Fragment>
									)}
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_sexo}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCargaCFDINominaView.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	listDropbox: PropTypes.array,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCargaCFDINominaView;
