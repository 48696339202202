import React, {Component} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import {FacturacionService} from "../../../../services/FacturacionService/FacturacionService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Chart from "react-google-charts";


class ModalEstadisticas extends Component {
	
	state = {};
	
	Usr = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			listar_estadistica_usuario: [],
			listar_estadistica_empresa: [],
			facturas_usuario: [],
			facturas_empresa: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		
		return new Promise((resolve, reject) => {
			FacturacionService.Estadistica().then((response) => {
				this.setState({
					listar_estadistica_usuario: response.data.estadistica_usuario || [],
					listar_estadistica_empresa: response.data.estadistica_empresa || [],
					facturas_usuario: response.data.grafica_usuario || [],
					facturas_empresa: response.data.grafica_empresa || [],
				});
				resolve(true);
			}).catch((error) => {
				this.setState({
					listar_facturacion_estadistica: [],
				});
				this.props.showSnackBars('error', error.mensaje);
				reject(false)
			});
		});
	};
	
	
	calcularTotalProducto = () => {
		setTimeout(() => {
			let cantidad = this.state.cantidad;
			let precio_unitario = this.state.precio_unitario;
			let total = 0;
			
			if (cantidad > 0 && precio_unitario > 0) {
				total = (cantidad * precio_unitario);
			}
			
			this.setState({
				total: total
			});
		});
	};
	
	calcularPrecioUnitarioProducto = () => {
		setTimeout(() => {
			let cantidad = this.state.cantidad || 1;
			let precio_unitario = 0;
			let total = this.state.total;
			
			if (cantidad > 0 && total > 0) {
				if (this.state.iva_aplicado === 0) {
					precio_unitario = (total / cantidad);
				}
			}
			
			this.setState({
				cantidad: cantidad,
				precio_unitario: precio_unitario
			});
		});
	};
	
	calcularTotalCompra = () => {
		setTimeout(() => {
			let lista_facturacion_producto = this.state.lista_facturacion_producto || [];
			let total = 0;
			for (let i = 0; i < lista_facturacion_producto.length; i++) {
				let item = lista_facturacion_producto[i];
				if (Number(item.total) > 0) {
					total += this.calcularTotalConSinIVA(item);
				}
			}
			this.setState({
				facturacion_total: total
			});
		});
	};
	
	calcularSubTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1 && value.iva_aplicado === 0) {
			return value.precio_unitario / 1.16;
		} else {
			return value.precio_unitario;
		}
	};
	
	calcularIVAPrecioUnitario = (value) => {
		if (value.iva_aplicado === 0) {
			if (value.iva_incluido === 1) {
				return value.precio_unitario - (value.precio_unitario / 1.16);
			} else {
				return value.precio_unitario * 0.16;
			}
		} else {
			return 0;
		}
	};
	
	calcularTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1 || value.iva_aplicado === 1) {
			return value.precio_unitario;
		} else {
			return value.precio_unitario * 1.16;
		}
	};
	
	calcularSubTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1 && value.iva_aplicado === 0) {
			return value.total / 1.16;
		} else {
			return value.total;
		}
	};
	
	calcularIVA = (value) => {
		if (value.iva_aplicado === 0) {
			if (value.iva_incluido === 1) {
				return value.total - (value.total / 1.16);
			} else {
				return value.total * 0.16;
			}
		} else {
			return 0;
		}
	};
	
	calcularTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1 || value.iva_aplicado === 1) {
			return value.total;
		} else {
			return value.total * 1.16;
		}
	};
	
	calcularDesgoseTotales = () => {
		let desglose = {
			subtotal: 0,
			iva: 0,
			total: 0,
		};
		let lista_facturacion_producto = this.state.lista_facturacion_producto;
		for (let i = 0; i < lista_facturacion_producto.length; i++) {
			let item = lista_facturacion_producto[i];
			desglose.subtotal += this.calcularSubTotalConSinIVA(item);
			desglose.iva += this.calcularIVA(item);
			desglose.total += this.calcularTotalConSinIVA(item);
		}
		return desglose;
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	
	render() {
		
		const is_root = this.is_root();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.componente}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Estadísticas de Facturación
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<p style={{textAlign: 'left', margin: '25px 0px 10px 0px', fontStyle: 'italic'}}>Facturación por usuario</p>
								<Table className={'desing-facturacion-estadistica'}>
									<TableHead>
										<TableRow>
											<TableCell component="th" align={'left'}>No.</TableCell>
											<TableCell component="th" align={'left'}>Usuario/Solicitante</TableCell>
											<TableCell component="th" align={'left'}>No. CFDI's Solicitados</TableCell>
											<TableCell component="th" align={'left'}>No. CFDI's Timbrados</TableCell>
											<TableCell component="th" align={'left'}>Subtotal</TableCell>
											<TableCell component="th" align={'left'}>IVA (16%)</TableCell>
											{/*<TableCell component="th" align={'left'}>ISR</TableCell>*/}
											<TableCell component="th" align={'left'}>Total</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.state.listar_estadistica_usuario.map((item, key) => (
											<TableRow key={key}>
												<TableCell align={'left'}>{key}</TableCell>
												<TableCell align={'left'}>{item.nombre_completo_solicitante}</TableCell>
												<TableCell align={'left'}>{item.facturas_solicitadas}</TableCell>
												<TableCell align={'left'}>{item.facturas_timbradas}</TableCell>
												<TableCell align={'left'}>${FieldsJs.Currency(item.subtotal)}</TableCell>
												<TableCell align={'left'}>${FieldsJs.Currency(item.iva)}</TableCell>
												{/*<TableCell align={'left'}>{item.isr}</TableCell>*/}
												<TableCell align={'left'}>${FieldsJs.Currency(item.total)}</TableCell>
											</TableRow>
										))}
										
									</TableBody>
								</Table>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{paddingTop: '30px'}}>
								{this.state.facturas_usuario.length > 1 ? (
									<Chart
										width={'500px'}
										height={'300px'}
										chartType="Bar"
										loader={<div>Cargando grafica ...</div>}
										data={
											this.state.facturas_usuario
										}
										options={{
											chart: {
												title: 'Grafica de facturas por usuario',
												// subtitle: 'Sales, Expenses, and Profit: 2014-2017',
											},
											colors: ['#474747', '#0E549D'],
										}}
										rootProps={{ 'data-testid': '2' }}
									/>
								) : null }
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{paddingTop: '30px'}}>
								{this.state.facturas_empresa.length > 1 ? (
									<Chart
										width={'500px'}
										height={'300px'}
										chartType="Bar"
										loader={<div>Cargando grafica ...</div>}
										data={
											this.state.facturas_empresa
										}
										options={{
											chart: {
												title: 'Grafica de facturas por empresa',
												// subtitle: 'Sales, Expenses, and Profit: 2014-2017',
											},
											colors: ['#8e1e32', '#0E549D', '#474747'],
										}}
										rootProps={{ 'data-testid': '2' }}
									/>
								) : null }
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<p style={{textAlign: 'left', margin: '25px 0px 10px 0px', fontStyle: 'italic'}}>Facturación por empresa</p>
								<Table className={'desing-facturacion-estadistica'}>
									<TableHead>
										<TableRow>
											<TableCell component="th" align={'left'}>No.</TableCell>
											<TableCell component="th" align={'left'}>RFC</TableCell>
											<TableCell component="th" align={'left'}>Empresa</TableCell>
											<TableCell component="th" align={'left'}>No. CFDI's Timbrados</TableCell>
											<TableCell component="th" align={'left'}>Subtotal</TableCell>
											<TableCell component="th" align={'left'}>IVA (16%)</TableCell>
											{/*<TableCell component="th" align={'left'}>ISR</TableCell>*/}
											<TableCell component="th" align={'left'}>Total</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.state.listar_estadistica_empresa.map((item, key) => (
											<TableRow>
												<TableCell align={'left'}>{key}</TableCell>
												<TableCell align={'left'}>{item.rfc}</TableCell>
												<TableCell align={'left'}>{item.razon_social}</TableCell>
												<TableCell align={'left'}>{item.facturas_timbradas}</TableCell>
												<TableCell align={'left'}>${FieldsJs.Currency(item.subtotal)}</TableCell>
												<TableCell align={'left'}>${FieldsJs.Currency(item.iva)}</TableCell>
												{/*<TableCell align={'left'}>{item.isr}</TableCell>*/}
												<TableCell align={'left'}>${FieldsJs.Currency(item.total)}</TableCell>
											</TableRow>
										))}
									
									</TableBody>
								</Table>
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEstadisticas.propTypes = {
	componente: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalEstadisticas;
