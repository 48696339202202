import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {CancelPresentation, Description, Message} from "@material-ui/icons";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import ModalPdfCFDI from "./ModalPdfCFDI";
import ModalCancelarCFDINomina from "./ModalCancelarCFDINomina";
import ModalObservacionCFDINomina from "./ModalObservacionCFDINomina";

class ListaTabla extends Component {
	
	Usr = {};
	
	RefreshList = this.props.RefreshList;
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Open(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	render() {
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		
		const {item} = this.props;
		
		return (
			<Fragment>
				<div className={'bg-white card-1'}>
					
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={'px-14 text-center bg-white padding-5'}>
							Datos de las parcialidades
						</Typography>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Table className={'desing-acresco'}>
							<TableHead>
								<TableRow>
									{/*<TableCell component="th" align={'left'}>Concepto de Facturación</TableCell>
									<TableCell component="th" align={'left'}>Clave Unidad</TableCell>
									<TableCell component="th" align={'left'}>Clave Concepto</TableCell>
									<TableCell component="th" align={'left'}>Tipo Comprobante</TableCell>*/}
									<TableCell component="th" align={'left'}>Serie</TableCell>
									<TableCell component="th" align={'left'}>Folio</TableCell>
									<TableCell component="th" align={'left'}>Fecha</TableCell>
									<TableCell component="th" align={'left'}>Saldo Anterior</TableCell>
									<TableCell component="th" align={'left'}>Importe Pagado</TableCell>
									<TableCell component="th" align={'left'}>Saldo Insoluto</TableCell>
									<TableCell component="th" align={'left'}>Forma de Pago</TableCell>
									<TableCell component="th" align={'left'}>Metodo de Pago</TableCell>
									<TableCell component="th" align={'left'}>Banco</TableCell>
									<TableCell component="th" align={'left'}>Número de parcialidad</TableCell>
									<TableCell component="th" align={'center'}>Acciones</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{item.parcialidades.map((item, key) => (
									<Fragment key={key}>
										<TableRow>
											{/*<TableCell align={'left'}>{item.concepto}</TableCell>
											<TableCell align={'left'}>{item.clave_unidad}</TableCell>
											<TableCell align={'left'}>{item.clave_concepto}</TableCell>
											<TableCell align={'left'}>{item.tipo_comprobante}</TableCell>*/}
											<TableCell align={'left'}>{item.serie}</TableCell>
											<TableCell align={'left'}>{item.folio}</TableCell>
											<TableCell align={'left'}>{item.fecha ? DateFormat.getFechaText(item.fecha, '/') : 'Sin registrar'}</TableCell>
											<TableCell align={'left'}>{item.importe_saldo_anterior}</TableCell>
											<TableCell align={'left'}>{item.importe_pagado}</TableCell>
											<TableCell align={'left'}>{item.importe_saldo_insoluto}</TableCell>
											<TableCell align={'left'}>{item.forma_pago}</TableCell>
											<TableCell align={'left'}>{item.metodo_pago}</TableCell>
											<TableCell align={'left'}>{item.banco}</TableCell>
											<TableCell align={'left'}>{item.numero_parcialidad}</TableCell>
											<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
												<div className={'w-auto vertical-inline'}>
													
													<ModalCancelarCFDINomina
														tipo={'add'}
														item={item}
														componente={
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Cancelar">
																<IconButton aria-label="Cancelar" onClick={() => {
																}}>
																	<CancelPresentation style={{color: 'red'}}/>
																</IconButton>
															</Tooltip>
														}
														RefreshList={this.RefreshList}
														showSnackBars={this.showSnackBars}
													/>
													
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver XML">
														<IconButton aria-label="Ver XML" onClick={() => this.exportar(item.archivo)}>
															<Description style={{color: 'orange'}}/>
														</IconButton>
													</Tooltip>
													
													{item.archivo_pdf ? (
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver PDF">
															<IconButton aria-label="Ver PDF" onClick={() => this.exportar(item.archivo_pdf)}>
																<PictureAsPdf style={{color: '#8E1E32'}}/>
															</IconButton>
														</Tooltip>
													) : (
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver PDF">
															<IconButton aria-label="Ver PDF" onClick={() =>
																this.showSnackBars('error', "No se ha agregado el PDF correspondiente ó el CFDI no cuenta con representación impresa.")}>
																<PictureAsPdf style={{color: '#8E1E32'}}/>
															</IconButton>
														</Tooltip>
														/*<ModalPdfCFDI
															tipo={'add'}
															item={item}
															componente={
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver PDF">
																	<IconButton aria-label="Ver PDF" onClick={() => {
																	}}>
																		<PictureAsPdf style={{color: '#8E1E32'}}/>
																	</IconButton>
																</Tooltip>
															}
															RefreshList={this.RefreshList}
															showSnackBars={this.showSnackBars}
														/>*/
													)}
													
													<ModalObservacionCFDINomina
														tipo={'add'}
														item={item}
														componente={
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Observación">
																<IconButton aria-label="Observación" onClick={() => {
																}}>
																	<Message style={{color: item.observacion ? '#3f51b5' : 'rgba(0, 0, 0, 0.54)'}}/>
																</IconButton>
															</Tooltip>
														}
														RefreshList={this.RefreshList}
														showSnackBars={this.showSnackBars}
													/>
												</div>
											</TableCell>
										</TableRow>
									</Fragment>
								
								))}
							</TableBody>
						</Table>
					</Grid>
				</div>
			</Fragment>
		)
	}
}

ListaTabla.propTypes = {
	item: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ListaTabla;
