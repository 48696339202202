import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const AdquisicionService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				adquisicion: filtro.adquisicion || null,
				precio: filtro.precio || null,
				plazo: filtro.plazo || null,
				socio: filtro.socio || null,
				propietario: filtro.propietario || null,
				broker: filtro.broker || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Adquisicion_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarPagos: (form) => {
		let params = {
			id_adquisicion: form.id_adquisicion || null,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Adquisicion_Datos_Pago', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalle: (form) => {
		let params = {
			id_adquisicion: form.id_adquisicion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Adquisicion_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_inicial = form.fecha_inicial && form.fecha_inicial !== 'NaN-NaN-NaN' ? DateFormat.FormatSql(form.fecha_inicial) : '';
		
		let params = {
			id_adquisicion: null,
			id_cat_tipo_adquisicion: form.id_cat_tipo_adquisicion,
			otro_tipo_adquisicion: form.otro_tipo_adquisicion,
			id_usuario: form.id_usuario,
			precio: form.precio,
			enganche: form.enganche,
			fecha_inicial: fecha_inicial,
			id_cat_periodicidad: form.id_cat_periodicidad,
			plazo: form.plazo,
			propietario: form.propietario,
			broker: form.broker,
			socio: form.socio,
			direccion: form.direccion,
			plazo_contrato: form.plazo_contrato,
			pago_periodicidad: form.pago_periodicidad,
			activo: form.activo ? 1 : 0,
			
			lista_contactos: form.lista_contactos,
			lista_cuentas: form.lista_cuentas,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Adquisicion_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_inicial = form.fecha_inicial && form.fecha_inicial !== 'NaN-NaN-NaN' ? DateFormat.FormatSql(form.fecha_inicial) : '';
		
		let params = {
			id_adquisicion: form.id_adquisicion,
			id_cat_tipo_adquisicion: form.id_cat_tipo_adquisicion,
			otro_tipo_adquisicion: form.otro_tipo_adquisicion,
			id_usuario: form.id_usuario,
			precio: form.precio,
			enganche: form.enganche,
			fecha_inicial: fecha_inicial,
			id_cat_periodicidad: form.id_cat_periodicidad,
			plazo: form.plazo,
			propietario: form.propietario,
			broker: form.broker,
			socio: form.socio,
			direccion: form.direccion,
			plazo_contrato: form.plazo_contrato,
			pago_periodicidad: form.pago_periodicidad,
			activo: form.activo ? 1 : 0,
			
			lista_contactos: form.lista_contactos,
			lista_cuentas: form.lista_cuentas,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Adquisicion_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SocioGuardar: (form) => {
		let params = {
			id_adquisicion: form.id_adquisicion,
			
			lista_socios: form.lista_socios,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Adquisicion_Guardar_Socio', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_adquisicion) => {
		let params = {
			id_adquisicion: id_adquisicion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Adquisicion_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	PagoGuardar: (form) => {
		let fecha_pago = (form.fecha_pago || null) ? DateFormat.FormatSql(form.fecha_pago) : null;
		
		let params = {
			id_adquisicion: form.id_adquisicion,
			id_adquisicion_pago: form.id_adquisicion_pago || null,
			id_cat_tipo_adquisicion_pago: form.id_cat_tipo_adquisicion_pago || null,
			importe: form.importe || null,
			fecha: fecha_pago,
			archivo: form.archivo,
			observacion: form.observacion,
			activo: 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Adquisicion_Guardar_Pago', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ConfirmarPago: (form) => {
		let params = {
			id_adquisicion: form.id_adquisicion,
			id_adquisicion_pago: form.id_adquisicion_pago || null,
			confirmar: form.confirmar || null,
			activo: 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Adquisicion_Confirmar_Pago', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SolicitudEdicion: (form) => {
		let params = {
			id_adquisicion: form.id_adquisicion,
			id_adquisicion_pago: form.id_adquisicion_pago || null,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Adquisicion_Solicitud_Edicion', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
