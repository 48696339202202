import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const FormatosService = {
	Listar: (filtro, paginacion) => {
		let fecha_inicio = (filtro.fecha_inicio || null) ? DateFormat.FormatSql(filtro.fecha_inicio) : null;
		let fecha_fin = (filtro.fecha_fin || null) ? DateFormat.FormatSql(filtro.fecha_fin) : null;
		let fecha_envio = (filtro.fecha_envio || null) ? DateFormat.FormatSql(filtro.fecha_envio) : null;
		
		let params = {
			filtro: {
				id_importe_neto: filtro.id_importe_neto || null,
				id_empresa: filtro.id_empresa || null,
				fecha_inicio: fecha_inicio || null,
				fecha_fin: fecha_fin || null,
				fecha_envio: fecha_envio || null,
				estatus: filtro.estatus || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Formatos_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_inicio = (form.fecha_inicio || null) ? DateFormat.FormatSql(form.fecha_inicio) : null;
		let fecha_fin = (form.fecha_fin || null) ? DateFormat.FormatSql(form.fecha_fin) : null;
		let fecha_envio = (form.fecha_envio || null) ? DateFormat.FormatSql(form.fecha_envio) : null;
		
		let params = {
			id_importe_neto: null,
			id_empresa: form.id_empresa,
			fecha_inicio: fecha_inicio || null,
			fecha_fin: fecha_fin || null,
			fecha_envio: fecha_envio || null,
			estatus: form.estatus || null,
			observaciones: form.observaciones || null,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Formatos_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_inicio = (form.fecha_inicio || null) ? DateFormat.FormatSql(form.fecha_inicio) : null;
		let fecha_fin = (form.fecha_fin || null) ? DateFormat.FormatSql(form.fecha_fin) : null;
		let fecha_envio = (form.fecha_envio || null) ? DateFormat.FormatSql(form.fecha_envio) : null;
		
		let params = {
			id_importe_neto: form.id_importe_neto,
			id_empresa: form.id_empresa,
			fecha_inicio: fecha_inicio || null,
			fecha_fin: fecha_fin || null,
			fecha_envio: fecha_envio || null,
			estatus: form.estatus || null,
			observaciones: form.observaciones || null,
			activo: form.activo ? 1 : 0,
		};
		
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Formatos_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_importe_neto) => {
		let params = {
			id_importe_neto: id_importe_neto
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Formatos_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SendMail: (id_importe_neto) => {
		let params = {
			id_importe_neto: id_importe_neto
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Formatos_SendMail', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ImportarArchivo: (form) => {
		let params = {
			id_importe_neto: form.id_importe_neto,
			comprobante_archivo: form.comprobante_archivo,
			comprobante_formato: form.comprobante_formato,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Formatos_Importar_Archivo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
