import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const GiroClienteService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Giro_Cliente_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			giro_cliente: form.giro_cliente,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Giro_Cliente_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_giro_cliente: form.id_cat_giro_cliente,
			giro_cliente: form.giro_cliente,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Giro_Cliente_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_giro_cliente) => {
		let params = {
			id_cat_giro_cliente: id_cat_giro_cliente
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Giro_Cliente_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
