import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import {TimbradoService} from "../../../../services/TimbradoService/TimbradoService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {CONFIG} from "../../../../settings/Config/Config";
import {MetodoPagoService} from "../../../../services/_Cat/MetodoPagoService/MetodoPagoService";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {FormaPagoService} from "../../../../services/_Cat/FormaPagoService/FormaPagoService";
import {TipoComprobanteService} from "../../../../services/_Cat/TipoComprobanteService/TipoComprobanteService";
import ArchivosFacturacion from "../Includes/ArchivosFacturacion";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import moment from "moment";
import {Dropbox} from "dropbox";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import utf8 from "utf8";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {CargaCFDIService} from "../../../../services/CargaCFDIService/CargaCFDIService";
import Typography from "@material-ui/core/Typography";
import {DropzoneArea} from "material-ui-dropzone";
import DivSvg from "../../../Include/MiniComponents/DivSvg";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import img_lista_vacia from "../../../../assets/img/logo_acresco.svg";
import {Description} from "@material-ui/icons";


class ModalTimbrar extends Component {
	
	state = {};
	
	Usr = {};
	
	//handleChange = this.props.handleChange;
	showSnackBars = this.props.showSnackBars;
	RefreshList = this.props.RefreshList;
	
	constructor(props) {
		super(props);
		
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo,
			
			id_facturacion_archivo: props.id_facturacion_archivo,
			id_facturacion: '',
			
			id_cat_forma_pago: '',
			id_cat_metodo_pago: '',
			id_cat_periodo_pago: '',
			id_cat_tipo_comprobante: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			nombre_comercial: '',
			razon_social: '',
			id_cat_tipo_persona: '',
			rfc: '',
			serie: '',
			folio: '',
			uuid: '',
			folio_fiscal: '',
			url_xml_factura: '',
			url_pdf_factura: '',
			url_xml_factura_externa: '',
			url_pdf_factura_externa: '',
			fecha_emitir_comprobante: null,
			
			listar_tipo_comprobante: [],
			listar_forma_pago: [],
			listar_metodo_pago: [],
			listar_periodo_pago: [],
			ficha_tecnica_archivo_integracion_expediente: [],
			
			id_cliente: '',
			id_empresa: '',
			id_cat_facturacion_estatus: '',
			observacion_cfdi: '',
			observacion_interna: '',
			observacion_facturacion: '',
			facturacion_total: '',
			id_cat_clave_producto_servicio: '',
			id_clave_unidad_medida: '',
			numero_identificacion: '',
			descripcion_concepto: '',
			cantidad: '',
			precio_unitario: '',
			total: '',
			iva_incluido: false,
			iva_aplicado: false,
			
			pagado: false,
			
			files: [],
			archivos: [],
			jsonXml: '',
			select_cliente: {},
			fecha_pago: null,
			
			lista_facturacion_producto: [],
			
			id_project: '',
			id_venta:''
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		this.ListaPeriodoPago();
		this.ListaMetodoPago();
		this.ListaFormaPago();
		this.ListaTipoComprobante();
		let {item} = this.props;
		
		this.setState({
			ficha_tecnica_archivo_integracion_expediente: [
				{id_cat_archivo_integracion_expediente: 1, archivo_integracion_expediente: 'PDF Factura', is_required: 1, archivo: item.url_pdf_factura || ''},
				{id_cat_archivo_integracion_expediente: 2, archivo_integracion_expediente: 'XML Factura', is_required: 1, archivo: item.url_xml_factura || ''}
			]
		});
		
		
		return new Promise((resolve, reject) => {
			if (!this.props.id_facturacion_archivo) {
				TimbradoService.Detalles(this.props.id_facturacion).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_emitir_comprobante = item.fecha_emitir_comprobante ? moment(item.fecha_emitir_comprobante) : null;
					this.setState({
						id_facturacion: item.id_facturacion,
						
						
						id_cat_tipo_comprobante: item.id_cat_tipo_comprobante || '',
						id_cliente: item.id_cliente || '',
						id_empresa: item.id_empresa || '',
						serie: item.serie || '',
						folio: item.folio || '',
						uuid: item.uuid || '',
						id_cat_forma_pago: item.id_cat_forma_pago || '',
						id_cat_metodo_pago: item.id_cat_metodo_pago || '',
						id_cat_facturacion_estatus: item.id_cat_facturacion_estatus || '',
						observacion_cfdi: item.observacion_cfdi || '',
						observacion_interna: item.observacion_interna || '',
						fecha_emitir_comprobante: fecha_emitir_comprobante || null,
						facturacion_total: item.total || '',
						nombre: item.nombre || '',
						apellido_paterno: item.apellido_paterno || '',
						apellido_materno: item.apellido_materno || '',
						nombre_comercial: item.nombre_comercial || '',
						razon_social: item.razon_social || '',
						id_cat_tipo_persona: item.id_cat_tipo_persona || '',
						rfc: item.rfc || '',
						
						key: null,
						id_cat_clave_producto_servicio: '',
						id_clave_unidad_medida: '',
						numero_identificacion: '',
						descripcion_concepto: '',
						cantidad: '',
						precio_unitario: '',
						total: '',
						iva_incluido: false,
						iva_aplicado: false,
						pagado: item.pagado || false,
						observacion_facturacion: item.observacion_facturacion || '',
						id_cat_periodo_pago: item.id_cat_periodo_pago || '',
						
						disabled_periodo_pago: item.id_cat_periodo_pago ? true : false,
						
						
						lista_facturacion_producto: item.facturacion_producto || [],
						
						id_project: item.id_project || '',
						id_venta: item.id_venta || ''
						
					});
					
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				TimbradoService.Detalles(this.props.id_facturacion).then((response) => {
					let item = FieldsJs.Copy(response.data);
					this.setState({
						nombre: item.nombre || '',
						apellido_paterno: item.apellido_paterno || '',
						apellido_materno: item.apellido_materno || '',
						nombre_comercial: item.nombre_comercial || '',
						razon_social: item.razon_social || '',
						id_cat_tipo_persona: item.id_cat_tipo_persona || '',
						rfc: item.rfc || '',
					});
				});
				resolve(true);
			}
		});
	};
	
	
	ListaPeriodoPago = () => {
		CatService.ListPeriodoPago().then((response) => {
			this.setState({
				listar_periodo_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_periodo_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	ListaFormaPago = () => {
		FormaPagoService.Listar({}, {}).then((response) => {
			this.setState({
				listar_forma_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_forma_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaMetodoPago = () => {
		MetodoPagoService.Listar({}, {}).then((response) => {
			this.setState({
				listar_metodo_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_metodo_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaTipoComprobante = () => {
		TipoComprobanteService.Listar({}, {}).then((response) => {
			this.setState({
				listar_tipo_comprobante: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_tipo_comprobante: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	add = () => {
		const {item} = this.props;
		console.log("THIS.PROPS::: ", this.props);
		this.setState({
			id_facturacion: item.id_facturacion,
			id_cliente: item.id_cliente || '',
			id_project: item.id_project || '',
			id_venta: item.id_venta || '',
			serie: '',
			folio: '',
			uuid: '',
			folio_fiscal: '',
			id_cat_forma_pago: item.id_cat_forma_pago || '',
			id_cat_metodo_pago: item.id_cat_metodo_pago || '',
			id_cat_periodo_pago: item.id_cat_periodo_pago || '',
			id_cat_tipo_comprobante: item.id_cat_tipo_comprobante || '',
			url_xml_factura: '',
			url_pdf_factura: '',
			url_xml_factura_externa: '',
			url_pdf_factura_externa: '',
			observacion_facturacion: '',
			pagado: false,
			jsonXML: ''
		});
		
		this.open();
		
	};
	
	edit = (value, key) => {
		
		const {item} = this.props;
		console.log("THIS.PROPS::: ", this.props);
		this.setState({
			id_facturacion: item.id_facturacion,
			id_cliente: item.id_cliente || '',
			serie: item.serie || '',
			folio: item.folio || '',
			folio_fiscal: item.folio_fiscal || '',
			id_cat_forma_pago: item.id_cat_forma_pago || '',
			id_cat_metodo_pago: item.id_cat_metodo_pago || '',
			id_cat_periodo_pago: item.id_cat_periodo_pago || '',
			id_cat_tipo_comprobante: item.id_cat_tipo_comprobante || '',
			url_xml_factura: item.url_xml_factura || '',
			url_pdf_factura: item.url_pdf_factura || '',
			url_xml_factura_externa: item.url_xml_factura_externa || '',
			url_pdf_factura_externa: item.url_pdf_factura_externa || '',
			observacion_facturacion: item.observacion || '',
			fecha_emitir_comprobante: item.fecha_emitir_comprobante || '',
			pagado: (item.pagado === 1),
			activo: (item.activo === 1),
		});
		
		
		this.open();
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_cat_clave_producto_servicio: '',
			id_cat_clave_unidad_medida: '',
			descripcion_concepto: '',
			cantidad: '',
			precio_unitario: '',
			numero_identificacion: '',
			total: '',
			iva_incluido: false,
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar este concepto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_facturacion_producto = FieldsJs.Copy(this.state.lista_facturacion_producto);
					
					lista_facturacion_producto.splice(key, 1);
					
					this.setState({
						lista_facturacion_producto: lista_facturacion_producto
					});
					
					this.calcularTotalCompra();
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	
	active_inactive_spinner = (active, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente) => {
		/*
		* INICIO: Activar el spinner
		* */
		let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
		if (id_ficha_tecnica_archivo_integracion_expediente || id_cat_archivo_integracion_expediente) {
			let uno = false;
			let dos = false;
			for (let i = 0; i < arr_temp.length; i++) {
				uno = Number(arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente) === Number(id_ficha_tecnica_archivo_integracion_expediente) && Number(id_ficha_tecnica_archivo_integracion_expediente) > 0;
				dos = Number(arr_temp[i].id_cat_archivo_integracion_expediente) === Number(id_cat_archivo_integracion_expediente) && Number(id_cat_archivo_integracion_expediente) > 0;
				if (uno && dos) {
					arr_temp[i].loading = active;
				} else if (uno && !dos) {
					arr_temp[i].loading = active;
				} else if (!uno && dos) {
					arr_temp[i].loading = active;
				}
			}
		}
		this.setState({
			ficha_tecnica_archivo_integracion_expediente: arr_temp
		});
		/*
		* FIN: Activar el spinner
		* */
	};
	
	
	getBase64 = (e, id, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente) => {
		let formatos = [];
		let files = e.target.files[0];
		if (id_cat_archivo_integracion_expediente === 1) {
			formatos = ["application/pdf"];
		} else {
			formatos = ["text/xml"];
		}
		
		console.log("FILE:::", files);
		FileBase64.Base64(e.target, formatos).then((response) => {
			
			/*
			* Actualizar local variables
			* */
			let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
			if (id_ficha_tecnica_archivo_integracion_expediente || id_cat_archivo_integracion_expediente) {
				let uno = false;
				let dos = false;
				for (let i = 0; i < arr_temp.length; i++) {
					uno = Number(arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente) === Number(id_ficha_tecnica_archivo_integracion_expediente) && Number(id_ficha_tecnica_archivo_integracion_expediente) > 0;
					dos = Number(arr_temp[i].id_cat_archivo_integracion_expediente) === Number(id_cat_archivo_integracion_expediente) && Number(id_cat_archivo_integracion_expediente) > 0;
					if (uno && dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
						arr_temp[i].file = files;
					} else if (uno && !dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
						arr_temp[i].file = files;
					} else if (!uno && dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
						arr_temp[i].file = files;
					}
				}
			} else {
				arr_temp.push({
					loading: false,
					base64: response.base64,
					formato: response.formato,
					archivo: response.archivo,
					id_ficha_tecnica_archivo_integracion_expediente: id_ficha_tecnica_archivo_integracion_expediente || null,
					id_cat_archivo_integracion_expediente: id_cat_archivo_integracion_expediente || null,
					file: files
				});
			}
			this.setState({
				ficha_tecnica_archivo_integracion_expediente: arr_temp
			});
			console.log("FICHA_TECNICA_ARCHIVO_INTEGRACION_EXPEDIENTE:::", this.state.ficha_tecnica_archivo_integracion_expediente);
			
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	fileSelect = (id) => {
		document.getElementById(id).value = '';
		document.getElementById(id).click();
	};
	
	disabledFieldPromotorIntegracionExpedienteBolean = () => {
		
		let disabledFieldPromotorIntegracionExpediente = true;
		
		if (FieldsJs.inArray([1, 2, 9], this.Usr.id_cat_tipo_usuario)) {
			disabledFieldPromotorIntegracionExpediente = false;
		}
		
		return disabledFieldPromotorIntegracionExpediente;
	};
	
	searchXmlToJson = (json, name, elemento) => {
		let valor = '';
		if (json.hasOwnProperty('elements')) {
			for (let a = 0; a < json.elements.length; a++) {
				if (json.elements[a].hasOwnProperty('name') && json.elements[a].hasOwnProperty('attributes')) {
					if (json.elements[a].name === name) {
						valor = json.elements[a].attributes[elemento];
					}
				}
				if (json.elements[a].hasOwnProperty('elements')) {
					for (let b = 0; b < json.elements[a].elements.length; b++) {
						if (json.elements[a].elements[b].hasOwnProperty('name') && json.elements[a].elements[b].hasOwnProperty('attributes')) {
							if (json.elements[a].elements[b].name === name) {
								valor = json.elements[a].elements[b].attributes[elemento];
							}
						}
						if (json.elements[a].elements[b].hasOwnProperty('elements')) {
							for (let c = 0; c < json.elements[a].elements[b].elements.length; c++) {
								if (json.elements[a].elements[b].elements[c].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].hasOwnProperty('attributes')) {
									if (json.elements[a].elements[b].elements[c].name === name) {
										valor = json.elements[a].elements[b].elements[c].attributes[elemento];
									}
								}
								if (json.elements[a].elements[b].elements[c].hasOwnProperty('elements')) {
									for (let d = 0; d < json.elements[a].elements[b].elements[c].elements.length; d++) {
										if (json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('attributes')) {
											if (json.elements[a].elements[b].elements[c].elements[d].name === name) {
												valor = json.elements[a].elements[b].elements[c].elements[d].attributes[elemento];
											}
										}
										if (json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('elements')) {
											for (let f = 0; f < json.elements[a].elements[b].elements[c].elements[d].elements.length; f++) {
												if (json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('attributes')) {
													if (json.elements[a].elements[b].elements[c].elements[d].elements[f].name === name) {
														valor = json.elements[a].elements[b].elements[c].elements[d].elements[f].attributes[elemento];
													}
												}
												if (json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('elements')) {
													for (let g = 0; g < json.elements[a].elements[b].elements[c].elements[d].elements[f].elements.length; g++) {
														if (json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].hasOwnProperty('attributes')) {
															if (json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].name === name) {
																valor = json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].attributes[elemento];
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		if (name === 'cfdi:Emisor' && elemento === 'Nombre' ||
			name === 'cfdi:Receptor' && elemento === 'Nombre')
			valor = utf8.decode(valor);
		
		return valor;
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
		
		let xmls = [];
		
		if (files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				if (files[i].type.split("/")[1] === 'xml') {
					FileBase64.Base64XmlToJson(files[i]).then((response) => {
						
						let xmljson = {
							rfc_emisor: this.searchXmlToJson(response, 'cfdi:Emisor', 'Rfc' || ''),
							empresa_facturadora: this.searchXmlToJson(response, 'cfdi:Emisor', 'Nombre' || ''),
							rfc_receptor: this.searchXmlToJson(response, 'cfdi:Receptor', 'Rfc' || ''),
							cliente: this.searchXmlToJson(response, 'cfdi:Receptor', 'Nombre' || ''),
							iva: this.searchXmlToJson(response, 'cfdi:Impuestos', 'TotalImpuestosTrasladados' || ''),
							total_facturado: this.searchXmlToJson(response, 'cfdi:Emisor', 'Rfc' || ''),
							concepto_facturacion: this.searchXmlToJson(response, 'cfdi:Concepto', 'Descripcion' || ''),
							clave_unidad: this.searchXmlToJson(response, 'cfdi:Concepto', 'ClaveUnidad' || ''),
							clave_concepto: this.searchXmlToJson(response, 'cfdi:Concepto', 'ClaveProdServ' || ''),
							
							version: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Version' || ''),
							serie: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Serie' || ''),
							folio: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Folio' || ''),
							fecha: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Fecha') ? DateFormat.FormatSql(moment(this.searchXmlToJson(response, 'cfdi:Comprobante', 'Fecha'))) : '',
							sello: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Sello' || ''),
							forma_pago: this.searchXmlToJson(response, 'cfdi:Comprobante', 'FormaPago' || ''),
							numero_certificado: this.searchXmlToJson(response, 'cfdi:Comprobante', 'NoCertificado' || ''),
							certificado: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Certificado' || ''),
							subtotal: this.searchXmlToJson(response, 'cfdi:Comprobante', 'SubTotal' || ''),
							moneda: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Moneda' || ''),
							tipo_cambio: this.searchXmlToJson(response, 'cfdi:Comprobante', 'TipoCambio' || ''),
							total: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Total' || ''),
							tipo_comprobante: this.searchXmlToJson(response, 'cfdi:Comprobante', 'TipoDeComprobante' || ''),
							metodo_pago: this.searchXmlToJson(response, 'cfdi:Comprobante', 'MetodoPago' || ''),
							lugar_expedicion: this.searchXmlToJson(response, 'cfdi:Comprobante', 'LugarExpedicion' || ''),
							uuid: this.searchXmlToJson(response, 'tfd:TimbreFiscalDigital', 'UUID') || '',
							nombre_archivo: files[i].name
						};
						
						xmls.push(xmljson);
						
						console.log("JSONXML :::: ", xmls);
						
						this.setState({
							serie : xmljson.serie,
							folio : xmljson.folio,
							folio_fiscal : xmljson.uuid,
							//archivos_facturacion: item.ficha_tecnica_archivo_integracion_expediente,
							
							jsonXML: xmls
						});
						
						
					}).catch((error) => {
						this.setState({jsonXML: ''});
					})
				}
			}
		} else {
			this.setState({jsonXML: ''});
		}
	};
	
	
	guardarDocTimbradoDbx = () => {
		let ftar = this.state.files;
		console.log("1 FTAR::: ", ftar);
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		let files;
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i]) === true) {
					band++;
				}
			}
			let archives = [];
			let years_fechaxml = moment(this.state.jsonXML[0].fecha).get('years');
			let month_fechaxml = moment(this.state.jsonXML[0].fecha).get('month');
			month_fechaxml = month_fechaxml + 1;
			if (month_fechaxml < 10) {
				month_fechaxml = '0' + month_fechaxml;
			}
			
			
			for (let i = 0; i < ftar.length; i++) {
				console.log("2 FTAR::: ", ftar);
				files = ftar[i];
				
				let empresa = this.existCompany(this.state.jsonXML[0].rfc_emisor , this.props.listDropbox);
				let ruta = '';
				if (!empresa) {
					ruta = '/' + this.state.jsonXML[0].rfc_emisor + '-' + this.state.jsonXML[0].empresa_facturadora
						+ '/facturacion/' + years_fechaxml + '/' + month_fechaxml + '/' +
						files.name;
				} else {
					ruta = '/' + empresa + '/facturacion/' + years_fechaxml + '/' + month_fechaxml + '/' +
						files.name;
				}
				
				DropboxApi.Upload(ruta, files).then(function (response) {
					archives.push(response.path_display);
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	existCompany = (company, listcompany) => {
		
		let comp = false;
		listcompany.map((item, key) => {
			let name = item.name.split('-');
			console.log(name);
			if (company === name[0]) {
				comp = item.name
			}
		});
		
		return comp;
	};
	
	save = () => {
		console.log("THIS.STATE: ", this.state);
		
		try {
			
			if (this.state.files.length <= 0) {
				throw Object({
					status: false, mensaje: 'Agrega un archivo.'
				})
			}
			
			if (!this.state.folio_fiscal) {
				throw Object({
					status: false, mensaje: 'Folio fiscal requerido.'
				})
			}
			
			if (this.state.jsonXML[0].tipo_comprobante !== 'I' && this.state.jsonXML[0].tipo_comprobante !== 'P') {
				throw Object({
					status: false, mensaje: 'XML incorrecto.'
				})
			}
			
			let guardar = new Promise((resolve, reject) => {
				this.guardarDocTimbradoDbx().then(response => {
					console.log("GUARDARFICHATECNICAARCHIVOELABORACIONPROPUESTADBX::: ", response);
					let exp = FieldsJs.Copy(this.state.ficha_tecnica_archivo_integracion_expediente);
					
					if (response.length > 0) {

						for (let i = 0; i < response.length; i++) {
							let ar = response[i].split('.');
							if (ar[ar.length - 1] === 'pdf') {
								exp[0].archivo = response[i];
								exp[0].formato = 'pdf';
							} else {
								exp[1].archivo = response[i];
								exp[1].formato = 'xml';
							}
						}
					}
					
					this.setState({
						archivos: response,
						ficha_tecnica_archivo_integracion_expediente: exp
					});
					resolve(response);
					console.log("DROPBOX UPLOAD: ", this.state);
					
				}).catch(error => {
					reject(error);
				})
			});
			
			guardar.then(response => {
				TimbradoService.Timbrado(this.state).then((response) => {
					CargaCFDIService.GuardarX(this.state).then((response) => {
						this.closeModal();
						this.props.RefreshList();
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			})
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};

	render() {
		let image = img_lista_vacia;
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			/*case 'view':
				funcion = this.view;
				break;*/
			default:
				funcion = () => {
				};
		}
		
		const disabledFieldPromotorIntegracionExpediente = this.disabledFieldPromotorIntegracionExpedienteBolean();
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_facturacion_archivo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				{/*<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>*/}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Datos de Timbrado
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'margin-15-B'} style={{color: 'gray', opacity: '0.8'}}>
									Solo puedes seleccionar un archivo XML y un PDF, para visualizar el contenido y poder subirlo posteriormente.
								</Typography>
							</Grid>
							
							
							
							{/*<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Description className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="folio_fiscal"
										           onChange={this.handleChange}
										           label="Folio físcal" autoComplete={'off'}
										           value={this.state.folio_fiscal}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>*/}
							
							
							
							<Grid item xs={12} sm={12} md={12} lg={this.state.pagado ? 7:10} xl={this.state.pagado ? 7:10}>
								<TextField
									rows={1}
									multiline
									margin="none"
									name="observacion_facturacion"
									label="Observación"
									type="text"
									fullWidth
									value={this.state.observacion_facturacion}
									onChange={this.handleChange}
									disabled={this.is_view()}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='pagado'
												checked={this.state.pagado}
												value="activo"
											/>
										}
										label={'Pagado'}
									/>
								</FormGroup>
							
							</Grid>
							
							{this.state.pagado ? <Grid item xs={12} sm={16} md={6} lg={3} xl={3}>
								<KeyboardDateTimePicker
									format="yyyy/MM/dd hh:mm a"
									fullWidth
									
									clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha de Pago',
									}}
									label="Fecha de Pago"
									value={this.state.fecha_pago}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_pago');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									
									disabled={this.is_view()}
								/>
							</Grid>
								: null }
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100'}>
									<DropzoneArea
										onChange={this.handleChangeDropzoneArea.bind(this)}
										open={this.state.open}
										filesLimit={2}
										dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
										acceptedFiles={['text/xml', 'application/pdf']}
										showPreviews={false}
										maxFileSize={5000000}
										showAlerts={false}
										showFileNames={true}
										showFileNamesInPreview={true}
									/>
								</div>
							</Grid>
							
							{this.state.jsonXML ? this.state.jsonXML[0].tipo_comprobante !== 'I' ? (
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'margin-0-B margin-15-T'} style={{color: 'red', opacity: '0.8'}}>
											El tipo de comprobante no es válido para el registro, favor de agregar un XML de tipo ingreso.
										</Typography>
									</Grid>
								) : null
								: null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{this.state.jsonXML ?
									(
										<Grid container spacing={1}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'borde-punteado-gray-2 margin-20-T margin-20-B padding-20'}>
												<Grid container spacing={1}>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Uuid</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].uuid}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>RFC Emisor</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].rfc_emisor}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Empresa Facturadora</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].empresa_facturadora}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>RFC Receptor</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].rfc_receptor}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Cliente</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].cliente}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Serie</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].serie}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Folio</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].folio}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Fecha Emisión</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].fecha}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>SubTotal</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].subtotal}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Iva</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].iva}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Total Facturado</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].total}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Concepto Facturación</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].concepto_facturacion}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Clave Unidad</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].clave_unidad}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Clave Concepto</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].clave_concepto}</p>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<h5 style={{margin: '0px'}}>Tipo Comprobante</h5>
														<p style={{margin: '0px'}}>{this.state.jsonXML[0].tipo_comprobante}</p>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									) : (
										<Fragment>
											<div className={'w-auto'} align={'center'} style={{padding: '20px'}}>
												<DivSvg img={image} height={'100px'} width={'100px'} opacity={true}/>
												<Typography className={'text-center px-16'} style={{opacity: 0.5, padding: "20px", color: 'gray'}}>
													{'No se encontraron datos.'}
												</Typography>
											</div>
										</Fragment>
									)}
							
							</Grid>
							
							{/*<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<FeaturedPlayList className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="serie"
										           onChange={this.handleChange}
										           label="Serie" autoComplete={'off'}
										           value={this.state.serie}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Assignment className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="folio"
										           onChange={this.handleChange}
										           label="Folio" autoComplete={'off'}
										           value={this.state.folio}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Description className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="folio_fiscal"
										           onChange={this.handleChange}
										           label="Folio físcal" autoComplete={'off'}
										           value={this.state.folio_fiscal}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<KeyboardDateTimePicker
									format="yyyy/MM/dd hh:mm a"
									fullWidth
									required
									clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha de factura',
									}}
									label="Fecha de factura"
									value={this.state.fecha_emitir_comprobante}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_emitir_comprobante');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									
									disabled={this.is_view()}
								/>
							</Grid>
							
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									{!this.is_view() ? (
										<Grid item style={{width: '50px'}}>
											{FieldsJs.inArray([1, 2, 9], this.Usr.id_cat_tipo_usuario) ? (
												<ModalTipoComprobante
													componente={(
														<Tooltip
															TransitionComponent={Zoom}
															placement={"right"}
															title="Tipo comprobante"
															children={(
																<Fab
																	color="default"
																	size="small"
																	aria-label="Agregar nuevo tipo comprobante"
																	children={(
																		<PlaylistAddOutlined/>
																	)}
																/>
															)}
														/>
													)}
													id_cliente={null}
													tipo={'add'}
													item={{}}
													RefreshList={this.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											) : ''}
										</Grid>
									) : null}
									<Grid item style={{width: 'calc(100% - 50px)'}}>
										<TextField
											select
											fullWidth
											required
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											name="id_cat_tipo_comprobante"
											label="Tipo comprobante"
											value={this.state.id_cat_tipo_comprobante}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.listar_tipo_comprobante.map(option => (
												<option key={option.id_cat_tipo_comprobante} value={option.id_cat_tipo_comprobante}>
													{option.tipo_comprobante}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									{!this.is_view() ? (
										<Grid item style={{width: '50px'}}>
											{FieldsJs.inArray([1, 2, 9], this.Usr.id_cat_tipo_usuario) ? (
												<ModalFormaPago
													componente={(
														<Tooltip
															TransitionComponent={Zoom}
															placement={"right"}
															title="Forma de pago"
															children={(
																<Fab
																	color="default"
																	size="small"
																	aria-label="Agregar nueva forma de pago"
																	children={(
																		<PlaylistAddOutlined/>
																	)}
																/>
															)}
														/>
													)}
													id_cliente={null}
													tipo={'add'}
													item={{}}
													RefreshList={this.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											) : ''}
										</Grid>
									) : null}
									<Grid item style={{width: 'calc(100% - 50px)'}}>
										<TextField
											select
											fullWidth
											required
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											
											name="id_cat_forma_pago"
											label="Forma de pago"
											value={this.state.id_cat_forma_pago}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.listar_forma_pago.map(option => (
												<option key={option.id_cat_forma_pago} value={option.id_cat_forma_pago}>
													{option.forma_pago} - {option.descripcion}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									{!this.is_view() ? (
										<Grid item style={{width: '50px'}}>
											{FieldsJs.inArray([1, 2, 9], this.Usr.id_cat_tipo_usuario) ? (
												<ModalMetodoPago
													componente={(
														<Tooltip
															TransitionComponent={Zoom}
															placement={"right"}
															title="Metodo de pago"
															children={(
																<Fab
																	color="default"
																	size="small"
																	aria-label="Agregar nuevo metodo de pago"
																	children={(
																		<PlaylistAddOutlined/>
																	)}
																/>
															)}
														/>
													)}
													id_cliente={null}
													tipo={'add'}
													item={{}}
													RefreshList={this.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											) : ''}
										</Grid>
									) : null}
									<Grid item style={{width: 'calc(100% - 50px)'}}>
										<TextField
											select
											fullWidth
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											name="id_cat_metodo_pago"
											label="Metodo de pago"
											value={this.state.id_cat_metodo_pago}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.listar_metodo_pago.map(option => (
												<option key={option.id_cat_metodo_pago} value={option.id_cat_metodo_pago}>
													{option.metodo_pago} - {option.descripcion}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									disabled={this.state.disabled_periodo_pago}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_periodo_pago"
									label="Periodo pago"
									value={this.state.id_cat_periodo_pago}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_periodo_pago.map(option => (
										<option key={option.id_cat_periodo_pago} value={option.id_cat_periodo_pago}>
											{option.periodo_pago}
										</option>
									))}
								</TextField>
							</Grid>*/}
							
							
							
							{/*<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<InsertDriveFile className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="url_xml_factura_externa"
										           onChange={this.handleChange}
										           label="URL XML" autoComplete={'off'}
										           value={this.state.url_xml_factura_externa}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PictureAsPdf className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="url_pdf_factura_externa"
										           onChange={this.handleChange}
										           label="URL PDF" autoComplete={'off'}
										           value={this.state.url_pdf_factura_externa}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>*/}
							
							{/*<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								
								<Grid container spacing={2}>
									
									{this.state.ficha_tecnica_archivo_integracion_expediente.map((item, key) => (
										<Grid key={key} item className={'text-left'} xs={6} sm={5} md={5} lg={5} xl={5}>
											<ArchivosFacturacion
												index={key}
												id_facturacion={this.state.id_facturacion}
												variable={'ficha_tecnica_archivo_integracion_expediente'}
												texto={item.archivo_integracion_expediente}
												id_ficha_tecnica_archivo_integracion_expediente={item.id_ficha_tecnica_archivo_integracion_expediente || ""}
												id_cat_archivo_integracion_expediente={item.id_cat_archivo_integracion_expediente || ""}
												item={{
													loading: !!item.loading,
													base64: item.base64,
													formato: item.formato,
													archivo: item.archivo,
													is_required: item.is_required,
												}}
												fileSelect={this.fileSelect}
												getBase64={this.getBase64}
												disabledSeleccionarCambiarArchivo={disabledFieldPromotorIntegracionExpediente}
											/>
										</Grid>
									))}
								
								</Grid>
							
							</Grid>*/}
						
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : 'Agregar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalTimbrar.propTypes = {
	id_facturacion_archivo: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	id_facturacion: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	listDropbox: PropTypes.array,
	item: PropTypes.object.isRequired,
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	componente: PropTypes.element,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
};

export default ModalTimbrar;
