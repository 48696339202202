import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import {AttachMoney, Send, HighlightOff, FindInPage, Description, CloudDownload} from '@material-ui/icons';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {TimbradoService} from "../../../../services/TimbradoService/TimbradoService";
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import SubTablaFacturacion from "./SubTablaFacturacion";
import ModalSendRocketChat from "./ModalSendRocketChat";
import icon_rocket_chat from "../../../../assets/img/icons/rocketchat.svg";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import ModalPago from "./ModalPago";
import SubTablaFacturacionPago from "./SubTablaFacturacionPago";
import ModalCancelarCFDI from "./ModalCancelarCFDI";
import icon_xmlcancelado from '../../../../assets/img/icons/xmlcancelado.svg';
import icon_xml from '../../../../assets/img/icons/xml.svg';
import icon_pdf from '../../../../assets/img/icons/pdf.svg';
import ModalConsultaSat from "./ModalConsultaSat";
import ModalEnviarEmailCFDI from "./ModalEnviarEmailCFDI";
import ModalDescargarXml from "./ModalDescargarXml";
import Fab from "@material-ui/core/Fab";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});


class ListaTabla extends Component {
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	
	tipoPermiso = () => {
		if (this.Usr.id_cat_tipo_usuario === 1 || this.Usr.id_cat_tipo_usuario === 2 || this.Usr.id_cat_tipo_usuario === 4) {
			return 'fiscal';
		} else {
			return 'facturacion';
		}
	};
	
	autorizarFactura = (item) => {
		
		try {
			
			let bandFP = false;
			if (item.facturacion_producto.length > 0) {
				for (let i = 0; i < item.facturacion_producto.length; i++) {
					if (!item.facturacion_producto[i].id_cat_clave_producto_servicio) {
						bandFP = true;
					}
				}
			}
			
			
			if (bandFP) {
				throw Object({
					status: false, mensaje: 'Aún faltan claves por asignar.'
				});
			}
			
			let msg = `¿Deseas autorizar la factura con ID: ${item.id_facturacion}?`;
			PopupService.Confirm(['Cancelar', 'Autorizar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
				if (r.button === 'Autorizar') {
					TimbradoService.AutorizarFactura(item.id_facturacion).then((response) => {
						this.props.showSnackBars('success', response.mensaje);
						this.props.RefreshList();
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				}
			});
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
		
		
	};
	
	
	porAutorizarFactura = (item) => {
		let msg = `¿Deseas autorizar la factura con ID: ${item.id_facturacion}?`;
		PopupService.Confirm(['Cancelar', 'Autorizar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Autorizar') {
				TimbradoService.PorAutorizarFactura(item.id_facturacion).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	
	exportar = (link, tipo) => {
		if (tipo === 'PDF') {
			try {
				let params = {
					archivo: tipo,
					archivo_xml: link
				};
				HttpRequest.exportFacturacion('xmltopdf', ['xmltopdf'], params, false, false, false, true);
			} catch (e) {
				this.showSnackBars('error', e.mensaje);
			}
		} else if(tipo === 'Acuse') {
				let archiv = JSON.parse(link);
				let xml = archiv.acuse;
				let blob = new Blob([xml], {type: 'text/xml'});
				let url = URL.createObjectURL(blob);
				window.open(url, '', 'width=900, height=600');
				URL.revokeObjectURL(url);
			} else {
				let url = CONFIG.src_facturacion + link;
				window.open(url, '', 'width=900, height=600');
		}
	};
	
	ver_root = () => {
		return this.Usr.id_usuario === 1;
	};
	
	
	is_cancel = (item) => {
		return item.cancelado === 1;
	};
	
	
	render() {
		
		const {classes, lista} = this.props;
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		const bg_cartacancelado = {
			backgroundImage: `url(${icon_xmlcancelado})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain'
		};
		
		const bg_carta = {
			backgroundImage: `url(${icon_xml})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain'
		};
		
		const bg_pdf = {
			backgroundImage: `url(${icon_pdf})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain'
		};
		
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-mark'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'}>...</TableCell>
							<TableCell component="th" align={'left'}>Serie</TableCell>
							<TableCell component="th" align={'left'}>Folio</TableCell>
							<TableCell component="th" align={'left'}>UUID</TableCell>
							<TableCell component="th" align={'left'}>Emisor</TableCell>
							<TableCell component="th" align={'left'}>RFC Receptor</TableCell>
							<TableCell component="th" align={'left'}>Nombre Receptor</TableCell>
							<TableCell component="th" align={'left'}>Estatus</TableCell>
							<TableCell component="th" align={'center'}>Fecha</TableCell>
							<TableCell component="th" align={'center'}>Tipo de CFDI</TableCell>
							<TableCell component="th" align={'center'}>Metodo de Pago</TableCell>
							<TableCell component="th" align={'center'}>Forma de Pago</TableCell>
							<TableCell component="th" align={'left'}>Subtotal</TableCell>
							<TableCell component="th" align={'left'}>Impuesto</TableCell>
							<TableCell component="th" align={'left'}>Total</TableCell>
							<TableCell component="th" align={'center'}>CFDI</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => {
							return (
								<Fragment key={key}>
									<TableRow>
										<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
											{item.partidas.length > 0 ? (
												<div className={'w-100'} align={'center'}>
													<div style={!!item.expandir ? bg_menos : bg_mas}
													     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
													     onClick={() => this.props.SubTabla(item, key)}
													/>
												</div>
											) : '...'}
										</TableCell>
										<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.serie}</TableCell>
										<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.folio}</TableCell>
										<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.uuid}</TableCell>
										<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.empresa_nombre_razon_social}</TableCell>
										<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.rfc_receptor}</TableCell>
										<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.cliente_nombre_razon_social ?? item.cliente_externo}</TableCell>
										<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.cancelado ? "Cancelado" : "Vigente"}</TableCell>
										<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{DateFormat.getFechaText(item.fecha)}</TableCell>
										<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.tipo_cfdi}</TableCell>
										<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.metodo_pago}</TableCell>
										<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.forma_pago}</TableCell>
										<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>${FieldsJs.Currency(item.subtotal)}</TableCell>
										<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>${FieldsJs.Currency(item.total_trasladado)}</TableCell>
										<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>${FieldsJs.Currency(item.total)}</TableCell>
										<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
											<div className={'vertical-inline'}>
												{ FieldsJs.Field(item.acuse_cancelacion) ? (
													<div className={'v-center'}>
														<ModalDescargarXml
															tipo={'add'}
															item={item}
															componente={
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Acuse de cancelación">
																	<IconButton aria-label="Acuse de cancelación">
																		<div style={bg_cartacancelado} className={'h-25-px w-25-px'}/>
																	</IconButton>
																</Tooltip>
																/*<Fab variant="extended" size="small" color="default" aria-label="Descargar">
																	<CloudDownload className={'px-15'}/>
																</Fab>*/
															}
															showSnackBars={this.props.showSnackBars}
															RefreshList={this.props.RefreshList}
															descargarZip={this.descargarZip}
														/>
													</div>
												): null }
												
												<div className={'v-center'}>
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver XML">
														<IconButton aria-label="Ver XML" onClick={() => this.exportar(item.archivo_xml)}>
															<div style={bg_carta} className={'h-25-px w-25-px'}/>
														</IconButton>
													</Tooltip>
												</div>
												
												<div className={'v-center'}>
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver PDF">
														<IconButton aria-label="Ver PDF" onClick={() => this.exportar(item.archivo_xml, 'PDF')}>
															<div style={bg_pdf} className={'h-25-px w-25-px'}/>
														</IconButton>
													</Tooltip>
												</div>
											</div>
										</TableCell>
										
										<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
											
											<div className={'w-auto vertical-inline'} style={{minHeight: '40px'}}>
												
												<div className={'v-center'}>
													<ModalEnviarEmailCFDI
														item={item}
														tipo={'add'}
														project={2}
														componente={(
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Enviar CFDI">
																<IconButton aria-label="Enviar CFDI">
																	<Send className={'v-center'} style={{opacity: 0.7, color: '#e87e44'}}/>
																</IconButton>
															</Tooltip>
														)}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
														history={this.props.history}
													/>
												</div>
												
												<div className={'v-center'}>
													<ModalPago
														item={item}
														tipo={'add'}
														component={(
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Timbrado Pago">
																<IconButton aria-label="Timbrado Pago">
																	<AttachMoney style={{opacity: 0.7, color: '#3f51b5'}}/>
																</IconButton>
															</Tooltip>
														)}
														listar_usuarios={this.props.listar_usuarios}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
												
												<div className={'v-center'}>
													<ModalSendRocketChat
														item={item}
														tipo={'add'}
														component={(
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Enviar Notificación">
																<IconButton aria-label="Enviar Notificación">
																	<img className={'v-center'} src={icon_rocket_chat} alt="add" width={25} height={25} style={{opacity: 0.7}}/>
																</IconButton>
															</Tooltip>
														)}
														listar_usuarios={this.props.listar_usuarios}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
												
												<div className={'v-center'}>
													<ModalCancelarCFDI
														item={item}
														tipo={'add'}
														project={item.proyecto}
														componente={(
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Cancelar CFDI">
																<IconButton aria-label="Cancelar CFDI" color="default">
																	<HighlightOff className={'v-center'} style={{opacity: 0.7}}/>
																</IconButton>
															</Tooltip>
														)}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
														history={this.props.history}
													/>
												</div>
												
												<div className={'v-center'}>
													<ModalConsultaSat
														tipo={'add'}
														item={item}
														componente={
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Consulta SAT">
																<IconButton aria-label="Consulta SAT" color="default">
																	<FindInPage className={'v-center'} style={{opacity: 0.7, color: '#51ac9a'}}/>
																</IconButton>
															</Tooltip>
														}
														RefreshList={this.RefreshList}
														showSnackBars={this.showSnackBars}
													/>
												</div>
												
												{/*{FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario) || item.id_usuario_alta_cliente === this.Usr.id_usuario ? (
														<div className={'v-center'}>
															<ModalFacturacionCancelar
																item={item}
																tipo={'add'}
																componente={(
																	<Tooltip TransitionComponent={Zoom} placement={"top"}
																	         title="Cancelar">
																		<IconButton aria-label="Cancelar">
																			<Cancel style={{color: item.cancelacion.length > 0 ? '#8E1E32' : ''}}/>
																		</IconButton>
																	</Tooltip>
																)}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
															/>
														</div>
													) : null}*/}
											
											</div>
										</TableCell>
									</TableRow>
									
									{(
										<Fragment>
											{!!item.expandir ? (
												<TableRow>
													<TableCell align={'center'}
													           className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
													           colSpan={this.ver_root() ? 17 : 17}
													>
														
														<SubTablaFacturacion
															tipo_vista={this.props.tipo_vista}
															item={{lista: item.partidas}}
															showSnackBars={this.props.showSnackBars}
															RefreshList={this.props.RefreshList}
														/>
														
														{item.pagos.length > 0 ? (
															<SubTablaFacturacionPago
																tipo_vista={this.props.tipo_vista}
																item={{pagos: item.pagos}}
																showSnackBars={this.props.showSnackBars}
																RefreshList={this.props.RefreshList}
															/>
														) : null}
													
													</TableCell>
												</TableRow>
											) : null}
										</Fragment>
									)}
								</Fragment>
							)
						})}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	listDropbox: PropTypes.array,
	listar_usuarios: PropTypes.array,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
