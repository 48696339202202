import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import {Ballot, Cancel, EditOutlined, ForwardOutlined, LocalAtm, SearchOutlined, Send} from '@material-ui/icons';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {FieldsJs} from "../../../../settings/General/General";
import ModalFacturacionFiscal from "./ModalFacturacionFiscal";
import PictureAsTimbrar from "@material-ui/icons/RoomService";
import Fab from "@material-ui/core/Fab";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import ModalTimbrar from "./ModalTimbrar";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {FacturacionService} from "../../../../services/FacturacionService/FacturacionService";
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import SubTablaFacturacion from "./SubTablaFacturacion";
import ModalFacturacionPromotor from "./ModalFacturacionPromotor";
import ModalFacturacionPromotorFiscal from "./ModalFacturacionPromotorFiscal";
import ModalFacturacionCancelar from "./ModalFacturacionCancelar";
import ModalComprobante from "./ModalComprobante";
import ModalSendMail from "./ModalSendMail";
import ModalSendRocketChat from "./ModalSendRocketChat";
import icon_rocket_chat from "../../../../assets/img/icons/rocketchat.svg";
import ModalEstatusSat from "./ModalEstatusSat";


const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});


class ListaTabla extends Component {
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	
	tipoPermiso = () => {
		if (this.Usr.id_cat_tipo_usuario === 1 || this.Usr.id_cat_tipo_usuario === 2 || this.Usr.id_cat_tipo_usuario === 4) {
			return 'fiscal';
		} else {
			return 'facturacion';
		}
	};
	
	autorizarFactura = (item) => {
		
		try {
			
			let bandFP = false;
			if (item.facturacion_producto.length > 0) {
				for (let i = 0; i < item.facturacion_producto.length; i++) {
					if (!item.facturacion_producto[i].id_cat_clave_producto_servicio) {
						bandFP = true;
					}
				}
			}
			
			
			if (bandFP) {
				throw Object({
					status: false, mensaje: 'Aún faltan claves por asignar.'
				});
			}
			
			let msg = `¿Deseas autorizar la factura con ID: ${item.id_facturacion}?`;
			PopupService.Confirm(['Cancelar', 'Autorizar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
				if (r.button === 'Autorizar') {
					FacturacionService.AutorizarFactura(item.id_facturacion).then((response) => {
						this.props.showSnackBars('success', response.mensaje);
						this.props.RefreshList();
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				}
			});
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
		
		
	};
	
	
	porAutorizarFactura = (item) => {
		let msg = `¿Deseas autorizar la factura con ID: ${item.id_facturacion}?`;
		PopupService.Confirm(['Cancelar', 'Autorizar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Autorizar') {
				FacturacionService.PorAutorizarFactura(item.id_facturacion).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	
	exportar = (url) => {
		window.open(url, '', 'width=900, height=600');
	};
	
	ver_root = () => {
		return this.Usr.id_usuario === 1;
	};
	
	
	render() {
		
		const {classes, lista} = this.props;
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-mark'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'}>...</TableCell>
							
							{/*<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>No. identificación</TableCell>*/}
							<TableCell component="th" align={'left'}>ID</TableCell>
							<TableCell component="th" align={'left'}>Solicitante</TableCell>
							<TableCell component="th" align={'left'}>Empresa</TableCell>
							<TableCell component="th" align={'left'}>Cliente</TableCell>
							<TableCell component="th" align={'left'}>Estatus Sat</TableCell>
							<TableCell component="th" align={'left'}>Estatus Pago</TableCell>
							<TableCell component="th" align={'left'}>Fecha Pago</TableCell>
							<TableCell component="th" align={'left'}>Fecha de solicitud</TableCell>
							<TableCell component="th" align={'left'}>Total</TableCell>
							<TableCell component="th" align={'center'}>Estatus</TableCell>
							{/*<TableCell component="th" align={'center'}>Subir comprobante</TableCell>*/}
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => {
							return (
								<Fragment key={key}>
									<TableRow>
										<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
											{item.timbrados.length > 0 ? (
												<div className={'w-100'} align={'center'}>
													<div style={!!item.expandir ? bg_menos : bg_mas}
													     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
													     onClick={() => this.props.SubTabla(item, key)}
													/>
												</div>
											) : '...'}
										</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_facturacion_estatus) === 10 || Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.id_facturacion}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_facturacion_estatus) === 10 || Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.nombre_completo_solicitante}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_facturacion_estatus) === 10 || Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.empresa_nombre_comercial ? item.empresa_nombre_comercial : "Por Asignar"}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_facturacion_estatus) === 10 || Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.id_cat_tipo_persona === 1 ? item.nombre_completo : item.nombre_comercial}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_facturacion_estatus) === 10 || Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.facturacion_status_sat}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_facturacion_estatus) === 10 || Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.facturacion_status_pago}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_facturacion_estatus) === 10 || Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.fecha_pago ? DateFormat.FormatText(item.fecha_pago) : "Sin registrar"}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_facturacion_estatus) === 10 || Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.fecha_emitir_comprobante ? DateFormat.FormatText(item.fecha_emitir_comprobante) : "Sin registrar"}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_facturacion_estatus) === 10 || Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>${FieldsJs.Currency(item.total)}</TableCell>
										<TableCell align={'center'} className={Number(item.id_cat_facturacion_estatus) === 10 || Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.facturacion_estatus}</TableCell>
										
										<TableCell align={'right'} className={'padding-5-L padding-5-R'}>
											
											<div className={'w-auto vertical-inline'} style={{minHeight: '40px'}}>
												
												{/*{FieldsJs.inArray([1, 2, 3], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
															<IconButton aria-label="Eliminar"
															            onClick={() => this.props.delete(item)}>
																<DeleteOutlined/>
															</IconButton>
														</Tooltip>
													</div>
												) : null}*/}
												
												<div className={'v-center'}>
													<ModalSendRocketChat
														item={item}
														tipo={'add'}
														component={(
															<Tooltip TransitionComponent={Zoom} placement={"top"}
															         title="Enviar Notificación">
																<IconButton aria-label="Enviar Notificación">
																	<img className={'v-center'} src={icon_rocket_chat} alt="add" width={25} height={25} style={{opacity: 0.7}}/>
																</IconButton>
															</Tooltip>
														)}
														listar_usuarios={this.props.listar_usuarios}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
												
												{FieldsJs.inArray([1, 2, 3], this.Usr.id_cat_tipo_usuario) && item.id_cat_facturacion_estatus === 1 ? (
													<div className={'v-center'}>
														<ModalFacturacionPromotor
															id_facturacion={item.id_facturacion}
															item={item}
															tipo={'edit'}
															icono={(
																<Tooltip TransitionComponent={Zoom} placement={"top"}
																         title="Editar">
																	<IconButton aria-label="Editar">
																		<EditOutlined/>
																	</IconButton>
																</Tooltip>
															)}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												) : null}
												
												
												{FieldsJs.inArray([1, 2, 3, 4, 9, 10, 13], this.Usr.id_cat_tipo_usuario) && item.id_cat_facturacion_estatus === 2 ? (
													<div className={'v-center'}>
														<ModalFacturacionPromotorFiscal
															id_facturacion={item.id_facturacion}
															item={item}
															tipo={'add'}
															icono={(
																<Tooltip TransitionComponent={Zoom} placement={"top"}
																         title="Asignar Claves">
																	<IconButton aria-label="Agregar">
																		<Ballot/>
																	</IconButton>
																</Tooltip>
															)}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												) : null}
												
												{FieldsJs.inArray([1, 2, 4], this.Usr.id_cat_tipo_usuario) && item.id_cat_facturacion_estatus === 2 ? (
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Autorizar">
															<IconButton aria-label="Autorizar" onClick={() => this.autorizarFactura(item)}>
																<ForwardOutlined/>
															</IconButton>
														</Tooltip>
													</div>
												) : null}
												
												{FieldsJs.inArray([1, 2, 3, 9, 10, 13], this.Usr.id_cat_tipo_usuario) && item.id_cat_facturacion_estatus === 1 ? (
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Autorizar">
															<IconButton aria-label="Autorizar" onClick={() => this.porAutorizarFactura(item)}>
																<ForwardOutlined/>
															</IconButton>
														</Tooltip>
													</div>
												) : null}
												
												{/*{FieldsJs.inArray([1, 2, 9], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([3, 4, 5], item.id_cat_facturacion_estatus) && (item.id_cat_periodo_pago != 1 || item.id_cat_periodo_pago === 1 && item.timbrados.length === 0) ? (*/}
												{FieldsJs.inArray([1, 2, 9], this.Usr.id_cat_tipo_usuario) || FieldsJs.inArray([1, 2, 9], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([3, 4, 5], item.id_cat_facturacion_estatus)  ? (
													<div className={'v-center'}>
														<ModalTimbrar
															id_facturacion={item.id_facturacion}
															item={item}
															tipo={'add'}
															componente={(
																<Fab variant="extended" size="small" color="primary" aria-label="Timbrar"
																     className={'margin-10-L bg-green-2'}>
																	<PictureAsTimbrar className={'margin-5-R px-14'}/>
																	Timbrar
																</Fab>
															)}
															listDropbox={this.props.listDropbox}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
															handleChange={this.handleChange}
														/>
													</div>
												) : null}
												
												
												{/*{FieldsJs.inArray([1, 2, 9], this.Usr.id_cat_tipo_usuario) && item.id_cat_facturacion_estatus === 2 || FieldsJs.inArray([9], this.Usr.id_cat_tipo_usuario) && item.id_cat_periodo_pago === 1 && item.timbrados.length === 1 ? (
													<div className={'v-center'}>
														<span>
															<Fab variant="extended" size="small" color="default" aria-label="Timbrar"
															     className={'margin-10-L bg-gray-2'} style={{opacity: '0.5', cursor: 'default'}}>
																<PictureAsTimbrar className={'margin-5-R px-14'}/>
																Timbrar
															</Fab>
														</span>
													</div>
												) : null}*/}
												
												{/*{FieldsJs.inArray([1, 2, 4, 9, 10, 13], this.Usr.id_cat_tipo_usuario) || item.id_usuario_alta_cliente === this.Usr.id_usuario ? (*/}
												{FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario) || item.id_usuario_alta_cliente === this.Usr.id_usuario ? (
													<div className={'v-center'}>
														<ModalFacturacionCancelar
															item={item}
															tipo={'add'}
															componente={(
																<Tooltip TransitionComponent={Zoom} placement={"top"}
																         title="Cancelar">
																	<IconButton aria-label="Cancelar">
																		<Cancel style={{color: item.cancelacion.length > 0 ? '#8E1E32' : ''}}/>
																	</IconButton>
																</Tooltip>
															)}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												) : null}
												
												{FieldsJs.inArray([1, 2, 3, 9, 10], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<ModalFacturacionPromotor
															id_facturacion={item.id_facturacion}
															item={item}
															tipo={'view'}
															icono={(
																<Tooltip TransitionComponent={Zoom} placement={"top"}
																         title="Detalles">
																	<IconButton aria-label="Detalles">
																		<SearchOutlined/>
																	</IconButton>
																</Tooltip>
															)}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												) : null}
												
												{FieldsJs.inArray([1, 4], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<ModalFacturacionFiscal
															id_facturacion={item.id_facturacion}
															item={item}
															tipo={'view'}
															icono={(
																<Tooltip TransitionComponent={Zoom} placement={"top"}
																         title="Detalles">
																	<IconButton aria-label="Detalles">
																		<SearchOutlined/>
																	</IconButton>
																</Tooltip>
															)}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												) : null}
												
												{FieldsJs.inArray([1, 10, 13], item.id_cat_tipo_usuario) && !FieldsJs.inArray([4, 9], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<ModalFacturacionPromotorFiscal
															id_facturacion={item.id_facturacion}
															item={item}
															tipo={'view'}
															icono={(
																<Tooltip TransitionComponent={Zoom} placement={"top"}
																         title="Detalle">
																	<IconButton aria-label="Detalle">
																		<SearchOutlined/>
																	</IconButton>
																</Tooltip>
															)}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												) : null}
											</div>
											
											{/*{FieldsJs.inArray([4], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([4], item.id_cat_tipo_usuario) && FieldsJs.inArray([3, 7], item.id_cat_facturacion_estatus) ? (
												<div className={'v-center'}>
													<ModalFacturacionFiscal
														id_facturacion={item.id_facturacion}
														item={item}
														tipo={'view'}
														icono={(
															<Tooltip TransitionComponent={Zoom} placement={"top"}
															         title="Detalle">
																<IconButton aria-label="Detalles">
																	<SearchOutlined/>
																</IconButton>
															</Tooltip>
														)}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
											) : null}*/}
										
										</TableCell>
									</TableRow>
									
									{(
										<Fragment>
											{!!item.expandir ? (
												<TableRow>
													<TableCell align={'center'}
													           className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
													           colSpan={this.ver_root() ? 13 : 13}
													>
														
														<SubTablaFacturacion
															tipo_vista={this.props.tipo_vista}
															item={{lista: item.timbrados}}
															showSnackBars={this.props.showSnackBars}
															RefreshList={this.props.RefreshList}
														/>
													
													</TableCell>
												</TableRow>
											) : null}
										</Fragment>
									)}
								</Fragment>
							)
						})}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	listDropbox: PropTypes.array,
	listar_usuarios: PropTypes.array,
	delete: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
