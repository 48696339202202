import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {Business, HdrStrong, PersonOutlined, SearchOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {FormaPagoService} from "../../../../services/_Cat/FormaPagoService/FormaPagoService";
import {MetodoPagoService} from "../../../../services/_Cat/MetodoPagoService/MetodoPagoService";
import {FacturacionEstatusService} from "../../../../services/_Cat/FacturacionEstatusService/FacturacionEstatusService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";


class FiltrosFacturacion extends Component {
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			id_facturacion: '',
			cliente: '',
			id_cat_forma_pago: '',
			id_cat_metodo_pago: '',
			id_cat_facturacion_estatus: '',
			fecha_alta: false,
			ordenar_fecha_alta: false,
			
			filtro_cat_forma_pago: [],
			filtro_cat_metodo_pago: [],
			filtro_cat_facturacion_estatus: [],
			
			listar_forma_pago: [],
			listar_metodo_pago: [],
			listar_facturacion_estatus: [],
		};
		
		// this.ListaUsuarioDisenador();
		this.ListaFormaPago();
		this.ListaMetodoPago();
		this.ListaFacturacionEstatus();
	}
	
	ListaFormaPago = () => {
		FormaPagoService.Listar({}, {}).then((response) => {
			this.setState({
				listar_forma_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_forma_pago: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaMetodoPago = () => {
		MetodoPagoService.Listar({}, {}).then((response) => {
			this.setState({
				listar_metodo_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_metodo_pago: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaFacturacionEstatus = () => {
		FacturacionEstatusService.Listar({}, {}).then((response) => {
			let status = [];
			if (response.data.length > 0) {
				for (let i = 0; i < response.data.length; i++) {
					if (Number(response.data[i].id_cat_facturacion_estatus) !== 3) {
						status.push(response.data[i])
					}
				}
			}
			this.setState({
				listar_facturacion_estatus: status
			})
		}).catch((error) => {
			this.setState({
				listar_facturacion_estatus: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_facturacion = this.state.id_facturacion || undefined;
		filtro.cliente = this.state.cliente || undefined;
		filtro.id_cat_forma_pago = this.state.id_cat_forma_pago || undefined;
		filtro.id_cat_metodo_pago = this.state.id_cat_metodo_pago || undefined;
		filtro.id_cat_facturacion_estatus = this.state.id_cat_facturacion_estatus || undefined;
		filtro.fecha_alta = this.state.fecha_alta || undefined;
		filtro.ordenar_fecha_alta = this.state.ordenar_fecha_alta ? 1 : undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	/*ListaUsuarioDisenador = () => {
		CatService.ListUsuarioDisenador().then((cat) => {
			this.setState({
				filtro_cat_usuario_disenador: cat.data
			})
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};*/
	
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={6} sm={6} md={4} lg={2} xl={2} align={'left'}>
						<TextField
							type={'text'}
							fullWidth
							name="id_facturacion"
							onChange={this.handleChange}
							label="ID"
							autoComplete={'off'}
							value={this.state.id_facturacion}
						/>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="cliente"
									onChange={this.handleChange}
									label="Cliente"
									autoComplete={'off'}
									value={this.state.cliente}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Business className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="empresa"
									onChange={this.handleChange}
									label="Empresa"
									autoComplete={'off'}
									value={this.state.empresa}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					{/*<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
						<TextField
							select
							fullWidth
							margin="none"
							onChange={this.handleChange}
							SelectProps={{
								native: true,
								MenuProps: {
									className: '',
								},
							}}
							
							name="id_cat_forma_pago"
							label="Forma de pago"
							value={this.state.id_cat_forma_pago}
						>
							<option key={0} value={''}>&nbsp;</option>
							{this.state.listar_forma_pago.map(option => (
								<option key={option.id_cat_forma_pago}
								        value={option.id_cat_forma_pago}>
									{option.forma_pago} - {option.descripcion}
								</option>
							))}
						</TextField>
					</Grid>
					
					<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
						<TextField
							select
							fullWidth
							margin="none"
							onChange={this.handleChange}
							SelectProps={{
								native: true,
								MenuProps: {
									className: '',
								},
							}}
							
							name="id_cat_metodo_pago"
							label="Metodo de pago"
							value={this.state.id_cat_metodo_pago}
						>
							<option key={0} value={''}>&nbsp;</option>
							{this.state.listar_metodo_pago.map(option => (
								<option key={option.id_cat_metodo_pago}
								        value={option.id_cat_metodo_pago}>
									{option.metodo_pago} - {option.descripcion}
								</option>
							))}
						</TextField>
					</Grid>*/}
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_facturacion_estatus"
									label="Estatus"
									value={this.state.id_cat_facturacion_estatus}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_facturacion_estatus.map(option => (
										<option key={option.id_cat_facturacion_estatus}
										        value={option.id_cat_facturacion_estatus}>
											{option.facturacion_estatus}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					
					{/*<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<MDDatePicker
							type={'range'}
							component={(
								<TextField
									fullWidth
									label="Fecha de alta"
									value={(this.state.fecha_alta_inicio && this.state.fecha_alta_fin) ? (DateFormat.FormatText(this.state.fecha_alta_inicio) + " al " + DateFormat.FormatText(this.state.fecha_alta_fin)) : ''}
									readOnly
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton aria-label="Fecha de alta">
													<DateRangeOutlined/>
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							)}
							startDate={this.state.fecha_alta_inicio}
							endDate={this.state.fecha_alta_fin}
							onChange={(range) => {
								this.setState({
									fecha_alta_inicio: range.startDate,
									fecha_alta_fin: range.endDate,
								});
								setTimeout(() => this.HandleFiltro());
							}}
						/>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<FormControlLabel
							control={
								<Checkbox type="checkbox" name='ordenar_fecha_alta'
								          checked={this.state.ordenar_fecha_alta}
								          onChange={this.handleChange} value="activo" color="primary"
								/>
							}
							label={'Ordenar por fecha de alta'}
						/>
					</Grid>*/}
					
					<Grid item xs={6} sm={6} md={4} lg={1} xl={1} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosFacturacion.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosFacturacion;
