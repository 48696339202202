import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const ClienteFacturacionExtService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				cliente: filtro.cliente || null,
				rfc: filtro.rfc || null,
				correo_electronico: filtro.correo_electronico || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
				id_usuario: filtro.id_usuario || '',
				id_proyecto: 1,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Datos', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarTodo: (form) => {
		let params = {
			id_usuario: form.id_usuario || '',
			id_proyecto: 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Datos_All', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalle: (id_cliente) => {
		let params = {
			id_cliente:id_cliente
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Detalle', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cliente: form.id_cliente || '',
			id_usuario: form.id_usuario || '',
			id_proyecto: 1,
			id_cat_tipo_persona: form.id_cat_tipo_persona || '',
			tipo_persona: form.id_cat_tipo_persona === 1 ? 'fisica' : 'moral',
			id_empresa: form.id_empresa || '',
			nombre_razon_social: form.nombre_razon_social || '',
			correo_electronico: form.correo_electronico || '',
			correo_electronico_copia: form.correo_electronico_copia || '',
			celular: form.celular || '',
			telefono: form.telefono || '',
			rfc: form.rfc || '',
			curp: form.curp || '',
			codigo_postal: form.codigo_postal || '',
			registro_identidad_fiscal: form.registro_identidad_fiscal || '',
			id_cat_pais: form.id_cat_pais || '',
			id_cat_regimen_fiscal: form.id_cat_regimen_fiscal || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Guardar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cliente: form.id_cliente || '',
			id_usuario: form.id_usuario || '',
			id_proyecto: 1,
			id_cat_tipo_persona: form.id_cat_tipo_persona || '',
			tipo_persona: form.id_cat_tipo_persona === 1 ? 'fisica' : 'moral',
			id_empresa: form.id_empresa || '',
			nombre_razon_social: form.nombre_razon_social || '',
			correo_electronico: form.correo_electronico || '',
			correo_electronico_copia: form.correo_electronico_copia || '',
			celular: form.celular || '',
			telefono: form.telefono || '',
			rfc: form.rfc || '',
			curp: form.curp || '',
			codigo_postal: form.codigo_postal || '',
			registro_identidad_fiscal: form.registro_identidad_fiscal || '',
			id_cat_pais: form.id_cat_pais || '',
			id_cat_regimen_fiscal: form.id_cat_regimen_fiscal || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Modificar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cliente) => {
		let params = {
			id_cliente: id_cliente
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Eliminar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ClienteRFC: (rfc) => {
		let params = {
			rfc: rfc
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_RFC', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
