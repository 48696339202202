import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import {CONFIG} from '../../../../settings/Config/Config';
import {withStyles} from '@material-ui/core/styles/index';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";


const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
		backgroundColor: 'transparent'
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {};
		
	}
	
	
	eliminar = (item) => {
		this.props.$BroadcastModalUsuarios({
			accion: 'delete',
			status: true,
			item: item
		});
	};
	
	
	render() {
		
		const {classes, lista} = this.props;
		
		
		return (
			
			<Grid container spacing={2} className={'h-100'}>
				
				
				{this.props.filtros.view ?
					<Fragment>
						{lista.length > 0 ? lista.map((item, key) => (
								<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
									<Card className={classes.root}>
										
										<article>
											<article className={'bg-size-datos-personales-transparent'}>
												<div className={'row-flex'}>
													<div className={'w-100 bg-img-cover-x-center-y-center'} style={{backgroundImage: `url(${CONFIG.src + item.fondo_usuario})`}}>
														<div className={'w-100 padding-10-B padding-10-T'} align={'center'}>
															<div className={'w-150-px h-150-px card-1 b-r-100'}>
																<div
																	className={'w-150-px h-150-px b-r-100 bg-img-contain-x-center-y-center'}
																	style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
																</div>
															</div>
														</div>
													</div>
												</div>
												
												<div className={'padding-15'}>
													
													
													<div className={'w-100 margin-10-T margin-10-B'}>
														<Typography variant="h5" gutterBottom>
															{item.nombre_completo}
														</Typography>
													</div>
													
													<div className={'w-100 margin-10-T margin-10-B'}>
														<div className={'w-100 vertical-inline'}>
															<div className={'v-center w-50'}>
																<Typography className={'px-14'} gutterBottom>
																	Fecha de Registro:
																</Typography>
																<Typography className={'px-14'} gutterBottom>
																	{item.created_at}
																</Typography>
															</div>
															<div className={'v-center w-50'}>
																<Typography className={'px-14'} gutterBottom>
																	Ultima Sesión:
																</Typography>
																<Typography className={'px-14'} style={{fontWeight: 'bold', color: '#901C30'}} gutterBottom>
																	{item.ultima_sesion}
																</Typography>
															</div>
														</div>
													</div>
													
													<div className={'w-100 margin-10-T margin-10-B text-left'}>
														<Typography className={'px-14'} gutterBottom>
															Correo Electronico:
														</Typography>
														<Typography className={'px-14'} gutterBottom>
															{item.correo_electronico}
														</Typography>
													</div>
													
													<div className={'w-100 margin-10-T margin-10-B text-left'}>
														<Typography className={'px-14'} gutterBottom>
															Teléfono / Celular:
														</Typography>
														<Typography className={'px-14'} gutterBottom>
															{item.telefono} {item.telefono && item.celular ? ' / ' : ' '} {item.celular}
														</Typography>
													</div>
													<div className={'w-100 margin-10-T margin-10-B text-right'}>
														<Typography className={'px-14'} style={{fontWeight: 'bold'}} gutterBottom>
															{item.tipo_usuario}
														</Typography>
													</div>
												</div>
												
												{/*<div className="padding-10 padding-30-T">
										<Button variant="contained" color="primary"
										        className={'btn-default-primary text-transform-none'}>
											Guardar datos personales
										</Button>
									</div>*/}
											</article>
										</article>
									
									</Card>
								</Grid>
							)
						) : null
						}
					</Fragment>
					:
					<Fragment>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Table className={classes.table + ' desing-acresco margin-20-T'}>
								<TableHead>
									<TableRow>
										<TableCell component="th" align={'left'}>Nombre completo</TableCell>
										<TableCell component="th" align={'left'}>
											Usuario y Correo electrónico
										</TableCell>
										<TableCell component="th" align={'center'}>Teléfono</TableCell>
										<TableCell component="th" align={'center'}>Ultima sesión</TableCell>
										<TableCell component="th" align={'center'}>Id / Usuario Rocket Chat</TableCell>
										<TableCell component="th" align={'center'}>Recibir correo electrónico</TableCell>
										<TableCell component="th" align={'center'}>Activo</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										lista.map((item, key) => {
											return (
												<TableRow key={key}>
													
													<TableCell align={'left'}>
														{item.nombre} {item.apellido_paterno} {item.apellido_materno}<br/>
														<b>({item.tipo_usuario})</b>
													</TableCell>
													
													<TableCell align={'left'}>
														<div>
															<div>
																Usuario: {item.username}<br/>
															</div>
															<div>
																<Typography className={'px-12'}>
																	Contraseña: {item.password || 'En proceso'}<br/><br/>
																</Typography>
															</div>
															<div>
																Correo electrónico: {item.correo_electronico}
															</div>
														</div>
													</TableCell>
													
													<TableCell align={'center'}>
														{item.telefono || 'Sin telefono'}
													</TableCell>
													
													<TableCell align={'center'}>
														{item.ultima_sesion}
													</TableCell>
													
													<TableCell align={'center'}>
														{!item.id_rocket_chat && !item.usuario_rocket_chat ? 'Sin registrar' : (
															<Fragment>
																<p className={'margin-0'}>{item.id_rocket_chat}</p>
																<p className={'margin-0'} style={{backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: 6}}>{item.usuario_rocket_chat}</p>
															</Fragment>
														)}
													</TableCell>
													
													<TableCell align={'center'}>
														{item.sendmail === 1 ? (
															<div style={{
																width: "30px",
																color: "white",
																borderRadius: "3px",
																padding: "2px 10px",
																background: "darkgreen",
																display: "inline-grid",
															}}>Si</div>
														) : (
															<div style={{
																width: "30px",
																color: "white",
																borderRadius: "3px",
																padding: "2px 10px",
																background: "#901C30",
																display: "inline-grid",
															}}>No</div>
														)}
													</TableCell>
													
													<TableCell align={'center'}>
														{item.activo === 1 ? (
															<div style={{
																width: "50px",
																color: "white",
																borderRadius: "3px",
																padding: "2px 10px",
																background: "darkgreen",
																display: "inline-grid",
															}}>Activo</div>
														) : (
															<div style={{
																width: "50px",
																color: "white",
																borderRadius: "3px",
																padding: "2px 10px",
																background: "#901C30",
																display: "inline-grid",
															}}>Inactivo</div>
														)}
													</TableCell>
												
												</TableRow>
											)
										})
									}
								</TableBody>
							</Table>
						</Grid>
					</Fragment>
				}
			
			</Grid>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	filtros: PropTypes.object,
	$BroadcastModalUsuarios: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
