import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {TimbradoService} from "../../../../services/TimbradoService/TimbradoService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {AddOutlined, Cancel, CancelOutlined, CloudDownload, DeleteOutlined, EditOutlined, PlaylistAddOutlined, SaveOutlined, Visibility} from '@material-ui/icons';
import Fab from "@material-ui/core/Fab";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";

import carrito_de_compra_3 from '../../../../assets/img/icons/facturacion.svg'
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ModalCliente from "../../ClienteFacturacion/Includes/ModalClienteFacturacion";
import ModalEmpresa from "../../EmpresaFacturacion/Includes/ModalEmpresaFacturacion";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {CONFIG} from "../../../../settings/Config/Config";
import {MetodoPagoService} from "../../../../services/_Cat/MetodoPagoService/MetodoPagoService";
import {FormaPagoService} from "../../../../services/_Cat/FormaPagoService/FormaPagoService";
import {ClaveProductoServicioService} from "../../../../services/_Cat/ClaveProductoServicioService/ClaveProductoServicioService";
import {ClaveUnidadMedidaService} from "../../../../services/_Cat/ClaveUnidadMedidaService/ClaveUnidadMedidaService";
import {TipoComprobanteService} from "../../../../services/_Cat/TipoComprobanteService/TipoComprobanteService";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import {TabPanel} from "@material-ui/lab";
import TabContext from "@material-ui/lab/TabContext";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";

class ModalTimbrado extends Component {
	
	state = {};
	
	Usr = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			id_facturacion: props.id_facturacion,
			
			
			serie: '',
			folio: '',
			
			id_cat_forma_pago: '',
			id_cat_metodo_pago: '',
			id_cat_facturacion_estatus: '',
			id_cat_periodo_pago: '',
			observacion_cfdi: '',
			observacion_interna: '',
			fecha_emitir_comprobante: null,
			facturacion_total: '',
			
			key: null,
			id_cat_clave_producto_servicio: null,
			id_clave_unidad_medida: null,
			id_cat_tipo_comprobante: '',
			
			numero_identificacion: '',
			descripcion_concepto: '',
			cantidad: 1.0,
			precio_unitario: '',
			total: '',
			iva_incluido: false,
			iva_aplicado: false,
			
			lista_facturacion_producto: [],
			
			listar_cliente: [],
			listar_empresa: [],
			listar_empresa_sucursal: [],
			listar_empresa_regimen_fiscal: [],
			
			listar_forma_pago: [],
			listar_clave_producto_servicio: [],
			listar_clave_unidad_medida: [],
			listar_tipo_comprobante: [],
			
			listar_metodo_pago: [],
			listar_periodo_pago: [],
			listar_cat_impuesto: [],
			listar_cat_tasa: [],
			listar_concepto: [],
			listar_unidad_medida: [],
			
			listar_cat_impuestos: [],
			listar_cat_impuestos_retenidos: [],
			listar_cat_impuestos_trasladados: [],
			listar_cat_tipo_factor: [],
			
			
			id_empresa: '',
			id_cliente: '',
			id_empresa_sucursal: '',
			id_cat_regimen_fiscal: '',
			lugar_expedicion: '',
			cliente_regimen_fiscal: '',
			cliente_numero_registro_if: '',
			clave_concepto: '',
			tipo_descuento: '1',
			descuento: '',
			id_empresa_clave_unidad: '',
			
			
			cat_regimen_fiscal: [],
			valuetab: '1'
		};
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	handleChangeIvaIncluido = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		
		if (!this.state.iva_incluido)
			this.setState({
				iva_aplicado: false
			});
	};
	
	handleChangeIvaAplicado = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		
		if (!this.state.iva_aplicado)
			this.setState({
				iva_incluido: false
			});
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		
		this.ListaFormaPago();
		this.ListaMetodoPago();
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		this.ListaImpuestos();
		this.ListaImpuestosRetenidos();
		this.ListaImpuestosTraslados();
		this.ListaTipoFactor();
		this.ListaEmpresa();
		this.ListaCliente();
		this.ListaMetodoPago();
		this.ListaFormaPago();
		this.ListaClaveProductoServicio();
		this.ListaClaveUnidadMedida();
		this.ListaTipoComprobante();
		
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};
	
	RefreshListFacturacion = (id_cliente) => {
		if (id_cliente > 0) {
			this.setState({
				id_cliente: id_cliente
			})
		}
		this.ListaCliente();
		this.ListaMetodoPago();
		this.ListaFormaPago();
		this.ListaClaveProductoServicio();
		this.ListaClaveUnidadMedida();
		this.ListaTipoComprobante();
		this.ListaEmpresa();
	};
	
	RefreshListFormaPago = (id_cat_forma_pago) => {
		if (id_cat_forma_pago > 0) {
			this.setState({
				id_cat_forma_pago: id_cat_forma_pago
			})
		}
		this.ListaFormaPago();
	};
	
	RefreshListMetodoPago = (id_cat_metodo_pago) => {
		if (id_cat_metodo_pago > 0) {
			this.setState({
				id_cat_metodo_pago: id_cat_metodo_pago
			})
		}
		this.ListaMetodoPago();
	};
	
	RefreshListClaveProductoServicio = (id_cat_clave_producto_servicio) => {
		if (id_cat_clave_producto_servicio > 0) {
			this.setState({
				id_cat_clave_producto_servicio: id_cat_clave_producto_servicio
			})
		}
		this.ListaClaveProductoServicio();
		this.ListaClaveUnidadMedida();
		setTimeout(() => this.colocarValoresEnCampos(id_cat_clave_producto_servicio), 1000);
	};
	
	colocarValoresEnCampos = (id) => {
		let id_cat_clave_producto_servicio = Number(id);
		let id_cat_clave_unidad_medida = '';
		let producto_servicio = '';
		let numero_identificacion = '';
		for (let i = 0; i < this.state.listar_clave_producto_servicio.length; i++) {
			if (Number(this.state.listar_clave_producto_servicio[i].id_cat_clave_producto_servicio) === Number(id_cat_clave_producto_servicio)) {
				id_cat_clave_unidad_medida = this.state.listar_clave_producto_servicio[i].id_cat_clave_unidad_medida;
				producto_servicio = this.state.listar_clave_producto_servicio[i].producto_servicio;
				numero_identificacion = this.state.listar_clave_producto_servicio[i].numero_identificacion;
			}
		}
		this.setState({
			id_cat_clave_unidad_medida: id_cat_clave_unidad_medida,
			descripcion_concepto: producto_servicio,
			numero_identificacion: numero_identificacion
		});
	};
	
	RefreshListClaveUnidadMedida = (id_cat_clave_unidad_medida) => {
		if (id_cat_clave_unidad_medida > 0) {
			this.setState({
				id_cat_clave_unidad_medida: id_cat_clave_unidad_medida
			})
		}
		this.ListaClaveUnidadMedida();
	};
	
	RefreshListTipoComprobante = (id_cat_tipo_comprobante) => {
		if (id_cat_tipo_comprobante > 0) {
			this.setState({
				id_cat_tipo_comprobante: id_cat_tipo_comprobante
			})
		}
		this.ListaTipoComprobante();
	};
	
	RefreshListEmpresa = (id_empresa) => {
		if (id_empresa > 0) {
			this.setState({
				id_empresa: id_empresa
			})
		}
		this.ListaEmpresa();
	};
	
	ListaEmpresa = () => {
		CatService.ListEmpresaFac(2, this.Usr.id_usuario, this.Usr.id_plaza).then((response) => {
			this.setState({
				listar_empresa: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_empresa: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaEmpresaSucursal = (id_empresa) => {
		if (FieldsJs.Field(id_empresa)) {
			CatService.ListEmpresaSucursalFac(id_empresa, {}).then((response) => {
				this.setState({
					listar_empresa_sucursal: response.data,
				});
			}).catch((error) => {
				this.setState({
					listar_empresa_sucursal: []
				});
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			this.setState({
				listar_empresa_sucursal: []
			});
		}
	};
	
	ListaEmpresaRegimenFiscal = (id_empresa) => {
		if (FieldsJs.Field(id_empresa)) {
			CatService.ListEmpresaRegimenFiscalFac(id_empresa, {}).then((response) => {
				this.setState({
					listar_empresa_regimen_fiscal: response.data,
				});
			}).catch((error) => {
				this.setState({
					listar_empresa_regimen_fiscal: []
				});
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			this.setState({
				listar_empresa_regimen_fiscal: []
			});
		}
	};
	
	ListaCliente = () => {
		CatService.ListClienteFac({}, {}).then((response) => {
			this.setState({
				listar_cliente: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cliente: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	lugarExpedicion = (id_empresa_sucursal) => {
		for (let i = 0; i < this.state.listar_empresa_sucursal.length; i++) {
			if (Number(id_empresa_sucursal) === Number(this.state.listar_empresa_sucursal[i].id_empresa_sucursal)) {
				this.setState({
					lugar_expedicion: this.state.listar_empresa_sucursal[i].codigo_postal
				});
			}
		}
	}
	
	clienteRecidenciaRegistro = (id_cliente) => {
		for (let i = 0; i < this.state.listar_cliente.length; i++) {
			if (Number(id_cliente) === Number(this.state.listar_cliente[i].id_cliente)) {
				this.setState({
					cliente_regimen_fiscal: this.state.listar_cliente[i].descripcion ? this.state.listar_cliente[i].regimen_fiscal + ' - ' + this.state.listar_cliente[i].descripcion : this.state.listar_cliente[i].regimen_fiscal,
					cliente_numero_registro_if: this.state.listar_cliente[i].numero_registro_if
				});
			}
		}
	}
	
	ListaUnidadMedida = (id_empresa) => {
		return new Promise((resolve, reject) => {
			let params = {
				id_empresa: id_empresa,
				valuetab: '2'
			};
			EmpresaFacturacionExtService.Catalogos(params).then((response) => {
				this.setState({
					listar_unidad_medida: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					listar_unidad_medida: []
				});
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	ListaConcepto = (id_empresa) => {
		return new Promise((resolve, reject) => {
			let params = {
				id_empresa: id_empresa,
				valuetab: '3'
			};
			EmpresaFacturacionExtService.Catalogos(params).then((response) => {
				this.setState({
					listar_concepto: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					listar_concepto: []
				});
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	ConceptoSelect = (id_empresa_clave_producto) => {
		let con = FieldsJs.Copy(this.state.listar_concepto);
		for (let i = 0; i < con.length; i++) {
			if (Number(id_empresa_clave_producto) === con[i].id_empresa_clave_producto) {
				this.setState({
					clave_concepto: con[i].clave_producto_servicio,
					precio_unitario: con[i].precio_unitario,
					id_empresa_clave_unidad: con[i].id_empresa_clave_unidad,
					numero_identificacion: con[i].numero_identificacion,
					tipo_descuento: con[i].tipo_descuento.toString(),
					descuento: con[i].descuento,
				});
			}
		}
	}
	
	obtenerDatosConcepto = () => {
		let con = FieldsJs.Copy(this.state.listar_concepto);
		let band = false;
		for (let i = 0; i < con.length; i++) {
			if (this.state.clave_concepto === con[i].clave_producto_servicio) {
				band = true;
				this.setState({
					clave_concepto: con[i].clave_producto_servicio,
					id_empresa_clave_producto: con[i].id_empresa_clave_producto,
					precio_unitario: con[i].precio_unitario,
					id_empresa_clave_unidad: con[i].id_empresa_clave_unidad,
					numero_identificacion: con[i].numero_identificacion,
					tipo_descuento: con[i].tipo_descuento.toString(),
					descuento: con[i].descuento,
				});
			}
		}
		if (!band) {
			this.setState({
				id_empresa_clave_producto: '',
				precio_unitario: '',
				id_empresa_clave_unidad: '',
				numero_identificacion: '',
				tipo_descuento: '',
				descuento: '',
			});
		}
	}
	
	ListaImpuestos = () => {
		CatService.ListImpuestoFac().then((response) => {
			this.setState({
				listar_cat_impuestos: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_impuestos: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaImpuestosRetenidos = () => {
		CatService.ListImpuestoRetenidoFac().then((response) => {
			this.setState({
				listar_cat_impuestos_retenidos: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_impuestos_retenidos: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaImpuestosTraslados = () => {
		CatService.ListImpuestoTrasladoFac().then((response) => {
			this.setState({
				listar_cat_impuestos_trasladados: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_impuestos_trasladados: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaTipoFactor = () => {
		CatService.ListTipoFactorFac().then((response) => {
			this.setState({
				listar_cat_tipo_factor: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_tipo_factor: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	ListaFormaPago = () => {
		FormaPagoService.Listar({}, {}).then((response) => {
			this.setState({
				listar_forma_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_forma_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaMetodoPago = () => {
		MetodoPagoService.Listar({}, {}).then((response) => {
			this.setState({
				listar_metodo_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_metodo_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaClaveProductoServicio = () => {
		ClaveProductoServicioService.Listar({}, {}).then((response) => {
			this.setState({
				listar_clave_producto_servicio: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_clave_producto_servicio: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaClaveUnidadMedida = () => {
		ClaveUnidadMedidaService.Listar({}, {}).then((response) => {
			this.setState({
				listar_clave_unidad_medida: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_clave_unidad_medida: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaTipoComprobante = () => {
		TipoComprobanteService.Listar({}, {}).then((response) => {
			this.setState({
				listar_tipo_comprobante: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_tipo_comprobante: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	add = () => {
		
		try {
			
			if (!this.state.id_cat_clave_producto_servicio) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Clave producto servicio.'
				})
			}
			
			if (!this.state.id_cat_clave_unidad_medida) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Clave unidad medida.'
				})
			}
			
			if (!this.state.descripcion_concepto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Descripción.'
				})
			}
			
			if (!(this.state.cantidad > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cantidad.'
				})
			}
			
			if (!(this.state.precio_unitario > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Precio unitario.'
				})
			}
			
			if (!(this.state.total > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Total.'
				})
			}
			
			let lista_facturacion_producto = FieldsJs.Copy(this.state.lista_facturacion_producto || []);
			
			if (this.is_edited_product()) {
				for (let i = 0; i < lista_facturacion_producto.length; i++) {
					let item = lista_facturacion_producto[i];
					if (this.state.key === i) {
						item.id_cat_clave_producto_servicio = this.state.id_cat_clave_producto_servicio || null;
						item.id_cat_clave_unidad_medida = this.state.id_cat_clave_unidad_medida || null;
						item.id_facturacion = this.state.id_facturacion || null;
						item.descripcion_concepto = this.state.descripcion_concepto || '';
						item.cantidad = this.state.cantidad || 0;
						item.precio_unitario = this.state.precio_unitario || 0;
						item.total = this.state.total || 0;
						item.iva_incluido = this.state.iva_incluido ? 1 : 0;
						item.iva_aplicado = this.state.iva_aplicado ? 1 : 0;
					}
				}
			} else {
				let item;
				item = {
					id_cat_clave_producto_servicio: this.state.id_cat_clave_producto_servicio || null,
					id_cat_clave_unidad_medida: this.state.id_cat_clave_unidad_medida || null,
					id_facturacion: this.state.id_facturacion || null,
					descripcion_concepto: this.state.descripcion_concepto || '',
					cantidad: this.state.cantidad || 0,
					precio_unitario: this.state.precio_unitario || 0,
					numero_identificacion: this.state.numero_identificacion || 0,
					iva_incluido: this.state.iva_incluido ? 1 : 0,
					iva_aplicado: this.state.iva_aplicado ? 1 : 0,
					total: this.state.total || 0,
				};
				lista_facturacion_producto.push(item);
			}
			
			this.setState({
				key: null,
				id_cat_clave_producto_servicio: '',
				id_cat_clave_unidad_medida: '',
				descripcion_concepto: '',
				cantidad: 1.0,
				precio_unitario: '',
				numero_identificacion: '',
				total: '',
				iva_incluido: false,
				iva_aplicado: false,
				lista_facturacion_producto: lista_facturacion_producto
			});
			
			this.calcularTotalCompra();
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	save = () => {
		try {
			if (!this.state.id_empresa) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Empresa.'
				})
			}
			
			if (!this.state.id_cliente) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cliente.'
				})
			}
			
			if (!this.state.id_cat_forma_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Forma de pago.'
				})
			}
			
			if (!this.state.id_cat_metodo_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Metodo de pago.'
				})
			}
			
			if (!this.state.fecha_emitir_comprobante) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha de factura.'
				})
			}
			
			if (!this.state.facturacion_total) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Importe.'
				})
			}
			
			try {
				TimbradoService.Guardar(this.state).then((response) => {
					this.closeModal();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			} catch (e) {
				this.props.showSnackBars('error', e.mensaje);
			}
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	edit = (value, key) => {
		try {
			
			this.setState({
				key: key,
				id_cat_clave_producto_servicio: value.id_cat_clave_producto_servicio,
				id_cat_clave_unidad_medida: value.id_cat_clave_unidad_medida,
				descripcion_concepto: value.descripcion_concepto,
				cantidad: value.cantidad,
				precio_unitario: value.precio_unitario,
				numero_identificacion: value.numero_identificacion,
				iva_incluido: (value.iva_incluido === 1),
				iva_aplicado: (value.iva_aplicado === 1),
				total: value.total,
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_cat_clave_producto_servicio: '',
			id_cat_clave_unidad_medida: '',
			descripcion_concepto: '',
			cantidad: '',
			precio_unitario: '',
			numero_identificacion: '',
			total: '',
			iva_incluido: false,
			iva_aplicado: false,
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar este concepto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_facturacion_producto = FieldsJs.Copy(this.state.lista_facturacion_producto);
					
					lista_facturacion_producto.splice(key, 1);
					
					this.setState({
						lista_facturacion_producto: lista_facturacion_producto
					});
					
					this.calcularTotalCompra();
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	calcularTotalProducto = () => {
		setTimeout(() => {
			let cantidad = this.state.cantidad;
			let precio_unitario = this.state.precio_unitario;
			let total = 0;
			
			if (cantidad > 0 && precio_unitario > 0) {
				total = (cantidad * precio_unitario);
			}
			
			this.setState({
				total: total
			});
		});
	};
	
	calcularPrecioUnitarioProducto = () => {
		setTimeout(() => {
			let cantidad = this.state.cantidad || 1;
			let precio_unitario = 0;
			let total = this.state.total;
			
			if (cantidad > 0 && total > 0) {
				if (this.state.iva_aplicado === 0) {
					precio_unitario = (total / cantidad);
				}
			}
			
			this.setState({
				cantidad: cantidad,
				precio_unitario: precio_unitario
			});
		});
	};
	
	calcularTotalCompra = () => {
		setTimeout(() => {
			let lista_facturacion_producto = this.state.lista_facturacion_producto || [];
			let total = 0;
			for (let i = 0; i < lista_facturacion_producto.length; i++) {
				let item = lista_facturacion_producto[i];
				if (Number(item.total) > 0) {
					total += this.calcularTotalConSinIVA(item);
				}
			}
			this.setState({
				facturacion_total: total
			});
		});
	};
	
	calcularSubTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1 && value.iva_aplicado === 0) {
			return value.precio_unitario / 1.16;
		} else {
			return value.precio_unitario;
		}
	};
	
	calcularIVAPrecioUnitario = (value) => {
		if (value.iva_aplicado === 0) {
			if (value.iva_incluido === 1) {
				return value.precio_unitario - (value.precio_unitario / 1.16);
			} else {
				return value.precio_unitario * 0.16;
			}
		} else {
			return 0;
		}
	};
	
	calcularTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1 || value.iva_aplicado === 1) {
			return value.precio_unitario;
		} else {
			return value.precio_unitario * 1.16;
		}
	};
	
	calcularSubTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1 && value.iva_aplicado === 0) {
			return value.total / 1.16;
		} else {
			return value.total;
		}
	};
	
	calcularIVA = (value) => {
		if (value.iva_aplicado === 0) {
			if (value.iva_incluido === 1) {
				return value.total - (value.total / 1.16);
			} else {
				return value.total * 0.16;
			}
		} else {
			return 0;
		}
	};
	
	calcularTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1 || value.iva_aplicado === 1) {
			return value.total;
		} else {
			return value.total * 1.16;
		}
	};
	
	calcularDesgoseTotales = () => {
		let desglose = {
			subtotal: 0,
			iva: 0,
			total: 0,
		};
		let lista_facturacion_producto = this.state.lista_facturacion_producto;
		for (let i = 0; i < lista_facturacion_producto.length; i++) {
			let item = lista_facturacion_producto[i];
			desglose.subtotal += this.calcularSubTotalConSinIVA(item);
			desglose.iva += this.calcularIVA(item);
			desglose.total += this.calcularTotalConSinIVA(item);
		}
		return desglose;
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	getClaveProductoServicio = (id_clave_ps) => {
		let clave;
		
		for (let i = 0; i < this.state.listar_clave_producto_servicio.length; i++) {
			if (Number(id_clave_ps) === Number(this.state.listar_clave_producto_servicio[i].id_cat_clave_producto_servicio)) {
				clave = this.state.listar_clave_producto_servicio[i].clave_producto_servicio;
			}
		}
		return clave;
	};
	
	handleChangeTabs = (e, variable, campo, date, input) => {
		console.log(e, variable);
		this.setState({
			valuetab: variable
		});
	};
	
	render() {
		
		const is_root = this.is_root();
		
		const desglose = this.calcularDesgoseTotales();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Facturación
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TabContext value={this.state.valuetab}>
									<AppBar position="static">
										<TabList variant="fullWidth" onChange={this.handleChangeTabs} aria-label="simple tabs example">
											<Tab label={<Fragment>
												<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>Datos Fiscales</p>
												{/*<p className={'margin-0 padding-0 px-13'}>{this.props.titulo}</p>*/}
											</Fragment>} value="1"/>
											<Tab label={<Fragment>
												<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>Comprobante</p>
												{/*<p className={'margin-0 padding-0 px-13'}>Contratos individuales firmados</p>*/}
											</Fragment>} value="2"/>
											<Tab label={<Fragment>
												<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>Generar</p>
												{/*<p className={'margin-0 padding-0 px-13'}>Contratos individuales firmados</p>*/}
											</Fragment>} value="3"/>
										</TabList>
									</AppBar>
									
									<TabPanel value="1" className={'padding-0-L padding-0-R'}>
										<Grid container spacing={2} alignContent={"flex-start"} alignItems={"stretch"}>
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
												
												<fieldset className={'borde-punteado-gray-2 text-left'}>
													<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Emisor</legend>
													
													<Grid container spacing={2}>
														
														<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
															<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
																{!this.is_view() ? (
																	<Grid item style={{width: '50px'}}>
																		<ModalEmpresa
																			componente={(
																				<Tooltip
																					TransitionComponent={Zoom}
																					placement={"right"}
																					title="Empresa"
																					children={(
																						<Fab
																							color="default"
																							size="small"
																							aria-label="Agregar empresa"
																							children={(
																								<PlaylistAddOutlined/>
																							)}
																						/>
																					)}
																				/>
																			)}
																			id_empresa={null}
																			tipo={'add'}
																			item={{}}
																			RefreshList={this.RefreshListEmpresa}
																			showSnackBars={this.props.showSnackBars}
																		/>
																	</Grid>
																) : null}
																<Grid item style={{width: 'calc(100% - 50px)'}}>
																	<TextField
																		select
																		fullWidth
																		required
																		margin="none"
																		onChange={(e) => {
																			this.handleChange(e);
																			this.ListaEmpresaSucursal(e.target.value);
																			this.ListaEmpresaRegimenFiscal(e.target.value);
																			this.ListaConcepto(e.target.value);
																			this.ListaUnidadMedida(e.target.value);
																		}}
																		disabled={this.is_view()}
																		SelectProps={{
																			native: true,
																			MenuProps: {
																				className: '',
																			},
																		}}
																		name="id_empresa"
																		label="Empresa"
																		value={this.state.id_empresa}
																	>
																		<option key={0} value={''}>&nbsp;</option>
																		{this.state.listar_empresa.map(option => (
																			<option key={option.id_empresa} value={option.id_empresa}>
																				{option.nombre_razon_social}
																			</option>
																		))}
																	</TextField>
																</Grid>
															</Grid>
														</Grid>
														
														<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
															<FormControl fullWidth required>
																<InputLabel>Sucursal</InputLabel>
																<Select
																	native
																	value={this.state.id_empresa_sucursal}
																	onChange={(e) => {
																		this.handleChange(e);
																		this.lugarExpedicion(e.target.value);
																	}}
																	name="id_empresa_sucursal"
																	input={<Input/>}
																	disabled={this.props.tipo === 'view'}
																>
																	<option value=""/>
																	{this.state.listar_empresa_sucursal.map(option => (
																		<option key={option.id_empresa_sucursal}
																		        value={option.id_empresa_sucursal}>
																			{option.nombre}
																		</option>
																	))}
																</Select>
															</FormControl>
														</Grid>
														
														<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
															<FormControl fullWidth required>
																<InputLabel>Régimen Fiscal</InputLabel>
																<Select
																	native
																	value={this.state.id_empresa_regimen_fiscal}
																	onChange={(e) => {
																		this.handleChange(e);
																	}}
																	name="id_empresa_regimen_fiscal"
																	input={<Input/>}
																	disabled={this.props.tipo === 'view'}
																>
																	<option value=""/>
																	{this.state.listar_empresa_regimen_fiscal.map(option => (
																		<option key={option.id_empresa_regimen_fiscal}
																		        value={option.id_empresa_regimen_fiscal}>
																			{option.descripcion ? option.regimen_fiscal + ' - ' + option.descripcion : option.regimen_fiscal}
																		</option>
																	))}
																</Select>
															</FormControl>
														</Grid>
														
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															
															<TextField
																required
																name="lugar_expedicion"
																label="Lugar de Expedición"
																type="lugar_expedicion"
																fullWidth
																value={this.state.lugar_expedicion}
																onChange={this.handleChange}
																disabled
															/>
														
														</Grid>
													
													</Grid>
												
												</fieldset>
											
											</Grid>
											
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
												
												<fieldset className={'borde-punteado-gray-2 text-left'}>
													<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Receptor</legend>
													
													<Grid container spacing={2}>
														
														<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
															<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
																{!this.is_view() ? (
																	<Grid item style={{width: '50px'}}>
																		
																		<ModalCliente
																			componente={(
																				<Tooltip
																					TransitionComponent={Zoom}
																					placement={"right"}
																					title="Agregar nuevo cliente"
																					children={(
																						<Fab
																							color="default"
																							size="small"
																							aria-label="Agregar nuevo cliente"
																							children={(
																								<PlaylistAddOutlined/>
																							)}
																						/>
																					)}
																				/>
																			)}
																			id_cliente={null}
																			tipo={'add'}
																			item={{}}
																			RefreshList={this.RefreshListFacturacion}
																			showSnackBars={this.props.showSnackBars}
																		/>
																	</Grid>
																) : null}
																<Grid item style={{width: 'calc(100% - 50px)'}}>
																	<TextField
																		select
																		fullWidth
																		required
																		margin="none"
																		onChange={(e) => {
																			this.handleChange(e);
																			this.clienteRecidenciaRegistro(e.target.value);
																		}}
																		disabled={this.is_view()}
																		SelectProps={{
																			native: true,
																			MenuProps: {
																				className: '',
																			},
																		}}
																		name="id_cliente"
																		label="RFC Receptor"
																		value={this.state.id_cliente}
																	>
																		<option key={0} value={''}>&nbsp;</option>
																		{this.state.listar_cliente.map(option => (
																			<option key={option.id_cliente} value={option.id_cliente}>
																				{option.rfc + ' - ' + option.nombre_razon_social}
																			</option>
																		))}
																	</TextField>
																</Grid>
															</Grid>
														</Grid>
														
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															
															<TextField
																name="cliente_regimen_fiscal"
																label="Régimen fiscal"
																type="cliente_regimen_fiscal"
																fullWidth
																value={this.state.cliente_regimen_fiscal}
																onChange={this.handleChange}
																disabled
															/>
														
														</Grid>
														
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															
															<TextField
																name="cliente_numero_registro_if"
																label="No. Registro Identidad Fiscal"
																type="cliente_numero_registro_if"
																fullWidth
																value={this.state.cliente_numero_registro_if}
																onChange={this.handleChange}
																disabled
															/>
														
														</Grid>
													
													
													</Grid>
												
												</fieldset>
											
											</Grid>
										</Grid>
									</TabPanel>
									
									<TabPanel value="2" className={'padding-0-L padding-0-R'}>
										<Grid container spacing={2} alignContent={"flex-start"} alignItems={"stretch"}>
											
											<Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
												<TextField
													margin="none"
													name="clave_concepto"
													label="Clave Concepto"
													type="text"
													fullWidth
													value={this.state.clave_concepto}
													onChange={this.handleChange}
													required
													onKeyDown={this.obtenerDatosConcepto}
												/>
											</Grid>
											
											<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
												<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
													{FieldsJs.inArray([1, 2, 4, 9], this.Usr.id_cat_tipo_usuario) ? (
														<Grid item style={{width: 'calc(100%)'}}>
															<TextField
																select
																fullWidth
																required
																margin="none"
																onChange={(e) => {
																	this.handleChange(e);
																	this.ConceptoSelect(e.target.value);
																}}
																disabled={this.is_view()}
																SelectProps={{
																	native: true,
																	MenuProps: {
																		className: '',
																	},
																}}
																name="id_empresa_clave_producto"
																label="Concepto"
																value={this.state.id_empresa_clave_producto}
															>
																<option key={0} value={''}>&nbsp;</option>
																{this.state.listar_concepto.map(option => (
																	<option key={option.id_empresa_clave_producto} value={option.id_empresa_clave_producto}>
																		{option.concepto}
																	</option>
																))}
															</TextField>
														</Grid>
													) : null}
												</Grid>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
												<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
													{FieldsJs.inArray([1, 2, 4, 9], this.Usr.id_cat_tipo_usuario) ? (
														<Grid item style={{width: 'calc(100%)'}}>
															<TextField
																select
																fullWidth
																required
																margin="none"
																onChange={this.handleChange}
																disabled={this.is_view()}
																SelectProps={{
																	native: true,
																	MenuProps: {
																		className: '',
																	},
																}}
																name="id_empresa_clave_unidad"
																label="Unidad de Medida"
																value={this.state.id_empresa_clave_unidad}
															>
																<option key={0} value={''}>&nbsp;</option>
																{this.state.listar_unidad_medida.map(option => (
																	<option key={option.id_empresa_clave_unidad} value={option.id_empresa_clave_unidad}>
																		{option.descripcion ? option.clave_unidad + ' - ' + option.descripcion : option.clave_unidad}
																	</option>
																))}
															</TextField>
														</Grid>
													) : null}
												</Grid>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
												<TextField
													margin="none"
													name="numero_identificacion"
													label="No. Identificación"
													type="text"
													fullWidth
													value={this.state.numero_identificacion}
													onChange={this.handleChange}
												/>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
												<TextField
													margin="none"
													name="cantidad"
													label="Cantidad"
													type="text"
													fullWidth
													value={this.state.cantidad}
													onChange={(e) => {
														this.handleChange(e);
													}}
													onKeyPress={EnteroSolo}
													//inputProps={{maxLength: 15}}
													required
												/>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
												<TextField
													margin="none"
													name="precio_unitario"
													label="Valor Unitario"
													type="text"
													fullWidth
													value={this.state.precio_unitario}
													onChange={(e) => {
														this.handleChange(e);
													}}
													onKeyPress={EnteroSolo}
													//inputProps={{maxLength: 15}}
													required
												/>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
												<span className={'text-left text-black px-13'}>Descuento por:</span>
												{/*<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span>*/}
												<Grid item className={'text-left ticketfrecuencia'} xs={12} sm={12} md={12} lg={12} xl={12}>
													<RadioGroup
														row aria-label="position"
														name="tipo_descuento"
														value={this.state.tipo_descuento}
														onChange={this.handleChange}>
														
														<FormControlLabel
															style={{color: 'gray', fontSize: '13px'}}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={"1"}
															label={"Sin descuento"}
															disabled={this.props.tipo === 'view'}
														/>
														<FormControlLabel
															style={{color: 'gray', fontSize: '13px'}}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={"2"}
															label={"Porcentaje"}
															disabled={this.props.tipo === 'view'}
														/>
														<FormControlLabel
															style={{color: 'gray', fontSize: '13px'}}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={"3"}
															label={"Monto"}
															disabled={this.props.tipo === 'view'}
														/>
													</RadioGroup>
												</Grid>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
												<TextField
													margin="none"
													name="descuento"
													label="Descuento"
													type="text"
													fullWidth
													value={this.state.descuento}
													onChange={(e) => {
														this.handleChange(e);
													}}
													onKeyPress={EnteroSolo}
													//inputProps={{maxLength: 15}}
												/>
											</Grid>
											
											<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
												<TextField
													margin="none"
													name="numero_pedimento"
													label="Número de pedimento"
													type="text"
													fullWidth
													value={this.state.numero_pedimento}
													onChange={(e) => {
														this.handleChange(e);
													}}
													onKeyPress={EnteroSolo}
													//inputProps={{maxLength: 15}}
												/>
											</Grid>
											
											<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
												<TextField
													margin="none"
													name="numero_predial"
													label="Número Cuenta Predial"
													type="text"
													fullWidth
													value={this.state.numero_predial}
													onChange={(e) => {
														this.handleChange(e);
													}}
													onKeyPress={EnteroSolo}
													//inputProps={{maxLength: 15}}
												/>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
												<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
												<Typography className={'text-left px-16'} style={{opacity: 0.8, color: 'black'}}>
													{"Impuestos Trasladados del Concepto"}
												</Typography>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
												<TextField
													select
													fullWidth
													required
													margin="none"
													onChange={this.handleChange}
													disabled={this.is_view()}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}
													name="id_cat_impuesto_trasladado"
													label="Impuestos"
													value={this.state.id_cat_impuesto_trasladado}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.listar_cat_impuestos_trasladados.map(option => (
														<option key={option.id_cat_impuesto_trasladado} value={option.id_cat_impuesto_trasladado}>
															{option.impuesto_trasladado}
														</option>
													))}
												</TextField>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
												<TextField
													select
													fullWidth
													required
													margin="none"
													onChange={this.handleChange}
													disabled={this.is_view()}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}
													name="id_cat_tipo_factor_trasladado"
													label="Tipo Factor"
													value={this.state.id_cat_tipo_factor_trasladado}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.listar_cat_tipo_factor.map(option => (
														<Fragment>
															{Number(option.tipo_impuesto) === 1 ? (
																<option key={option.id_cat_tipo_factor} value={option.id_cat_tipo_factor}>
																	{option.tipo_factor}
																</option>
															) : null}
														</Fragment>
													))}
												</TextField>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
												<TextField
													select
													fullWidth
													required
													margin="none"
													onChange={this.handleChange}
													disabled={this.is_view()}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}
													name="id_cat_impuesto"
													label="Impuestos"
													value={this.state.id_cat_impuesto}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.listar_cat_impuestos.map(option => (
														<option key={option.id_cat_impuesto} value={option.id_cat_impuesto}>
															{option.impuesto}
														</option>
													))}
												</TextField>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3} align={'right'}>
												<div className={'w-auto vertical-inline h-100'}>
													<div className={'v-center'}>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title={this.is_edited_product() ? "Actualizar" : "Agregar"}
															children={(
																<Fab size="small" color="secondary"
																     aria-label={"Agregar"}
																     onClick={() => {}}
																     style={{marginLeft: "15px"}}>
																		<AddOutlined/>
																</Fab>
															)}
														/>
													</div>
												</div>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<table className={'desing-mark'}>
													<thead>
													<tr>
														<th align={"center"}>Impuesto</th>
														<th align={"center"}>Tipo Factor</th>
														<th align={"center"}>Tasa o Cuota</th>
														<th align={"right"}>Acciones</th>
													</tr>
													</thead>
													<tbody>
													{this.state.lista_facturacion_producto.map((item, index) => (
															<tr key={index}>
																<td>{item.impuesto_trasladado}</td>
																<td>{item.tipo_factor_trasladado}</td>
																<td>{item.tasa_cuota_trasladado}</td>
																<td>
																	<td align={'right'}>
																		<Tooltip
																			TransitionComponent={Zoom}
																			placement={"top"}
																			title="Eliminar"
																			children={(
																				<Fab
																					size="small"
																					aria-label="Eliminar"
																					onClick={() => this.deleteImpuestoRetenido(item, index)}
																					style={{
																						marginLeft: "10px",
																						backgroundColor: "transparent",
																						color: "#808080",
																						boxShadow: "none",
																					}}
																					children={(
																						<DeleteOutlined/>
																					)}
																				/>
																			)}
																		/>
																	</td>
																</td>
															</tr>
														)
													)}
													</tbody>
												</table>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
												<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
												<Typography className={'text-left px-16'} style={{opacity: 0.8, color: 'black'}}>
													{"Impuestos Retenidos del Concepto"}
												</Typography>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
												<TextField
													select
													fullWidth
													required
													margin="none"
													onChange={this.handleChange}
													disabled={this.is_view()}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}
													name="id_cat_impuesto_retenido"
													label="Impuestos"
													value={this.state.id_cat_impuesto_retenido}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.listar_cat_impuestos_retenidos.map(option => (
														<option key={option.id_cat_impuesto_retenido} value={option.id_cat_impuesto_retenido}>
															{option.impuesto_retenido}
														</option>
													))}
												</TextField>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
												<TextField
													select
													fullWidth
													required
													margin="none"
													onChange={this.handleChange}
													disabled={this.is_view()}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}
													name="id_cat_tipo_factor_retenido"
													label="Tipo Factor"
													value={this.state.id_cat_tipo_factor_retenido}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.listar_cat_tipo_factor.map(option => (
														<Fragment>
															{Number(option.tipo_impuesto) === 2 ? (
																<option key={option.id_cat_tipo_factor} value={option.id_cat_tipo_factor}>
																	{option.tipo_factor}
																</option>
															) : null}
														</Fragment>
													))}
												</TextField>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
												<TextField
													margin="none"
													name="tasa_cuota_retenido"
													label="Tasa o Cuota"
													type="text"
													fullWidth
													value={this.state.tasa_cuota_retenido}
													onChange={(e) => {
														this.handleChange(e);
													}}
													onKeyPress={EnteroSolo}
													//inputProps={{maxLength: 15}}
												/>
											</Grid>
											
											<Grid item xs={12} sm={6} md={3} lg={3} xl={3} align={'right'}>
												<div className={'w-auto vertical-inline h-100'}>
													<div className={'v-center'}>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title={this.is_edited_product() ? "Actualizar" : "Agregar"}
															children={(
																<Fab size="small" color="secondary"
																     aria-label={"Agregar"}
																     onClick={() => {}}
																     style={{marginLeft: "15px"}}>
																		<AddOutlined/>
																</Fab>
															)}
														/>
													</div>
												</div>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<table className={'desing-mark'}>
													<thead>
													<tr>
														<th align={"center"}>Impuesto</th>
														<th align={"center"}>Tipo Factor</th>
														<th align={"center"}>Tasa o Cuota</th>
														<th align={"right"}>Acciones</th>
													</tr>
													</thead>
													<tbody>
													{this.state.lista_facturacion_producto.map((item, index) => (
														<tr key={index}>
															<td>{item.impuesto_retenido}</td>
															<td>{item.tipo_factor_retenido}</td>
															<td>{item.tasa_cuota_retenido}</td>
															<td>
																<td align={'right'}>
																	<Tooltip
																		TransitionComponent={Zoom}
																		placement={"top"}
																		title="Eliminar"
																		children={(
																			<Fab
																				size="small"
																				aria-label="Eliminar"
																				onClick={() => this.deleteImpuestoRetenido(item, index)}
																				style={{
																					marginLeft: "10px",
																					backgroundColor: "transparent",
																					color: "#808080",
																					boxShadow: "none",
																				}}
																				children={(
																					<DeleteOutlined/>
																				)}
																			/>
																		)}
																	/>
																</td>
															</td>
														</tr>
														)
													)}
													</tbody>
												</table>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
												<Divider style={{marginTop: "10px", marginBottom: "15px"}}/>
												<Button color="primary" size={'small'} variant="contained">
													{'Agregar Partida'}
												</Button>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
												<Divider style={{marginTop: "0px", marginBottom: "10px"}}/>
												<Typography className={'text-left px-16'} style={{opacity: 0.8, color: 'black'}}>
													{"Detalle de Partidas"}
												</Typography>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<table className={'desing-mark'}>
													<thead>
													<tr>
														<th align={"center"}>Número de identificación</th>
														<th align={"center"}>Cantidad</th>
														<th align={"center"}>Unidad</th>
														<th align={"center"}>Descripción</th>
														<th align={"right"}>Valor Unitario</th>
														<th align={"right"}>Importe</th>
														<th align={"right"}>Trasladados</th>
														<th align={"right"}>Retenidos</th>
														<th align={"right"}>Descuentos</th>
														<th align={"right"}>Partes</th>
														<th align={"right"}>Complemento Concepto</th>
														<th align={"right"}>Acciones</th>
													</tr>
													</thead>
													<tbody>
													{this.state.lista_facturacion_producto.map((item, index) => (
														<tr key={index}>
															<td>{item.numero_identificacion}</td>
															<td>{item.cantidad}</td>
															<td align={"center"}>{item.cantidad}</td>
															<td align={"center"}>{item.unidad}</td>
															<td align={"center"}>{item.descripcion}</td>
															<td align={"center"}>{item.valor_unitario}</td>
															<td align={"center"}>{item.importe}</td>
															<td align={"center"}>{item.traslado}</td>
															<td align={"center"}>{item.retenido}</td>
															<td align={"center"}>{item.descuento}</td>
															<td align={"center"}>{item.partes}</td>
															<td align={"center"}>{item.complemento}</td>
															
															<td align={'right'}>
																<Tooltip
																	TransitionComponent={Zoom}
																	placement={"top"}
																	title="Eliminar"
																	children={(
																		<Fab
																			size="small"
																			aria-label="Eliminar"
																			onClick={() => this.delete(item, index)}
																			style={{
																				marginLeft: "10px",
																				backgroundColor: "transparent",
																				color: "#808080",
																				boxShadow: "none",
																			}}
																			children={(
																				<DeleteOutlined/>
																			)}
																		/>
																	)}
																/>
															</td>
														</tr>
													))}
													{!(this.state.lista_facturacion_producto.length > 0) ? (
														<tr>
															<td colSpan={12}
															    align={'center'}>
																<VistaVacia
																	numero={null}
																	mensaje={'Ningún concepto agregado.'}
																	image={carrito_de_compra_3}
																	padding={'20px'}
																	paddingText={'20px'}
																	height={'80px'}
																	width={'80px'}
																/>
															</td>
														</tr>
													) : null}
													<tr>
														<td colSpan={8}/>
														<td colSpan={2} align={"right"}>Total Descuentos</td>
														<td colSpan={2} align={"right"}>${FieldsJs.Currency(desglose.subtotal)}</td>
														<td/>
													</tr>
													<tr>
														<td colSpan={8}/>
														<td colSpan={2} align={"right"}>Total Impuestos Trasladados</td>
														<td colSpan={2} align={"right"}>${FieldsJs.Currency(desglose.subtotal)}</td>
														<td/>
													</tr>
													<tr>
														<td colSpan={8}/>
														<td colSpan={2} align={"right"}>Total Impuestos Retenidos</td>
														<td colSpan={2} align={"right"}>${FieldsJs.Currency(desglose.subtotal)}</td>
														<td/>
													</tr>
													<tr>
														<td colSpan={8}/>
														<td colSpan={2} align={"right"}>Subtotal</td>
														<td colSpan={2} align={"right"}>${FieldsJs.Currency(desglose.subtotal)}</td>
														<td/>
													</tr>
													<tr>
														<td colSpan={8}/>
														<td colSpan={2} align={"right"}>Total</td>
														<td colSpan={2} align={"right"}>${FieldsJs.Currency(desglose.iva)}</td>
														<td/>
													</tr>
													<tr>
														<td colSpan={8}/>
														<td colSpan={2} align={"right"}>Gran Total</td>
														<td colSpan={2} align={"right"}>${FieldsJs.Currency(desglose.total)}</td>
														<td/>
													</tr>
													</tbody>
												</table>
											</Grid>
										</Grid>
									</TabPanel>
									
									<TabPanel value="3" className={'padding-0-L padding-0-R'}>
										<Grid container spacing={2} alignContent={"flex-start"} alignItems={"stretch"}>
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
												
												<fieldset className={'borde-punteado-gray-2 text-left'}>
													<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Verificación</legend>
													
													<Grid container spacing={2}>
														
														<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
															<TextField
																required
																name="rfc_emisor"
																label="RFC Emisor"
																type="text"
																fullWidth
																value={this.state.rfc_emisor}
																onChange={this.handleChange}
																disabled
															/>
														</Grid>
														
														<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
															<TextField
																required
																name="sucursal_emisor"
																label="Sucursal"
																type="text"
																fullWidth
																value={this.state.sucursal_emisor}
																onChange={this.handleChange}
																disabled
															/>
														</Grid>
														
														<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
															<TextField
																required
																name="certificado_emisor"
																label="Certificado"
																type="text"
																fullWidth
																value={this.state.certificado_emisor}
																onChange={this.handleChange}
																disabled
															/>
														</Grid>
														
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															
															<TextField
																multiline
																name="observaciones_emisor"
																label="Observaciones"
																type="observaciones_emisor"
																fullWidth
																rows={3}
																value={this.state.observaciones_emisor}
																onChange={this.handleChange}
															/>
														
														</Grid>
													
													</Grid>
												
												</fieldset>
											
											</Grid>
											
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
												
												<fieldset className={'borde-punteado-gray-2 text-left'}>
													<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Dactos de envío</legend>
													
													<Grid container spacing={2}>
														
														<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
															<TextField
																required
																name="enviar_a"
																label="Enviar a: "
																type="text"
																fullWidth
																value={this.state.enviar_a}
																onChange={this.handleChange}
															/>
														</Grid>
														
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<TextField
																required
																name="enviar_cc"
																label="Cc:"
																type="text"
																fullWidth
																value={this.state.enviar_cc}
																onChange={this.handleChange}
															/>
														
														</Grid>
														
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<TextField
																required
																name="enviar_cco"
																label="Cco:"
																type="text"
																fullWidth
																value={this.state.enviar_cco}
																onChange={this.handleChange}
															/>
														
														</Grid>
														
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'right'}>
															<Button onClick={() => {
															}} color="primary" size={'small'} variant="contained" startIcon={<Visibility/>}>
																{'Previsualizar'}
															</Button>
														</Grid>
													
													
													</Grid>
												
												</fieldset>
											
											</Grid>
										</Grid>
									</TabPanel>
								</TabContext>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : 'Agregar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalTimbrado.propTypes = {
	id_facturacion: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalTimbrado;
