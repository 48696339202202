import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {Business, DateRangeOutlined, FontDownload, HdrStrong, HomeWork, LooksOne, Person} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";


class ModalExcelCFDI extends Component {
	
	state = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			c_plaza: true,
			c_rfc_emisor: true,
			c_empresa_facturadora: true,
			c_rfc_receptor: true,
			c_cliente: true,
			c_serie: true,
			c_folio: true,
			c_status_sat: true,
			c_status_pago: true,
			c_fecha_emision: true,
			c_fecha_pago: true,
			c_subtotal: true,
			c_iva: true,
			c_total_facturado: true,
			c_concepto: true,
			c_clave_unidad: true,
			c_clave_concepto: true,
			c_tipo_comprobante: true,
			c_metodo_pago: true,
			c_forma_pago: true,
			
			fecha_inicio: '',
			id_cat_tipo_cfdi: '',
			fecha_fin: '',
			anio: '',
			empresa: '',
			cliente: '',
			uuid: '',
			id_cat_facturacion_status_sat: '',
			id_cat_facturacion_status_pago: '',
			id_cat_facturacion_plaza: '',
			
			listar_facturacion_plaza: [],
			listar_facturacion_estatus_sat: [],
			listar_facturacion_estatus_pago: [],
			listar_cat_tipo_cfdi: [],
			
		};
		
		this.ListaFacturacionPlaza();
		this.ListaFacturacionStatusSat();
		this.ListaFacturacionStatusPago();
		this.ListaTipoCFDI();
		
	}
	
	ListaTipoCFDI = () => {
		CatService.ListTipoCFDIFac({}, {}).then((response) => {
			this.setState({
				listar_cat_tipo_cfdi: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_tipo_cfdi: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaFacturacionPlaza = () => {
		CatService.ListFacturacionPlaza({}, {}).then((response) => {
			this.setState({
				listar_facturacion_plaza: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_facturacion_plaza: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaFacturacionStatusSat = () => {
		CatService.ListFacturacionStatusSat({}, {}).then((response) => {
			this.setState({
				listar_facturacion_estatus_sat: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_facturacion_estatus_sat: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaFacturacionStatusPago = () => {
		CatService.ListFacturacionStatusPago({}, {}).then((response) => {
			this.setState({
				listar_facturacion_estatus_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_facturacion_estatus_pago: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
			
			fecha_inicio: (props.filtro || null).fecha_inicio,
			fecha_fin: (props.filtro || null).fecha_fin,
			empresa: (props.filtro || null).empresa,
			cliente: (props.filtro || null).cliente,
			id_cat_facturacion_status_sat: (props.filtro || null).id_cat_facturacion_status_sat,
			id_cat_facturacion_status_pago: (props.filtro || null).id_cat_facturacion_status_pago,
			id_cat_facturacion_plaza: (props.filtro || null).id_cat_facturacion_plaza,
			anio: (props.filtro || null).anio || null,
			periodo: (props.filtro || null).periodo || null,
			serie: (props.filtro || null).serie || null,
			uuid: (props.filtro || null).uuid || null,
			folio: (props.filtro || null).folio || null,
			id_proyecto: 2,
			id_usuario: (props.filtro || null).id_usuario || "",
			id_plaza: (props.filtro || null).id_plaza || "",
			id_cat_tipo_cfdi: (props.filtro || null).id_cat_tipo_cfdi || "",
			lugar_expedicion: (props.filtro || null).lugar_expedicion || "",
			id_cat_forma_pago: (props.filtro || null).id_cat_forma_pago || "",
			id_cat_metodo_pago: (props.filtro || null).id_cat_metodo_pago || "",
			activo: (Number((props.filtro || null).activo) === 1 || Number((props.filtro || null).activo) === 0) ? Number((props.filtro || null).activo) : null
		});
		console.log("THIS.PROPS.FILTRO:::", props.filtro);
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	exportaryenviar = (item, archivo, auth, email) => {
		try {
			if (!this.state.c_plaza && !this.state.c_rfc_emisor && !this.state.c_empresa_facturadora && !this.state.c_rfc_receptor &&
				!this.state.c_cliente && !this.state.c_serie && !this.state.c_folio && !this.state.c_status_sat && !this.state.c_status_pago &&
				!this.state.c_fecha_emision && !this.state.c_fecha_pago && !this.state.c_subtotal && !this.state.c_iva &&
				!this.state.c_total_facturado && !this.state.c_concepto && !this.state.c_clave_unidad && !this.state.c_clave_concepto) {
				throw Object({
					status: false,
					mensaje: "Selecciona mínimo una columna para exportar."
				});
			}
			let fecha_inicio = this.state.fecha_inicio ? DateFormat.FormatSql(this.state.fecha_inicio) : null;
			let fecha_fin = this.state.fecha_fin ? DateFormat.FormatSql(this.state.fecha_fin) : null;
			
			
			let filtro = {
				fecha_inicio: fecha_inicio,
				fecha_fin: fecha_fin,
				empresa: this.state.empresa || null,
				cliente: this.state.cliente || null,
				id_cat_facturacion_status_sat: this.state.id_cat_facturacion_status_sat || null,
				id_cat_facturacion_status_pago: this.state.id_cat_facturacion_status_pago || null,
				id_cat_facturacion_plaza: this.state.id_cat_facturacion_plaza || null,
				anio: this.state.anio || null,
				periodo: this.state.periodo || null,
				serie: this.state.serie || null,
				uuid: this.state.uuid || null,
				folio: this.state.folio || null,
				id_proyecto: 2,
				id_usuario: this.state.id_usuario || "",
				id_plaza: this.state.id_plaza || "",
				id_cat_tipo_cfdi: this.state.id_cat_tipo_cfdi || "",
				lugar_expedicion: this.state.lugar_expedicion || "",
				id_cat_forma_pago: this.state.id_cat_forma_pago || "",
				id_cat_metodo_pago: this.state.id_cat_metodo_pago || "",
				activo: (Number(this.state.activo) === 1 || Number(this.state.activo) === 0) ? Number(this.state.activo) : null,
			};
			
			let columns = {
				c_plaza: this.state.c_plaza,
				c_rfc_emisor: this.state.c_rfc_emisor,
				c_empresa_facturadora: this.state.c_empresa_facturadora,
				c_rfc_receptor: this.state.c_rfc_receptor,
				c_cliente: this.state.c_cliente,
				c_serie: this.state.c_serie,
				c_folio: this.state.c_folio,
				c_status_sat: this.state.c_status_sat,
				c_status_pago: this.state.c_status_pago,
				c_fecha_emision: this.state.c_fecha_emision,
				c_fecha_pago: this.state.c_fecha_pago,
				c_subtotal: this.state.c_subtotal,
				c_iva: this.state.c_iva,
				c_total_facturado: this.state.c_total_facturado,
				c_concepto: this.state.c_concepto,
				c_clave_unidad: this.state.c_clave_unidad,
				c_clave_concepto: this.state.c_clave_concepto,
				c_tipo_comprobante:this.state.c_tipo_comprobante,
				c_metodo_pago: this.state.c_metodo_pago,
				c_forma_pago: this.state.c_forma_pago
			};
			
			let params = {
				auth: auth,
				archivo: archivo,
				filtro: filtro,
				columns: columns
			};
			
			HttpRequest.exportFacturacion('_Carga_CFDI_Datos_Excel', ['Cargas_cfdi'], params, false, false, false, archivo === "EXCEL");
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	
	render() {
		
		return (
			<div>
				
				<Fragment>
					<span style={{cursor: 'pointer'}} children={this.props.componente} onClick={() => this.open()}/>
				</Fragment>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Exportar CFDI´s'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1} className={'desing-acresco-excel-export'}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={2}>
									<Grid item xs={6} sm={6} md={4} lg={1} xl={1} align={'left'}>
										<div className={'vertical-inline w-100 h-100'}>
											<p className={'v-center margin-0 text-center'} style={{color: 'gray'}}>Filtros:</p>
										</div>
									</Grid>
									
									<Grid item xs={6} sm={6} md={8} lg={11} xl={11}>
										<Grid container spacing={1}>
											<Grid item xs={6} sm={6} md={4} lg={4} xl={4} align={'left'}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<HdrStrong className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField
															type={'text'}
															fullWidth
															name="uuid"
															disabled
															onChange={this.handleChange}
															label="Uuid"
															autoComplete={'off'}
															value={this.state.uuid}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item xs={6} sm={6} md={4} lg={2} xl={2} align={'left'}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<FontDownload className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField
															type={'text'}
															fullWidth
															name="serie"
															onChange={this.handleChange}
															label="Serie"
															autoComplete={'off'}
															value={this.state.serie}
															disabled
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item xs={6} sm={6} md={4} lg={2} xl={2} align={'left'}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<LooksOne className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField
															type={'text'}
															fullWidth
															name="folio"
															onChange={this.handleChange}
															label="Folio"
															autoComplete={'off'}
															value={this.state.folio}
															disabled
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<Business className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField
															type={'text'}
															fullWidth
															name="empresa"
															onChange={this.handleChange}
															label="Empresa"
															autoComplete={'off'}
															value={this.state.empresa}
															disabled
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<Person className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField
															type={'text'}
															fullWidth
															name="cliente"
															onChange={this.handleChange}
															label="Cliente"
															autoComplete={'off'}
															value={this.state.cliente}
															disabled
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
												<MDDatePicker
													type={'range'}
													disabled={true}
													component={(
														<TextField
															fullWidth
															label="Fecha"
															value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
															readOnly
															disabled
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<IconButton aria-label="Fecha">
																			<DateRangeOutlined/>
																		</IconButton>
																	</InputAdornment>
																)
															}}
														/>
													)}
													startDate={this.state.fecha_inicio}
													endDate={this.state.fecha_fin}
													onChange={(range) => {
														this.setState({
															fecha_inicio: range.startDate,
															fecha_fin: range.endDate,
														});
														setTimeout(() => this.HandleFiltro());
													}}
												/>
											</Grid>
											
											<Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<HdrStrong className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField
															select
															fullWidth
															margin="none"
															onChange={this.handleChange}
															SelectProps={{
																native: true,
																MenuProps: {
																	className: '',
																},
															}}
															disabled
															name="id_cat_tipo_cfdi"
															label="Tipo de CFDI"
															value={this.state.id_cat_tipo_cfdi}
														>
															<option key={0} value={''}>&nbsp;</option>
															{this.state.listar_cat_tipo_cfdi.map(option => (
																<option key={option.id_cat_tipo_cfdi}
																        value={option.id_cat_tipo_cfdi}>
																	{option.tipo_cfdi}
																</option>
															))}
														</TextField>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<br/>
								<Divider/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'margin-15-B'} style={{color: 'gray', opacity: '0.8'}}>
									Seleccionar las columnas que deseas exportar:
								</Typography>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1}>
									{/*<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														onChange={this.handleChange}
														color="primary"
														name='c_plaza'
														checked={this.state.c_plaza}
														value="c_plaza"
													/>
												}
												label={'Plaza'}
											/>
										</FormGroup>
									</Grid>*/}
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_rfc_emisor'
														checked={this.state.c_rfc_emisor}
														value="c_rfc_emisor"
													/>
												}
												label={'RfC Emisor'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_empresa_facturadora'
														checked={this.state.c_empresa_facturadora}
														value="c_empresa_facturadora"
													/>
												}
												label={'Empresa Facturadora'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_rfc_receptor'
														checked={this.state.c_rfc_receptor}
														value="c_rfc_receptor"
													/>
												}
												label={'RFC Receptor'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_cliente'
														checked={this.state.c_cliente}
														value="c_cliente"
													/>
												}
												label={'Cliente'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_serie'
														checked={this.state.c_serie}
														value="c_serie"
													/>
												}
												label={'Serie'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_folio'
														checked={this.state.c_folio}
														value="c_folio"
													/>
												}
												label={'Folio'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_status_sat'
														checked={this.state.c_status_sat}
														value="c_status_sat"
													/>
												}
												label={'Estatus Sat'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_status_pago'
														checked={this.state.c_status_pago}
														value="c_status_pago"
													/>
												}
												label={'Estatus Pago'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_fecha_emision'
														checked={this.state.c_fecha_emision}
														value="c_fecha_emision"
													/>
												}
												label={'Fecha Emisión'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_fecha_pago'
														checked={this.state.c_fecha_pago}
														value="c_fecha_pago"
													/>
												}
												label={'Fecha Pago'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_subtotal'
														checked={this.state.c_subtotal}
														value="c_subtotal"
													/>
												}
												label={'Subtotal'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_iva'
														checked={this.state.c_iva}
														value="c_iva"
													/>
												}
												label={'Iva'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_total_facturado'
														checked={this.state.c_total_facturado}
														value="c_total_facturado"
													/>
												}
												label={'Total Facturado'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_concepto'
														checked={this.state.c_concepto}
														value="c_concepto"
													/>
												}
												label={'Concepto Facturación'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_clave_unidad'
														checked={this.state.c_clave_unidad}
														value="c_clave_unidad"
													/>
												}
												label={'Clave Unidad'}
											/>
										</FormGroup>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_clave_concepto'
														checked={this.state.c_clave_concepto}
														value="c_clave_concepto"
													/>
												}
												label={'Clave Concepto'}
											/>
										</FormGroup>
									</Grid>
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_tipo_comprobante'
														checked={this.state.c_tipo_comprobante}
														value="c_tipo_comprobante"
													/>
												}
												label={'Tipo Compbante'}
											/>
										</FormGroup>
									</Grid>
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_metodo_pago'
														checked={this.state.c_metodo_pago}
														value="c_metodo_pago"
													/>
												}
												label={'Metodo de Pago'}
											/>
										</FormGroup>
									</Grid>
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='c_forma_pago'
														checked={this.state.c_forma_pago}
														value="c_forma_pago"
													/>
												}
												label={'Forma de Pago'}
											/>
										</FormGroup>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider/>
								<br/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Paper style={{overflowX: "auto"}}>
									<Table>
										<TableBody>
											<TableRow>
												<TableCell align={'center'} className={'padding-20-L padding-20-R'}>0</TableCell>
												{/*{this.state.c_plaza ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Plaza</TableCell>) : null}*/}
												{this.state.c_rfc_emisor ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>RFC Emisor</TableCell>) : null}
												{this.state.c_empresa_facturadora ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Empresa Facturadora</TableCell>) : null}
												{this.state.c_rfc_receptor ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>RFC Receptor</TableCell>) : null}
												{this.state.c_cliente ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Cliente</TableCell>) : null}
												{this.state.c_serie ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Serie</TableCell>) : null}
												{this.state.c_folio ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Folio</TableCell>) : null}
												{this.state.c_status_sat ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Estatus Sat</TableCell>) : null}
												{this.state.c_status_pago ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Estatus Pago</TableCell>) : null}
												{this.state.c_fecha_emision ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Fecha Emisión</TableCell>) : null}
												{this.state.c_fecha_pago ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Fecha Pago</TableCell>) : null}
												{this.state.c_subtotal ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>SubTotal</TableCell>) : null}
												{this.state.c_iva ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Iva</TableCell>) : null}
												{this.state.c_total_facturado ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Total Facturado</TableCell>) : null}
												{this.state.c_concepto ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Concepto de Facturación</TableCell>) : null}
												{this.state.c_clave_unidad ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Clave Unidad</TableCell>) : null}
												{this.state.c_clave_concepto ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Clave Concepto</TableCell>) : null}
												{this.state.c_tipo_comprobante ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Tipo Comprobante</TableCell>) : null}
												{this.state.c_metodo_pago ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Metodo de Pago</TableCell>) : null}
												{this.state.c_forma_pago ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Forma de Pago</TableCell>) : null}
											</TableRow>
											
											<TableRow>
												<TableCell align={'center'} className={'padding-20-L padding-20-R'}>1</TableCell>
												{/*{this.state.c_plaza ? (<TableCell align={'left'}>Plaza</TableCell>) : null}*/}
												{this.state.c_rfc_emisor ? (<TableCell align={'left'}>rfc_emisor</TableCell>) : null}
												{this.state.c_empresa_facturadora ? (<TableCell align={'left'}>empresa_facturadora</TableCell>) : null}
												{this.state.c_rfc_receptor ? (<TableCell align={'left'}>rfc_receptor</TableCell>) : null}
												{this.state.c_cliente ? (<TableCell align={'left'}>cliente</TableCell>) : null}
												{this.state.c_serie ? (<TableCell align={'left'}>serie</TableCell>) : null}
												{this.state.c_folio ? (<TableCell align={'left'}>folio</TableCell>) : null}
												{this.state.c_status_sat ? (<TableCell align={'left'}>facturacion_status_sat</TableCell>) : null}
												{this.state.c_status_pago ? (<TableCell align={'left'}>facturacion_status_pago</TableCell>) : null}
												{this.state.c_fecha_emision ? (<TableCell align={'left'}>fecha</TableCell>) : null}
												{this.state.c_fecha_pago ? (<TableCell align={'left'}>fecha_pago</TableCell>) : null}
												{this.state.c_subtotal ? (<TableCell align={'left'}>subtotal</TableCell>) : null}
												{this.state.c_iva ? (<TableCell align={'left'}>iva</TableCell>) : null}
												{this.state.c_total_facturado ? (<TableCell align={'left'}>total</TableCell>) : null}
												{this.state.c_concepto ? (<TableCell align={'left'}>concepto</TableCell>) : null}
												{this.state.c_clave_unidad ? (<TableCell align={'left'}>clave_unidad</TableCell>) : null}
												{this.state.c_clave_concepto ? (<TableCell align={'left'}>clave_concepto</TableCell>) : null}
												{this.state.c_tipo_comprobante ? (<TableCell align={'left'}>Tipo Comprobante</TableCell>) : null}
												{this.state.c_metodo_pago ? (<TableCell align={'left'}>Metodo de Pago</TableCell>) : null}
												{this.state.c_forma_pago ? (<TableCell align={'left'}>Forma de Pago</TableCell>) : null}
											</TableRow>
										
										</TableBody>
									</Table>
								</Paper>
								<br/>
								<br/>
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								Cancelar
							</Button>
							<Button onClick={() => this.exportaryenviar({}, 'EXCEL')} color="primary">
								Exportar
							</Button>
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalExcelCFDI.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	filtro: PropTypes.object,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalExcelCFDI;
