import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {FacturacionService} from "../../../../services/FacturacionService/FacturacionService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {AddOutlined, CancelOutlined, DeleteOutlined, EditOutlined, PlaylistAddOutlined, SaveOutlined} from '@material-ui/icons';
import Fab from "@material-ui/core/Fab";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";

import carrito_de_compra_3 from '../../../../assets/img/icons/facturacion.svg'
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ModalCliente from "../../Cliente/Includes/ModalCliente";
import ModalFormaPago from "../../Cat/FormaPago/Includes/ModalFormaPago";
import ModalMetodoPago from "../../Cat/MetodoPago/Includes/ModalMetodoPago";
import ModalClaveProductoServicio from "../../Cat/ClaveProductoServicio/Includes/ModalClaveProductoServicio";
import ModalClaveUnidadMedida from "../../Cat/ClaveUnidadMedida/Includes/ModalClaveUnidadMedida";
import ModalTipoComprobante from "../../Cat/TipoComprobante/Includes/ModalTipoComprobante";
import ModalEmpresa from "../../Cat/Empresa/Includes/ModalEmpresa";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CONFIG} from "../../../../settings/Config/Config";
import {ClienteService} from "../../../../services/ClienteService/ClienteService";
import {MetodoPagoService} from "../../../../services/_Cat/MetodoPagoService/MetodoPagoService";
import {FormaPagoService} from "../../../../services/_Cat/FormaPagoService/FormaPagoService";
import {ClaveProductoServicioService} from "../../../../services/_Cat/ClaveProductoServicioService/ClaveProductoServicioService";
import {ClaveUnidadMedidaService} from "../../../../services/_Cat/ClaveUnidadMedidaService/ClaveUnidadMedidaService";
import {TipoComprobanteService} from "../../../../services/_Cat/TipoComprobanteService/TipoComprobanteService";
import {EmpresaService} from "../../../../services/_Cat/EmpresaService/EmpresaService";
import {CatService} from "../../../../services/_Cat/CatService/CatService";

class ModalFacturacion extends Component {
	
	state = {};
	
	Usr = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			id_facturacion: props.id_facturacion,
			
			
			id_cliente: '',
			id_empresa: '',
			serie: '',
			folio: '',
			
			id_cat_forma_pago: '',
			id_cat_metodo_pago: '',
			id_cat_facturacion_estatus: '',
			id_cat_periodo_pago: '',
			observacion_cfdi: '',
			observacion_interna: '',
			fecha_emitir_comprobante: null,
			facturacion_total: '',
			
			key: null,
			id_cat_clave_producto_servicio: null,
			id_clave_unidad_medida: null,
			id_cat_tipo_comprobante: '',
			
			numero_identificacion: '',
			descripcion_concepto: '',
			cantidad: '',
			precio_unitario: '',
			total: '',
			iva_incluido: false,
			iva_aplicado: false,
			
			lista_facturacion_producto: [],
			listar_cliente: [],
			listar_empresa: [],
			listar_forma_pago: [],
			listar_clave_producto_servicio: [],
			listar_clave_unidad_medida: [],
			listar_tipo_comprobante: [],
			
			
			listar_metodo_pago: [],
			listar_periodo_pago: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	handleChangeIvaIncluido = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		
		if (!this.state.iva_incluido)
			this.setState({
				iva_aplicado: false
			});
	};
	
	handleChangeIvaAplicado = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		
		if (!this.state.iva_aplicado)
			this.setState({
				iva_incluido: false
			});
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		
		this.ListaFormaPago();
		this.ListaMetodoPago();
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		this.ListaPeriodoPago();
		this.ListaEmpresa();
		this.ListaCliente();
		this.ListaMetodoPago();
		this.ListaFormaPago();
		this.ListaClaveProductoServicio();
		this.ListaClaveUnidadMedida();
		this.ListaTipoComprobante();
		
		return new Promise((resolve, reject) => {
			if (this.props.id_facturacion > 0) {
				FacturacionService.Detalles(this.props.id_facturacion).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_emitir_comprobante = item.fecha_emitir_comprobante ? moment(item.fecha_emitir_comprobante) : null;
					this.setState({
						id_facturacion: item.id_facturacion,
						
						
						id_cat_tipo_comprobante: item.id_cat_tipo_comprobante || '',
						id_cliente: item.id_cliente || '',
						id_empresa: item.id_empresa || '',
						serie: item.serie || '',
						folio: item.folio || '',
						id_cat_forma_pago: item.id_cat_forma_pago || '',
						id_cat_metodo_pago: item.id_cat_metodo_pago || '',
						id_cat_facturacion_estatus: item.id_cat_facturacion_estatus || '',
						observacion_cfdi: item.observacion_cfdi || '',
						observacion_interna: item.observacion_interna || '',
						fecha_emitir_comprobante: fecha_emitir_comprobante || null,
						facturacion_total: item.total || '',
						
						key: null,
						id_cat_clave_producto_servicio: '',
						id_clave_unidad_medida: '',
						numero_identificacion: '',
						descripcion_concepto: '',
						cantidad: '',
						precio_unitario: '',
						total: '',
						iva_incluido: false,
						iva_aplicado: false,
						
						id_cat_periodo_pago: item.id_cat_periodo_pago || [],
						
						lista_facturacion_producto: item.facturacion_producto || [],
					});
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				this.setState({
					
					id_facturacion: null,
					
					id_cat_tipo_comprobante: '',
					id_cliente: '',
					serie: '',
					folio: '',
					id_cat_forma_pago: '',
					id_cat_metodo_pago: '',
					id_cat_facturacion_estatus: '',
					id_cat_periodo_pago: '',
					observacion_cfdi: '',
					observacion_interna: '',
					fecha_emitir_comprobante: null,
					facturacion_total: '',
					
					key: null,
					id_cat_clave_producto_servicio: '',
					id_clave_unidad_medida: '',
					numero_identificacion: '',
					descripcion_concepto: '',
					cantidad: '',
					precio_unitario: '',
					total: '',
					iva_incluido: false,
					iva_aplicado: false,
					
					
					lista_facturacion_producto: [],
					listar_periodo_pago: [],
				});
				resolve(true);
			}
		});
	};
	
	RefreshListFacturacion = (id_cliente) => {
		if (id_cliente > 0) {
			this.setState({
				id_cliente: id_cliente
			})
		}
		this.ListaCliente();
		this.ListaMetodoPago();
		this.ListaFormaPago();
		this.ListaClaveProductoServicio();
		this.ListaClaveUnidadMedida();
		this.ListaTipoComprobante();
		this.ListaEmpresa();
		this.ListaPeriodoPago();
	};
	
	RefreshListFormaPago = (id_cat_forma_pago) => {
		if (id_cat_forma_pago > 0) {
			this.setState({
				id_cat_forma_pago: id_cat_forma_pago
			})
		}
		this.ListaFormaPago();
	};
	
	RefreshListMetodoPago = (id_cat_metodo_pago) => {
		if (id_cat_metodo_pago > 0) {
			this.setState({
				id_cat_metodo_pago: id_cat_metodo_pago
			})
		}
		this.ListaMetodoPago();
	};
	
	RefreshListClaveProductoServicio = (id_cat_clave_producto_servicio) => {
		if (id_cat_clave_producto_servicio > 0) {
			this.setState({
				id_cat_clave_producto_servicio: id_cat_clave_producto_servicio
			})
		}
		this.ListaClaveProductoServicio();
		this.ListaClaveUnidadMedida();
		setTimeout(() => this.colocarValoresEnCampos(id_cat_clave_producto_servicio), 1000);
	};
	
	colocarValoresEnCampos = (id) => {
		let id_cat_clave_producto_servicio = Number(id);
		let id_cat_clave_unidad_medida = '';
		let producto_servicio = '';
		let numero_identificacion = '';
		for (let i = 0; i < this.state.listar_clave_producto_servicio.length; i++) {
			if (Number(this.state.listar_clave_producto_servicio[i].id_cat_clave_producto_servicio) === Number(id_cat_clave_producto_servicio)) {
				id_cat_clave_unidad_medida = this.state.listar_clave_producto_servicio[i].id_cat_clave_unidad_medida;
				producto_servicio = this.state.listar_clave_producto_servicio[i].producto_servicio;
				numero_identificacion = this.state.listar_clave_producto_servicio[i].numero_identificacion;
			}
		}
		this.setState({
			id_cat_clave_unidad_medida: id_cat_clave_unidad_medida,
			descripcion_concepto: producto_servicio,
			numero_identificacion: numero_identificacion
		});
	};
	
	RefreshListClaveUnidadMedida = (id_cat_clave_unidad_medida) => {
		if (id_cat_clave_unidad_medida > 0) {
			this.setState({
				id_cat_clave_unidad_medida: id_cat_clave_unidad_medida
			})
		}
		this.ListaClaveUnidadMedida();
	};
	
	RefreshListTipoComprobante = (id_cat_tipo_comprobante) => {
		if (id_cat_tipo_comprobante > 0) {
			this.setState({
				id_cat_tipo_comprobante: id_cat_tipo_comprobante
			})
		}
		this.ListaTipoComprobante();
	};
	
	RefreshListEmpresa = (id_empresa) => {
		if (id_empresa > 0) {
			this.setState({
				id_empresa: id_empresa
			})
		}
		this.ListaEmpresa();
	};
	
	ListaEmpresa = () => {
		CatService.ListEmpresa({}, {}).then((response) => {
			this.setState({
				listar_empresa: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_empresa: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaCliente = () => {
		ClienteService.Listar({}, {}).then((response) => {
			this.setState({
				listar_cliente: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cliente: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaPeriodoPago = () => {
		CatService.ListPeriodoPago().then((response) => {
			this.setState({
				listar_periodo_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_periodo_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	ListaFormaPago = () => {
		FormaPagoService.Listar({}, {}).then((response) => {
			this.setState({
				listar_forma_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_forma_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaMetodoPago = () => {
		MetodoPagoService.Listar({}, {}).then((response) => {
			this.setState({
				listar_metodo_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_metodo_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaClaveProductoServicio = () => {
		ClaveProductoServicioService.Listar({}, {}).then((response) => {
			this.setState({
				listar_clave_producto_servicio: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_clave_producto_servicio: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaClaveUnidadMedida = () => {
		ClaveUnidadMedidaService.Listar({}, {}).then((response) => {
			this.setState({
				listar_clave_unidad_medida: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_clave_unidad_medida: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaTipoComprobante = () => {
		TipoComprobanteService.Listar({}, {}).then((response) => {
			this.setState({
				listar_tipo_comprobante: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_tipo_comprobante: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	add = () => {
		
		try {
			
			if (!this.state.id_cat_clave_producto_servicio) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Clave producto servicio.'
				})
			}
			
			if (!this.state.id_cat_clave_unidad_medida) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Clave unidad medida.'
				})
			}
			
			if (!this.state.descripcion_concepto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Descripción.'
				})
			}
			
			if (!(this.state.cantidad > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cantidad.'
				})
			}
			
			if (!(this.state.precio_unitario > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Precio unitario.'
				})
			}
			
			if (!(this.state.total > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Total.'
				})
			}
			
			let lista_facturacion_producto = FieldsJs.Copy(this.state.lista_facturacion_producto || []);
			
			if (this.is_edited_product()) {
				for (let i = 0; i < lista_facturacion_producto.length; i++) {
					let item = lista_facturacion_producto[i];
					if (this.state.key === i) {
						item.id_cat_clave_producto_servicio = this.state.id_cat_clave_producto_servicio || null;
						item.id_cat_clave_unidad_medida = this.state.id_cat_clave_unidad_medida || null;
						item.id_facturacion = this.state.id_facturacion || null;
						item.descripcion_concepto = this.state.descripcion_concepto || '';
						item.cantidad = this.state.cantidad || 0;
						item.precio_unitario = this.state.precio_unitario || 0;
						item.total = this.state.total || 0;
						item.iva_incluido = this.state.iva_incluido ? 1 : 0;
						item.iva_aplicado = this.state.iva_aplicado ? 1 : 0;
					}
				}
			} else {
				let item;
				item = {
					id_cat_clave_producto_servicio: this.state.id_cat_clave_producto_servicio || null,
					id_cat_clave_unidad_medida: this.state.id_cat_clave_unidad_medida || null,
					id_facturacion: this.state.id_facturacion || null,
					descripcion_concepto: this.state.descripcion_concepto || '',
					cantidad: this.state.cantidad || 0,
					precio_unitario: this.state.precio_unitario || 0,
					numero_identificacion: this.state.numero_identificacion || 0,
					iva_incluido: this.state.iva_incluido ? 1 : 0,
					iva_aplicado: this.state.iva_aplicado ? 1 : 0,
					total: this.state.total || 0,
				};
				lista_facturacion_producto.push(item);
			}
			
			this.setState({
				key: null,
				id_cat_clave_producto_servicio: '',
				id_cat_clave_unidad_medida: '',
				descripcion_concepto: '',
				cantidad: '',
				precio_unitario: '',
				numero_identificacion: '',
				total: '',
				iva_incluido: false,
				iva_aplicado: false,
				lista_facturacion_producto: lista_facturacion_producto
			});
			
			this.calcularTotalCompra();
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	save = () => {
		try {
			if (!this.state.id_empresa) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Empresa.'
				})
			}
			
			if (!this.state.id_cliente) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cliente.'
				})
			}
			
			if (!this.state.id_cat_forma_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Forma de pago.'
				})
			}
			
			if (!this.state.id_cat_metodo_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Metodo de pago.'
				})
			}
			
			if (!this.state.fecha_emitir_comprobante) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha de factura.'
				})
			}
			
			if (!this.state.facturacion_total) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Importe.'
				})
			}
			
			try {
				FacturacionService.Guardar(this.state).then((response) => {
					this.closeModal();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			} catch (e) {
				this.props.showSnackBars('error', e.mensaje);
			}
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	edit = (value, key) => {
		try {
			
			this.setState({
				key: key,
				id_cat_clave_producto_servicio: value.id_cat_clave_producto_servicio,
				id_cat_clave_unidad_medida: value.id_cat_clave_unidad_medida,
				descripcion_concepto: value.descripcion_concepto,
				cantidad: value.cantidad,
				precio_unitario: value.precio_unitario,
				numero_identificacion: value.numero_identificacion,
				iva_incluido: (value.iva_incluido === 1),
				iva_aplicado: (value.iva_aplicado === 1),
				total: value.total,
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_cat_clave_producto_servicio: '',
			id_cat_clave_unidad_medida: '',
			descripcion_concepto: '',
			cantidad: '',
			precio_unitario: '',
			numero_identificacion: '',
			total: '',
			iva_incluido: false,
			iva_aplicado: false,
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar este concepto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_facturacion_producto = FieldsJs.Copy(this.state.lista_facturacion_producto);
					
					lista_facturacion_producto.splice(key, 1);
					
					this.setState({
						lista_facturacion_producto: lista_facturacion_producto
					});
					
					this.calcularTotalCompra();
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	calcularTotalProducto = () => {
		setTimeout(() => {
			let cantidad = this.state.cantidad;
			let precio_unitario = this.state.precio_unitario;
			let total = 0;
			
			if (cantidad > 0 && precio_unitario > 0) {
				total = (cantidad * precio_unitario);
			}
			
			this.setState({
				total: total
			});
		});
	};
	
	calcularPrecioUnitarioProducto = () => {
		setTimeout(() => {
			let cantidad = this.state.cantidad || 1;
			let precio_unitario = 0;
			let total = this.state.total;
			
			if (cantidad > 0 && total > 0) {
				if (this.state.iva_aplicado === 0) {
					precio_unitario = (total / cantidad);
				}
			}
			
			this.setState({
				cantidad: cantidad,
				precio_unitario: precio_unitario
			});
		});
	};
	
	calcularTotalCompra = () => {
		setTimeout(() => {
			let lista_facturacion_producto = this.state.lista_facturacion_producto || [];
			let total = 0;
			for (let i = 0; i < lista_facturacion_producto.length; i++) {
				let item = lista_facturacion_producto[i];
				if (Number(item.total) > 0) {
					total += this.calcularTotalConSinIVA(item);
				}
			}
			this.setState({
				facturacion_total: total
			});
		});
	};
	
	calcularSubTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1 && value.iva_aplicado === 0) {
			return value.precio_unitario / 1.16;
		} else {
			return value.precio_unitario;
		}
	};
	
	calcularIVAPrecioUnitario = (value) => {
		if (value.iva_aplicado === 0) {
			if (value.iva_incluido === 1) {
				return value.precio_unitario - (value.precio_unitario / 1.16);
			} else {
				return value.precio_unitario * 0.16;
			}
		} else {
			return 0;
		}
	};
	
	calcularTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1 || value.iva_aplicado === 1) {
			return value.precio_unitario;
		} else {
			return value.precio_unitario * 1.16;
		}
	};
	
	calcularSubTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1 && value.iva_aplicado === 0) {
			return value.total / 1.16;
		} else {
			return value.total;
		}
	};
	
	calcularIVA = (value) => {
		if (value.iva_aplicado === 0) {
			if (value.iva_incluido === 1) {
				return value.total - (value.total / 1.16);
			} else {
				return value.total * 0.16;
			}
		} else {
			return 0;
		}
	};
	
	calcularTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1 || value.iva_aplicado === 1) {
			return value.total;
		} else {
			return value.total * 1.16;
		}
	};
	
	calcularDesgoseTotales = () => {
		let desglose = {
			subtotal: 0,
			iva: 0,
			total: 0,
		};
		let lista_facturacion_producto = this.state.lista_facturacion_producto;
		for (let i = 0; i < lista_facturacion_producto.length; i++) {
			let item = lista_facturacion_producto[i];
			desglose.subtotal += this.calcularSubTotalConSinIVA(item);
			desglose.iva += this.calcularIVA(item);
			desglose.total += this.calcularTotalConSinIVA(item);
		}
		return desglose;
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	getClaveProductoServicio = (id_clave_ps) => {
		let clave;
		
		for (let i = 0; i < this.state.listar_clave_producto_servicio.length; i++) {
			if (Number(id_clave_ps) === Number(this.state.listar_clave_producto_servicio[i].id_cat_clave_producto_servicio)) {
				clave = this.state.listar_clave_producto_servicio[i].clave_producto_servicio;
			}
		}
		return clave;
	};
	
	render() {
		
		const is_root = this.is_root();
		
		const desglose = this.calcularDesgoseTotales();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Facturación
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							{FieldsJs.inArray([1, 2, 4], this.Usr.id_cat_tipo_usuario) ? (
								<Fragment>
									<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
										<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
											{!this.is_view() ? (
												<Grid item style={{width: '50px'}}>
													<ModalEmpresa
														componente={(
															<Tooltip
																TransitionComponent={Zoom}
																placement={"right"}
																title="Empresa"
																children={(
																	<Fab
																		color="secondary"
																		size="small"
																		aria-label="Agregar empresa"
																		children={(
																			<PlaylistAddOutlined/>
																		)}
																	/>
																)}
															/>
														)}
														id_empresa={null}
														tipo={'add'}
														item={{}}
														RefreshList={this.RefreshListEmpresa}
														showSnackBars={this.props.showSnackBars}
													/>
												</Grid>
											) : null}
											<Grid item style={{width: 'calc(100% - 50px)'}}>
												<TextField
													select
													fullWidth
													required
													margin="none"
													onChange={this.handleChange}
													disabled={this.is_view()}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}
													
													name="id_empresa"
													label="Empresa"
													value={this.state.id_empresa}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.listar_empresa.map(option => (
														<option key={option.id_empresa} value={option.id_empresa}>
															{option.nombre_comercial}
														</option>
													))}
												</TextField>
											</Grid>
										</Grid>
									</Grid>
								</Fragment>) : null}
							
							{FieldsJs.inArray([1, 2, 4], this.Usr.id_cat_tipo_usuario) ? (
								<Fragment>
									<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
										<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
											{!this.is_view() ? (
												<Grid item style={{width: '50px'}}>
													
													<ModalTipoComprobante
														componente={(
															<Tooltip
																TransitionComponent={Zoom}
																placement={"right"}
																title="Tipo comprobante"
																children={(
																	<Fab
																		color="default"
																		size="small"
																		aria-label="Agregar nuevo tipo comprobante"
																		children={(
																			<PlaylistAddOutlined/>
																		)}
																	/>
																)}
															/>
														)}
														id_cliente={null}
														tipo={'add'}
														item={{}}
														RefreshList={this.RefreshListTipoComprobante}
														showSnackBars={this.props.showSnackBars}
													/>
												</Grid>
											) : null}
											<Grid item style={{width: 'calc(100% - 50px)'}}>
												<TextField
													select
													fullWidth
													required
													margin="none"
													onChange={this.handleChange}
													disabled={this.is_view()}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}
													
													name="id_cat_tipo_comprobante"
													label="Tipo comprobante"
													value={this.state.id_cat_tipo_comprobante}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.listar_tipo_comprobante.map(option => (
														<option key={option.id_cat_tipo_comprobante} value={option.id_cat_tipo_comprobante}>
															{option.tipo_comprobante}
														</option>
													))}
												</TextField>
											</Grid>
										</Grid>
									</Grid>
								</Fragment>
							) : null}
							{FieldsJs.inArray([1, 2, 4], this.Usr.id_cat_tipo_usuario) ? (
								<Fragment>
									<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
										<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
											{!this.is_view() ? (
												<Grid item style={{width: '50px'}}>
													
													<ModalCliente
														componente={(
															<Tooltip
																TransitionComponent={Zoom}
																placement={"right"}
																title="Agregar nuevo cliente"
																children={(
																	<Fab
																		color="default"
																		size="small"
																		aria-label="Agregar nuevo cliente"
																		children={(
																			<PlaylistAddOutlined/>
																		)}
																	/>
																)}
															/>
														)}
														id_cliente={null}
														tipo={'add'}
														item={{}}
														RefreshList={this.RefreshListFacturacion}
														showSnackBars={this.props.showSnackBars}
													/>
												</Grid>
											) : null}
											<Grid item style={{width: 'calc(100% - 50px)'}}>
												<TextField
													select
													fullWidth
													required
													margin="none"
													onChange={this.handleChange}
													disabled={this.is_view()}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}
													
													name="id_cliente"
													label="Cliente"
													value={this.state.id_cliente}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.listar_cliente.map(option => (
														<option key={option.id_cliente} value={option.id_cliente}>
															{option.id_cat_tipo_persona === 1 ? option.nombre_completo : option.nombre_comercial}
														</option>
													))}
												</TextField>
											</Grid>
										</Grid>
									</Grid>
								</Fragment>
							) : null}
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<KeyboardDateTimePicker
									format="yyyy/MM/dd hh:mm a"
									fullWidth
									required
									clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha de factura',
									}}
									label="Fecha de factura"
									value={this.state.fecha_emitir_comprobante}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_emitir_comprobante');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									
									disabled={this.is_view()}
								/>
							</Grid>
							
							{/*<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<TextField
									margin="none"
									name="serie"
									label="No. Serie"
									type="text"
									fullWidth
									value={this.state.serie}
									onChange={(e) => {
										this.handleChange(e);
									}}
									inputProps={{}}
									required={true}
								/>
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<TextField
									margin="none"
									name="folio"
									label="Folio"
									type="text"
									fullWidth
									value={this.state.folio}
									onChange={(e) => {
										this.handleChange(e);
									}}
									onKeyPress={EnteroSolo}
									inputProps={{maxLength: 10}}
									required={true}
								/>
							</Grid>*/}
							
							<Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
								<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
									
									{FieldsJs.inArray([1, 2, 4, 9], this.Usr.id_cat_tipo_usuario) ? (
										<Fragment>
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
													{!this.is_view() ? (
														<Grid item style={{width: '50px'}}>
															
															<ModalFormaPago
																componente={(
																	<Tooltip
																		TransitionComponent={Zoom}
																		placement={"right"}
																		title="Forma de pago"
																		children={(
																			<Fab
																				color="default"
																				size="small"
																				aria-label="Agregar nueva forma de pago"
																				children={(
																					<PlaylistAddOutlined/>
																				)}
																			/>
																		)}
																	/>
																)}
																id_cliente={null}
																tipo={'add'}
																item={{}}
																RefreshList={this.RefreshListFormaPago}
																showSnackBars={this.props.showSnackBars}
															/>
														</Grid>
													) : null}
													<Grid item style={{width: 'calc(100% - 50px)'}}>
														<TextField
															select
															fullWidth
															required
															margin="none"
															onChange={this.handleChange}
															disabled={this.is_view()}
															SelectProps={{
																native: true,
																MenuProps: {
																	className: '',
																},
															}}
															
															name="id_cat_forma_pago"
															label="Forma de pago"
															value={this.state.id_cat_forma_pago}
														>
															<option key={0} value={''}>&nbsp;</option>
															{this.state.listar_forma_pago.map(option => (
																<option key={option.id_cat_forma_pago} value={option.id_cat_forma_pago}>
																	{option.forma_pago} - {option.descripcion}
																</option>
															))}
														</TextField>
													</Grid>
												</Grid>
											</Grid>
										</Fragment>
									) : null}
									
									{FieldsJs.inArray([1, 2, 4, 9], this.Usr.id_cat_tipo_usuario) ? (
										<Fragment>
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
													{!this.is_view() ? (
														<Grid item style={{width: '50px'}}>
															
															<ModalMetodoPago
																componente={(
																	<Tooltip
																		TransitionComponent={Zoom}
																		placement={"right"}
																		title="Metodo de pago"
																		children={(
																			<Fab
																				color="default"
																				size="small"
																				aria-label="Agregar nuevo metodo de pago"
																				children={(
																					<PlaylistAddOutlined/>
																				)}
																			/>
																		)}
																	/>
																)}
																id_cliente={null}
																tipo={'add'}
																item={{}}
																RefreshList={this.RefreshListMetodoPago}
																showSnackBars={this.props.showSnackBars}
															/>
														</Grid>
													) : null}
													<Grid item style={{width: 'calc(100% - 50px)'}}>
														<TextField
															select
															fullWidth
															margin="none"
															onChange={this.handleChange}
															disabled={this.is_view()}
															SelectProps={{
																native: true,
																MenuProps: {
																	className: '',
																},
															}}
															
															name="id_cat_metodo_pago"
															label="Metodo de pago"
															value={this.state.id_cat_metodo_pago}
														>
															<option key={0} value={''}>&nbsp;</option>
															{this.state.listar_metodo_pago.map(option => (
																<option key={option.id_cat_metodo_pago} value={option.id_cat_metodo_pago}>
																	{option.metodo_pago} - {option.descripcion}
																</option>
															))}
														</TextField>
													</Grid>
												</Grid>
											</Grid>
										</Fragment>
									) : null}
								</Grid>
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									{FieldsJs.inArray([1, 2, 4, 9], this.Usr.id_cat_tipo_usuario) ? (
										<Grid item style={{width: 'calc(100%)'}}>
											<TextField
												select
												fullWidth
												required
												margin="none"
												onChange={this.handleChange}
												disabled={this.is_view()}
												SelectProps={{
													native: true,
													MenuProps: {
														className: '',
													},
												}}
												name="id_cat_periodo_pago"
												label="Periodo pago"
												value={this.state.id_cat_periodo_pago}
											>
												<option key={0} value={''}>&nbsp;</option>
												{this.state.listar_periodo_pago.map(option => (
													<option key={option.id_cat_periodo_pago} value={option.id_cat_periodo_pago}>
														{option.periodo_pago}
													</option>
												))}
											</TextField>
										</Grid>
									) : null}
								</Grid>
							</Grid>
							
							<Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
								<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
									
									
									<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
										<TextField
											rows={4}
											multiline
											margin="none"
											name="observacion_cfdi"
											label="Observación CFDI"
											type="text"
											fullWidth
											value={this.state.observacion_cfdi}
											onChange={this.handleChange}
											disabled={this.is_view()}
										/>
									</Grid>
									
									<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
										<TextField
											rows={4}
											multiline
											margin="none"
											name="observacion_interna"
											label="Observación interna"
											type="text"
											fullWidth
											value={this.state.observacion_interna}
											onChange={this.handleChange}
											disabled={this.is_view()}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"} alignContent={"flex-end"}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={"center"} style={{paddingBottom: '10px'}}>
										<Typography component={'h2'} className={'margin-0 padding-0 px-14'}>
											Monto total:
										</Typography>
										<Typography component={'h2'} className={'margin-0 padding-0 px-25'}>
											${FieldsJs.Currency(this.state.facturacion_total)}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							
							{!this.is_view() ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={2} alignItems={"flex-end"}
												      alignContent={"flex-end"}>
													
													<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
														<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
															{!this.is_view() ? (
																<Grid item style={{width: '50px'}}>
																	{FieldsJs.inArray([1, 2, 4], this.Usr.id_cat_tipo_usuario) ? (
																		<ModalClaveProductoServicio
																			componente={(
																				<Tooltip
																					TransitionComponent={Zoom}
																					placement={"right"}
																					title="Clave producto / servicio"
																					children={(
																						<Fab
																							color="default"
																							size="small"
																							aria-label="Agregar nueva clave de producto / servicio"
																							children={(
																								<PlaylistAddOutlined/>
																							)}
																						/>
																					)}
																				/>
																			)}
																			id_cliente={null}
																			tipo={'add'}
																			item={{}}
																			RefreshList={this.RefreshListClaveProductoServicio}
																			showSnackBars={this.props.showSnackBars}
																		/>
																	) : ''}
																</Grid>
															) : null}
															<Grid item style={{width: 'calc(100% - 50px)'}}>
																<TextField
																	select
																	fullWidth
																	required
																	margin="none"
																	onChange={(e) => {
																		this.handleChange(e);
																		this.colocarValoresEnCampos(e.target.value);
																	}}
																	disabled={this.is_view()}
																	SelectProps={{
																		native: true,
																		MenuProps: {
																			className: '',
																		},
																	}}
																	
																	name="id_cat_clave_producto_servicio"
																	label="Clave producto / servicio"
																	value={this.state.id_cat_clave_producto_servicio}
																>
																	<option key={0} value={''}>&nbsp;</option>
																	{this.state.listar_clave_producto_servicio.map(option => (
																		<option key={option.id_cat_clave_producto_servicio} value={option.id_cat_clave_producto_servicio}>
																			{option.clave_producto_servicio} - {option.producto_servicio}
																		</option>
																	))}
																</TextField>
															</Grid>
														</Grid>
													</Grid>
													
													<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
														<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
															{!this.is_view() ? (
																<Grid item style={{width: '50px'}}>
																	{FieldsJs.inArray([1, 2, 4], this.Usr.id_cat_tipo_usuario) ? (
																		<ModalClaveUnidadMedida
																			componente={(
																				<Tooltip
																					TransitionComponent={Zoom}
																					placement={"right"}
																					title="Clave unidad medida"
																					children={(
																						<Fab
																							color="default"
																							size="small"
																							aria-label="Agregar nueva clave unidad medida"
																							children={(
																								<PlaylistAddOutlined/>
																							)}
																						/>
																					)}
																				/>
																			)}
																			id_cliente={null}
																			tipo={'add'}
																			item={{}}
																			RefreshList={this.RefreshListClaveUnidadMedida}
																			showSnackBars={this.props.showSnackBars}
																		/>
																	) : ''}
																</Grid>
															) : null}
															<Grid item style={{width: 'calc(100% - 50px)'}}>
																<TextField
																	select
																	fullWidth
																	required
																	margin="none"
																	onChange={this.handleChange}
																	disabled={this.is_view()}
																	SelectProps={{
																		native: true,
																		MenuProps: {
																			className: '',
																		},
																	}}
																	
																	name="id_cat_clave_unidad_medida"
																	label="Clave unidad medida"
																	value={this.state.id_cat_clave_unidad_medida}
																>
																	<option key={0} value={''}>&nbsp;</option>
																	{this.state.listar_clave_unidad_medida.map(option => (
																		<option key={option.id_cat_clave_unidad_medida} value={option.id_cat_clave_unidad_medida}>
																			{option.clave_unidad_medida} - {option.unidad_medida}
																		</option>
																	))}
																</TextField>
															</Grid>
														</Grid>
													</Grid>
													
													<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
														<TextField
															margin="none"
															name="numero_identificacion"
															label="Número de identificación"
															type="text"
															fullWidth
															value={this.state.numero_identificacion}
															onChange={(e) => {
																this.handleChange(e);
															}}
														/>
													</Grid>
													
													<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
														<FormGroup row>
															<FormControlLabel
																control={
																	<Checkbox
																		type="checkbox"
																		name='iva_incluido'
																		checked={this.state.iva_incluido}
																		onChange={this.handleChangeIvaIncluido}
																		value="iva_incluido"
																		color="primary"
																		disabled={this.props.tipo === 'view'}
																	/>
																}
																label={'iva incluido'}
															/>
														</FormGroup>
													</Grid>
													
													<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
														<FormGroup row>
															<FormControlLabel
																control={
																	<Checkbox
																		type="checkbox"
																		name='iva_aplicado'
																		checked={this.state.iva_aplicado}
																		onChange={this.handleChangeIvaAplicado}
																		value="iva_aplicado"
																		color="primary"
																		disabled={this.props.tipo === 'view'}
																	/>
																}
																label={'No aplica IVA'}
															/>
														</FormGroup>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
											<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
												<TextField
													margin="none"
													name="descripcion_concepto"
													label="Descripción"
													type="text"
													fullWidth
													required
													value={this.state.descripcion_concepto}
													onChange={this.handleChange}
												/>
											</Grid>
											
											<Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
												<Grid container spacing={2} alignItems={"flex-end"}
												      alignContent={"flex-end"}>
													
													<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
														<TextField
															margin="none"
															name="cantidad"
															label="Cantidad"
															type="text"
															fullWidth
															value={this.state.cantidad}
															onChange={(e) => {
																this.handleChange(e);
																this.calcularTotalProducto();
															}}
															onKeyPress={EnteroSolo}
															inputProps={{maxLength: 5}}
															required={true}
														/>
													</Grid>
													
													<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
														<TextField
															margin="none"
															name="precio_unitario"
															label="Precio Unitario"
															type="text"
															fullWidth
															value={this.state.precio_unitario}
															onChange={(e) => {
																this.handleChange(e);
																this.calcularTotalProducto();
															}}
															onKeyPress={EnteroSolo}
															inputProps={{maxLength: 50}}
															required={true}
														/>
													</Grid>
													
													<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
														<TextField
															margin="none"
															name="total"
															label="Importe"
															type="text"
															fullWidth
															value={this.state.total}
															onChange={(e) => {
																this.handleChange(e);
																this.calcularPrecioUnitarioProducto();
															}}
															onKeyPress={EnteroSolo}
															inputProps={{maxLength: 50}}
															required={true}
														/>
													</Grid>
													
													
													<Grid item xs={3} sm={3} md={3} lg={3} xl={3} align={"right"}>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Cancelar edición"
															children={(
																<span>
																	<Fab size="small" color="secondary"
																	     aria-label="Cancelar edición"
																	     onClick={() => this.edit_cancel()}
																	     style={{marginLeft: "15px"}}
																	     disabled={!this.is_edited_product()}>
																		<CancelOutlined/>
																	</Fab>
																</span>
															)}
														/>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title={this.is_edited_product() ? "Actualizar" : "Agregar"}
															children={(
																<Fab size="small" color="secondary"
																     aria-label={this.is_edited_product() ? "Actualizar" : "Agregar"}
																     onClick={() => this.add()}
																     style={{marginLeft: "15px"}}>
																	{this.is_edited_product() ? (
																		<SaveOutlined/>
																	) : (
																		<AddOutlined/>
																	)}
																</Fab>
															)}
														/>
													</Grid>
												
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Fragment>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<table className={'desing-mark'}>
									<thead>
									<tr>
										<th>Clave Producto/Servicio</th>
										<th>Descripción</th>
										{is_root ? (
											<th align={"center"} style={{color: '#00a8ff'}}>IVA incluido</th>
										) : null}
										<th align={"center"}>Cantidad</th>
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>Importe P. U.</th>
										) : null}
										<th align={"right"}>
											{is_root ? 'Subtotal P. U.' : 'Precio Unitario'}
										</th>
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>IVA P. U.</th>
										) : null}
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>Precio Unitario</th>
										) : null}
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>Importe Total</th>
										) : null}
										<th align={"right"}>
											{is_root ? 'Subtotal' : 'Total'}
										</th>
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>IVA</th>
										) : null}
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>Importe</th>
										) : null}
										{!this.is_view() ? (
											<th align={'right'} width={'110px'}>Acciones</th>
										) : null}
									</tr>
									</thead>
									<tbody>
									{this.state.lista_facturacion_producto.map((value, index) => (
										<tr key={index}>
											<td>{this.getClaveProductoServicio(value.id_cat_clave_producto_servicio)}</td>
											<td>{value.descripcion_concepto}</td>
											{is_root ? (
												<td align={"center"}>
													{value.iva_incluido === 1 ? (
														<div style={{
															width: "15px",
															color: "white",
															borderRadius: "3px",
															padding: "1px 7px",
															background: "#3F51B5",
															display: "inline-grid",
															fontSize: '12px',
														}}>Si</div>
													) : (
														<div style={{
															width: "15px",
															color: "white",
															borderRadius: "3px",
															padding: "1px 7px",
															background: "#FF5722",
															display: "inline-grid",
															fontSize: '12px',
														}}>No</div>
													)}
												</td>
											) : null}
											<td align={"center"}>
												{value.cantidad}
											</td>
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													${FieldsJs.Currency(value.precio_unitario)}
												</td>
											) : null}
											<td align={"right"}>
												<b>
													${FieldsJs.Currency(this.calcularSubTotalConSinIVAPrecioUnitario(value))}
												</b>
											</td>
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													<b>
														${FieldsJs.Currency(this.calcularIVAPrecioUnitario(value))}
													</b>
												</td>
											) : null}
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													<b>
														${FieldsJs.Currency(this.calcularTotalConSinIVAPrecioUnitario(value))}
													</b>
												</td>
											) : null}
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													${FieldsJs.Currency(value.total)}
												</td>
											) : null}
											<td align={"right"}>
												<b>
													${FieldsJs.Currency(this.calcularSubTotalConSinIVA(value))}
												</b>
											</td>
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													<b>
														${FieldsJs.Currency(this.calcularIVA(value))}
													</b>
												</td>
											) : null}
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													<b>
														${FieldsJs.Currency(this.calcularTotalConSinIVA(value))}
													</b>
												</td>
											) : null}
											{!this.is_view() ? (
												<td align={'right'}>
													<Tooltip
														TransitionComponent={Zoom}
														placement={"top"}
														title="Eliminar"
														children={(
															<Fab
																size="small"
																aria-label="Eliminar"
																onClick={() => this.delete(value, index)}
																style={{
																	marginLeft: "10px",
																	backgroundColor: "transparent",
																	color: "#808080",
																	boxShadow: "none",
																}}
																children={(
																	<DeleteOutlined/>
																)}
															/>
														)}
													/>
													{this.state.key === index ? (
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Producto en edición"
															children={(
																<Fab
																	size="small"
																	aria-label="Editar"
																	onClick={() => this.edit(value, index)}
																	style={{
																		marginLeft: "10px",
																		backgroundColor: "#f50057",
																		color: "white",
																	}}
																	children={(
																		<EditOutlined/>
																	)}
																/>
															)}
														/>
													) : (
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Editr producto"
															children={(
																<Fab
																	size="small"
																	aria-label="Editar"
																	onClick={() => this.edit(value, index)}
																	style={{
																		marginLeft: "10px",
																		backgroundColor: "transparent",
																		color: "#808080",
																		boxShadow: "none",
																	}}
																	children={(
																		<EditOutlined/>
																	)}
																/>
															)}
														/>
													)}
												</td>
											) : null}
										</tr>
									))}
									{!(this.state.lista_facturacion_producto.length > 0) ? (
										<tr>
											<td colSpan={!this.is_view() ? (is_root ? 13 : 6) : (is_root ? 12 : 5)}
											    align={'center'}>
												<VistaVacia
													numero={null}
													mensaje={'Ningún producto agregado.'}
													image={carrito_de_compra_3}
													padding={'20px'}
													paddingText={'20px'}
													height={'80px'}
													width={'80px'}
												/>
											</td>
										</tr>
									) : null}
									<tr>
										<td colSpan={(is_root ? 10 : 3)}/>
										<td align={"right"}>Subtotal</td>
										<td align={"right"}>${FieldsJs.Currency(desglose.subtotal)}</td>
										<td/>
									</tr>
									<tr>
										<td colSpan={(is_root ? 10 : 3)}/>
										<td align={"right"}>IVA</td>
										<td align={"right"}>${FieldsJs.Currency(desglose.iva)}</td>
										<td/>
									</tr>
									<tr>
										<td colSpan={(is_root ? 10 : 3)}/>
										<td align={"right"}>Total</td>
										<td align={"right"}>${FieldsJs.Currency(desglose.total)}</td>
										<td/>
									</tr>
									</tbody>
								</table>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : 'Agregar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalFacturacion.propTypes = {
	id_facturacion: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalFacturacion;
