import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const ArchivoElaboracionPropuestaService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Archivo_Elaboracion_Propuesta_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			archivo_elaboracion_propuesta: form.archivo_elaboracion_propuesta,
			is_required: form.is_required ? 1 : 0,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Archivo_Elaboracion_Propuesta_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_archivo_elaboracion_propuesta: form.id_cat_archivo_elaboracion_propuesta,
			archivo_elaboracion_propuesta: form.archivo_elaboracion_propuesta,
			is_required: form.is_required ? 1 : 0,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Archivo_Elaboracion_Propuesta_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_archivo_elaboracion_propuesta) => {
		let params = {
			id_cat_archivo_elaboracion_propuesta: id_cat_archivo_elaboracion_propuesta
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Archivo_Elaboracion_Propuesta_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
