import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {ClienteFacturacionExtService} from "../../../../services/ClienteFacturacionExtService/ClienteFacturacionExtService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {
	AddOutlined,
	AlternateEmailOutlined,
	CancelOutlined,
	DeleteOutlined,
	EditOutlined,
	GrainOutlined,
	HdrStrongOutlined,
	HdrWeakOutlined,
	HowToVoteOutlined,
	ImageAspectRatioOutlined,
	PhoneIphoneOutlined,
	PhoneOutlined,
	PinDropOutlined,
	SaveOutlined,
	StreetviewOutlined,
	TurnedInNotOutlined,
	Description
} from "@material-ui/icons";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import carrito_de_compra_3 from "../../../../assets/img/icons/contacto_tarjeta.svg";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {ReactMultiEmail} from "react-multi-email";

class ModalClienteFacturacion extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_usuario: this.Usr.id_usuario,
			id_cliente: (props.item || {}).id_cliente,
			id_cat_tipo_persona: '1',
			tipo_persona: 'fisica',
			id_empresa: '',
			nombre_razon_social: '',
			correo_electronico: [],
			correo_electronico_copia: [],
			telefono: '',
			rfc: '',
			curp: '',
			registro_identidad_fiscal: '',
			id_cat_pais: '',
			id_cat_regimen_fiscal: '',
			cat_pais: [],
			cat_regimen_fiscal: [],
			
			activo: true,
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			this.ListPais();
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	ListPais = () => {
		CatService.ListPais().then((response) => {
			this.setState({
				cat_pais: response.data,
				id_cat_pais: 401
			});
		});
	};
	
	ListRegimenFiscal = (id_cat_tipo_persona) => {
		CatService.ListRegimenFiscalFac(id_cat_tipo_persona ? id_cat_tipo_persona : this.state.id_cat_tipo_persona).then((response) => {
			this.setState({
				cat_regimen_fiscal: response.data
			});
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
		if (this.state.id_cliente > 0) {
			ClienteFacturacionExtService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			ClienteFacturacionExtService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		this.setState({
			id_usuario: this.Usr.id_usuario,
			id_cliente: '',
			id_cat_tipo_persona: '',
			id_empresa: '',
			nombre_razon_social: '',
			correo_electronico: [],
			correo_electronico_copia: [],
			telefono: '',
			regimen_fiscal: '',
			rfc: '',
			curp: '',
			codigo_postal: '',
			registro_identidad_fiscal: '',
			id_cat_pais: '',
			id_cat_regimen_fiscal: '',
			activo: true,
			
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		this.detalle(item.id_cliente);
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.detalle(item.id_cliente);
		
		this.open();
	};
	
	detalle = (id_cliente) => {
		ClienteFacturacionExtService.Detalle(id_cliente).then((response) => {
			this.setState({
				id_cliente: response.data.id_cliente || '',
				id_cat_tipo_persona: response.data.id_cat_tipo_persona || '',
				tipo_persona: response.data.id_cat_tipo_persona === 1 ? 'fisica' : 'moral',
				id_empresa: response.data.id_empresa || '',
				nombre_razon_social: response.data.nombre_razon_social || '',
				correo_electronico: response.data.correo_electronico || [],
				correo_electronico_copia: response.data.correo_electronico_copia || [],
				celular: response.data.celular || '',
				telefono: response.data.telefono || '',
				rfc: response.data.rfc || '',
				curp: response.data.curp || '',
				codigo_postal: response.data.codigo_postal || '',
				registro_identidad_fiscal: response.data.registro_identidad_fiscal || '',
				id_cat_pais: response.data.id_cat_pais || '',
				id_cat_regimen_fiscal: response.data.id_cat_regimen_fiscal || '',
				activo: (response.data.activo === 1),
			});
			this.ListRegimenFiscal(response.data.id_cat_tipo_persona ? response.data.id_cat_tipo_persona : false);
		}).catch((error) => {
			this.setState({
				id_cliente: '',
				id_cat_tipo_persona: '',
				tipo_persona: 1 ? 'fisica' : 'moral',
				id_empresa: '',
				nombre_razon_social: '',
				correo_electronico: [],
				correo_electronico_copia: [],
				celular: '',
				telefono: '',
				rfc: '',
				curp: '',
				codigo_postal: '',
				registro_identidad_fiscal: '',
				id_cat_pais: '',
				id_cat_regimen_fiscal: '',
				activo: false,
				
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	
	clienteRFC = () => {
		if(this.state.rfc.length > 11) {
			ClienteFacturacionExtService.ClienteRFC(this.state.rfc).then((response) => {
				let data = response.data;
				this.setState({
					id_cat_tipo_persona: data.id_cat_tipo_persona || '',
					nombre_razon_social: data.id_cat_tipo_persona === 2 ? data.razon_social : data.nombre,
					curp: '',
					correo_electronico: data.correo_electronico || [],
					correo_electronico_copia: data.correo_electronico_copia || [],
					telefono: data.telefono || data.celular || '',
					id_cat_pais: '',
					id_cat_regimen_fiscal: '',
					registro_identidad_fiscal: '',
					codigo_postal: ''
				});
				
				this.ListRegimenFiscal(data.id_cat_tipo_persona);
			}).catch((error) => {
				this.setState({
					nombre_razon_social: '',
					curp: '',
					correo_electronico: [],
					correo_electronico_copia: [],
					telefono: '',
					id_cat_pais: '',
					regimen_fiscal: '',
					codigo_postal: '',
					registro_identidad_fiscal: '',
					id_cat_tipo_persona: this.state.rfc.length < 13 ? '2' : '1',
				});
				this.ListRegimenFiscal(this.state.rfc.length < 13 ? '2' : '1');
			});
		}
	}
	
	setEmails = (emails, tipo) => {
		if (!tipo) {
			this.setState({correo_electronico: emails});
		} else {
			this.setState({correo_electronico_copia: emails});
		}
	}
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cliente}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Cliente Facturación'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							{/*{this.state.tipo_persona}<br/>
							{this.state.id_cat_tipo_persona}<br/>
							{this.state.razon_social}<br/>*/}
							
							<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HowToVoteOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="rfc"
											onChange={this.handleChange}
											onBlur={this.clienteRFC}
											inputProps={{maxLength: 13}}
											label="R.F.C." autoComplete={'off'}
											value={this.state.rfc}
											required
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Tipo de persona</InputLabel>
											<Select
												native
												value={this.state.id_cat_tipo_persona}
												onChange={this.handleChange}
												name="id_cat_tipo_persona"
												input={<Input/>}
												disabled
											>
												<option value=""/>
												<option key={"1"} value={"1"}> {"Física"} </option>
												<option key={"2"} value={"2"}> {"Moral"} </option>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HowToVoteOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="curp"
										           onChange={this.handleChange}
										           label="C.U.R.P." autoComplete={'off'}
										           value={this.state.curp}
										           required={Number(this.state.id_cat_tipo_persona) === 2 || this.state.rfc === "XAXX010101000" ? false : true}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="nombre_razon_social"
										           onChange={this.handleChange}
										           label="Nombre o Razón Social" autoComplete={'off'}
										           value={this.state.nombre_razon_social}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PhoneOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="telefono"
										           onChange={this.handleChange}
										           inputProps={{maxLength: 10}}
										           onKeyPress={EnteroSolo}
										           label="Teléfono" autoComplete={'off'}
										           value={this.state.telefono}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>País</InputLabel>
											<Select
												native
												value={this.state.id_cat_pais}
												onChange={this.handleChange}
												name="id_cat_pais"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_pais.map(option => (
													<option key={option.id_cat_pais}
													        value={option.id_cat_pais}>
														{option.pais}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AlternateEmailOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px margin-10-T'}>
										<ReactMultiEmail
											disabled={this.props.tipo === 'view'}
											placeholder='Correo electrónico'
											emails={this.state.correo_electronico}
											onChange={(_emails) => {
												this.setEmails(_emails);
											}}
											autoFocus={false}
											getLabel={(email, index, removeEmail) => {
												return (
													<div data-tag key={index}>
														<div data-tag-item>{email}</div>
														<span data-tag-handle onClick={() => removeEmail(index)}>
															                ×
															              </span>
													</div>
												);
											}}
										/>
										{/*<TextField type={'text'} fullWidth name="correo_electronico"
										           onChange={this.handleChange}
										           label="Correo electrónico" autoComplete={'off'}
										           value={this.state.correo_electronico}
										           required
										           disabled={this.props.tipo === 'view'}
										/>*/}
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AlternateEmailOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px margin-10-T'}>
										<ReactMultiEmail
											disabled={this.props.tipo === 'view'}
											placeholder='Correo electrónico Cc'
											emails={this.state.correo_electronico_copia}
											onChange={(_emails) => {
												this.setEmails(_emails, true);
											}}
											autoFocus={false}
											getLabel={(email, index, removeEmail) => {
												return (
													<div data-tag key={index}>
														<div data-tag-item>{email}</div>
														<span data-tag-handle onClick={() => removeEmail(index)}>
															                ×
															              </span>
													</div>
												);
											}}
										/>
										{/*<TextField type={'text'} fullWidth name="correo_electronico"
										           onChange={this.handleChange}
										           label="Correo electrónico" autoComplete={'off'}
										           value={this.state.correo_electronico}
										           required
										           disabled={this.props.tipo === 'view'}
										/>*/}
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Description className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="registro_identidad_fiscal"
										           onChange={this.handleChange}
										           label="No. Registro Identidad Fiscal" autoComplete={'off'}
										           value={this.state.registro_identidad_fiscal}
										           multiline
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<ImageAspectRatioOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} required fullWidth name="codigo_postal"
										           onChange={this.handleChange}
										           label="Código Postal" autoComplete={'off'}
										           inputProps={{maxLength: 5}}
										           onKeyPress={EnteroSolo}
										           value={this.state.codigo_postal}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							
							<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Régimen Fiscal</InputLabel>
											<Select
												native
												value={this.state.id_cat_regimen_fiscal}
												onChange={this.handleChange}
												name="id_cat_regimen_fiscal"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_regimen_fiscal.map(option => (
													<option key={option.id_cat_regimen_fiscal}
													        value={option.id_cat_regimen_fiscal}>
														{option.regimen_fiscal ? option.regimen_fiscal + " - " + option.descripcion : option.descripcion}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox type="checkbox" name='activo'
													          checked={this.state.activo}
													          onChange={this.handleChange} value="activo"
													          color="primary"
													          disabled={this.props.tipo === 'view'}/>
												}
												label={'Activo'}
											/>
										</FormGroup>
									</Grid>
								</Grid>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<BotonAccionFooterModal
							id={this.state.id_cliente}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalClienteFacturacion.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalClienteFacturacion;
