import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Typography from "@material-ui/core/Typography/index";
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import EventNote from '@material-ui/icons/EventNote';
import Sync from '@material-ui/icons/Sync';
import ListaTabla from './Includes/ListaTabla';
import ModalTipoPagoComprobantePago from './Includes/ModalTipoPagoComprobantePago';

import {TipoPagoComprobantePagoService} from '../../../../services/_Cat/TipoPagoComprobantePagoService/TipoPagoComprobantePagoService';
import {PopupService} from '../../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Zoom from '@material-ui/core/Zoom/index';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab/index";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../../settings/Config/Config";
import Tooltip from "@material-ui/core/Tooltip";


class TipoPagoComprobantePago extends Component {
	
	state = {
		modal: {
			open: false,
			title: '',
			tipo: ''
		},
		id_cat_tipo_pago_comprobante_pago: '',
		tipo_pago_comprobante_pago: '',
		abreviacion: '',
		activo: false,
		listar_cat_tipo_pago_comprobante_pago: []
	};
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.handleChange = this.handleChange.bind(this);
		this.showSnackBars = this.showSnackBars.bind(this);
		
		this.delete = this.delete.bind(this);
		this.Listar = this.Listar.bind(this);
		
		this.Listar();
	}
	
	handleChange(e) {
		const {value, name, checked, type} = e.target;
		this.setState({
			[name]: type === 'checkbox' ? checked : value
		});
	}
	
	showSnackBars(type, message) {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	Listar = () => {
		TipoPagoComprobantePagoService.Listar().then((response) => {
			
			this.setState({listar_cat_tipo_pago_comprobante_pago: response.data});
			
		}).catch((error) => {
			
			this.setState({listar_cat_tipo_pago_comprobante_pago: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	delete = (item) => {
		
		let msg = `¿Deseas eliminar el tipo pago comprobante pago con id: ${item.id_cat_tipo_pago_comprobante_pago}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				TipoPagoComprobantePagoService.Eliminar(item.id_cat_tipo_pago_comprobante_pago).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<Fragment>
					
					<ModalTipoPagoComprobantePago modal={this.state.modal} form={this.state} save={this.save}
					               closeModal={this.closeModal} handleChange={this.handleChange}
					/>
				
				</Fragment>
				
				<div className={'row-flex margin-30-T margin-30-B'}>
					<div className={'w-100-300-px'}>
						<Typography variant={'h5'} className={'text-left'}>
							Tipo Pago Comprobante Pago
						</Typography>
					</div>
					<div className={'w-300-px text-right'}>
						{/*{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_pago_comprobante_pago) ? (
							<Fab variant="extended" size="small" color="primary" aria-label="PDF"
							     className={'margin-10-L bg-danger'}>
								<PictureAsPdf className={'margin-5-R px-14'}/>
								PDF
							</Fab>
						) : ''}
						{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_pago_comprobante_pago) ? (
							<Fab variant="extended" size="small" color="primary" aria-label="Excel"
							     className={'margin-10-L bg-green'}>
								<EventNote className={'margin-5-R px-14'}/>
								Excel
							</Fab>
						) : ''}*/}
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={this.Listar}>
							<Sync className={'margin-5-R px-14'}/>
							Actualizar
						</Fab>
					</div>
				</div>
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-30-T margin-30-B'}>
					
					</Typography>
					
					{this.state.listar_cat_tipo_pago_comprobante_pago.length > 0 ? (
						<ListaTabla lista={this.state.listar_cat_tipo_pago_comprobante_pago}
						            edit={this.edit}
						            view={this.view}
						            delete={this.delete}
						            RefreshList={this.Listar}
						            showSnackBars={this.showSnackBars}
						/>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalTipoPagoComprobantePago
					tipo={'add'}
					item={{}}
					RefreshList={this.Listar}
					showSnackBars={this.showSnackBars}
				/>
				
			
			</div>
		);
	}
}

TipoPagoComprobantePago.propTypes = {
};

export default IntegrationNotistack(TipoPagoComprobantePago);
