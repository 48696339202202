import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const TipoPagoService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Pago_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			tipo_pago: form.tipo_pago,
			abreviacion: form.abreviacion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Pago_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_tipo_pago: form.id_cat_tipo_pago,
			tipo_pago: form.tipo_pago,
			abreviacion: form.abreviacion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Pago_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_tipo_pago) => {
		let params = {
			id_cat_tipo_pago: id_cat_tipo_pago
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Pago_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
