import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const ClaveProductoServicioService = {
	Listar: (id_cliente) => {
		let params = {
			id_cliente: id_cliente || null
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Clave_Producto_Servicio_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cat_clave_unidad_medida: form.id_cat_clave_unidad_medida,
			clave_producto_servicio: form.clave_producto_servicio,
			producto_servicio: form.producto_servicio,
			numero_identificacion: form.numero_identificacion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Clave_Producto_Servicio_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_clave_producto_servicio: form.id_cat_clave_producto_servicio,
			id_cat_clave_unidad_medida: form.id_cat_clave_unidad_medida,
			clave_producto_servicio: form.clave_producto_servicio,
			producto_servicio: form.producto_servicio,
			numero_identificacion: form.numero_identificacion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Clave_Producto_Servicio_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_clave_producto_servicio) => {
		let params = {
			id_cat_clave_producto_servicio: id_cat_clave_producto_servicio
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Clave_Producto_Servicio_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
