import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const NotificacionesService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				tipo: filtro.tipo
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Notificaciones_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Total: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Notificaciones_Total', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (form) => {
		let params = {
			id_notificacion: form.id_notificacion,
			id_usuario: form.id_usuario
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Notificaciones_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
