import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const VacacionesService = {
	Listar: (filtro, paginacion) => {
		let fecha_inicio = (filtro.fecha_inicio || null) ? DateFormat.FormatSql(filtro.fecha_inicio) : null;
		let fecha_fin = (filtro.fecha_fin || null) ? DateFormat.FormatSql(filtro.fecha_fin) : null;
		
		let params = {
			filtro: {
				id_vacaciones: filtro.id_vacaciones || null,
				id_cat_proyecto: filtro.id_cat_proyecto || null,
				id_cat_proyecto_modulo: filtro.id_cat_proyecto_modulo || null,
				id_cat_cronograma_estatus: filtro.id_cat_cronograma_estatus || null,
				id_cat_tipo_usuario: filtro.id_cat_tipo_usuario || null,
				fecha_inicio: fecha_inicio || null,
				fecha_fin: fecha_fin || null,
				usuario_asignado: filtro.usuario_asignado || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Vacaciones_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_ingreso = form.fecha_ingreso ? DateFormat.FormatSql(form.fecha_ingreso) : '';
		let fecha_inicio = form.fecha_inicio ? DateFormat.FormatSql(form.fecha_inicio) : '';
		let fecha_inicio_labores = form.fecha_inicio_labores ? DateFormat.FormatSql(form.fecha_inicio_labores) : '';
		let fecha_elaboracion = form.fecha_elaboracion ? DateFormat.FormatSql(form.fecha_elaboracion) : '';
		
		let params = {
			id_vacaciones: form.id_vacaciones ?? null,
			id_empresa: form.id_empresa,
			nombre_trabajador: form.nombre_trabajador,
			fecha_ingreso: fecha_ingreso,
			anio_servicio: form.anio_servicio,
			dias_corresponden: form.dias_corresponden,
			dias_disfrutar: form.dias_disfrutar,
			fecha_inicio: fecha_inicio,
			fecha_inicio_labores: fecha_inicio_labores,
			fecha_elaboracion: fecha_elaboracion,
			area: form.area,
			puesto: form.puesto,
			periodo: form.periodo,
			observaciones: form.observaciones,
			usuarios_notificar: form.usuarios_notificar,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Vacaciones_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_ingreso = form.fecha_ingreso ? DateFormat.FormatSql(form.fecha_ingreso) : '';
		let fecha_inicio = form.fecha_inicio ? DateFormat.FormatSql(form.fecha_inicio) : '';
		let fecha_inicio_labores = form.fecha_inicio_labores ? DateFormat.FormatSql(form.fecha_inicio_labores) : '';
		let fecha_elaboracion = form.fecha_elaboracion ? DateFormat.FormatSql(form.fecha_elaboracion) : '';
		
		let params = {
			id_vacaciones: form.id_vacaciones ?? null,
			id_empresa: form.id_empresa,
			nombre_trabajador: form.nombre_trabajador,
			fecha_ingreso: fecha_ingreso,
			anio_servicio: form.anio_servicio,
			dias_corresponden: form.dias_corresponden,
			dias_disfrutar: form.dias_disfrutar,
			fecha_inicio: fecha_inicio,
			fecha_inicio_labores: fecha_inicio_labores,
			fecha_elaboracion: fecha_elaboracion,
			area: form.area,
			puesto: form.puesto,
			periodo: form.periodo,
			observaciones: form.observaciones,
			usuarios_notificar: form.usuarios_notificar,
			activo: form.activo ? 1 : 0,
		};
		
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Vacaciones_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_vacaciones) => {
		let params = {
			id_vacaciones: id_vacaciones
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Vacaciones_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Estatus: (form) => {
		let params = {
			id_vacaciones: form.id_vacaciones,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Vacaciones_Estatus', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
