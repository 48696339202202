import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import Typography from "@material-ui/core/Typography";
import {CloudDownload, CloudUpload} from "@material-ui/icons";
import icon_xml from "../../../../assets/img/icons/xml.svg";
import Fab from "@material-ui/core/Fab";
import icon_acresco from "../../../../assets/img/logo_acresco_talengo.svg";
import icon_integraciones from "../../../../assets/img/logo_integraciones.svg";
import icon_pdf from "../../../../assets/img/icons/pdf.svg";


class ModalDescargarDoc extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo,
			archivo: (props.item || '').url_xml_factura,
			archivo_pdf: (props.item || '').url_pdf_factura
		};
	}
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};
	
	add = () => {
		const {item} = this.props;
		console.log("THIS.PROPS::: ", this.props);
		this.setState({
			archivo: item.url_xml_factura || '',
			archivo_pdf: item.url_pdf_factura || '',
		});
		
		this.open();
		
	};
	
	
	render() {
		
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'add':
				funcion = this.add;
				break;
			
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_facturacion_archivo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'margin-15-B text-center'} style={{color: 'gray', opacity: '0.8'}}>
									Selecciona una opción:
								</Typography>
							</Grid>
							
							<Grid item xs={4} className={'borde-punteado-lightgray-2'}>
								<Fragment>
									<div className={'w-100 text-center margin-15-T margin-15-B margin-5-L margin-5-R'}>
										<img className={'padding-6-R'} alt={'Chat'} style={{opacity: 0.8}} src={icon_xml} width="80px" height="auto"/>
										<div>
											<Fab variant="extended" size="small" color="primary" style={{boxShadow: 'none', backgroundColor: "#e18c02"}} onClick={() => this.props.descargarZip(this.state.archivo)}>
												<CloudDownload style={{fontSize: 14, marginRight: 5}}/> Descargar XML
											</Fab>
										</div>
									</div>
								</Fragment>
							</Grid>
							
							<Grid item xs={4}>
								<div className={'w-100 h-100 vertical-inline'}>
									<div className={'v-center w-100 text-center'}>
										<img alt={'Chat'} style={{opacity: 0.5}} src={this.state.project ? icon_acresco : icon_integraciones} width="110px" height="auto"/>
										<div className={'margin-10-T'}>
											<Fab variant="extended" size="small" color="primary" style={{boxShadow: 'none', backgroundColor: "#165ba5"}} onClick={() => this.props.descargarZip(this.state.archivo, true)}>
												<CloudDownload style={{fontSize: 14, marginRight: 5}}/> Descargar ambos
											</Fab>
										</div>
									</div>
								</div>
							</Grid>
							
							<Grid item xs={4} className={'borde-punteado-lightgray-2'}>
								<Fragment>
									<div className={'w-100 text-center margin-15-T margin-15-B margin-5-L margin-5-R'}>
										<img className={'padding-6-R'} alt={'Chat'} style={{opacity: 0.8}} src={icon_pdf} width="80px" height="auto"/>
										<div>
											<Fab variant="extended" size="small" color="primary" style={{boxShadow: 'none', backgroundColor: "#8e1e32"}} onClick={() => this.props.descargarZip(this.state.archivo_pdf)}>
												<CloudDownload style={{fontSize: 14, marginRight: 5}}/> Descargar PDF
											</Fab>
										</div>
									</div>
								</Fragment>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={12} align={'right'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalDescargarDoc.propTypes = {
	item: PropTypes.object.isRequired,
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	componente: PropTypes.element,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	descargarZip: PropTypes.func.isRequired,
};

export default ModalDescargarDoc;
