import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const MenuEspecificoService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Menu_Especifico_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_acceso_menu_especifico: form.id_acceso_menu_especifico,
			id_usuario: form.id_usuario,
			id_cat_tipo_usuario: form.id_cat_tipo_usuario,
			id_menu: form.id_menu,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Menu_Especifico_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_acceso_menu_especifico: form.id_acceso_menu_especifico,
			id_usuario: form.id_usuario,
			id_cat_tipo_usuario: form.id_cat_tipo_usuario,
			id_menu: form.id_menu,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Menu_Especifico_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_acceso_menu_especifico) => {
		let params = {
			id_acceso_menu_especifico: id_acceso_menu_especifico
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Menu_Especifico_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
