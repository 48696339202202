import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {MenuEspecificoService} from "../../../../../services/_Cat/MenuEspecificoService/MenuEspecificoService";
import {
	BotonAccionAbrirModal,
	BotonAccionFooterModal,
	TituloHeaderModal
} from "../../../../Include/MiniComponents/GlobalComponent";
import {TicketsService} from "../../../../../services/TicketsService/TicketsService";
import {CatService} from "../../../../../services/_Cat/CatService/CatService";

class ModalMenuEspecifico extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_acceso_menu_especifico: (props.item || '').id_acceso_menu_especifico,
			id_usuario: '',
			id_cat_tipo_usuario: '',
			tipo_usuario: '',
			id_menu: '',
			
			listar_usuarios: [],
			listar_menu: [],
			activo: true,
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	selectUser = (id_usuario) => {
		var usuarios = FieldsJs.Copy(this.state.listar_usuarios);
		for (let i = 0; i < usuarios.length; i++) {
			if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
				console.log(id_usuario, usuarios[i].id_cat_tipo_usuario, usuarios[i].tipo_usuario);
				
				this.setState({
					id_cat_tipo_usuario: usuarios[i].id_cat_tipo_usuario,
					tipo_usuario: usuarios[i].tipo_usuario
				});
			}
		}
	};
	
	listarUsuarios = () => {
		return new Promise((resolve, reject) => {
			CatService.ListUsuario().then((response) => {
				this.setState({
					listar_usuarios: response.data
				});
				resolve(response.data);
			}).catch((error) => {
				reject(error);
				this.props.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	listarMenu = () => {
		return new Promise((resolve, reject) => {
			CatService.ListMenu().then((response) => {
				this.setState({
					listar_menu: response.data
				});
				resolve(response.data);
			}).catch((error) => {
				reject(error);
				this.props.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	save = () => {
		if (this.state.id_acceso_menu_especifico > 0) {
			MenuEspecificoService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			MenuEspecificoService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		this.listarUsuarios();
		this.listarMenu();
		console.log(this.props);
		this.setState({
			id_acceso_menu_especifico: '',
			id_usuario: '',
			id_cat_tipo_usuario: '',
			tipo_usuario: '',
			id_menu: '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		this.listarUsuarios().then((response) => {
			this.setState({
				id_usuario: item.id_usuario || '',
			});
		});
		this.listarMenu().then((response) => {
			this.setState({
				id_menu: item.id_menu || '',
			});
		});
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_acceso_menu_especifico: item.id_acceso_menu_especifico || '',
			id_cat_tipo_usuario: item.id_cat_tipo_usuario || '',
			tipo_usuario: item.tipo_usuario || '',
			id_menu: item.id_menu || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		this.listarUsuarios().then((response) => {
			this.setState({
				id_usuario: item.id_usuario || '',
			});
		});
		this.listarMenu().then((response) => {
			this.setState({
				id_menu: item.id_menu || '',
			});
		});
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_acceso_menu_especifico: item.id_acceso_menu_especifico || '',
			id_cat_tipo_usuario: item.id_cat_tipo_usuario || '',
			tipo_usuario: item.tipo_usuario || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_acceso_menu_especifico}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'id_usuario'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={(e) => {
										this.handleChange(e);
										this.selectUser(e.target.value);
									}}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_usuario"
									label="Usuario"
									value={this.state.id_usuario}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_usuarios.map(option => (
										<option key={option.id_usuario} value={option.id_usuario}>
											{option.correo_electronico + ' - ' + option.nombre_completo}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									autoFocus
									margin="dense"
									name="tipo_usuario"
									label="Tipo Usuario"
									type="text"
									fullWidth
									value={this.state.tipo_usuario}
									onChange={this.handleChange}
									disabled={true}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_menu"
									label="Menú / Módulo"
									value={this.state.id_menu}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_menu.map(option => (
										<option key={option.id_menu} value={option.id_menu}>
											{option.menu}
										</option>
									))}
								</TextField>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_acceso_menu_especifico}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalMenuEspecifico.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalMenuEspecifico;
