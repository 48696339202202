import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {Business, DateRangeOutlined, FontDownload, HdrStrong, HomeWork, LooksOne, Person, PersonOutlined, SearchOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {FormaPagoService} from "../../../../services/_Cat/FormaPagoService/FormaPagoService";
import {MetodoPagoService} from "../../../../services/_Cat/MetodoPagoService/MetodoPagoService";
import {FacturacionEstatusService} from "../../../../services/_Cat/FacturacionEstatusService/FacturacionEstatusService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import moment from "moment";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";


class FiltrosFacturacion extends Component {
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			empresa: '',
			cliente: '',
			id_cat_facturacion_status_sat: '',
			id_cat_facturacion_status_pago: '',
			id_cat_facturacion_plaza: '',
			anio: moment().year() || '',
			periodo: this.mesCurso() || '',
			serie: '',
			folio: '',
			uuid: '',
			
			listar_empresa: [],
			listar_facturacion_plaza: [],
			listar_facturacion_estatus_sat: [],
			listar_facturacion_estatus_pago: [],
			
			fecha_inicio: new Date(),
			fecha_fin: new Date()
		};
		
		// this.ListaUsuarioDisenador();
		//this.ListaEmpresa();
		this.ListaFacturacionPlaza();
		this.ListaFacturacionStatusSat();
		this.ListaFacturacionStatusPago();
		setTimeout(() => this.HandleFiltro());
	}
	
	ListaEmpresa = () => {
		CatService.ListEmpresa({}, {}).then((response) => {
			this.setState({
				listar_empresa: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_empresa: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaFacturacionPlaza = () => {
		CatService.ListFacturacionPlaza({}, {}).then((response) => {
			this.setState({
				listar_facturacion_plaza: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_facturacion_plaza: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaFacturacionStatusSat = () => {
		CatService.ListFacturacionStatusSat({}, {}).then((response) => {
			this.setState({
				listar_facturacion_estatus_sat: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_facturacion_estatus_sat: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaFacturacionStatusPago = () => {
		CatService.ListFacturacionStatusPago({}, {}).then((response) => {
			this.setState({
				listar_facturacion_estatus_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_facturacion_estatus_pago: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	

	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		//filtro.id_empresa = this.state.id_empresa || undefined;
		filtro.empresa = this.state.empresa || undefined;
		filtro.cliente = this.state.cliente || undefined;
		filtro.id_cat_facturacion_plaza = this.state.id_cat_facturacion_plaza || undefined;
		filtro.id_cat_facturacion_status_sat = this.state.id_cat_facturacion_status_sat || undefined;
		filtro.id_cat_facturacion_status_pago = this.state.id_cat_facturacion_status_pago || undefined;
		filtro.anio = this.state.anio || undefined;
		filtro.periodo = this.state.periodo || undefined;
		filtro.serie = this.state.serie || undefined;
		filtro.fecha_inicio = this.state.fecha_inicio || undefined;
		filtro.fecha_fin = this.state.fecha_fin || undefined;
		filtro.folio = this.state.folio || undefined;
		filtro.uuid = this.state.uuid || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	/*ListaUsuarioDisenador = () => {
		CatService.ListUsuarioDisenador().then((cat) => {
			this.setState({
				filtro_cat_usuario_disenador: cat.data
			})
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};*/
	
	
	mesCurso = () => {
		let mesactual = moment().month();
		let meses = ['01','02','03','04','05','06','07','08','09','10','11','12'];
		console.log("Mes actual", mesactual, meses[mesactual]);
		// this.setState({
		// 	periodo: meses[mesactual]
		// });
		return meses[mesactual];
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					{/*<Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="anio"
									label="Ejercicio"
									value={this.state.anio}
								>
									<option key={0} value={''}> &nbsp; </option>
									<option key={1} value={'2020'} selected> 2020</option>
									<option key={2} value={'2021'} selected> 2021</option>
								</TextField>
							</Grid>
						</Grid>
					</Grid>*/}
					
					<Grid item xs={6} sm={6} md={6} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<MDDatePicker
									type={'range'}
									component={(
										<TextField
											fullWidth
											label="Periodo / Ejercicio"
											value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
											readOnly
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton aria-label="Periodo / Ejercicio">
															<DateRangeOutlined/>
														</IconButton>
													</InputAdornment>
												)
											}}
										/>
									)}
									startDate={this.state.fecha_inicio}
									endDate={this.state.fecha_fin}
									onChange={(range) => {
										console.log("RANGE", range);
										this.setState({
											fecha_inicio: range.startDate,
											fecha_fin: range.endDate,
										});
										setTimeout(() => this.HandleFiltro());
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					{/*<Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="periodo"
									label="Periodo"
									value={this.state.periodo}
								>
									<option key={0} value={''}> &nbsp; </option>
									<option key={1} value={'01'}> Enero </option>
									<option key={2} value={'02'}> Febrero </option>
									<option key={3} value={'03'}> Marzo </option>
									<option key={4} value={'04'}> Abril </option>
									<option key={5} value={'05'}> Mayo </option>
									<option key={6} value={'06'}> Junio </option>
									<option key={7} value={'07'}> Julio </option>
									<option key={8} value={'08'}> Agosto </option>
									<option key={9} value={'09'}> Septiembre </option>
									<option key={10} value={'10'}> Octubre  </option>
									<option key={11} value={'11'}> Nomviembre </option>
									<option key={12} value={'12'}> Diciembre </option>
								
								</TextField>
							</Grid>
						</Grid>
					</Grid>*/}
					
					{/*<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
					
					<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_empresa"
									label="Empresa"
									value={this.state.id_empresa}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_empresa.map(option => (
										<option key={option.id_empresa}
										        value={option.id_empresa}>
											{option.rfc ?  option.rfc.toUpperCase()+' - '+(option.razon_social ? option.razon_social:option.nombre_comercial) : (option.razon_social ? option.razon_social:option.nombre_comercial)}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>*/}
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Business className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="empresa"
									onChange={this.handleChange}
									label="Empresa"
									autoComplete={'off'}
									value={this.state.empresa}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Person className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="cliente"
									onChange={this.handleChange}
									label="Cliente"
									autoComplete={'off'}
									value={this.state.cliente}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HomeWork className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_facturacion_plaza"
									label="Plaza"
									value={this.state.id_cat_facturacion_plaza}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_facturacion_plaza.map(option => (
										<option key={option.serie}
										        value={option.serie}>
											{option.facturacion_plaza}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={1} xl={1} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<FontDownload className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="serie"
									onChange={this.handleChange}
									label="Serie"
									autoComplete={'off'}
									value={this.state.serie}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={1} xl={1} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<LooksOne className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="folio"
									onChange={this.handleChange}
									label="Folio"
									autoComplete={'off'}
									value={this.state.folio}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_facturacion_status_sat"
									label="Estatus Sat"
									value={this.state.id_cat_facturacion_status_sat}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_facturacion_estatus_sat.map(option => (
										<option key={option.id_cat_facturacion_status_sat}
										        value={option.id_cat_facturacion_status_sat}>
											{option.facturacion_status_sat}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_facturacion_status_pago"
									label="Estatus Pago"
									value={this.state.id_cat_facturacion_status_pago}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_facturacion_estatus_pago.map(option => (
										<option key={option.id_cat_facturacion_status_pago}
										        value={option.id_cat_facturacion_status_pago}>
											{option.facturacion_status_pago}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="uuid"
									onChange={this.handleChange}
									label="Uuid"
									autoComplete={'off'}
									value={this.state.uuid}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={1} xl={1} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosFacturacion.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosFacturacion;
