import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const EmpresaFacturacionExtService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				empresa: filtro.empresa || null,
				id_proyecto: 1,
				rfc: filtro.rfc || null,
				correo_electronico: filtro.correo_electronico || null,
				id_cat_tipo_usuario: filtro.id_cat_tipo_usuario || null,
				id_plaza: filtro.id_plaza || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Datos', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalle: (id_empresa) => {
		let params = {
			id_empresa: id_empresa || null
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Detalle', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUnidadMedida: (filtro, paginacion) => {
		let params = {
			filtro: {
				unidad_medida: filtro.unidad_medida || '',
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Unidad_Medida_Datos', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarUnidadMedida: (form) => {
		let params = {
			id_empresa_clave_unidad: null ,
			id_empresa: form.id_empresa,
			id_cat_clave_unidad: form.id_cat_clave_unidad || '',
			concepto: form.concepto || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Unidad_Medida_Guardar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ModificarUnidadMedida: (form) => {
		let params = {
			id_empresa_clave_unidad: form.id_empresa_clave_unidad || null ,
			id_empresa: form.id_empresa,
			id_cat_clave_unidad: form.id_cat_clave_unidad || '',
			concepto: form.concepto || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Unidad_Medida_Modificar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarProducto: (filtro, paginacion) => {
		let params = {
			filtro: {
				producto: filtro.producto || '',
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Producto_Datos', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarProducto: (form) => {
		let params = {
			id_empresa: form.id_empresa,
			id_cat_clave_producto_servicio: form.id_cat_clave_producto_servicio || '',
			concepto: form.concepto || '',
			precio_unitario: form.precio_unitario || '',
			tipo_descuento: form.tipo_descuento || '',
			descuento: form.descuento || '',
			id_empresa_clave_unidad: form.id_empresa_clave_unidad || '',
			numero_identificacion: form.numero_identificacion || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Producto_Guardar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ModificarProducto: (form) => {
		let params = {
			id_empresa: form.id_empresa,
			id_empresa_clave_producto: form.id_empresa_clave_producto,
			id_cat_clave_producto_servicio: form.id_cat_clave_producto_servicio || '',
			concepto: form.concepto || '',
			precio_unitario: form.precio_unitario || '',
			tipo_descuento: form.tipo_descuento || '',
			descuento: form.descuento || '',
			id_empresa_clave_unidad: form.id_empresa_clave_unidad || '',
			numero_identificacion: form.numero_identificacion || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Producto_Modificar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarTodo: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Datos_All', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_empresa: form.id_empresa || '',
			id_cat_tipo_persona: form.id_cat_tipo_persona || '',
			nombre_razon_social: form.nombre_razon_social || '',
			rfc: form.rfc || '',
			id_proyecto: 1,
			sucursal: form.sucursal || '',
			correo_electronico: form.correo_electronico || '',
			telefono: form.telefono || '',
			id_cat_pais: form.id_cat_pais || '',
			codigo_postal: form.codigo_postal || '',
			password: form.password || '',
			pagina_web: form.pagina_web || '',
			serie: form.serie || '',
			folio_inicial: form.folio_inicial || '',
			
			logo_base64Tipo: form.logo_base64Tipo || '',
			logo_base64: form.logo_base64 || '',
			logo_archivo: form.logo_archivo || '',
			logo_formato: form.logo_formato || '',
			
			cer_base64Tipo: form.cer_base64Tipo || '',
			cer_base64: form.cer_base64 || '',
			cer_archivo: form.cer_archivo || '',
			cer_formato: form.cer_formato || '',
			
			key_base64Tipo: form.key_base64Tipo || '',
			key_base64: form.key_base64 || '',
			key_archivo: form.key_archivo || '',
			key_formato: form.key_formato || '',
			
			terminos_condiciones: form.terminos_condiciones ? 1 : 0,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Guardar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_empresa: form.id_empresa || '',
			id_cat_tipo_persona: form.id_cat_tipo_persona || '',
			nombre_razon_social: form.nombre_razon_social || '',
			rfc: form.rfc || '',
			id_proyecto: 1,
			sucursal: form.sucursal || '',
			correo_electronico: form.correo_electronico || '',
			telefono: form.telefono || '',
			id_cat_pais: form.id_cat_pais || '',
			codigo_postal: form.codigo_postal || '',
			password: form.password || '',
			pagina_web: form.pagina_web || '',
			serie: form.serie || '',
			folio_inicial: form.folio_inicial || '',
			
			logo_base64Tipo: form.logo_base64Tipo || '',
			logo_base64: form.logo_base64 || '',
			logo_archivo: form.logo_archivo || '',
			logo_formato: form.logo_formato || '',
			
			cer_base64Tipo: form.cer_base64Tipo || '',
			cer_base64: form.cer_base64 || '',
			cer_archivo: form.cer_archivo || '',
			cer_formato: form.cer_formato || '',
			
			key_base64Tipo: form.key_base64Tipo || '',
			key_base64: form.key_base64 || '',
			key_archivo: form.key_archivo || '',
			key_formato: form.key_formato || '',
			
			terminos_condiciones: form.terminos_condiciones ? 1 : 0,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Modificar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cliente) => {
		let params = {
			id_cliente: id_cliente
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Eliminar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	EmpresaRFC: (rfc) => {
		let params = {
			rfc: rfc
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_RFC', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Sucursales: (form) => {
		let params = {
			id_empresa: form.id_empresa
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Sucursal_Datos', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SucursalesAgregar: (form) => {
		let params = {
			id_empresa_sucursal: null,
			id_empresa: form.id_empresa || '',
			sucursal: form.sucursal || '',
			telefono: form.telefono || '',
			id_cat_pais: form.id_cat_pais || '',
			correo_electronico: form.correo_electronico || '',
			codigo_postal: form.codigo_postal || '',
			logo_base64: form.logo_base64 || '',
			logo_base64Tipo: form.logo_base64Tipo || '',
			logo_archivo: form.logo_archivo || '',
			logo_formato: form.logo_formato || '',
			activo: 1
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Sucursal_Guardar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SucursalesModificar: (form) => {
		let params = {
			id_empresa_sucursal: form.id_empresa_sucursal || '',
			id_empresa: form.id_empresa || '',
			sucursal: form.sucursal || '',
			telefono: form.telefono || '',
			id_cat_pais: form.id_cat_pais || '',
			correo_electronico: form.correo_electronico || '',
			codigo_postal: form.codigo_postal || '',
			logo_base64: form.logo_base64 || '',
			logo_base64Tipo: form.logo_base64Tipo || '',
			logo_archivo: form.logo_archivo || '',
			logo_formato: form.logo_formato || '',
			activo: form.activo ? 1 : 0
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Sucursal_Modificar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SucursalesDelete: (form) => {
		let params = {
			id_empresa_sucursal: form.id_empresa_sucursal
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Sucursal_Eliminar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Certificados: (id_empresa_sucursal) => {
		let params = {
			id_empresa_sucursal: id_empresa_sucursal,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Sucursal_Certificado_Datos', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	CertificadosAgregar: (form) => {
		let fecha_vigencia = (form.fecha_vigencia || null) ? DateFormat.FormatSql(form.fecha_vigencia) : null;
		
		let params = {
			id_empresa_sucursal_certificado: null,
			id_empresa_sucursal: form.id_empresa_sucursal || '',
			id_empresa: form.id_empresa || '',
			password: form.password || '',
			numero_certificado: form.numero_certificado || '',
			fecha_vigencia: fecha_vigencia,
			activo: 1
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Sucursal_Certificado_Guardar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	CertificadosModificar: (form) => {
		let fecha_vigencia = (form.fecha_vigencia || null) ? DateFormat.FormatSql(form.fecha_vigencia) : null;
		
		let params = {
			id_empresa_sucursal_certificado: form.id_empresa_sucursal_certificado || '',
			id_empresa_sucursal: form.id_empresa_sucursal || '',
			id_empresa: form.id_empresa || '',
			numero_certificado: form.numero_certificado || '',
			fecha_vigencia: fecha_vigencia,
			password: form.password || '',
			base64Tipo: form.base64Tipo || '',
			base64: form.base64 || '',
			archivo: form.archivo || '',
			formato: form.formato || '',
			tipo_archivo: form.tipo_archivo || '',
			activo: form.activo ? 1 : 0
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Sucursal_Certificado_Modificar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	CertificadosDelete: (form) => {
		let params = {
			id_empresa_sucursal_certificado: form.id_empresa_sucursal_certificado
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Sucursal_Certificado_Eliminar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Series: (id_empresa_sucursal) => {
		let params = {
			id_empresa_sucursal: id_empresa_sucursal,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Sucursal_Serie_Datos', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SeriesAgregar: (form) => {
		let params = {
			id_empresa_sucursal_serie: null,
			id_empresa_sucursal: form.id_empresa_sucursal || '',
			serie: form.serie || '',
			folio_inicial: form.folio_inicial || '',
			activo: 1
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Sucursal_Serie_Guardar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SeriesModificar: (form) => {
		let params = {
			id_empresa_sucursal_serie: form.id_empresa_sucursal_serie || '',
			id_empresa_sucursal: form.id_empresa_sucursal || '',
			serie: form.serie || '',
			folio_inicial: form.folio_inicial || '',
			activo: form.activo ? 1 : 0
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Sucursal_Serie_Modificar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SeriesDelete: (form) => {
		let params = {
			id_empresa_sucursal_serie: form.id_empresa_sucursal_serie
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Sucursal_Serie_Eliminar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Catalogos: (form) => {
		let params = {
			id_empresa: form.id_empresa,
			tab: form.valuetab,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Catalogo_Datos', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	CatalogosAgregar: (form) => {
		let params = {
			tab: form.valuetab || '',
			id_empresa: form.id_empresa || '',
			id_cat_regimen_fiscal: form.id_cat_regimen_fiscal || '',
			id_cat_clave_unidad: form.id_cat_clave_unidad || '',
			id_cat_clave_producto_servicio: form.id_cat_clave_producto_servicio || '',
			id_cat_moneda: form.id_cat_moneda || '',
			activo: 1
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Catalogo_Guardar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	CatalogosDelete: (form, valuetab) => {
		let params = {
			tab: valuetab || '',
			id_empresa: form.id_empresa || '',
			id_empresa_regimen_fiscal: form.id_empresa_regimen_fiscal || '',
			id_empresa_clave_unidad: form.id_empresa_clave_unidad || '',
			id_empresa_clave_producto: form.id_empresa_clave_producto || '',
			id_empresa_moneda: form.id_empresa_moneda || '',
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Catalogo_Eliminar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SerieFolio: (id_empresa_sucursal_serie) => {
		let params = {
			id_empresa_sucursal_serie: id_empresa_sucursal_serie || '',
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Serie_Folio', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
