import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

class BancoTipoCuentaService {
	static Listar = () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Tipo_Cuenta_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Agregar = (form) => {
		let params = {
			id_cat_banco_tipo_cuenta: null,
			banco_tipo_cuenta: form.banco_tipo_cuenta,
			abreviacion: form.abreviacion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Tipo_Cuenta_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Modificar = (form) => {
		let params = {
			id_cat_banco_tipo_cuenta: form.id_cat_banco_tipo_cuenta,
			banco_tipo_cuenta: form.banco_tipo_cuenta,
			abreviacion: form.abreviacion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Tipo_Cuenta_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Eliminar = (id_cat_banco_tipo_cuenta) => {
		let params = {
			id_cat_banco_tipo_cuenta: id_cat_banco_tipo_cuenta
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Tipo_Cuenta_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
}

export default BancoTipoCuentaService;
