import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Fab from '@material-ui/core/Fab';
import {DateRangeOutlined, SendOutlined} from '@material-ui/icons';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import EditOutlined from "@material-ui/icons/EditOutlined";
import SearchOutlined from "@material-ui/icons/SearchOutlined";
import DivSvg from "../../../../Include/MiniComponents/DivSvg";
import {CONFIG} from "../../../../../settings/Config/Config";
import VistaVacia from "../../../../Include/VistaVacia/VistaVacia";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {DateFormat} from "../../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import MDDatePicker from "../../../../Include/MDDatePicker/MDDatePicker";
import {HttpRequest} from "../../../../../settings/HttpRequest/HttpRequest";
import {PopupService} from "../../../../../settings/PoPup/PoPup";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: "100%",
	},
});

class ListaTabla extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		this.state = {
			fecha_inicio: null,
			fecha_fin: null,
		};
	}
	
	reporte = () => {
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Estas seguro enviar el reporte?').then((r) => {
			if (r.button === 'Aceptar') {
				const value = this.props.value;
				console.log(value);
				switch (value.id_cat_tipo_reporte) {
					case 1:
						HttpRequest.get('_Cron_Jobs_Reporte_Diario', {},  {spinner: true},  null).then((response) => {
							this.props.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.props.showSnackBars('error', error.mensaje);
						});
						break;
					case 2:
						HttpRequest.get('_Cron_Jobs_Reporte_Semanal', {},  {spinner: true},  null).then((response) => {
							this.props.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.props.showSnackBars('error', error.mensaje);
						});
						break;
					case 3:
						HttpRequest.get('_Cron_Jobs_Reporte_Mensual', {},  {spinner: true},  null).then((response) => {
							this.props.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.props.showSnackBars('error', error.mensaje);
						});
						break;
					case 4:
						try {
							if(!this.state.fecha_inicio) {
								throw Object({
									status: false,
									message: "Selecciona la fecha inicio para el reporte",
								})
							}
							if(!this.state.fecha_fin) {
								throw Object({
									status: false,
									message: "Selecciona la fecha inicio para el reporte",
								})
							}
							let params = {
								fecha_inicio: DateFormat.FormatSql(this.state.fecha_inicio),
								fecha_fin: DateFormat.FormatSql(this.state.fecha_fin),
							};
							HttpRequest.get(`_Cron_Jobs_Reporte_Por_Fecha/${params.fecha_inicio}/${params.fecha_fin}`, {},  {spinner: true},  null).then((response) => {
								this.props.showSnackBars('success', response.mensaje);
							}).catch((error) => {
								this.props.showSnackBars('error', error.mensaje);
							});
						} catch (e) {
							this.props.showSnackBars('error', e.message);
						}
						break;
				}
			}
		});
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'} colSpan={2}>
								<Grid container spacing={2} direction="row" alignContent={"center"} alignItems={"center"}>
									<Grid item>
										<Fab size="small" color="secondary" style={{backgroundColor: '#8E1E32'}} aria-label="Enviar reporte" onClick={() => this.reporte()}>
											<SendOutlined/>
										</Fab>
									</Grid>
									<Grid item>
										<h3 className={'margin-0 padding-5'}>{this.props.titulo}</h3>
									</Grid>
									{this.props.value.id_cat_tipo_reporte === 4 ? (
										<Grid item>
											<MDDatePicker
												type={'range'}
												component={(
													<TextField
														fullWidth
														label="Fecha"
														value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
														readOnly
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton aria-label="Fecha">
																		<DateRangeOutlined/>
																	</IconButton>
																</InputAdornment>
															)
														}}
													/>
												)}
												startDate={this.state.fecha_inicio}
												endDate={this.state.fecha_fin}
												onChange={(range) => {
													this.setState({
														fecha_inicio: range.startDate,
														fecha_fin: range.endDate,
													});
												}}
											/>
										</Grid>
									) : null}
								</Grid>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.length > 0 ? (
							<Fragment>
								{lista.map((item, key) => {
									return (
										<TableRow key={key}>
											<TableCell align={'left'}>
												<div className={'vertical-inline v-center'}>
													<div className={'v-center'}>
														<DivSvg img={CONFIG.src + item.foto} height={40} width={40}/>
													</div>
													<div className={'v-center padding-20-L'}>
														{item.nombre_completo}
														<br/>
														{item.correo_electronico}
														<br/>
														{item.activo === 1 ? (
															<div style={{
																width: "auto",
																color: "white",
																borderRadius: "3px",
																padding: "2px 10px",
																background: "darkgreen",
																display: "inline-grid",
																fontSize: "14px",
																lineHeight: "14px",
															}}>Activo</div>
														) : (
															<div style={{
																width: "auto",
																color: "white",
																borderRadius: "3px",
																padding: "2px 10px",
																background: "#901C30",
																display: "inline-grid",
																fontSize: "14px",
																lineHeight: "14px",
															}}>Inactivo</div>
														)}
													</div>
												</div>
											</TableCell>
											<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
												<div className={'w-auto vertical-inline'}>
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"}
														         title="Eliminar">
															<IconButton aria-label="Eliminar"
															            onClick={() => this.props.delete(item)}>
																<DeleteOutlined/>
															</IconButton>
														</Tooltip>
													</div>
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"}
														         title="Editar">
															<IconButton aria-label="Editar"
															            onClick={() => this.props.edit(item)}>
																<EditOutlined/>
															</IconButton>
														</Tooltip>
													</div>
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"}
														         title="Detalles">
															<IconButton aria-label="Detalles"
															            onClick={() => this.props.view(item)}>
																<SearchOutlined/>
															</IconButton>
														</Tooltip>
													</div>
												</div>
											</TableCell>
										</TableRow>
									)
								})}
							</Fragment>
						) : (
							<Fragment>
								<TableRow>
									<TableCell align={'left'}>
										<VistaVacia
											height={100}
											width={100}
											numero={0}
											mensaje={'No se encontraron datos.'}
										/>
									</TableCell>
								</TableRow>
							</Fragment>
						)}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	value: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
