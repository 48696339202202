import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import {FormatosService} from "../../../../services/FormatosService/FormatosService";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {DropzoneArea} from "material-ui-dropzone";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";


class ModalImportarArchivo extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			id_importe_neto: (props.item || '').id_importe_neto,
			comprobante_base64: '',
			comprobante_base64Tipo: '',
			comprobante_archivo: '',
			comprobante_formato: '',
			
			files: [],
			archivos: [],
			open: true,
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		const {item} = this.props;
		
		this.setState({
			id_importe_neto: item.id_importe_neto || '',
			comprobante_base64: '',
			comprobante_base64Tipo: '',
			comprobante_archivo: '',
			comprobante_formato: '',
		});
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	darformato = (file) => {
		return new Promise((resolve, reject) => {
			let formatos = [
				"application/xlsx",
				"application/xls",
				"application/vnd.ms-excel"
			];
			let fil = {files: [file]};
			FileBase64.Base64(fil, formatos, this.props.MegaByte || null, true).then((response) => {
				//this.props.onChange(response);
				this.handleChange({
					target: {
						name: 'comprobante_base64',
						value: response.base64
					}
				});
				this.handleChange({
					target: {
						name: 'comprobante_base64Tipo',
						value: response.base64Tipo
					}
				});
				this.handleChange({
					target: {
						name: 'comprobante_archivo',
						value: response.archivo
					}
				});
				this.handleChange({
					target: {
						name: 'comprobante_formato',
						value: response.formato
					}
				});
				resolve(true);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
				reject();
			});
		});
	}
	
	subir_comprobante = () => {
		try {
			let cont = this.state.files.length;
			
			if (cont <= 0) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Ingresa un archivo para importar.'
				})
			}
			
			/*if (!this.state.comprobante_archivo) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Selecciona un comprobante.'
				})
			}*/
			
			for (let i = 0; i < cont; i++) {
				this.darformato(this.state.files[i]).then(() => {
					
					FormatosService.ImportarArchivo(this.state).then((response) => {
						this.setState({
							comprobante_base64: '',
							comprobante_base64Tipo: '',
							comprobante_archivo: '',
							comprobante_formato: '',
							
							facturados: response.data.facturados,
							no_facturados: response.data.no_facturados
						});
						
						if (i === (cont-1)) {
							this.props.showSnackBars('success', response.mensaje);
							this.closeModal();
							this.props.RefreshList();
						}
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				});
			}
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	render() {
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => this.open()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_empresa}
						tipo={this.state.tipo}
						onClick={() => this.open()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Importar Archivo
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<DropzoneArea
									onChange={this.handleChangeDropzoneArea.bind(this)}
									open={this.state.open}
									filesLimit={10}
									dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
									acceptedFiles={[]}
									showPreviews={false}
									maxFileSize={5000000}
									showAlerts={false}
									showFileNames={true}
									showFileNamesInPreview={true}
								/>
								{/*<BoxSelectFile
									MegaByte={5}
									label={(
										<b className={'vertical-inline v-center text-blue'}>Archivo Excel</b>
									)}
									style={{
										color: 'black',
										height: '115px'
									}}
									item={{
										base64: this.state.comprobante_base64,
										base64Tipo: this.state.comprobante_base64Tipo,
										archivo: this.state.comprobante_archivo,
										formato: this.state.comprobante_formato,
									}}
									onChange={(r) => {
										this.handleChange({
											target: {
												name: 'comprobante_base64',
												value: r.base64
											}
										});
										this.handleChange({
											target: {
												name: 'comprobante_base64Tipo',
												value: r.base64Tipo
											}
										});
										this.handleChange({
											target: {
												name: 'comprobante_archivo',
												value: r.archivo
											}
										});
										this.handleChange({
											target: {
												name: 'comprobante_formato',
												value: r.formato
											}
										});
									}}
									showSnackBars={this.props.showSnackBars}
								/>*/}
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button onClick={() => this.subir_comprobante()} color="primary" variant={"contained"}>
									Importar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ModalImportarArchivo.propTypes = {
	item: PropTypes.object.isRequired,
	componente: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalImportarArchivo;
