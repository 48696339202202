import React, {Component, Fragment} from 'react';

import './Home.css';
import logo_svg from '../../../assets/img/logo.png';

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {ReactLocalStorageService} from "../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {FieldsJs} from "../../../settings/General/General";
import Icon from '@material-ui/core/Icon';
import Chart from "react-google-charts";
import Typography from "@material-ui/core/Typography";
import {HomeService} from "../../../services/HomeService/HomeService";
import {CONFIG} from "../../../settings/Config/Config";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import VistaVacia from "../../Include/VistaVacia/VistaVacia";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import {VacacionesService} from "../../../services/VacacionesService/VacacionesService";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";

// import Button from "@material-ui/core/Button";


const ItemGraficaArea = ({state}) => {
	let grafica = [
		['Progreso de ventas', 'Porcentaje']
	];
	grafica.push([state.venta_creado_disenador_asignado_texto, state.venta_creado_disenador_asignado.length]);
	
	return (
		<Fragment>
			<div align={'center'} style={{width: '100%'}} className={'margin-5 fill-transparent'}>
				
				<Typography component={'h2'} className={'ff-arial'} style={{}}>
					Gráfica porcentaje progreso de ventas
				</Typography>
				
				{grafica.length > 0 ? (
					<Chart
						width={'100%'}
						height={'190px'}
						chartType="Sankey   "
						loader={
							<Typography component={'h2'} className={'ff-arial'}>
								Cargando gráficas...
							</Typography>
						}
						data={grafica}
						options={{
							is3D: true,
							backgroundColor: {fill: 'transparent'},
							title: '',
							legend: {
								alignment: 'center',
								position: 'bottom',
								textStyle: {
									color: 'black',
									fontSize: 10,
								},
							},
							pieHole: 0,
							animation: {
								duration: 1000,
								easing: 'out',
								startup: true,
							},
						}}
						rootProps={{'data-testid': '3'}}
					/>
				) : (
					<Fragment>
						<Typography component={'h2'} className={'ff-arial'}>
							Esperando datos...
						</Typography>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};


const ItemGrafica = ({state}) => {
	let grafica = [
		['Progreso de ventas', 'Porcentaje']
	];
	grafica.push([state.venta_creado_disenador_asignado_texto, state.venta_creado_disenador_asignado.length]);
	const columns = [
		{type: "string", id: "President"},
		{type: "number", id: "Start"},
		{type: "number", id: "End"}
	];
	
	const rows = [
		["VANTIS S DE RL DE CV", 13, 16],
		["VANGUARDIA EN INYECCION DE PLASTICOS DEL BAJIO S DE RL DE CV", 3, 10]
	];
	
	return (
		<Fragment>
			<div align={'center'} style={{width: '100%'}} className={'margin-5 fill-transparent'}>
				
				<Typography component={'h2'} className={'ff-arial'} style={{}}>
					Gráfica porcentaje progreso de ventas
				</Typography>
				
				{grafica.length > 0 ? (
					<Chart
						width={'100%'}
						height={'190px'}
						chartType="Timeline"
						loader={
							<Typography component={'h2'} className={'ff-arial'}>
								Cargando gráficas...
							</Typography>
						}
						data={[columns, ...rows]}
						options={{
							timeline: {colorByRowLabel: true},
							backgroundColor: '#ffd',
						}}
						rootProps={{'data-testid': '3'}}
					/>
				) : (
					<Fragment>
						<Typography component={'h2'} className={'ff-arial'}>
							Esperando datos...
						</Typography>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};


class Home extends Component {
	
	state = {};
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			Usr: (ReactLocalStorageService.get('Usr') || {}),
			
			venta_creado_disenador_asignado_texto: '',
			venta_creado_disenador_asignado: [
				['Element', 'Density', {role: 'style'}, {role: 'annotation'}],
				['Copper', 8.94, '#b87333', 'Cu'],
				['Silver', 10.49, 'silver', 'Ag'],
				['Gold', 19.30, 'gold', 'Au'],
				['Platinum', 21.45, 'color: #e5e4e2', 'Pt']
			],
			venta_en_produccion_texto: '', venta_en_produccion: [],
			venta_en_almacen_texto: '', venta_en_almacen: [],
			
			listar_home: [],
			lista_tickets: [],
			registros: [],
			cfdi: [],
			acceso: [],
			actividades: [],
			plataformas: [],
			vacaciones: [],
		};
		
		//this.ListarHome();
		//this.ListarPlataformas();
		//this.ListarVacaciones();
	}
	
	ListarVacaciones = () => {
		let params = {
			id_vacaciones: '',
			id_cat_proyecto: '',
			id_cat_proyecto_modulo: '',
			id_cat_cronograma_estatus: '',
			id_cat_tipo_usuario: '',
			usuario_asignado: '',
			fecha_inicio: null,
			fecha_fin: null,
			activo: 1
		};
		let paginacion = {
			total: null,
			page: 1,
			limit: 5,
			rangos: 5
		};
		VacacionesService.Listar(params, paginacion).then((response) => {
			this.setState({
				vacaciones: response.data,
			});
		}).catch((error) => {
			this.setState({vacaciones: []});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListarHome = () => {
		HomeService.Listar().then((response) => {
			this.setState({
				listar_home: response.data,
				lista_tickets: response.data.lista_tickets,
				registros: response.data.registros,
				cfdi: response.data.cfdi,
				acceso: response.data.acceso,
				actividades: response.data.actividades,
			});
		}).catch((error) => {
			this.setState({
				listar_home: [],
				lista_tickets: [],
				registros: [],
				cfdi: [],
				acceso: [],
				actividades: [],
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListarPlataformas = () => {
		CatService.ListPlataformas().then((response) => {
			this.setState({
				plataformas: response.data
			});
		}).catch((error) => {
			this.setState({
				plataformas: [],
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	renderSwitchIcon = (icono) => {
		return <Icon className={'px-30 margin-10-R'} style={{color: '#d9e9eb'}}>{icono}</Icon>;
	};
	
	go = (u) => {
		this.props.history.push('/' + u);
	};
	
	redireccion = (url) => {
		if (FieldsJs.Field(url)) {
			window.open(url, '_blank');
		}
	};
	
	getRegistros = (id_menu) => {
		let registros = 0;
		if (this.state.registros.length > 0) {
			for (let i = 0; i < this.state.registros.length; i++) {
				if (Number(id_menu) === Number(this.state.registros[i].id_menu)) {
					registros = this.state.registros[i].total
				}
			}
			
			/*switch (key) {
				case 2:
					registros = this.state.registros[0].total;
					break;
				case 3:
					registros = this.state.registros[1].total;
					break;
				case 4:
					registros = this.state.registros[2].total;
					break;
				case 5:
					registros = this.state.registros[3].total;
					break;
				case 6:
					registros = this.state.registros[4].total;
					break;
				case 7:
					registros = this.state.registros[7].total;
					break;
				case 8:
					registros = this.state.registros[5].total;
					break;
				case 9:
					registros = this.state.registros[4].total;
					break;
				case 10:
					registros = this.state.registros[4].total;
					break;
				case 20:
					registros = this.state.registros[8].total;
					break;
				default:
					break;
			}*/
			
		}
		return registros;
	};
	
	
	render() {
		let menu = this.state.Usr.menu || [];
		const bg_logo_svg = {
			backgroundImage: `url(${logo_svg})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundAttachment: 'fixed',
			backgroundSize: '300px 150px',
			opacity: '0.7',
		};
		
		return (
			<div className={'w-100'} align={'center'}>
				<div>
					<div className='Home ContaineViewComponet' style={bg_logo_svg}>
						<Grid container spacing={3}>
							<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
							
							</Grid>
							
							<Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
							
							</Grid>
						
						</Grid>
					</div>
				</div>
			</div>
		);
	}
}

Home.propTypes = {};

export default IntegrationNotistack(Home);
