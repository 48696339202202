import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import TextField from "@material-ui/core/TextField";
import {TimbradoFacturacionExtService} from "../../../../services/TimbradoFacturacionExtService/TimbradoFacturacionExtService";
import {DialogActions} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import {Send} from "@material-ui/icons";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {CatService} from "../../../../services/_Cat/CatService/CatService";



class ModalPago extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_facturacion_cfdi: props.item.id_facturacion_cfdi || '',
			enviar_a: '',
			enviar_cc: '',
			enviar_cco: '',
			fecha_pago: null,
			id_cat_forma_pago: '',
			monto: '',
			rfc_emisor_cuenta_ord: '',
			banco: '',
			rfc_emisor_cuenta_ben: '',
			cuenta_beneficiario: '',
			numero_parcialidad: '',
			importe_saldo_anterior: '',
			importe_pagado: '',
			importe_saldo_insoluto: '',
			id_cat_objeto_impuesto: '',
			
			listar_cat_objeto_impuesto: [],
			listar_cat_forma_pago: [],
			
		};
	}
	
	ListaFormaPago = () => {
		CatService.ListFormaPagoFac({}, {}).then((response) => {
			this.setState({
				listar_cat_forma_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_forma_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	ListaObjetoImpuesto = () => {
		CatService.ListObjetoImpuestoFac({}, {}).then((response) => {
			this.setState({
				listar_cat_objeto_impuesto: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_objeto_impuesto: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	
	add = () => {
		let {item} = this.props;
		this.ListaFormaPago();
		this.ListaObjetoImpuesto();
		
		this.setState({
			id_facturacion_cfdi: item.id_facturacion_cfdi || '',
			enviar_a: 'desarrollador@acresco.com',
			enviar_cc: 'implant@adinc.com.mx',
			enviar_cco: '',
			fecha_pago: null,
			id_cat_forma_pago: '',
			monto: '',
			rfc_emisor_cuenta_ord: '',
			banco: '',
			rfc_emisor_cuenta_ben: '',
			cuenta_beneficiario: '',
			numero_parcialidad: '',
			importe_saldo_anterior: '',
			importe_pagado: '',
			id_cat_objeto_impuesto: '',
			importe_saldo_insoluto: '',
			
		});
		
		this.open();
		
	};
	
	pagoSat = () => {
		TimbradoFacturacionExtService.PagoCFDI(this.state).then((response) => {
			
			console.log(response);
			this.props.showSnackBars('success', response.mensaje);
			this.closeModal();
			this.props.RefreshList();
		}).catch((error) => {
			
			
			this.props.showSnackBars('error', error.mensaje);
			
		});
	}
	
	obtenerSaldoInsoluto = () => {
		let imp_ant = FieldsJs.Field(this.state.importe_saldo_anterior) ? Number(this.state.importe_saldo_anterior) : 0;
		let imp_pag = FieldsJs.Field(this.state.importe_pagado) ? Number(this.state.importe_pagado) : 0;
		
		this.setState({
			importe_saldo_insoluto: (imp_ant - imp_pag)
		});
	}
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'add':
				funcion = this.add;
				break;
			
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.component ? (
					<Fragment>
						<span onClick={() => this.add()} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
					</Fragment>
				) : (
					<Fragment>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={() => this.openModal('paper')}>
							<Send className={'margin-5-R px-14'}/>
							Enviar Correo Electrónico
						</Fab>
					</Fragment>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Recibo Electrónico de Pago
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<KeyboardDateTimePicker
									format="yyyy/MM/dd hh:mm a"
									fullWidth
									required
									clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha de Pago',
									}}
									label="Fecha de Pago"
									value={this.state.fecha_pago}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_pago');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_forma_pago"
									label="Forma de Pago"
									value={this.state.id_cat_forma_pago}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_forma_pago.map(option => (
										<option key={option.id_cat_forma_pago} value={option.id_cat_forma_pago}>
											{option.descripcion ? option.forma_pago + " - " + option.descripcion : option.forma_pago}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									margin="none"
									name="monto"
									label="Monto"
									type="text"
									fullWidth
									value={this.state.monto}
									onChange={(e) => {
										this.handleChange(e);
									}}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_objeto_impuesto"
									label="Objeto de Impuesto"
									value={this.state.id_cat_objeto_impuesto}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_objeto_impuesto.map(option => (
										<option key={option.id_cat_objeto_impuesto} value={option.id_cat_objeto_impuesto}>
											{option.descripcion ? option.objeto_impuesto + ' - ' + option.descripcion : option.objeto_impuesto}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="rfc_emisor_cuenta_ord"
									label="RFC Emisor Cuenta Ord: "
									type="text"
									fullWidth
									value={this.state.rfc_emisor_cuenta_ord}
									onChange={this.handleChange}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="banco"
									label="Banco"
									type="text"
									fullWidth
									value={this.state.banco}
									onChange={this.handleChange}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="rfc_emisor_cuenta_ben"
									label="RFC Emisor Cuenta Beneficiario: "
									type="text"
									fullWidth
									value={this.state.rfc_emisor_cuenta_ben}
									onChange={this.handleChange}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="cuenta_beneficiario"
									label="Cuenta Beneficiario"
									type="text"
									fullWidth
									value={this.state.cuenta_beneficiario}
									onChange={this.handleChange}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="numero_parcialidad"
									label="Número de parcialidad"
									type="text"
									fullWidth
									value={this.state.numero_parcialidad}
									onChange={this.handleChange}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="importe_saldo_anterior"
									label="Importe Saldo Anterior"
									type="text"
									fullWidth
									value={this.state.importe_saldo_anterior}
									onChange={(e) => {
										this.handleChange(e);
										setTimeout(() => {
											this.obtenerSaldoInsoluto(e);
										}, 800);
									}}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="importe_pagado"
									label="Importe Pagado"
									type="text"
									fullWidth
									value={this.state.importe_pagado}
									onChange={(e) => {
										this.handleChange(e);
										setTimeout(() => {
											this.obtenerSaldoInsoluto(e);
										}, 800);
									}}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="importe_saldo_insoluto"
									label="Importe Saldo Insoluto"
									type="text"
									fullWidth
									value={this.state.importe_saldo_insoluto}
									onChange={this.handleChange}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'margin-15-T'}>
								
								<fieldset className={'borde-punteado-gray-2 text-left'}>
									<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Dactos de envío</legend>
									
									<Grid container spacing={2}>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField
												required
												name="enviar_a"
												label="Enviar a: "
												type="text"
												fullWidth
												value={this.state.enviar_a}
												onChange={this.handleChange}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<TextField
												name="enviar_cc"
												label="Cc:"
												type="text"
												fullWidth
												value={this.state.enviar_cc}
												onChange={this.handleChange}
											/>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<TextField
												name="enviar_cco"
												label="Cco:"
												type="text"
												fullWidth
												value={this.state.enviar_cco}
												onChange={this.handleChange}
											/>
										
										</Grid>
									
									</Grid>
								
								</fieldset>
							
							</Grid>
							{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
								<Divider style={{marginTop: "10px", marginBottom: "15px"}}/>
								<div className={'text-right'}>
									<div className={'vertical-inline'}>
										<Button className={'v-center margin-15-R'} color="default" size={'small'} variant="contained" onClick={() => this.handleChangeTabs(null, '2')}>
											{'Cerrar'}
										</Button>
										<Button className={'v-center'} color="primary" size={'small'} variant="contained" onClick={() => this.pagoSat()}>
											{'Generar Pago'}
										</Button>
									</div>
								</div>
							</Grid>*/}
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button onClick={() => this.pagoSat()} color="primary" variant={"contained"}>
									Generar Pago
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ModalPago.propTypes = {
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	item: PropTypes.object,
	componente: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalPago;
