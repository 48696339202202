import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {Send} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import icon_xml from "../../../../assets/img/icons/xml.svg";
import icon_pdf from "../../../../assets/img/icons/pdf.svg";
import icon_cfdi from "../../../../assets/img/icons/lineas-de-texto.svg";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import {CONFIG} from "../../../../settings/Config/Config";

class ModalOpcionesTimbrado extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: this.props.open,
			scroll: 'paper',
			
			tipo: props.tipo,
			item: props.item,
			archivo_xml: props.archivo_xml || ''
		};
	}
	
	
	openModal = () => {
		const {item} = this.props;
		console.log("ITEM:::", item);
		
		this.setState({
			open: true,
			scroll: 'paper',
			item: item,
			archivo_xml: this.props.archivo_xml || ''
		});
	};
	
	closeModal = () => {
		this.setState({open: false});
		this.props.closeModalOpciones();
	};
	
	RefreshList = () => {
		this.closeModal();
		this.props.RefreshList();
	}
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	close = () => {
		console.log("cerrar");
		this.closeModal();
	};
	
	exportar = (link, tipo) => {
		if (tipo === 'PDF') {
			try {
				let params = {
					archivo: tipo,
					archivo_xml: link
				};
				HttpRequest.exportFacturacion('xmltopdf', ['xmltopdf'], params, false, false, false, true);
			} catch (e) {
				this.showSnackBars('error', e.mensaje);
			}
		} else {
			let url = CONFIG.src_facturacion+link;
			window.open(url, '', 'width=900, height=600');
		}
	};
	
	render() {
		return (
			<span>
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.props.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.closeModal}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal} className={'sendMessage-ticket'}
				>
					
					<DialogTitle>
						<Typography className={'px-14'} style={{fontWeight: 'bold'}}>
							El timbrado ha sido realizado correctamente.
						</Typography>
					</DialogTitle>
					
					<DialogContent>
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'text-center px-13'} style={{fontWeight: 'bold'}}>
									Selecciona una opción:
								</Typography>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<div className={'borde-punteado-lightgray-1 card-0 text-center margin-10-R cursor-pointer hover-opcion'} onClick={() => this.exportar(this.props.archivo_xml)}>
									<div className={'w-100 text-center padding-10-B padding-10-T'}>
										<span style={{cursor: 'pointer', fontSize: 14}}>
											Ver XML
										</span>
									</div>
									<div className={'w-100 text-center check-notificacion'}>
										<div className={'w-100 margin-10-B'}>
											<img className={'v-center'} src={icon_xml} alt="add" width={80} height={80} style={{opacity: 0.7}}/>
										</div>
									</div>
								</div>
							</Grid>
					
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<div className={'borde-punteado-lightgray-1 card-0 text-center margin-10-R cursor-pointer hover-opcion'} onClick={() => this.exportar(this.props.archivo_xml, 'PDF')}>
									<div className={'w-100 text-center padding-10-B padding-10-T'}>
										<span style={{cursor: 'pointer', fontSize: 14}}>
											Ver PDF
										</span>
									</div>
									<div className={'w-100 text-center check-notificacion'}>
										<div className={'w-100 margin-10-B'}>
											<img className={'v-center'} src={icon_pdf} alt="add" width={80} height={80} style={{opacity: 0.7}}/>
										</div>
									</div>
								</div>
							</Grid>
					
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<div className={'borde-punteado-lightgray-1 card-0 text-center margin-10-R cursor-pointer hover-opcion'} onClick={() => this.RefreshList()}>
									<div className={'w-100 text-center padding-10-B padding-10-T'}>
										<span style={{cursor: 'pointer', fontSize: 14}}>
											Generar otro CFDI
										</span>
									</div>
									<div className={'w-100 text-center check-notificacion'}>
										<div className={'w-100 margin-10-B'}>
											<img className={'v-center'} src={icon_cfdi} alt="add" width={80} height={80} style={{opacity: 0.7}}/>
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
						<br/>
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={0}>
							<Grid item xs={12} align={'right'}>
								<Button onClick={() => {
									this.RefreshList();
									this.close();
								}} color="primary">
									{"Cerrar"}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			
			</span>
		);
	}
}

ModalOpcionesTimbrado.propTypes = {
	tipo: PropTypes.oneOf([
		"add",
		"view",
		"edit",
	]),
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	handleChangeModalOpciones: PropTypes.func.isRequired,
	closeModalOpciones: PropTypes.func.isRequired,
};

export default ModalOpcionesTimbrado;
