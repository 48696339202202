import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import Typography from "@material-ui/core/Typography";

import icon_integraciones from "../../../../assets/img/logo_integraciones.svg";
import icon_acresco from "../../../../assets/img/logo_acresco_talengo.svg";
import icon_xml from "../../../../assets/img/icons/xml.svg";
import icon_pdf from "../../../../assets/img/icons/pdf.svg";

import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import ModalCargaCFDI from "./ModalCargaCFDI";
import ModalCargaPdfCFDI from "./ModalCargaPdfCFDI";
import {CloudUpload} from "@material-ui/icons";


class ModalOptionsAcresco extends Component {
	
	state = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false,
			},
			
			tipo: props.tipo,
			project: props.tipo === 'acresco' ? 1 : 0,
			select: '',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const {tipo} = this.props;
		this.setState({
			modal: {
				open: true
			},
			project: tipo === 'acresco' ? 1 : 0
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			//this.ListaClientes();
			resolve(true);
		});
	};
	
	
	render() {
		
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => this.open()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => {
						}}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={this.state.project ? 'Masivo Acresco Talento' : 'Masivo Integraciones'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'margin-15-B text-center'} style={{color: 'gray', opacity: '0.8'}}>
									Selecciona una opción:
								</Typography>
							</Grid>
							
							<Grid item xs={4} className={'borde-punteado-lightgray-2'}>
								<Fragment>
									<div className={'w-100 text-center margin-15-T margin-15-B margin-5-L margin-5-R'}>
										<img className={'padding-6-R'} alt={'Chat'} style={{opacity: 0.8}} src={icon_xml} width="80px" height="auto"/>
										
										<ModalCargaCFDI
											tipo={'add'}
											item={{}}
											project={this.state.project}
											listDropbox={this.props.listDropbox}
											componente={
												<Fab variant="extended" size="small" color="primary" style={{boxShadow: 'none', backgroundColor: "#e18c02"}}>
													<CloudUpload style={{fontSize: 14, marginRight: 5}}/> Masivo XML
												</Fab>
											}
											RefreshList={this.props.RefreshList}
											showSnackBars={this.props.showSnackBars}
										/>
									</div>
								</Fragment>
							</Grid>
							
							<Grid item xs={4}>
								<div className={'w-100 h-100 vertical-inline'}>
									<div className={'v-center w-100 text-center'}>
										<img alt={'Chat'} style={{opacity: 0.5}} src={this.state.project ? icon_acresco : icon_integraciones} width="130px" height="auto"/>
									</div>
								</div>
							</Grid>
							
							<Grid item xs={4} className={'borde-punteado-lightgray-2'}>
								<Fragment>
									<div className={'w-100 text-center margin-15-T margin-15-B margin-5-L margin-5-R'}>
										<img className={'padding-6-R'} alt={'Chat'} style={{opacity: 0.8}} src={icon_pdf} width="80px" height="auto"/>
										
										<ModalCargaPdfCFDI
											tipo={'add'}
											item={{}}
											componente={
												<Fab variant="extended" size="small" color="primary" style={{boxShadow: 'none', backgroundColor: "#8e1e32"}}>
													<CloudUpload style={{fontSize: 14, marginRight: 5}}/> Masivo PDF
												</Fab>
											}
											RefreshList={this.props.RefreshList}
											showSnackBars={this.props.showSnackBars}
										/>
									</div>
								</Fragment>
							</Grid>
						
						</Grid>
						
						<Grid item xs={12}>
							<br/>
						</Grid>
					</DialogContent>
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								{'Cancelar'}
							</Button>
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalOptionsAcresco.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	listDropbox: PropTypes.array,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalOptionsAcresco;
