import {CONFIG} from '../Config/Config';
import {$cSuccess, FieldsJs, FirstError, hideSpinner, showSpinner} from '../General/General';
import {ReactLocalStorageService} from '../ReactLocalStorageService/ReactLocalStorageService';

import axios from 'axios/index';
import FriendlyURLsId from "../FriendlyURLsId/FriendlyURLsId";


const imprimir = (strung, obj, type) => {
	let dato = [];
	if (type) {
		dato = [
			"%c%s%c%s\n",
			"color: white; background: gray; font-size: 12px;font-weight: bold;letter-spacing: 10px;",
			" " + strung,
			"color: #30568C; background: #FAFAFA; font-size: 12px;font-weight: bold;",
			obj
		];
		window.console.log(
			dato[0],
			dato[1],
			dato[2],
			dato[3],
			JSON.stringify(dato[4], null, 2)
		);
	} else {
		dato = [
			"%c%s%c\n",
			"color: white; background: gray; font-size: 12px;font-weight: bold;letter-spacing: 10px;",
			" " + strung,
			"color: #30568C; background: #FAFAFA; font-size: 12px;font-weight: bold;",
			obj
		];
		window.console.log(
			dato[0],
			dato[1],
			dato[2],
			dato[3],
			dato[4]
		);
	}
};

export const ErrorMessageServerRequest = {
	
	GetMessage: (response, $PROMISSESUCCESS, $PROMISSEERROR) => {
		var msgerrorserver = "";
		
		if (FieldsJs.Field(response.error) === true) {
			if (!FieldsJs.Array(response.error)) {
				var errorserver = response.error.split("::");
				msgerrorserver += (errorserver[1] ? errorserver[1] : response.error) + " ";
			}
		}
		
		if (FieldsJs.Field(response.file) === true) {
			var fileserver = response.file.split("/");
			msgerrorserver += fileserver[fileserver.length - 1] + " ";
		}
		
		if (FieldsJs.Field(response.line) === true) {
			msgerrorserver += response.line;
		}
		
		if (msgerrorserver) {
			
			let msg = "ADVERTENCIA: " + msgerrorserver;
			
			$PROMISSEERROR({
				success: false,
				codigo_api: 400,
				mensaje: FieldsJs.FirstMayus(msg),
				response: response
			});
			
		} else {
			
			if (response.success === true) {
				
				let msg = FirstError(response);
				
				response.mensaje = FieldsJs.FirstMayus(msg);
				
				$PROMISSESUCCESS(response);
				
			} else {
				
				
				let msg = FirstError(response);
				
				if (FieldsJs.Field(response.verificacion_status) === true) {
					$PROMISSEERROR({
						success: false,
						codigo_api: 400,
						mensaje: FieldsJs.FirstMayus(msg),
						verificacion_status: response.verificacion_status,
						response: response
					});
				} else {
					$PROMISSEERROR({
						success: false,
						codigo_api: 400,
						mensaje: FieldsJs.FirstMayus(msg),
						response: response
					});
				}
				
			}
		}
	}
};


export const HttpRequest = {
	post: (ws, params, setting, time) => {
		
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.authentication = (setting.authentication === true || setting.authentication === undefined || setting.authentication === null);
		setting.spinner = (setting.spinner === true || setting.spinner === undefined || setting.spinner === null);
		setting.facturacion = (setting.facturacion === true || false);
		
		let data = {};
		
		if (setting.authentication) {
			let Usr = ReactLocalStorageService.get('Usr') || {};
			data = {
				token: Usr.token || '',
				credenciales: {
					id_project: CONFIG.id_project || '',
					id_usuario: Usr.id_usuario || '',
					username: Usr.username || ''
				},
				data: {}
			};
		} else {
			data = {
				credenciales: {
					id_project: CONFIG.id_project || '',
				},
				data: {}
			};
		}
		
		if (FieldsJs.Array(params)) {
			for (let i in params) {
				data.data[i] = params[i];
			}
		}
		
		if (setting.spinner) {
			showSpinner('spinner');
		}
		
		let config_api = CONFIG.api;
		if (setting.facturacion) {
			config_api = CONFIG.api_facturacion;
		}
		
		if (CONFIG.debug) {
			console.log("W E B S E R V I C E   &   D A T A [POST]:\n\n" + config_api + ws + "\n" + JSON.stringify(data, null, 2) + '\n');
		}
		
		return new Promise((resolve, reject) => {
			axios.post(config_api + ws, data, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				timeout: Cfg.timeout || undefined,
			}).then(response => {
				
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				let respuesta = FieldsJs.Copy(response.data);
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [POST::" + ws + "]:\n", response.data, false);
				}
				
				ErrorMessageServerRequest.GetMessage(respuesta, function (result) {
					resolve(result);
				}, function (error) {
					reject(error);
				});
				
			}).catch(function (error) {
				
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				let errors = {};
				
				if (!error) {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				} else {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				}
				
				if (CONFIG.debug) {
					window.console.error(errors);
				}
				
				reject(errors);
				
			});
		});
	},
	get: (ws, params, setting, time) => {
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.spinner = !(setting.spinner === false || setting.spinner === undefined || setting.spinner === null);
		setting.facturacion = (setting.facturacion === true || false);
		
		let temp = [];
		
		if (FieldsJs.Array(params)) {
			for (let i in params) {
				temp.push(i + '=' + params[i]);
			}
		}
		
		let data = temp.join('&');
		
		let urls = '';
		let config_api = CONFIG.api;
		if (setting.facturacion) {
			config_api = CONFIG.api_facturacion;
		}
		
		if (FieldsJs.Field(data)) {
			urls = config_api + ws + '?' + data;
		} else {
			urls = config_api + ws;
		}
		
		if (CONFIG.debug) {
			$cSuccess(urls);
		}
		
		if (setting.spinner) {
			showSpinner('spinner');
		}
		
		return new Promise((resolve, reject) => {
			axios.get(urls).then(response => {
				
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				let respuesta = response.data;
				
				if (CONFIG.debug) {
					window.console.log("R E S P O N S E [GET::" + urls + "]:\n", FieldsJs.Copy(respuesta));
				}
				
				ErrorMessageServerRequest.GetMessage(respuesta, function (result) {
					resolve(result);
				}, function (error) {
					reject(error);
				});
				
			}).catch(function (error) {
				
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				let errors = {};
				
				if (!error) {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				} else {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				}
				
				if (CONFIG.debug) {
					window.console.error(errors);
				}
				
				reject(errors);
				
			});
		});
	},
	export: (ws, arr_url, params, new_window, w, h, web, resolve, reject) => {
		
		if (!FieldsJs.isArray(arr_url)) {
			arr_url = ['file'];
		}
		
		if (!FieldsJs.Array(arr_url)) {
			arr_url = ['file'];
		}
		
		let Usr = ReactLocalStorageService.get('Usr') || {};
		
		let data = {
			token: Usr.token || '',
			credenciales: {
				id_project: CONFIG.id_project || '',
				id_usuario: Usr.id_usuario || '',
				username: Usr.username || ''
			},
			data: params || {}
		};
		
		let key = FriendlyURLsId.setURLs_Id(arr_url, JSON.stringify(data));
		let obj = FriendlyURLsId.getURLs_Id(key);
		
		console.warn(obj);
		
		let u;
		
		if (web) {
			u = CONFIG.src;
		} else {
			u = CONFIG.api;
		}
		
		let urls = u + ws + '/' + key;
		
		if (typeof resolve === "function" && typeof reject === "function") {
			
			showSpinner('spinner');
			
			axios.get(urls).then(response => {
				
				hideSpinner('spinner', 1000);
				
				let respuesta = response.data;
				
				if (CONFIG.debug) {
					window.console.log("R E S P O N S E [GET::" + urls + "]:\n", FieldsJs.Copy(respuesta));
				}
				
				ErrorMessageServerRequest.GetMessage(respuesta, function (result) {
					resolve(result);
				}, function (error) {
					reject(error);
				});
				
			}).catch(function (error) {
				
				hideSpinner('spinner', 1000);
				
				let errors = {};
				
				if (!error) {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				} else {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				}
				
				if (CONFIG.debug) {
					window.console.error("E R R O R [GET]::",errors);
				}
				
				reject(errors);
				
			});
		} else {
			if (new_window) {
				if (w && h) {
					window.open(urls, '', `width=${w}, height=${h}`);
				} else {
					window.open(urls, '', "width=900, height=600");
				}
			} else {
				window.open(urls, 'windowOpenTab');
			}
		}
	},
	
	exportFacturacion: (ws, arr_url, params, new_window, w, h, web, resolve, reject) => {
		
		if (!FieldsJs.isArray(arr_url)) {
			arr_url = ['file'];
		}
		
		if (!FieldsJs.Array(arr_url)) {
			arr_url = ['file'];
		}
		
		let Usr = ReactLocalStorageService.get('Usr') || {};
		
		let data = {
			token: Usr.token || '',
			credenciales: {
				id_project: CONFIG.id_project || '',
				id_usuario: Usr.id_usuario || '',
				username: Usr.username || ''
			},
			data: params || {}
		};
		
		let key = FriendlyURLsId.setURLs_Id(arr_url, JSON.stringify(data));
		let obj = FriendlyURLsId.getURLs_Id(key);
		
		console.warn(obj);
		
		let u;
		
		if (web) {
			u = CONFIG.src_facturacion;
		} else {
			u = CONFIG.api_facturacion;
		}
		
		let urls = u + ws + '/' + key;
		
		if (typeof resolve === "function" && typeof reject === "function") {
			
			showSpinner('spinner');
			
			axios.get(urls).then(response => {
				
				hideSpinner('spinner', 1000);
				
				let respuesta = response.data;
				
				if (CONFIG.debug) {
					window.console.log("R E S P O N S E [GET::" + urls + "]:\n", FieldsJs.Copy(respuesta));
				}
				
				ErrorMessageServerRequest.GetMessage(respuesta, function (result) {
					resolve(result);
				}, function (error) {
					reject(error);
				});
				
			}).catch(function (error) {
				
				hideSpinner('spinner', 1000);
				
				let errors = {};
				
				if (!error) {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				} else {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				}
				
				if (CONFIG.debug) {
					window.console.error("E R R O R [GET]::",errors);
				}
				
				reject(errors);
				
			});
		} else {
			if (new_window) {
				var top = window.outerHeight / 2 + window.screenY - ( h / 2)
				var left = window.outerWidth / 2 + window.screenX - ( w / 2)
				
				if (w && h) {
					window.open(urls, '', `width=${w}, height=${h}, top=${top}, left=${left}`);
				} else {
					window.open(urls, '', "width=900, height=600");
				}
			} else {
				window.open(urls, 'windowOpenTab');
			}
		}
	}
};

export const DropboxMessageServerRequest = {
	
	GetMessage: (response, $PROMISSESUCCESS, $PROMISSEERROR) => {
		var msgerrorserver = "";
		
		console.log("RESPONSE: ", response);
		
		
		if (FieldsJs.Field(response.error) === true) {
			msgerrorserver += response.error_summary;
		}
		
		if (msgerrorserver) {
			console.log("1.- RESPONSE: ", response);
			
			let msg = "ADVERTENCIA: " + msgerrorserver;
			
			$PROMISSEERROR({
				success: false,
				codigo_api: 400,
				mensaje: FieldsJs.FirstMayus(msg),
				response: response
			});
			
		} else {
			
			console.log("2.- RESPONSE: ", response);
			
			if (FieldsJs.Field(response.metadata) === true) {
				console.log("2.1.- RESPONSE: ", response);
				let msg = response.metadata.name + ' guardado con exito.';
				
				$PROMISSESUCCESS({
					success: false,
					codigo_api: 400,
					mensaje: msg,
					response: response
				});
				
			} else {
				console.log("2.2.- RESPONSE: ", response);
				let msg = FirstError(response.error_summary);
				
				$PROMISSEERROR({
					success: false,
					codigo_api: 400,
					mensaje: FieldsJs.FirstMayus(msg),
					response: response
				});
				
			}
		}
	}
};


export const HttpRequestDropbox = {
	post: (ws, params, setting, time, path) => {
		
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.authentication = (setting.authentication === true || setting.authentication === undefined || setting.authentication === null);
		setting.spinner = (setting.spinner === true || setting.spinner === undefined || setting.spinner === null);
		console.log('PATCH: ', path[0]);
		
		if (CONFIG.debug) {
			console.log("W E B S E R V I C E   &   D A T A [POST]:\n\n" + (path ? CONFIG.DROPBOX_CONTENT + ws + " - " + path : CONFIG.DROPBOX_API + ws)  + "\n" + JSON.stringify(params, null, 2) + '\n');
		}
		
		if (setting.spinner) {
			showSpinner('spinner');
		}
		let arg = {};
		var formData = new FormData();
		if (path) {
			formData.append("image", path[0]);
			arg =  {"path":"/prueba1/LogoAcresco.png", "mode": "overwrite"};
		}
		
		if (ws === "upload") {
			return new Promise((resolve, reject) => {
				axios.post(CONFIG.DROPBOX_CONTENT + ws, path, {
					headers: {
						'Authorization': 'Bearer '+CONFIG.DROPBOX_TOKEN,
						'Content-Type': 'application/octet-stream',
						'Accept': 'application/vnd.api+json',
						'Dropbox-API-Arg':  arg,
					},
					timeout: Cfg.timeout || undefined,
				}).then(response => {
					
					if (setting.spinner) {
						hideSpinner('spinner', time);
					}
					
					let respuesta = FieldsJs.Copy(response.data);
					
					if (CONFIG.debug) {
						imprimir("R E S P O N S E [POST::" + ws + "]:\n", respuesta, false);
					}
					
					DropboxMessageServerRequest.GetMessage(respuesta, function (result) {
						resolve(result);
					}, function (error) {
						reject(error);
					});
					
				}).catch(function (error) {
					
					if (setting.spinner) {
						hideSpinner('spinner', time);
					}
					
					let errors = {};
					
					if (!error) {
						errors = {
							success: false,
							codigo_api: 400,
							mensaje: "Error al procesar los datos",
							error: error
						}
					} else {
						errors = {
							success: false,
							codigo_api: 400,
							mensaje: "Error al procesar los datos",
							error: error
						}
					}
					
					if (CONFIG.debug) {
						window.console.error(errors);
					}
					
					reject(errors);
					
				});
			});
		} else {
			return new Promise((resolve, reject) => {
				axios.post(CONFIG.DROPBOX_API + ws, params, {
					headers: {
						'Authorization': 'Bearer '+CONFIG.DROPBOX_TOKEN,
						'Content-Type': 'application/json'
					},
					timeout: Cfg.timeout || undefined,
				}).then(response => {
					
					if (setting.spinner) {
						hideSpinner('spinner', time);
					}
					
					let respuesta = FieldsJs.Copy(response.data);
					
					if (CONFIG.debug) {
						imprimir("R E S P O N S E [POST::" + ws + "]:\n", respuesta, false);
					}
					
					DropboxMessageServerRequest.GetMessage(respuesta, function (result) {
						resolve(result);
					}, function (error) {
						reject(error);
					});
					
				}).catch(function (error) {
					
					if (setting.spinner) {
						hideSpinner('spinner', time);
					}
					
					let errors = {};
					
					if (!error) {
						errors = {
							success: false,
							codigo_api: 400,
							mensaje: "Error al procesar los datos",
							error: error
						}
					} else {
						errors = {
							success: false,
							codigo_api: 400,
							mensaje: "Error al procesar los datos",
							error: error
						}
					}
					
					if (CONFIG.debug) {
						window.console.error(errors);
					}
					
					reject(errors);
					
				});
			});
		}
		
	},
	get: (ws, params, setting, time) => {
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.spinner = !(setting.spinner === false || setting.spinner === undefined || setting.spinner === null);
		
		let temp = [];
		
		if (FieldsJs.Array(params)) {
			for (let i in params) {
				temp.push(i + '=' + params[i]);
			}
		}
		
		let data = temp.join('&');
		
		let urls = '';
		
		if (FieldsJs.Field(data)) {
			urls = CONFIG.api + ws + '?' + data;
		} else {
			urls = CONFIG.api + ws;
		}
		
		if (CONFIG.debug) {
			$cSuccess(urls);
		}
		
		if (setting.spinner) {
			showSpinner('spinner');
		}
		
		return new Promise((resolve, reject) => {
			axios.get(urls).then(response => {
				
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				let respuesta = response.data;
				
				if (CONFIG.debug) {
					window.console.log("R E S P O N S E [GET::" + urls + "]:\n", FieldsJs.Copy(respuesta));
				}
				
				ErrorMessageServerRequest.GetMessage(respuesta, function (result) {
					resolve(result);
				}, function (error) {
					reject(error);
				});
				
			}).catch(function (error) {
				
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				let errors = {};
				
				if (!error) {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				} else {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				}
				
				if (CONFIG.debug) {
					window.console.error(errors);
				}
				
				reject(errors);
				
			});
		});
	},
	export: (ws, arr_url, params, new_window, w, h, web, resolve, reject) => {
		
		if (!FieldsJs.isArray(arr_url)) {
			arr_url = ['file'];
		}
		
		if (!FieldsJs.Array(arr_url)) {
			arr_url = ['file'];
		}
		
		let Usr = ReactLocalStorageService.get('Usr') || {};
		
		let data = {
			token: Usr.token || '',
			credenciales: {
				id_project: CONFIG.id_project || '',
				id_usuario: Usr.id_usuario || '',
				username: Usr.username || ''
			},
			data: params || {}
		};
		
		let key = FriendlyURLsId.setURLs_Id(arr_url, JSON.stringify(data));
		let obj = FriendlyURLsId.getURLs_Id(key);
		
		console.warn(obj);
		
		let u;
		
		if (web) {
			u = CONFIG.src;
		} else {
			u = CONFIG.api;
		}
		
		let urls = u + ws + '/' + key;
		
		if (typeof resolve === "function" && typeof reject === "function") {
			
			showSpinner('spinner');
			
			axios.get(urls).then(response => {
				
				hideSpinner('spinner', 1000);
				
				if (CONFIG.debug) {
					window.console.log("R E S P O N S E [GET::" + urls + "]:\n", FieldsJs.Copy(response));
				}
				
				DropboxMessageServerRequest.GetMessage(response, function (result) {
					resolve(result);
				}, function (error) {
					reject(error);
				});
				
			}).catch(function (error) {
				
				hideSpinner('spinner', 1000);
				
				let errors = {};
				
				if (!error) {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				} else {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				}
				
				if (CONFIG.debug) {
					window.console.error(errors);
				}
				
				reject(errors);
				
			});
		} else {
			if (new_window) {
				if (w && h) {
					window.open(urls, '', `width=${w}, height=${h}`);
				} else {
					window.open(urls, '', "width=900, height=600");
				}
			} else {
				window.open(urls, 'windowOpenTab');
			}
		}
	}
};

