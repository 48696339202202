import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { Badge, Popper } from "@material-ui/core";
import IntegrationNotistack from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import Typography from "@material-ui/core/Typography";
import modulos from '../../../assets/img/icon-catalogo/modulos.svg';
import giro_de_clientes from "../../../assets/img/logo_integraciones.svg";
import DivSvg from "../MiniComponents/DivSvg";
import {Redirect} from "react-router-dom";
import VistaVacia from "../VistaVacia/VistaVacia";
import {FieldsJs, str_search} from "../../../settings/General/General";
import {NotificacionesService} from "../../../services/NotificacionesService/NotificacionesService";
import {ReactLocalStorageService} from "../../../settings/ReactLocalStorageService/ReactLocalStorageService";

class PopOverMenu extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = { open: false, anchorEl: null, go_to_notificaciones: false };
	}
	
	handleToggle = (event) => {
		this.setState({
			open: !this.state.open,
			anchorEl: this.state.anchorEl ? null : event.currentTarget
		});
	};
	
	show = (event) => {
		this.setState({
			open: true,
			anchorEl: event.currentTarget
		});
	};
	hide = (event) => {
		this.setState({
			open: false,
			anchorEl: null
		});
	};
	handleClose = () => {
		this.setState({ open: false, anchorEl: null});
		this.go();
	};
	
	handleListKeyDown = (event) => {
		if (event.key === "Tab") {
			event.preventDefault();
			this.setState({ open: false });
		}
	};
	
	go = (u) => {
		let isno = str_search(window.location.pathname, 'notificaciones') ? 1 : 0;
		if (!isno) {
			this.props.notificacionesTotal().then((response) => {
				this.setState({ go_to_notificaciones: true });
			}).catch((error) => {
				this.setState({ go_to_notificaciones: true });
			});
		} else {
			this.setState({ go_to_notificaciones: false });
		}
		
		//this.props.history.push('/notificaciones');
	};
	
	showMenuItems = () =>
		this.props.menu.map((item, i) => (
			<MenuItem key={i} onClick={() => this.handleClose} style={{maxWidth: '300px',whiteSpace: 'nowrap',textOverflow: 'ellipsis',overflow: 'hidden'}}>
				<div className={'vertical-inline w-300-px text-left'}>
					<div className={'w-30-px'}>
						<DivSvg img={giro_de_clientes} height={28} width={30}/>
					</div>
					<div className={'w-100-30-px'}>
						<Typography variant={'h5'} style={{fontWeight: 'bold'}} className={'v-center padding-15-L margin-0 text-black px-13'}>
							{item.modulo}
						</Typography>
						<Typography variant={'h5'} className={'truncate v-center padding-15-L margin-0 text-black px-13'}>
							{item.notificacion}
						</Typography>
					</div>
				</div>
			</MenuItem>
		));
	
	render() {
		if (this.state.go_to_notificaciones === true) {
			return <Redirect to='/notificaciones'/>
		}
		
		return (
			<div style={{
				marginLeft: "0px",
				marginTop: "0px"
			}}
			     className="popover-menu"
			     onMouseEnter={this.show}
			     onMouseLeave={this.hide}
			>
				<Button
					aria-controls={this.state.open ? "menu-list-grow" : null}
					aria-haspopup="true"
					onClick={this.handleToggle}
					style={{minWidth: '31px'}}
				>
					<Badge badgeContent={this.props.badgecount} color="primary" style={{color: 'white'}}>
						{this.props.icon}
					</Badge>
				</Button>
				
				<Popper
					placement="bottom"
					anchorEl={this.state.anchorEl}
					open={this.state.open}
					role={undefined}
					transition
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === "bottom" ? "center top" : "center bottom"
							}}
						>
							<Paper>
								<div>
									<Typography variant={'h5'} className={'padding-5 margin-0 text-black px-13 text-left'}>
										{this.props.title}
									</Typography>
								</div>
								<ClickAwayListener onClickAway={this.handleClose}>
									<MenuList style={{maxWidth: '300px'}}
										autoFocusItem={this.state.open}
										id="menu-list-grow"
										onKeyDown={this.handleListKeyDown}
									>
										{ this.props.menu.length > 0 ? (
											<Fragment>
												{this.showMenuItems()}
											</Fragment>
										):(
											<VistaVacia
												height={50}
												width={50}
												numero={0}
												mensaje={'No tienes notificaciones por el momento.'}
											/>
											)}
										
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
		);
	}
}

class PopoverMenu extends Component {

	/*
	constructor(props, context) {
	  super(props, context);
	}
	*/
	
	render() {
		return (
			<div className="login-badges" style={{ display: "flex", flexDirection: "row" }} >
				<PopOverMenu
					icon={this.props.icon}
					badgecount={this.props.cant}
					menu={this.props.items}
					title={this.props.title}
					notificacionesTotal={this.props.notificacionesTotal}
				/>
			</div>
		);
	}
}


PopoverMenu.propTypes = {
	icon: PropTypes.element,
	items: PropTypes.array,
	cant: PropTypes.number,
	title: PropTypes.string,
	notificacionesTotal: PropTypes.func.isRequired
};

export default IntegrationNotistack(PopoverMenu);
