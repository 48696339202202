import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const EjecutivoAsignadoService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Ejecutivo_Asignado_Tesoreria_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_usuario: form.id_usuario,
			descripcion: form.descripcion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Ejecutivo_Asignado_Tesoreria_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_ejecutivo_asignado_tesoreria: form.id_ejecutivo_asignado_tesoreria,
			id_usuario: form.id_usuario,
			descripcion: form.descripcion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Ejecutivo_Asignado_Tesoreria_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_ejecutivo_asignado_tesoreria) => {
		let params = {
			id_ejecutivo_asignado_tesoreria: id_ejecutivo_asignado_tesoreria
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Ejecutivo_Asignado_Tesoreria_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
