import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import {AttachFile, AttachMoney, Business, DeleteOutlined, Description, EditOutlined, HdrStrong, HdrWeakOutlined, HomeWork, House, SearchOutlined} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import EmpresaFacturacionService from "../../../../services/EmpresaFacturacionService/EmpresaFacturacionService";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import {CONFIG} from "../../../../settings/Config/Config";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import empresa_default from "../../../../assets/img/icons/business.svg";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CatService} from "../../../../services/_Cat/CatService/CatService";

class ModalEmpresaSucursal extends Component {
	
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_empresa_archivo: (props.item || '').id_empresa_archivo,
			id_empresa: (props.item || '').id_empresa,
			tipo: props.tipo,
			
			key: null,
			sucursal: '',
			id_cat_pais: '',
			correo_electronico: '',
			codigo_postal: '',
			
			logo_base64: '',
			logo_base64Tipo: '',
			logo_archivo: '',
			logo_formato: '',
			
			cat_pais: [],
			sucursales: [],
		};
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.ListPais();
		this.sucursales().then((response) => {
			this.setState({open: true, scroll: 'paper'});
		});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	ListPais = () => {
		CatService.ListPais().then((response) => {
			this.setState({
				cat_pais: response.data
			});
		});
	};
	
	sucursales = () => {
		return new Promise((resolve, reject) => {
			EmpresaFacturacionExtService.Sucursales(this.props.item).then((response) => {
				this.setState({
					sucursales: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	save = () => {
		
		try {
			
			/*if (!FieldsJs.Field(this.state.fecha_apertura)) {
				throw Object({
					status: false, mensaje: 'Campo fecha apertura es requerido.'
				})
			}
			*/
			
			if (this.state.key !== null) {
				EmpresaFacturacionExtService.SucursalesModificar(this.state).then((response) => {
					this.sucursales();
					this.edit_cancel();
					//this.modalClose();
					hideSpinner('spinner', 500);
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			} else {
				EmpresaFacturacionExtService.SucursalesAgregar(this.state).then((response) => {
					this.sucursales();
					this.edit_cancel();
					//this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					hideSpinner('spinner', 500);
					this.showSnackBars('error', error.mensaje);
				});
			}
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		const {item} = this.props;
		
		this.setState({
			id_empresa_archivo: item.id_empresa_archivo || '',
			id_empresa: item.id_empresa || '',
			key: null,
			id_empresa_sucursal: '',
			sucursal: '',
			id_cat_pais: '',
			correo_electronico: '',
			codigo_postal: '',
			
			logo_base64: '',
			logo_base64Tipo: '',
			logo_archivo: '',
			logo_formato: '',
			activo: true,
		});
		
		this.openModal();
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_empresa_archivo: item.id_empresa_archivo || '',
			id_empresa: item.id_empresa || '',
			tipo_archivo: item.tipo_archivo || '',
			observaciones: item.observaciones || null,
			fecha_emision: item.fecha_emision ? moment(item.fecha_emision) : null,
			fecha_apertura: item.fecha_apertura ? moment(item.fecha_apertura) : null,
			comprobante_pago: 1,
			archivos: item.archivos || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_empresa_archivo > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Estatus">
						<IconButton aria-label="Estatus" onClick={() => this.edit()}>
							<HdrStrong/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Sucursales">
					<IconButton aria-label="Sucursales" onClick={() => this.add()}><Business/></IconButton>
				</Tooltip>
			);
		}
		return BTN_ACTION;
	};
	
	edit = (value, key) => {
		try {
			
			this.setState({
				key: key,
				id_empresa_sucursal: value.id_empresa_sucursal || '',
				sucursal: value.nombre || '',
				telefono: value.telefono || '',
				id_cat_pais: value.id_cat_pais || '',
				correo_electronico: value.correo_electronico || '',
				codigo_postal: value.codigo_postal || '',
				
				logo_base64: CONFIG.src_facturacion + value.archivo_logo || '',
				logo_base64Tipo: value.base64Tipo || '',
				logo_archivo: CONFIG.src_facturacion + value.archivo_logo || '',
				logo_formato: value.formato_logo || '',
				
				activo: value.activo ? 1:0
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_empresa_sucursal: '',
			sucursal: '',
			telefono: '',
			id_cat_pais: '',
			correo_electronico: '',
			codigo_postal: '',
			logo_base64: '',
			logo_base64Tipo: '',
			logo_archivo: '',
			logo_formato: '',
			activo: true
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar la sucursal con Id: ' + key + '?').then((r) => {
				if (r.button === 'Aceptar') {
					EmpresaFacturacionExtService.SucursalesDelete(value).then((response) => {
						this.sucursales();
						this.edit_cancel();
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
						
					});
				}
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{'Sucursales'}</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} align={'center'}>
								<fieldset className={'borde-punteado-gray-2 text-center h-88'}>
									<legend align={'left'} style={{color: '#901C30', fontWeight: 'bold'}}> Empresa</legend>
									
									<Grid container spacing={2}>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
											<Typography variant={'h5'} className={'px-20 text-uppercase'} style={{fontWeight: 'bold'}}>
												{this.props.item.nombre_razon_social}
											</Typography>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<div className={''}>
												<div className={'card-0 w-70-px h-70-px'}>
													<img style={{width: 70, height: 70}} alt={'Logo'} src={this.props.item.archivo_logo ? CONFIG.src_facturacion + this.props.item.archivo_logo : empresa_default}/>
												</div>
											</div>
										</Grid>
										
										<Grid item xs={12} sm={12} md={8} lg={8} xl={8} align={'left'}>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
													<Typography variant={'h5'} className={'px-16 margin-0-T margin-0-B'}>
														{this.props.item.rfc}
													</Typography>
													<Typography variant={'h5'} className={'px-14'}>
														{this.props.item.tipo_persona}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								
								</fieldset>
							</Grid>
							
							<Grid item xs={12} sm={12} md={8} lg={8} xl={8} align={'left'}>
								<fieldset className={'borde-punteado-gray-2 text-left'}>
									<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Sucursal</legend>
									
									<Grid container spacing={2}>
										
										<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
											
											<Grid container spacing={2}>
												<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
													<TextField
														required
														name="sucursal"
														label="Nombre Sucursal"
														type="text"
														fullWidth
														value={this.state.sucursal}
														onChange={this.handleChange}
														disabled={this.state.tipo === 'view'}
													/>
												
												</Grid>
												
												<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
													
													<TextField
														required
														name="codigo_postal"
														label="Código Postal"
														type="text"
														fullWidth
														value={this.state.codigo_postal}
														onChange={this.handleChange}
														disabled={this.state.tipo === 'view'}
														onKeyPress={EnteroSolo}
														inputProps={{
															maxLength: 5,
														}}
													/>
												
												</Grid>
												
												<Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
													
													<TextField
														required
														name="correo_electronico"
														label="Correo electronico"
														type="text"
														fullWidth
														value={this.state.correo_electronico}
														onChange={this.handleChange}
														disabled={this.state.tipo === 'view'}
													/>
												
												</Grid>
												
												<Grid item className={'text-left'} xs={12} sm={12} md={5} lg={5} xl={5}>
													<FormControl fullWidth required>
														<InputLabel>País</InputLabel>
														<Select
															native
															value={this.state.id_cat_pais}
															onChange={this.handleChange}
															name="id_cat_pais"
															input={<Input/>}
															disabled={this.props.tipo === 'view'}
														>
															<option value=""/>
															{this.state.cat_pais.map(option => (
																<option key={option.id_cat_pais}
																        value={option.id_cat_pais}>
																	{option.pais}
																</option>
															))}
														
														</Select>
													</FormControl>
												
												</Grid>
											
											</Grid>
										</Grid>
										
										
										<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													
													<BoxSelectFile
														FormatAccepted={["image/png", "image/jpeg", "image/svg"]}
														MegaByte={5}
														// button={(
														// 	<AddOutlined/>
														// )}
														label={(
															<b className={'vertical-inline v-center text-blue'}>Logo</b>
														)}
														style={{
															color: 'black',
															height: '115px'
														}}
														item={{
															base64: this.state.logo_base64,
															base64Tipo: this.state.logo_base64Tipo,
															archivo: this.state.logo_archivo,
															formato: this.state.logo_formato,
														}}
														onChange={(r) => {
															this.handleChange({
																target: {
																	name: 'logo_base64',
																	value: r.base64
																}
															});
															this.handleChange({
																target: {
																	name: 'logo_base64Tipo',
																	value: r.base64Tipo
																}
															});
															this.handleChange({
																target: {
																	name: 'logo_archivo',
																	value: r.archivo
																}
															});
															this.handleChange({
																target: {
																	name: 'logo_formato',
																	value: r.formato
																}
															});
														}}
														showSnackBars={this.props.showSnackBars}
														disabled={this.state.tipo === 'view'}
													/>
												
												</Grid>
											</Grid>
										</Grid>
									
									</Grid>
								
								</fieldset>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Paper style={{overflowX: "auto"}}>
									<Table className={'desing-acresco'}>
										<TableHead>
											<TableRow>
												<TableCell component="th" align={'center'} className={'w-10-px padding-20-L padding-10-R'}>
													ID
												</TableCell>
												<TableCell component="th" align={'center'} className={'w-60-px'}>Logo</TableCell>
												<TableCell component="th" align={'left'}>Nombre o Razón Social</TableCell>
												<TableCell component="th" align={'left'}>Código Postal</TableCell>
												<TableCell component="th" align={'left'}>Correo electronico</TableCell>
												<TableCell component="th" align={'center'}>Telefono</TableCell>
												<TableCell component="th" align={'center'}>Activo</TableCell>
												<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.sucursales.map((item, key) => (
												<TableRow key={key}>
													<TableCell align={'center'} className={'padding-20-L padding-20-R'}> {key + 1} </TableCell>
													<TableCell align={'center'}>
														<div className={'margin-10-L'}>
															<div className={'card-0 b-r-100 w-40-px h-40-px'}>
																<Avatar
																	style={{
																		width: 40,
																		height: 40
																	}}
																	alt={item.archivo_logo}
																	src={CONFIG.src_facturacion + item.archivo_logo}
																/>
															</div>
														</div>
													</TableCell>
													<TableCell align={'left'}>{item.nombre}</TableCell>
													<TableCell align={'left'}>{item.codigo_postal}</TableCell>
													<TableCell align={'left'}>{item.correo_electronico}</TableCell>
													<TableCell align={'center'}>{item.telefono || '-'}</TableCell>
													
													<TableCell align={'center'}>
														{item.activo === 1 ? (
															<div style={{
																width: "50px",
																color: "white",
																borderRadius: "3px",
																padding: "2px 10px",
																background: "darkgreen",
																display: "inline-grid",
															}}>Activo</div>
														) : (
															<div style={{
																width: "50px",
																color: "white",
																borderRadius: "3px",
																padding: "2px 10px",
																background: "#901C30",
																display: "inline-grid",
															}}>Inactivo</div>
														)}
													</TableCell>
													
													
													<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
														<div className={'center'}>
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Eliminar"
																children={(
																	<Fab
																		size="small"
																		aria-label="Eliminar"
																		onClick={() => this.delete(item, key + 1)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "transparent",
																			color: "#808080",
																			boxShadow: "none",
																		}}
																		children={(
																			<DeleteOutlined/>
																		)}
																	/>
																)}
															/>
															{this.state.key === key ? (
																<Tooltip
																	TransitionComponent={Zoom}
																	placement={"top"}
																	title="Cancelar edición"
																	children={(
																		<Fab
																			size="small"
																			aria-label="Cancelar edición"
																			onClick={() => this.edit_cancel(item, key)}
																			style={{
																				marginLeft: "10px",
																				backgroundColor: "#f50057",
																				color: "white",
																			}}
																			children={(
																				<EditOutlined/>
																			)}
																		/>
																	)}
																/>
															) : (
																<Tooltip
																	TransitionComponent={Zoom}
																	placement={"top"}
																	title="Editar"
																	children={(
																		<Fab
																			size="small"
																			aria-label="Editar"
																			onClick={() => this.edit(item, key)}
																			style={{
																				marginLeft: "10px",
																				backgroundColor: "transparent",
																				color: "#808080",
																				boxShadow: "none",
																			}}
																			children={(
																				<EditOutlined/>
																			)}
																		/>
																	)}
																/>)}
														</div>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</Paper>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.key !== null ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEmpresaSucursal.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalEmpresaSucursal);
