import React, {Component, Fragment} from 'react';

import Typography from "@material-ui/core/Typography/index";
import Sync from '@material-ui/icons/Sync';
import ListaTabla from './Includes/ListaTabla';
import ModalFacturacion from './Includes/ModalFacturacion';
import ModalFacturacionPromotor from './Includes/ModalFacturacionPromotor';

import {FacturacionService} from '../../../services/FacturacionService/FacturacionService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Zoom from '@material-ui/core/Zoom/index';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab/index";
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import carrito_de_compra_1 from "../../../assets/img/icons/facturacion.svg";
import Paginacion from "../../Include/Paginacion/Paginacion";
import FiltrosFacturacion from "./Includes/FiltrosFacturacion";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {CONFIG} from "../../../settings/Config/Config";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import {BarChart} from "@material-ui/icons";
import ModalEstadisticas from "./Includes/ModalEstadisticas";
import {DropboxApi} from "../../../settings/Dropbox/DropboxApi";
import {CatService} from "../../../services/_Cat/CatService/CatService";


class Facturacion extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		let ids_expandido = ReactLocalStorageService.get('ids_expandido');
		
		
		this.state = {
			
			filtro: {},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			listar_facturacion: [],
			
			
			ids_expandido: ids_expandido || [],
			
			listDropbox: [],
			listar_usuarios: [],
		};
		
		this.RefreshList();
		this.listarUsuarios();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	RefreshList = () => {
		this.Listar();
		this.ListarDropbox();
	};
	
	Listar = () => {
		FacturacionService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			let listar_facturacion = response.data;
			
			this.setState({
				listar_facturacion: listar_facturacion,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
		}).catch((error) => {
			
			this.setState({listar_facturacion: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	listarUsuarios = () => {
		CatService.ListUsuario().then(response => {
			this.setState({
				listar_usuarios: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_usuarios: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListarDropbox = () => {
		DropboxApi.List('').then((response) => {
			
			let listar_cfdi = response;
			this.setState({
				listDropbox: listar_cfdi.entries
			});
			console.log("LISTAR CFDI::: ", listar_cfdi);
			
		}).catch((error) => {
			
			this.setState({listar_facturacion: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	delete = (item) => {
		
		let msg = `¿Deseas eliminar la factura?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				FacturacionService.Eliminar(item.id_facturacion).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	exportar = (item, archivo, auth) => {
		if (item.id_facturacion > 0) {
			let tipo = 2;
			HttpRequest.export('cxid', ['facturacion'], {
				id_facturacion: item.id_facturacion,
				tipo: tipo,
			}, !(tipo === 3))
		} else {
			let fecha_alta = (this.state.filtro.fecha_alta || null) ? DateFormat.FormatSql(this.state.filtro.fecha_alta) : null;
			
			let filtro = {
				id_facturacion: this.state.filtro.id_facturacion || null,
				cliente: this.state.filtro.cliente || null,
				id_cat_forma_pago: this.state.filtro.id_cat_forma_pago || null,
				id_cat_metodo_pago: this.state.filtro.id_cat_metodo_pago || null,
				fecha_alta: fecha_alta,
				ordenar_fecha_alta: this.state.filtro.ordenar_fecha_alta || null,
				
			};
			HttpRequest.export('c', ['facturacion'], {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			}, false, false, false, archivo === "EXCEL")
		}
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	ShowSubTabla = (item, key) => {
		let expandir = !!!item.expandir;
		this.handleChange({
			target: {
				value: expandir,
				name: 'expandir__' + key,
				type: 'text'
			}
		}, 'listar_facturacion', 'expandir');
		let ids_temp = this.state.ids_expandido;
		let ids_expandido = [];
		if (expandir) {
			ids_expandido = ids_temp;
			ids_expandido.push({
				id_facturacion: item.id_facturacion
			});
		} else {
			for (let i = 0; i < ids_temp.length; i++) {
				if (Number(ids_temp[i].id_facturacion) !== Number(item.id_facturacion)) {
					ids_expandido.push({
						id_facturacion: ids_temp[i].id_facturacion
					})
				}
			}
			
		}
		ReactLocalStorageService.set('ids_expandido', ids_expandido);
		this.setState({
			ids_expandido: ids_expandido
		});
	};
	
	SubTabla = (item, key) => {
		this.ShowSubTabla(item, key);
	};
	
	DeleteDropbox = () => {
		console.log("DROPBOX DELETE::: ", "/Resto del Mundo S.A. de C.V./expediente/RETS890702SF2_Comprobante de domicilio.pdf");
		
		DropboxApi.Delete('/Resto del Mundo S.A. de C.V./expediente/RETS890702SF2_Comprobante de domicilio.pdf').then(function (response) {
			
			
			console.log("DROPBOX UPLOAD: ", response);
			
		}).catch(function (error) {
			console.log('error', error);
		});
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				<div className={'row-flex margin-30-T margin-30-B'}>
					<div className={'w-100-300-px'}>
						<Typography variant={'h5'} className={'text-left'}>
							Facturación
						</Typography>
					</div>
					<div className={'w-300-px text-right vertical-inline'}>
						{/*<Fab variant="extended" size="small" color="primary" aria-label="PDF"
						     className={'margin-10-L bg-danger'} onClick={() => this.exportar({}, 'PDF', true)}>
							<PictureAsPdf className={'margin-5-R px-14'}/>
							PDF
						</Fab>
						<Fab variant="extended" size="small" color="primary" aria-label="Excel"
						     className={'margin-10-L bg-green'} onClick={() => this.exportar({}, 'EXCEL', true)}>
							<EventNote className={'margin-5-R px-14'}/>
							Excel
						</Fab>*/}
						
						<ModalEstadisticas
							className={'v-center margin-15-R'}
							componente={
								<Fab variant="extended" size="small" color="primary" style={{backgroundColor: '#e58836'}}>
									<BarChart className={'margin-5-R px-14'}/>
									Estadisticas
								</Fab>
							}
							RefreshList={this.RefreshList}
							showSnackBars={this.showSnackBars}
						/>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'v-center margin-10-L'} onClick={this.RefreshList}>
							<Sync className={'margin-5-R px-14'}/>
							Actualizar
						</Fab>
						
					</div>
				</div>
				
				{/*{FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario) ? (
					<Fab variant="extended" size="small" color="secondary" aria-label="Add"
					     className={'v-center margin-10-L'} onClick={() => this.DeleteDropbox()}>
						<Sync className={'margin-5-R px-14'}/>
						Delete Dropbox
					</Fab>
				):null}*/}

				
				<FiltrosFacturacion
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				<div className={'form margin-30-B'}>
					
					{this.state.listar_facturacion.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_facturacion}
								listDropbox={this.state.listDropbox}
								listar_usuarios={this.state.listar_usuarios}
								delete={this.delete}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								SubTabla={this.SubTabla}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={null}
							mensaje={'No se encontraron datos.'}
							image={carrito_de_compra_1}
							// padding={'20px'}
							// paddingText={'20px'}
							// height={'80px'}
							// width={'80px'}
						/>
					)}
				
				</div>
				{/*{FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario) ? (
					<ModalFacturacion
						id_facturacion={null}
						item={{}}
						tipo={'add'}
						icono={(
							<Zoom className={'btn-fixed-bottom-right cursor-pointer'}
							      key={'inherit'}
							      timeout={1500}
							      in={true}
							      style={{transitionDelay: `${100}ms`}}
							      unmountOnExit
							>
								<Fab color={'primary'}><Add/></Fab>
							</Zoom>
						)}
						RefreshList={this.RefreshList}
						showSnackBars={this.showSnackBars}
					/>
				) : null}*/}
				
				{FieldsJs.inArray([1, 2, 3, 9, 13], this.Usr.id_cat_tipo_usuario) || Number(this.Usr.id_usuario) === 101 ? (
					<ModalFacturacionPromotor
						id_facturacion={null}
						item={{}}
						tipo={'add'}
						icono={(
							<Zoom className={'btn-fixed-bottom-right cursor-pointer'}
							      key={'inherit'}
							      timeout={1500}
							      in={true}
							      style={{transitionDelay: `${100}ms`}}
							      unmountOnExit
							>
								<Fab color={'primary'}><Add/></Fab>
							</Zoom>
						)}
						RefreshList={this.RefreshList}
						showSnackBars={this.showSnackBars}
					/>
				) : null}
			
			</div>
		);
	}
}

Facturacion.propTypes = {};

export default IntegrationNotistack(Facturacion);
