import React, {Component, Fragment} from 'react';
import ListaTabla from './Includes/ListaTabla';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {BotonActualizarLista, BotonDescargaReporte, BotonExportarListaExcel, CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import {CONFIG} from "../../../settings/Config/Config";
import {CargaCFDIService} from "../../../services/CargaCFDIService/CargaCFDIService";
import Fab from "@material-ui/core/Fab";
import {PopupService} from "../../../settings/PoPup/PoPup";
import {DropboxApi} from "../../../settings/Dropbox/DropboxApi";
import FiltrosCargoCFDI from "./Includes/FiltrosCargoCFDI";
import Paginacion from "../../Include/Paginacion/Paginacion";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import ModalExcelCFDI from "./Includes/ModalExcelCFDI";
import moment from "moment";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import ModalOptionsAcresco from "./Includes/ModalOptionsAcresco";
import icon_integraciones from "../../../assets/img/logo_integraciones.svg";
import icon_acresco from "../../../assets/img/logo_acresco_talengo.svg";
import ModalDescargaCFDI from "./Includes/ModalDescargaCFDI";
import ModalVerificacionCFDI from "./Includes/ModalVerificacionCFDI";
import {PostAdd} from "@material-ui/icons";

class Nube extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		let ids_expandido = ReactLocalStorageService.get('ids_expandido');
		
		this.state = {
			listar_cfdi: [],
			
			archivos: [],
			archivos2: [],
			dbx: '',
			listDropbox: [],
			
			filtro: {
				anio: moment().year() || '',
				periodo: this.mesCurso() || '',
				fecha_inicio: moment(),
				fecha_fin: moment()
			},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			ids_expandido: ids_expandido || [],
			
			band_download_cfdi: false,
			directorio: ''
		};
		
		this.Listar();
		this.ListarDropbox();
	}
	
	mesCurso = () => {
		let mesactual = moment().month();
		let meses = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
		console.log("Mes actual", mesactual, meses[mesactual]);
		return meses[mesactual];
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
		this.ListarDropbox();
	};
	
	ListarDropbox = () => {
		DropboxApi.List('').then((response) => {
			
			let listar_cfdi = response;
			this.setState({
				listDropbox: listar_cfdi.entries
			});
			console.log("LISTAR CFDI::: ", listar_cfdi);
			
		}).catch((error) => {
			
			this.setState({listar_facturacion: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	Listar = () => {
		CargaCFDIService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			let listar_cfdi = response.data;
			
			this.setState({
				listar_cfdi: listar_cfdi,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
		}).catch((error) => {
			
			this.setState({listar_facturacion: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	ListarDonwload = () => {
		CargaCFDIService.ListarDownload(this.state.filtro).then((response) => {
			let data = response.data;
			if (data.length > 0) {
				this.setState({
					band_download_cfdi: true,
					directorio: response.data
				});
			} else {
				this.showSnackBars('error', response.mensaje);
			}
			
			/*setTimeout(() => {
				this.exportarDescargar({}, 'ZIP', true)
			}, 1000);*/
			
		}).catch((error) => {
			
			this.setState({listar_facturacion: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el archivo con id: ${item.id_facturacion_cfdi}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				DropboxApi.Delete(item.archivo).then(function (response) {
					console.log("DROPBOX UPLOAD: ", response);
				}).catch(function (error) {
					console.log('error', error);
				});
				
				CargaCFDIService.Eliminar(item.id_facturacion_cfdi).then(function (response) {
					this.showSnackBars('success', response.mensaje);
					this.RefreshList();
				}).catch(function (error) {
					this.showSnackBars('error', error.mensaje);
					console.log('error', error);
				});
			}
		});
	};
	
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key, variable);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
			}
		}
	};
	
	ShowSubTabla = (item, key) => {
		let expandir = !!!item.expandir;
		this.handleChange({
			target: {
				value: expandir,
				name: 'expandir__' + key,
				type: 'text'
			}
		}, 'listar_cfdi', 'expandir');
		let ids_temp = this.state.ids_expandido;
		let ids_expandido = [];
		if (expandir) {
			ids_expandido = ids_temp;
			ids_expandido.push({
				id_facturacion_cfdi: item.id_facturacion_cfdi
			});
		} else {
			for (let i = 0; i < ids_temp.length; i++) {
				if (Number(ids_temp[i].id_facturacion_cfdi) !== Number(item.id_facturacion_cfdi)) {
					ids_expandido.push({
						id_facturacion_cfdi: ids_temp[i].id_facturacion_cfdi
					})
				}
			}
		}
		ReactLocalStorageService.set('ids_expandido', ids_expandido);
		this.setState({
			ids_expandido: ids_expandido
		});
	};
	
	SubTabla = (item, key) => {
		this.ShowSubTabla(item, key);
	};
	
	exportExcel = () => {
		CargaCFDIService.Excel(this.state.filtro, this.state.paginacion, "EXCEL").then(function (response) {
			console.log('success', response);
		}).catch(function (error) {
			console.log('error', error);
		});
	};
	
	descargarReporte = (item, archivo, auth, email) => {
		try {
			/*if (!this.state.c_plaza && !this.state.c_rfc_emisor && !this.state.c_empresa_facturadora && !this.state.c_rfc_receptor &&
				!this.state.c_cliente && !this.state.c_serie && !this.state.c_folio && !this.state.c_status_sat && !this.state.c_status_pago &&
				!this.state.c_fecha_emision && !this.state.c_fecha_pago && !this.state.c_subtotal && !this.state.c_iva &&
				!this.state.c_total_facturado && !this.state.c_concepto && !this.state.c_clave_unidad && !this.state.c_clave_concepto) {
				throw Object({
					status: false,
					mensaje: "Selecciona mínimo una columna para exportar."
				});
			}*/
			let fecha_inicio = this.state.filtro.fecha_inicio ? DateFormat.FormatSql(this.state.filtro.fecha_inicio) : null;
			let fecha_fin = this.state.filtro.fecha_fin ? DateFormat.FormatSql(this.state.filtro.fecha_fin) : null;
			
			
			let filtro = {
				fecha_inicio: fecha_inicio,
				fecha_fin: fecha_fin,
				empresa: this.state.filtro.empresa || null,
				cliente: this.state.filtro.cliente || null,
				id_cat_facturacion_status_sat: this.state.filtro.id_cat_facturacion_status_sat || null,
				id_cat_facturacion_status_pago: this.state.filtro.id_cat_facturacion_status_pago || null,
				id_cat_facturacion_plaza: this.state.filtro.id_cat_facturacion_plaza || null,
				anio: this.state.filtro.anio || null,
				periodo: this.state.filtro.periodo || null,
				serie: this.state.filtro.serie || null,
				uuid: this.state.filtro.uuid || null,
				folio: this.state.filtro.folio || null,
			};
			
			let columns = {
				c_plaza: this.state.c_plaza || '',
				c_rfc_emisor: this.state.c_rfc_emisor || '',
				c_empresa_facturadora: this.state.c_empresa_facturadora || '',
				c_rfc_receptor: this.state.c_rfc_receptor || '',
				c_cliente: this.state.c_cliente || '',
				c_serie: this.state.c_serie || '',
				c_folio: this.state.c_folio || '',
				c_status_sat: this.state.c_status_sat || '',
				c_status_pago: this.state.c_status_pago || '',
				c_fecha_emision: this.state.c_fecha_emision || '',
				c_fecha_pago: this.state.c_fecha_pago || '',
				c_subtotal: this.state.c_subtotal || '',
				c_iva: this.state.c_iva || '',
				c_total_facturado: this.state.c_total_facturado || '',
				c_concepto: this.state.c_concepto || '',
				c_clave_unidad: this.state.c_clave_unidad || '',
				c_clave_concepto: this.state.c_clave_concepto || '',
			};
			
			let params = {
				auth: auth,
				archivo: archivo,
				filtro: filtro,
				columns: columns
			};
			
			HttpRequest.export('_Carga_CFDI_Reporte', ['Reporte_Fac_Emp'], params, false, false, false, archivo === "EXCEL");
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	exportaryenviar = (item, archivo, auth, email) => {
		try {
			/* if (!this.state.mensaje) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Mensaje"
				});
			}*/
			let fecha_inicio = this.state.filtro.fecha_inicio ? DateFormat.FormatSql(this.state.filtro.fecha_inicio) : null;
			let fecha_fin = this.state.filtro.fecha_fin ? DateFormat.FormatSql(this.state.filtro.fecha_fin) : null;
			
			
			let filtro = {
				fecha_inicio: fecha_inicio,
				fecha_fin: fecha_fin,
				empresa: this.state.filtro.empresa || null,
				cliente: this.state.filtro.cliente || null,
				id_cat_facturacion_status_sat: this.state.filtro.id_cat_facturacion_status_sat || null,
				id_cat_facturacion_status_pago: this.state.filtro.id_cat_facturacion_status_pago || null,
				id_cat_facturacion_plaza: this.state.filtro.id_cat_facturacion_plaza || null,
				anio: this.state.filtro.anio || null,
				periodo: this.state.filtro.periodo || null,
				serie: this.state.filtro.serie || null,
				uuid: this.state.filtro.uuid || null,
				folio: this.state.filtro.folio || null,
			};
			let params = {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			};
			HttpRequest.export('_Carga_CFDI_Datos_Excel', ['Cargas_cfdi'], params, false, false, false, archivo === "EXCEL");
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	exportarDescargar = (item, archivo, auth) => {
		try {
			let fecha_inicio = this.state.filtro.fecha_inicio ? DateFormat.FormatSql(this.state.filtro.fecha_inicio) : null;
			let fecha_fin = this.state.filtro.fecha_fin ? DateFormat.FormatSql(this.state.filtro.fecha_fin) : null;
			
			
			let filtro = {
				fecha_inicio: fecha_inicio,
				fecha_fin: fecha_fin,
				empresa: this.state.filtro.empresa || null,
				cliente: this.state.filtro.cliente || null,
				id_cat_facturacion_status_sat: this.state.filtro.id_cat_facturacion_status_sat || null,
				id_cat_facturacion_status_pago: this.state.filtro.id_cat_facturacion_status_pago || null,
				id_cat_facturacion_plaza: this.state.filtro.id_cat_facturacion_plaza || null,
				anio: this.state.filtro.anio || null,
				periodo: this.state.filtro.periodo || null,
				serie: this.state.filtro.serie || null,
				uuid: this.state.filtro.uuid || null,
				folio: this.state.filtro.folio || null,
			};
			let params = {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			};
			HttpRequest.export('_Carga_CFDI_Donwload_Zip', ['_Carga_CFDI_Donwload_Zip'], params, false, false, false, archivo === "ZIP");
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	cancelModalDescarga = () => {
		this.setState({
			band_download_cfdi: false
		});
	};
	
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Carga de CFDI´s'}
					
					botonReporte={
						<Fragment>
							<BotonDescargaReporte onClick={() => {
								this.descargarReporte({}, 'EXCEL');
							}}/>
						</Fragment>
					}
					
					botonValida={
						<Fragment>
							<ModalVerificacionCFDI
								item={{}}
								componente={
									<Fab className={'vertical-inline'} variant="extended" size="small" color="primary" style={{backgroundColor: "rgba(63,180,89,0.8)"}}>
										<PostAdd className={'v-center margin-5-R px-15'}/>
										<div className={'v-center px-13 text-white '}>
											Verificación CFDI´s
										</div>
									</Fab>
								}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
						</Fragment>
					}
					
					botonDropbox={
						<Fragment>
							{FieldsJs.inArray([1, 2, 4, 6, 9, 13, 14], this.Usr.id_cat_tipo_usuario) ? (
								<Fab className={'vertical-inline'} variant="extended" size="small" color="primary" style={{backgroundColor: "rgba(130,38,27,0.8)"}}
								     onClick={() => this.ListarDonwload()}>
									{/*<img className={'v-center padding-6-R'} alt={'Chat'} src={icon_integraciones} width="25px" height="auto"/>*/}
									<PostAdd className={'v-center margin-5-R px-15'}/>
									<div className={'v-center px-13 text-white'}>
										Descarga CFDI´s
									</div>
								</Fab>
							) : null}
						</Fragment>
					}
					
					botonEXCEL={
						<Fragment>
							{FieldsJs.inArray([1, 2, 7, 9, 11, 13, 14], this.Usr.id_cat_tipo_usuario) ? (
								<ModalExcelCFDI
									componente={
										<BotonExportarListaExcel onClick={() => {
											//this.exportaryenviar({}, 'EXCEL', true, 1)
										}}/>
									}
									filtro={this.state.filtro}
									RefreshList={this.RefreshList}
									showSnackBars={this.showSnackBars}
								/>
							) : null}
						</Fragment>
					}
					
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
					
					botonReadXML={
						<Fragment>
							<ModalOptionsAcresco
								listDropbox={this.state.listDropbox}
								tipo={'acresco'}
								componente={
									<Fab className={'vertical-inline'} variant="extended" size="small" color="primary" style={{backgroundColor: "rgba(22, 91, 165, 0.8)"}}>
										<img className={'v-center padding-6-R'} alt={'Chat'} src={icon_acresco} width="25px" height="auto"/>
										<div className={'v-center px-13 text-white'}>
											Masivo AT
										</div>
									</Fab>
								}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
						</Fragment>
					}
					
					botonPDF={
						<Fragment>
							<ModalOptionsAcresco
								listDropbox={this.state.listDropbox}
								tipo={'integraciones'}
								componente={
									<Fab className={'vertical-inline'} variant="extended" size="small" color="primary" style={{backgroundColor: "rgba(142, 30, 50, 0.6)"}}>
										<img className={'v-center padding-6-R'} alt={'Chat'} src={icon_integraciones} width="25px" height="auto"/>
										<div className={'v-center px-13 text-white'}>
											Masivo Integraciones
										</div>
									</Fab>
								}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
						</Fragment>
					}
				
				/>
				
				
				<FiltrosCargoCFDI
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				{this.state.band_download_cfdi ? (
					<Fragment>
						<ModalDescargaCFDI
							open={this.state.band_download_cfdi}
							item={this.state}
							cancelModalDescarga={this.cancelModalDescarga}
						/>
					</Fragment>
				) : null
				}
				
				<div className={'form margin-30-B margin-15-T'}>
					
					{this.state.listar_cfdi.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_cfdi}
								listDropbox={this.state.listDropbox}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								SubTabla={this.SubTabla}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				{/*<ModalCargaCFDIView
					tipo={'add'}
					item={{}}
					listDropbox={this.state.listDropbox}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>*/}
			
			</div>
		);
	}
}

Nube.propTypes = {};

export default IntegrationNotistack(Nube);
