import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import {AttachFile, AttachMoney, Lock, DeleteOutlined, Description, EditOutlined, HdrStrong, HdrWeakOutlined, HomeWork, House, SearchOutlined} from "@material-ui/icons";
import moment from "moment";
import {CONFIG} from "../../../../settings/Config/Config";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {DropzoneArea} from "material-ui-dropzone";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import * as x509 from "@peculiar/x509";

class ModalEmpresaSucursalCertificadoArchivo extends Component {
	
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_empresa_archivo: (props.item || '').id_empresa_archivo,
			id_empresa_sucursal: (props.item || '').id_empresa_sucursal,
			id_empresa: (props.item || '').id_empresa,
			tipo_archivo: props.tipo_archivo || '',
			tipo: props.tipo,
			id_empresa_sucursal_certificado: props.id_empresa_sucursal_certificado,
			
			key: props.key,
			password: '',
			numero_certificado: '',
			base64Tipo: '',
			base64: '',
			archivo: '',
			formato: '',
			
			files: [],
			sucursales: [],
			certificados: [],
		};
		
		console.log("ITEM:::: ", props.item);
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.sucursales().then((response) => {
			/*this.setState({
					id_empresa_sucursal: response.data[0].id_empresa_sucursal
				},
				()=> {
					this.certificados().then((response) => {
						this.setState({open: true, scroll: 'paper'});
					});
				});*/
			this.certificados().then((response) => {
				this.setState({open: true, scroll: 'paper'});
			});
		})
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	sucursales = () => {
		return new Promise((resolve, reject) => {
			EmpresaFacturacionExtService.Sucursales(this.props.item).then((response) => {
				this.setState({
					sucursales: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	certificados = (id_empresa_sucursal) => {
		return new Promise((resolve, reject) => {
			let key = this.state.id_empresa_sucursal_certificado;
			console.log(key);
			let response = [];
			if (this.state.key === null) {
				let id = id_empresa_sucursal ? id_empresa_sucursal : this.state.id_empresa_sucursal;
				
				EmpresaFacturacionExtService.Certificados(id).then((response) => {
					this.setState({
						certificados: response.data,
					});
					if (response.data.length > 0) {
						for (let i = 0; i < response.data.length; i++) {
							if (response.data[i].id_empresa_sucursal_certificado === key) {
								this.setState({
									id_empresa_sucursal_certificado: response.data[i].id_empresa_sucursal_certificado || '',
									id_empresa_sucursal: response.data[i].id_empresa_sucursal || '',
									password: response.data[i].password || '',
									numero_certificado: response.data[i].numero_certificado || '',
									id_empresa_archivo: response.data[i].id_empresa_archivo || '',
								});
							}
						}
					}
					resolve(response);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
					reject(error);
				});
			} else {
				resolve(response);
			}
		});
	}
	
	save = () => {
		
		try {
			let cont = this.state.files.length;
			
			if (cont <= 0) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Ingresa un archivo para importar.'
				})
			}
			
			EmpresaFacturacionExtService.CertificadosModificar(this.state).then((response) => {
					/*this.edit_cancel().then((res) => {
						setTimeout(() => {
							this.certificados();
						}, 900);
					});
					hideSpinner('spinner', 500);*/
					this.props.RefreshList();
					this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {

		console.log(this.props);
		const {tipo, item, id_empresa_sucursal_certificado, tipo_archivo} = this.props;
		
		this.setState({
			id_empresa_sucursal_certificado: id_empresa_sucursal_certificado || '',
			id_empresa_sucursal: item.id_empresa_sucursal || '',
			tipo: tipo ?? '',
			password: item.password_ver || '',
			tipo_archivo: tipo_archivo || '',
			numero_certificado: item.numero_certificado || '',
			id_empresa_archivo: item.id_empresa_archivo || '',
			id_empresa: item.id_empresa || '',
			key: null,
			base64Tipo: '',
			base64: '',
			archivo: '',
			formato: '',
			activo: true,
		});
		
		this.openModal();
		
	};
	
	view = () => {
		
		const {tipo, item, tipo_archivo} = this.props;
		
		console.log(this.props);
		
		this.setState({
			tipo: tipo ?? '',
			id_empresa_archivo: item.id_empresa_archivo || '',
			id_empresa: item.id_empresa || '',
			password: item.password || '',
			tipo_archivo: tipo_archivo || '',
			observaciones: item.observaciones || null,
			fecha_emision: item.fecha_emision ? moment(item.fecha_emision) : null,
			fecha_apertura: item.fecha_apertura ? moment(item.fecha_apertura) : null,
			comprobante_pago: 1,
			archivos: item.archivos || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	

	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_empresa_archivo > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Estatus">
						<IconButton aria-label="Estatus" onClick={() => this.edit()}>
							<HdrStrong/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Certificados">
					<IconButton aria-label="Certificados" onClick={() => this.add()}><Lock/></IconButton>
				</Tooltip>
			);
		}
		return BTN_ACTION;
	};
	
	TITLE = () => {
		var title = '';
		switch (this.props.tipo_archivo) {
			case 1:
				title='Subir Archivo Certificado (.CER)';
				break;
				case 2:
					title='Subir Archivo Certificado (.KEY)';
					break;
				case 3:
					title='Subir Archivo Certificado (.PEM)';
				break;
				case 4:
					title='Subir Archivo Llave Privada (.PEM)';
				break;
		}
		return title;
	};
	
	edit = (value, key) => {
		try {
			
			this.setState({
				key: key,
				id_empresa_sucursal_certificado: value.id_empresa_sucursal_certificado || '',
				id_empresa_sucursal: value.id_empresa_sucursal || '',
				password: value.password || '',
				numero_certificado: value.numero_certificado || '',
				base64Tipo: value.base64Tipo || '',
				base64: CONFIG.src_facturacion + value.archivo_logo || '',
				archivo: CONFIG.src_facturacion + value.archivo_logo || '',
				formato: value.formato_logo || '',
				
				activo: value.activo ? 1 : 0
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		return new Promise((resolve) => {
			this.setState({
				key: null,
				password: '',
				numero_certificado: '',
				base64Tipo: '',
				base64: '',
				archivo: '',
				formato: '',
				
				activo: true
			}, () => {
				resolve();
			});
			
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el certificado con Id: ' + key + '?').then((r) => {
				if (r.button === 'Aceptar') {
					EmpresaFacturacionExtService.CertificadosDelete(value).then((response) => {
						this.certificados();
						this.edit_cancel();
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
						
					});
				}
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	mouseEvent = (event, item) => {
		if (event.type === "mousedown") {
			this.setState({password_ver: item.password});
		} else {
			this.setState({password_ver: ""});
		}
	}
	
	descargar = (archivo) => {
		const arc = archivo.split('.');
		console.log(CONFIG.src_facturacion + archivo);
		const url = CONFIG.src_facturacion + archivo;
		//const url = URL.createObjectURL(CONFIG.src_facturacion + archivo);
		const a = document.createElement('a');
		a.href = url;
		a.download = arc[arc.length - 1] || 'download';
		const clickHandler = () => {
			setTimeout(() => {
				URL.revokeObjectURL(url);
			}, 150);
		};
		a.addEventListener('click', clickHandler, false);
		a.click();
	}
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		}, ()=> {
			if (this.state.files.length <= 0) {
				this.setState({
					base64Tipo: '',
					base64: '',
					archivo: '',
					formato: '',
				});
			} else {
				this.darformato(this.state.files[0]).then((response) => {
					console.log("STATE:::: ", this.state, response);
					const cert = new x509.X509Certificate(response);
					console.log(cert)
				});
			}
		});
	};
	
	darformato = (file) => {
		return new Promise((resolve, reject) => {
			let formatos = ['application/x-x509-ca-cert', "application/x-iwork-keynote-sffkey"];
			let fil = {files: [file]};
			FileBase64.Base64(fil, formatos, this.props.MegaByte || null, true).then((response) => {
				console.log("FileBase64.Base64", response);
				this.handleChange({
					target: {
						name: 'base64',
						value: response.base64
					}
				});
				this.handleChange({
					target: {
						name: 'base64Tipo',
						value: response.base64Tipo
					}
				});
				this.handleChange({
					target: {
						name: 'archivo',
						value: response.archivo
					}
				});
				this.handleChange({
					target: {
						name: 'formato',
						value: response.formato
					}
				});
				resolve(response.archivo);
			}).catch((error) => {
				
				this.props.showSnackBars('error', error.mensaje);
				reject();
			});
		});
	}
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		const title = this.TITLE();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{title}
					</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<DropzoneArea
									onChange={this.handleChangeDropzoneArea.bind(this)}
									open={true}
									filesLimit={1}
									dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
									acceptedFiles={[]}
									showPreviews={false}
									maxFileSize={5000000}
									showAlerts={false}
									showFileNames={true}
									showFileNamesInPreview={true}
								/>
							
							</Grid>
							
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.key !== null ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEmpresaSucursalCertificadoArchivo.propTypes = {
	id_empresa_sucursal_certificado: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalEmpresaSucursalCertificadoArchivo);
