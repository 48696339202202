import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, BotonAccionFooterModal} from "../../../Include/MiniComponents/GlobalComponent";
import {DropzoneArea} from 'material-ui-dropzone';
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import moment from "moment";
import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import {FacturacionService} from "../../../../services/FacturacionService/FacturacionService";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import Table from "@material-ui/core/Table";
import {withStyles} from "@material-ui/core/styles";
import {Description, Folder} from "@material-ui/icons";


const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
		backgroundColor: 'transparent'
	},
	table: {
		minWidth: '300px !important',
	},
});

class ModalComprobante extends Component {
	
	state = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			id_facturacion: (props.item || {}).id_facturacion,
			id_facturacion_archivo: (props.item || {}).id_facturacion_archivo,
			id_facturacion_comprobante: (props.item || {}).id_facturacion_comprobante,
			empresa: (props.item || {}).empresa_nombre_comercial,
			files: [],
			archivos: [],
			
			listDropbox: [],
			fecha_pago: null,
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		const item = this.props.item;
		
		this.setState({
			id_facturacion: item.id_facturacion || '',
			id_facturacion_archivo: item.id_facturacion_archivo || '',
			empresa: item.empresa_nombre_comercial || '',
			uuid: item.uuid || ''
		});
		
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	guardarDocTimbradoDbx = () => {
		let ftar = this.state.files;
		console.log("1 FTAR::: ", ftar);
		
		let files;
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i]) === true) {
					band++;
				}
			}
			
			let archives = [];
			
			let years_fechaxml = moment(this.state.fecha_pago).get('years');
			let month_fechaxml = moment(this.state.fecha_pago).get('month');
			month_fechaxml = month_fechaxml + 1;
			if (month_fechaxml < 10) {
				month_fechaxml = '0' + month_fechaxml;
			}
			
			
			for (let i = 0; i < ftar.length; i++) {
				files = ftar[i];
				
				let ruta = '/Facturacion/Comprobante Pago/' + this.state.empresa + '/' + years_fechaxml + '/' + month_fechaxml + '/' + files.name;
				console.log("RUTA::: ",ruta);
				DropboxApi.Upload(ruta, files).then(function (response) {
					archives.push(response.path_display);
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	
	save = () => {
		console.log("THIS.STATE: ", this.state);
		
		try {
			
			if (!FieldsJs.Field(this.state.fecha_pago)) {
				throw Object({
					status: false, mensaje: 'Fecha pago requerido.'
				})
			}
			
			let guardar = new Promise((resolve, reject) => {
				this.guardarDocTimbradoDbx().then(response => {
					console.log("GUARDARFICHATECNICAARCHIVOELABORACIONPROPUESTADBX::: ", response);
					this.setState({
						archivos: response
					});
					resolve(response);
					console.log("DROPBOX UPLOAD: ", this.state);
					
				}).catch(error => {
					reject(error);
				})
			});
			
			guardar.then(response => {
				FacturacionService.Comprobante(this.state).then((response) => {
					this.closeModal();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			})
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	add = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_facturacion: item.id_facturacion || '',
			fecha_pago: null,
			files: [],
			archivos: [],
			activo: true,
			jsonXML: '',
			uuid: item.uuid || '',
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Open(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	
	render() {
		
		const {classes} = this.props;
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_facturacion_comprobante}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Subir comprobante
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={0}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<KeyboardDatePicker
											format="dd/MM/yyyy"
											fullWidth
											required
											clearable
											inputProps={{readOnly: true}}
											/*KeyboardButtonProps={{
												'aria-label': 'Fecha de pago',
											}}*/
											label="Fecha de pago"
											value={this.state.fecha_pago}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_pago');
											}}
											// minDate={moment(new Date())}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											onError={console.log}
											disabled={this.is_view()}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={0}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<DropzoneArea
													onChange={this.handleChangeDropzoneArea.bind(this)}
													//open={this.state.open}
													filesLimit={1}
													dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
													acceptedFiles={['image/jpeg', 'image/png', 'application/pdf']}
													showPreviews={false}
													maxFileSize={5000000}
													showAlerts={false}
													showFileNames={true}
													showFileNamesInPreview={true}
												/>
											
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										{this.props.item.comprobante.length > 0 ? (
											<Table className={classes.table + ' desing-acresco'}>
												<TableHead>
													<TableRow>
														<TableCell component="th" align={'left'}>Archivo</TableCell>
														<TableCell component="th" align={'left'}>Nombre completo</TableCell>
														<TableCell component="th" align={'left'}>Fecha pago</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{
														this.props.item.comprobante.map((item, key) => {
															return (
																<TableRow key={key}>
																	
																	<TableCell align={'left'}>
																		<div className={'vertical-inline cursor-pointer'} onClick={() => this.exportar(item.archivo)}>
																			<div className={'v-center padding-15-R'}>
																				{item.thumb && Object.values(item.thumb)[0] !== 'failure' ? <img src={`data:image/jpeg;base64,${item.thumb.thumbnail}`} width={32}/>
																					: Object.values(item)[0] === "folder" ?
																						<Folder style={{fontSize: '32px', color: 'rgb(142, 30, 50)'}}/>
																						: <Description style={{fontSize: '32px', color: 'rgba(23,76,145,0.37)'}}/>
																				}
																			</div>
																			<div className={'v-center'}>
																				{item.name}
																			</div>
																		</div>
																	</TableCell>
																	
																	<TableCell align={'left'}>
																		{item.nombre_completo}
																	</TableCell>
																	
																	<TableCell align={'left'}>
																		<div>
																			<div>
																				{item.fecha_pago}
																			</div>
																		</div>
																	</TableCell>
																
																</TableRow>
															)
														})
													}
												</TableBody>
											</Table>
										) : null
										}
									
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_facturacion_comprobante}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalComprobante.propTypes = {
	classes: PropTypes.object.isRequired,
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ModalComprobante);
