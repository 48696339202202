import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

class UsuarioBancoService {
	static Listar = () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Usuario_Banco_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Agregar = (form) => {
		let params = {
			id_usuario_banco: null,
			id_usuario: form.id_usuario || '',
			id_cat_banco: form.id_cat_banco || '',
			id_cat_banco_sucursal: form.id_cat_banco_sucursal || '',
			id_cat_banco_tipo_cuenta: form.id_cat_banco_tipo_cuenta || '',
			numero_cliente: form.numero_cliente || '',
			numero_cuenta: form.numero_cuenta || '',
			clabe_interbancaria: form.clabe_interbancaria || '',
			observaciones: form.observaciones || '',
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Usuario_Banco_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Modificar = (form) => {
		let params = {
			id_usuario_banco: form.id_usuario_banco || '',
			id_usuario: form.id_usuario || '',
			id_cat_banco: form.id_cat_banco || '',
			id_cat_banco_sucursal: form.id_cat_banco_sucursal || '',
			id_cat_banco_tipo_cuenta: form.id_cat_banco_tipo_cuenta || '',
			numero_cliente: form.numero_cliente || '',
			numero_cuenta: form.numero_cuenta || '',
			clabe_interbancaria: form.clabe_interbancaria || '',
			observaciones: form.observaciones || '',
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Usuario_Banco_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Eliminar = (id_usuario_banco) => {
		let params = {
			id_usuario_banco: id_usuario_banco
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Usuario_Banco_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
}

export default UsuarioBancoService;
