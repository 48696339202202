import React, {Component, Fragment} from 'react';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {
	AccountBalance,
	AddOutlined,
	AlternateEmailOutlined,
	AttachMoney,
	CancelOutlined,
	DeleteOutlined,
	EditOutlined,
	HdrWeakOutlined,
	HowToVoteOutlined,
	PeopleAlt,
	Phone,
	PhoneIphoneOutlined,
	PinDropOutlined,
	SaveOutlined,
	StreetviewOutlined,
	Today,
	TurnedInNotOutlined
} from "@material-ui/icons";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import carrito_de_compra_3 from "../../../../assets/img/icons/contacto_tarjeta.svg";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import * as PropTypes from "prop-types";
import {AdquisicionService} from "../../../../services/AdquisicionService/AdquisicionService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import moment from "moment";

class ModalAdquisiciones extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			//Adquisicion
			id_adquisicion: (props.item || '').id_adquisicion,
			id_cat_tipo_adquisicion: '',
			tipo_adquisicion: '',
			id_usuario: '',
			enganche: '',
			precio: '',
			plazo: '',
			propietario: '',
			broker: '',
			socio: '',
			direccion: '',
			activo: true,
			
			cat_tipo_adquisicion: [],
			cat_banco: [],
			cat_periodicidad: [],
			//cat_dia_pago: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
			fecha_inicial: null,
			id_cat_periodicidad: '',
			periodicidad: '',
			plazo_contrato: '',
			pago_periodicidad: '',
			
			
			//Contactos
			contacto_nombre: '',
			contacto_rfc: '',
			contacto_direccion: '',
			contacto_correo_electronico: '',
			contacto_telefono: '',
			contacto_celular: '',
			
			lista_contactos: [],
			
			//Cuentas
			cuenta_titular: '',
			cuenta_id_cat_banco: '',
			cuenta_banco: '',
			cuenta_sucursal: '',
			cuenta: '',
			cuenta_clabe: '',
			cuenta_nombre: '',
			cuenta_numero_referencia: '',
			
			lista_cuentas: [],
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
			
			this.ListBanco();
			
			if (this.state.id_cat_estado > 0)
				this.ListMunicipio(null, this.state.id_cat_estado);
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	ListaCatAdquisicion = () => {
		CatService.ListTipoAdquisicion().then((response) => {
			this.setState({
				cat_tipo_adquisicion: response.data,
			});
		});
	};
	
	ListaCatPeriodicidad = () => {
		CatService.ListPeriodicidad().then((response) => {
			this.setState({
				cat_periodicidad: response.data,
			});
		});
	};
	selectPeriodicidad = (id_cat_periodicidad) => {
		let per = FieldsJs.Copy(this.state.cat_periodicidad);
		let periodicidad = "";
		for (let i = 0; i < per.length; i++) {
			if (Number(id_cat_periodicidad) === Number(per[i].id_cat_periodicidad)) {
				periodicidad = per[i].periodicidad;
			}
		}
		this.setState({
			periodicidad: periodicidad
		});
	}
	
	ListaAdquisicion = () => {
		CatService.ListTipoAdquisicion().then((response) => {
			const {item} = this.props;
			
			this.setState({
				cat_tipo_adquisicion: response.data,
				id_cat_tipo_adquisicion: item.id_cat_tipo_adquisicion || '',
				otro_tipo_adquisicion: item.otro_tipo_adquisicion || '',
			});
		});
	};
	
	ListBanco = () => {
		CatService.ListBanco().then((response) => {
			this.setState({
				cat_banco: response.data
			});
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
		if (this.state.id_adquisicion > 0) {
			AdquisicionService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			AdquisicionService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		const {item} = this.props;
		this.ListaCatAdquisicion();
		this.ListaCatPeriodicidad();
		this.setState({
			//Adquisicion
			id_adquisicion: item.id_adquisicion || '',
			tipo_adquisicion: '',
			id_cat_tipo_adquisicion: '',
			otro_tipo_adquisicion: '',
			id_usuario: '',
			enganche: '',
			precio: '',
			dia_pago: '',
			plazo: '',
			propietario: '',
			broker: '',
			socio: '',
			direccion: '',
			activo: true,
			periodicidad: '',
			plazo_contrato: '',
			pago_periodicidad: '',
			
			//Contactos
			contacto_nombre: '',
			contacto_rfc: '',
			contacto_direccion: '',
			contacto_correo_electronico: '',
			contacto_telefono: '',
			contacto_celular: '',
			
			//Cuentas
			cuenta_titular: '',
			cuenta_id_cat_banco: '',
			cuenta_banco: '',
			cuenta_sucursal: '',
			cuenta: '',
			cuenta_clabe: '',
			cuenta_nombre: '',
			cuenta_numero_referencia: '',
			
			
			fecha_inicial: null,
			id_cat_periodicidad: '',
			
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		
		this.ListaAdquisicion();
		this.ListaCatPeriodicidad();
		
		this.setState({
			//Adquisicion
			id_adquisicion: item.id_adquisicion || '',
			tipo_adquisicion: item.tipo || '',
			//id_cat_tipo_adquisicion: item.id_cat_tipo_adquisicion || '',
			otro_tipo_adquisicion: item.otro_tipo_adquisicion || '',
			id_usuario: item.id_usuario || '',
			enganche: item.enganche || '',
			precio: item.precio || '',
			dia_pago: item.dia_pago || '',
			plazo: item.plazo || '',
			propietario: item.propietario || '',
			broker: item.broker || '',
			socio: item.socio || '',
			direccion: item.direccion || '',
			periodicidad: item.periodicidad || '',
			plazo_contrato: item.plazo_contrato || '',
			pago_periodicidad: item.pago_periodicidad || '',
			activo: true,
			
			//Contactos
			contacto_nombre: '',
			contacto_rfc: '',
			contacto_direccion: '',
			contacto_correo_electronico: '',
			contacto_telefono: '',
			contacto_celular: '',
			lista_contactos: item.lista_contactos || [],
			
			//Cuentas
			cuenta_titular: '',
			cuenta_banco: '',
			cuenta_sucursal: '',
			cuenta: '',
			cuenta_clabe: '',
			cuenta_nombre: '',
			cuenta_numero_referencia: '',
			lista_cuentas: item.lista_cuentas || [],
			
			
			fecha_inicial: item.fecha_inicial ? moment(item.fecha_inicial) : null,
			id_cat_periodicidad: item.id_cat_periodicidad || '',
			
			// lista_contactos: item.cliente_contactos || [],
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		
		this.ListaAdquisicion();
		this.ListaCatPeriodicidad();
		
		this.setState({
			//Adquisicion
			id_adquisicion: item.id_adquisicion || '',
			tipo_adquisicion: item.tipo || '',
			//id_cat_tipo_adquisicion: item.id_cat_tipo_adquisicion || '',
			otro_tipo_adquisicion: item.otro_tipo_adquisicion || '',
			id_usuario: item.id_usuario || '',
			enganche: item.enganche || '',
			precio: item.precio || '',
			dia_pago: item.dia_pago || '',
			plazo: item.plazo || '',
			propietario: item.propietario || '',
			broker: item.broker || '',
			socio: item.socio || '',
			direccion: item.direccion || '',
			periodicidad: item.periodicidad || '',
			plazo_contrato: item.plazo_contrato || '',
			pago_periodicidad: item.pago_periodicidad || '',
			activo: true,
			
			//Contactos
			contacto_nombre: '',
			//contacto_rfc: '',
			contacto_direccion: '',
			contacto_correo_electronico: '',
			contacto_telefono: '',
			contacto_celular: '',
			lista_contactos: item.lista_contactos || [],
			
			//Cuentas
			cuenta_titular: '',
			cuenta_banco: '',
			cuenta_sucursal: '',
			cuenta: '',
			cuenta_clabe: '',
			cuenta_nombre: '',
			cuenta_numero_referencia: '',
			lista_cuentas: item.lista_cuentas || [],
			
			fecha_inicial: item.fecha_inicial ? moment(item.fecha_inicial) : null,
			id_cat_periodicidad: item.id_cat_periodicidad || '',
			
			// lista_contactos: item.cliente_contactos || [],
		});
		this.open();
	};
	
	edit_contacto = (value, key) => {
		try {
			
			this.setState({
				key: key,
				contacto_nombre: value.nombre,
				contacto_rfc: value.rfc,
				contacto_telefono: value.telefono,
				contacto_celular: value.celular,
				contacto_correo_electronico: value.correo_electronico,
				contacto_direccion: value.direccion
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			contacto_nombre: '',
			contacto_rfc: '',
			contacto_telefono: '',
			contacto_celular: '',
			contacto_correo_electronico: '',
			contacto_direccion: ''
		});
	};
	
	edit_cuenta = (value, key) => {
		try {
			
			this.setState({
				key_cuenta: key,
				cuenta_titular: value.titular,
				cuenta_id_cat_banco: value.id_cat_banco,
				cuenta_banco: value.banco,
				cuenta_sucursal: value.sucursal,
				cuenta: value.cuenta,
				cuenta_clabe: value.clabe,
				cuenta_nombre: value.nombre,
				cuenta_numero_referencia: value.numero_referencia
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel_cuenta = () => {
		this.setState({
			key_cuenta: null,
			cuenta_titular: '',
			cuenta_id_cat_banco: '',
			cuenta_banco: '',
			cuenta_sucursal: '',
			cuenta: '',
			cuenta_clabe: '',
			cuenta_nombre: '',
			cuenta_numero_referencia: '',
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el contacto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_contactos = FieldsJs.Copy(this.state.lista_contactos);
					
					lista_contactos.splice(key, 1);
					
					this.setState({
						lista_contactos: lista_contactos
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	delete_cuenta = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar la cuenta?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_cuenta = FieldsJs.Copy(this.state.lista_cuenta);
					
					lista_cuenta.splice(key, 1);
					
					this.setState({
						lista_cuenta: lista_cuenta
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	is_edited_product_cuenta = () => {
		return this.state.key_cuenta !== null && this.state.key_cuenta > -1;
	};
	
	add_contacto = () => {
		
		try {
			
			if (!this.state.contacto_nombre) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Nombre del contacto.'
				})
			}
			
			if (!(this.state.contacto_celular)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Celular del contacto.'
				})
			}
			
			
			let lista_contactos = FieldsJs.Copy(this.state.lista_contactos || []);
			
			if (this.is_edited_product()) {
				for (let i = 0; i < lista_contactos.length; i++) {
					let item = lista_contactos[i];
					if (this.state.key === i) {
						item.nombre = this.state.contacto_nombre || null;
						//item.rfc = this.state.contacto_rfc || null;
						item.telefono = this.state.contacto_telefono || null;
						item.celular = this.state.contacto_celular || '';
						item.correo_electronico = this.state.contacto_correo_electronico || '';
						item.direccion = this.state.contacto_direccion || '';
					}
				}
			} else {
				let item;
				item = {
					nombre: this.state.contacto_nombre || null,
					rfc: this.state.contacto_rfc || null,
					telefono: this.state.contacto_telefono || null,
					celular: this.state.contacto_celular || '',
					correo_electronico: this.state.contacto_correo_electronico || '',
					direccion: this.state.contacto_direccion || ''
				};
				lista_contactos.push(item);
			}
			
			this.setState({
				key: null,
				contacto_nombre: '',
				//contacto_rfc: '',
				contacto_telefono: '',
				contacto_celular: '',
				contacto_correo_electronico: '',
				contacto_direccion: '',
				
				lista_contactos: lista_contactos
			});
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	getBanco = (id_cat_banco) => {
		let bancos = FieldsJs.Copy(this.state.cat_banco);
		let banco = '';
		for (let i = 0; i < bancos.length; i++) {
			if (Number(id_cat_banco) === Number(bancos[i].id_cat_banco)) {
				banco = bancos[i].banco;
			}
		}
		
		return banco;
	};
	
	add_cuenta = () => {
		
		try {
			
			if (!this.state.cuenta_titular) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Titular de la cuenta.'
				})
			}
			
			if (!(this.state.cuenta_id_cat_banco)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Banco de la cuenta.'
				})
			}
			
			if (!(this.state.cuenta_clabe)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Clabe de la cuenta.'
				})
			}
			
			
			let lista_cuentas = FieldsJs.Copy(this.state.lista_cuentas || []);
			
			if (this.is_edited_product_cuenta()) {
				for (let i = 0; i < lista_cuentas.length; i++) {
					let item = lista_cuentas[i];
					if (this.state.key_cuenta === i) {
						item.titular = this.state.cuenta_titular || null;
						item.id_cat_banco = this.state.cuenta_id_cat_banco || null;
						item.banco = this.getBanco(this.state.cuenta_id_cat_banco) || null;
						item.sucursal = this.state.cuenta_sucursal || null;
						item.cuenta = this.state.cuenta || '';
						item.clabe = this.state.cuenta_clabe || '';
						item.nombre = this.state.cuenta_nombre || '';
						item.numero_referencia = this.state.cuenta_numero_referencia || '';
					}
				}
			} else {
				let item;
				item = {
					titular: this.state.cuenta_titular || null,
					id_cat_banco: this.state.cuenta_id_cat_banco || null,
					banco: this.getBanco(this.state.cuenta_id_cat_banco) || null,
					sucursal: this.state.cuenta_sucursal || null,
					cuenta: this.state.cuenta || '',
					clabe: this.state.cuenta_clabe || '',
					nombre: this.state.cuenta_nombre || '',
					numero_referencia: this.state.cuenta_numero_referencia || ''
				};
				lista_cuentas.push(item);
			}
			
			this.setState({
				key_cuenta: null,
				cuenta_titular: '',
				cuenta_id_cat_banco: '',
				cuenta_banco: '',
				cuenta_sucursal: '',
				cuenta: '',
				cuenta_clabe: '',
				cuenta_nombre: '',
				cuenta_numero_referencia: '',
				
				lista_cuentas: lista_cuentas
			});
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	tipoAdquisicion = (valor) => {
		let tipo = '';
		for (let i = 0; i < this.state.cat_tipo_adquisicion.length; i++) {
			if (Number(valor) === Number(this.state.cat_tipo_adquisicion[i].id_cat_tipo_adquisicion)) {
				tipo = this.state.cat_tipo_adquisicion[i].tipo;
			}
		}
		
		this.setState({
			id_cat_tipo_adquisicion: valor,
			tipo_adquisicion: tipo,
			otro_tipo_adquisicion: ''
		});
	}
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_adquisicion}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Operación'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Tipo de Operación</InputLabel>
											<Select
												native
												value={this.state.id_cat_tipo_adquisicion}
												onChange={(e) => {
													this.tipoAdquisicion(e.target.value);
												}}
												name="id_cat_tipo_adquisicion"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_tipo_adquisicion.map(option => (
													<option key={option.id_cat_tipo_adquisicion}
													        value={option.id_cat_tipo_adquisicion}>
														{option.tipo_adquisicion}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							{FieldsJs.inArray([6, 9], this.state.id_cat_tipo_adquisicion) ? (
								<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
									<Grid container spacing={1} alignItems={"flex-end"}>
										<Grid item className={'w-30-px'}>
											<HdrWeakOutlined className={'w-100 text-gray'}/>
										</Grid>
										<Grid item className={'w-100-30-px'}>
											<TextField type={'text'} fullWidth name="otro_tipo_adquisicion"
											           onChange={this.handleChange}
											           label={Number(this.state.id_cat_tipo_adquisicion) === 6 ? "Otro Tipo de Adquisición" : "Otro Tipo de Ingreso"} autoComplete={'off'}
											           value={this.state.otro_tipo_adquisicion}
											           disabled={this.props.tipo === 'view'}
											/>
										</Grid>
									</Grid>
								</Grid>
							) : null}
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="precio"
										           onChange={this.handleChange}
										           label={this.state.tipo_adquisicion === 'A' ? "Precio" : this.state.tipo_adquisicion === 'O' ? "Depósito en garantía" : "Capital"}
										           autoComplete={'off'}
											// inputProps={{maxLength: 5}}
											        required
											        onKeyPress={EnteroSolo}
											        value={this.state.precio}
											        disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="enganche"
										           onChange={this.handleChange}
										           label={this.state.tipo_adquisicion === 'O' ? "Cuota" : "Enganche"} autoComplete={'off'}
											// inputProps={{maxLength: 5}}
											        onKeyPress={EnteroSolo}
											        value={this.state.enganche}
											        disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<KeyboardDatePicker
									format="dd/MM/yyyy"
									fullWidth
									required
									clearable
									inputProps={{readOnly: true}}
									/*KeyboardButtonProps={{
										'aria-label': 'Fecha de pago',
									}}*/
									label="Fecha Inicial"
									value={this.state.fecha_inicial}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_inicial');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									disabled={this.is_view()}
								/>
							
							</Grid>
							
							{FieldsJs.inArray([8], this.state.id_cat_tipo_adquisicion) ? (
								<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
									<Grid container spacing={1} alignItems={"flex-end"}>
										<Grid item className={'w-30-px'}>
											<HdrWeakOutlined className={'w-100 text-gray'}/>
										</Grid>
										<Grid item className={'w-100-30-px'}>
											<TextField type={'text'} fullWidth
											           name="plazo_contrato"
											           onChange={this.handleChange}
											           label="Plazo de contrato" autoComplete={'off'}
												// inputProps={{maxLength: 5}}
												        required
												        onKeyPress={EnteroSolo}
												        value={this.state.plazo_contrato}
												        disabled={this.props.tipo === 'view'}
											/>
										</Grid>
									</Grid>
								</Grid>
							) : null}
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Periodicidad</InputLabel>
											<Select
												native
												value={this.state.id_cat_periodicidad}
												onChange={(e) => {
													this.handleChange(e);
													this.selectPeriodicidad(e.target.value);
												}}
												name="id_cat_periodicidad"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_periodicidad.map(option => (
													<option key={option.id_cat_periodicidad}
													        value={option.id_cat_periodicidad}>
														{option.periodicidad}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							{FieldsJs.inArray([8], this.state.id_cat_tipo_adquisicion) ? (
								<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
									<Grid container spacing={1} alignItems={"flex-end"}>
										<Grid item className={'w-30-px'}>
											<HdrWeakOutlined className={'w-100 text-gray'}/>
										</Grid>
										<Grid item className={'w-100-30-px'}>
											<TextField type={'text'} fullWidth
											           name="pago_periodicidad"
											           onChange={this.handleChange}
											           label={ this.state.periodicidad ? "Pago " + this.state.periodicidad : "Pago"}
											           autoComplete={'off'}
														  // inputProps={{maxLength: 5}}
												        required
												        onKeyPress={EnteroSolo}
												        value={this.state.pago_periodicidad}
												        disabled={this.props.tipo === 'view'}
											/>
										</Grid>
									</Grid>
								</Grid>
							) : null}
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth
										           name="plazo"
										           onChange={this.handleChange}
										           label="Número de pagos" autoComplete={'off'}
											// inputProps={{maxLength: 5}}
											        required
											        onKeyPress={EnteroSolo}
											        value={this.state.plazo}
											        disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="propietario"
										           onChange={this.handleChange}
										           label={this.state.tipo_adquisicion === 'A' ? "Propietario" : "Beneficiario"} autoComplete={'off'}
										           value={this.state.propietario}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<StreetviewOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="broker"
										           onChange={this.handleChange}
										           label="Broker" autoComplete={'off'}
										           value={this.state.broker}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PeopleAlt className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="socio"
										           onChange={this.handleChange}
										           label="Número de socios" autoComplete={'off'}
											// inputProps={{maxLength: 5}}
											        onKeyPress={EnteroSolo}
											        value={this.state.socio}
											        required
											        disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							{FieldsJs.inArray([6, 8, 9], this.state.id_cat_tipo_adquisicion) ? (
								<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
									<Grid container spacing={1} alignItems={"flex-end"}>
										<Grid item className={'w-30-px'}>
											<PinDropOutlined className={'w-100 text-gray'}/>
										</Grid>
										<Grid item className={'w-100-30-px'}>
											<TextField type={'text'} fullWidth name="direccion"
											           onChange={this.handleChange}
											           label="Dirección" autoComplete={'off'}
											           value={this.state.direccion}
											           disabled={this.props.tipo === 'view'}
											/>
										</Grid>
									</Grid>
								</Grid>
							) : (
								<Grid item className={'text-left'} xs={12} sm={12} md={9} lg={9} xl={9}>
									<Grid container spacing={1} alignItems={"flex-end"}>
										<Grid item className={'w-30-px'}>
											<PinDropOutlined className={'w-100 text-gray'}/>
										</Grid>
										<Grid item className={'w-100-30-px'}>
											<TextField type={'text'} fullWidth name="direccion"
											           onChange={this.handleChange}
											           label="Dirección" autoComplete={'off'}
											           value={this.state.direccion}
											           disabled={this.props.tipo === 'view'}
											/>
										</Grid>
									</Grid>
								</Grid>
							)}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<label>
									<TituloHeaderModal
										tipo={this.state.tipo}
										titulo={'Contacto'}
									/>
								</label>
							</Grid>
							
							{!this.is_view() ? (
								<Fragment>
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="contacto_nombre"
												           onChange={this.handleChange}
												           label="Nombre/Razón Social" autoComplete={'off'}
												           value={this.state.contacto_nombre}
												           required
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									{/*<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HowToVoteOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="contacto_rfc"
												           onChange={this.handleChange}
												           inputProps={{maxLength: 13}}
												           label="R.F.C." autoComplete={'off'}
												           value={this.state.contacto_rfc}
												           required
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>*/}
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Phone className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="contacto_telefono"
												           onChange={this.handleChange}
												           inputProps={{maxLength: 10}}
												           onKeyPress={EnteroSolo}
												           label="Teléfono" autoComplete={'off'}
												           value={this.state.contacto_telefono}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<PhoneIphoneOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="contacto_celular"
												           onChange={this.handleChange}
												           inputProps={{maxLength: 10}}
												           onKeyPress={EnteroSolo}
												           required
												           label="Celular" autoComplete={'off'}
												           value={this.state.contacto_celular}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AlternateEmailOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="contacto_correo_electronico"
												           onChange={this.handleChange}
												           label="Correo electrónico" autoComplete={'off'}
												           value={this.state.contacto_correo_electronico}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<StreetviewOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="contacto_direccion"
												           onChange={this.handleChange}
												           label="Dirección" autoComplete={'off'}
												           value={this.state.contacto_direccion}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									
									<Grid item xs={12} sm={3} md={3} lg={3} xl={3} align={"right"}>
										<Tooltip
											TransitionComponent={Zoom}
											placement={"top"}
											title="Cancelar edición"
											children={(
												<span>
											<Fab size="small" color="secondary"
											     aria-label="Cancelar edición"
											     onClick={() => this.edit_cancel()}
											     style={{marginLeft: "15px"}}
											     disabled={!this.is_edited_product()}>
												<CancelOutlined/>
											</Fab>
										</span>
											)}
										/>
										<Tooltip
											TransitionComponent={Zoom}
											placement={"top"}
											title={this.is_edited_product() ? "Actualizar" : "Agregar"}
											children={(
												<Fab size="small" color="secondary"
												     aria-label={this.is_edited_product() ? "Actualizar" : "Agregar"}
												     onClick={() => this.add_contacto()}
												     style={{marginLeft: "15px"}}>
													{this.is_edited_product() ? (
														<SaveOutlined/>
													) : (
														<AddOutlined/>
													)}
												</Fab>
											)}
										/>
									</Grid>
								
								</Fragment>
							) : ''}
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<table className={'desing-mark'}>
									<thead>
									<tr>
										<th>Nombre/Razón Social</th>
										{/*<th>RFC</th>*/}
										<th>Teléfono</th>
										<th>Celular</th>
										<th>Correo electrónico</th>
										<th>Dirección</th>
										{!this.is_view() ? (<th width={100} align={'center'}>Acciones</th>) : null}
									</tr>
									</thead>
									<tbody>
									{this.state.lista_contactos.map((value, index) => (
											<tr key={index}>
												<td>{value.nombre ? value.nombre : 'Sin registrar'}</td>
												{/*<td>{value.rfc}</td>*/}
												<td>{value.telefono ? value.telefono : 'Sin registrar'}</td>
												<td>{value.celular ? value.celular : 'Sin registrar'}</td>
												<td>{value.correo_electronico ? value.correo_electronico : 'Sin registrar'}</td>
												<td>{value.direccion ? value.direccion : 'Sin registrar'}</td>
												
												{!this.is_view() ? (
													<td align={'right'}>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Eliminar"
															children={(
																<Fab
																	size="small"
																	aria-label="Eliminar"
																	onClick={() => this.delete(value, index)}
																	style={{
																		marginLeft: "10px",
																		backgroundColor: "transparent",
																		color: "#808080",
																		boxShadow: "none",
																	}}
																	children={(
																		<DeleteOutlined/>
																	)}
																/>
															)}
														/>
														{this.state.key === index ? (
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Producto en edición"
																children={(
																	<Fab
																		size="small"
																		aria-label="Editar"
																		onClick={() => this.edit_contacto(value, index)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "#f50057",
																			color: "white",
																		}}
																		children={(
																			<EditOutlined/>
																		)}
																	/>
																)}
															/>
														) : (
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Editr producto"
																children={(
																	<Fab
																		size="small"
																		aria-label="Editar"
																		onClick={() => this.edit_contacto(value, index)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "transparent",
																			color: "#808080",
																			boxShadow: "none",
																		}}
																		children={(
																			<EditOutlined/>
																		)}
																	/>
																)}
															/>
														)}
													</td>
												) : null}
											</tr>
										)
									)}
									{!(this.state.lista_contactos.length > 0) ? (
										<tr>
											<td colSpan={!this.is_view() ? (is_root ? 13 : 6) : (is_root ? 12 : 5)}
											    align={'center'}>
												<VistaVacia
													numero={null}
													mensaje={'Ningún contacto agregado.'}
													image={carrito_de_compra_3}
													padding={'10px'}
													paddingText={'10px'}
													height={'60px'}
													width={'60px'}
												/>
											</td>
										</tr>
									) : null}
									
									</tbody>
								</table>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<label>
									<TituloHeaderModal
										tipo={this.state.tipo}
										titulo={'Cuenta para pago'}
									/>
								</label>
							</Grid>
							
							
							{!this.is_view() ? (
								<Fragment>
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="cuenta_titular"
												           onChange={this.handleChange}
												           label="Titular de la cuenta" autoComplete={'off'}
												           value={this.state.cuenta_titular}
												           required
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									{/*<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HowToVoteOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="cuenta_banco"
												           onChange={this.handleChange}
												           label="Banco" autoComplete={'off'}
												           value={this.state.cuenta_banco}
												           required
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>*/}
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AccountBalance className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth required>
													<InputLabel>Banco</InputLabel>
													<Select
														native
														value={this.state.cuenta_id_cat_banco}
														onChange={(e) => {
															this.handleChange(e);
														}}
														name="cuenta_id_cat_banco"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_banco.map(option => (
															<option key={option.id_cat_banco}
															        value={option.id_cat_banco}>
																{option.banco}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HowToVoteOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="cuenta_sucursal"
												           onChange={this.handleChange}
												           label="Sucursal" autoComplete={'off'}
												           value={this.state.cuenta_sucursal}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrWeakOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="cuenta"
												           onChange={this.handleChange}
													//inputProps={{maxLength: 10}}
													        onKeyPress={EnteroSolo}
													        label="Cuenta" autoComplete={'off'}
													        value={this.state.cuenta}
													        disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrWeakOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="cuenta_clabe"
												           onChange={this.handleChange}
												           inputProps={{maxLength: 18}}
												           onKeyPress={EnteroSolo}
												           required
												           label="Clabe" autoComplete={'off'}
												           value={this.state.cuenta_clabe}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<StreetviewOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="cuenta_nombre"
												           onChange={this.handleChange}
												           label="Nombre de la cuenta" autoComplete={'off'}
												           value={this.state.cuenta_nombre}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<StreetviewOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="cuenta_numero_referencia"
												           onChange={this.handleChange}
												           label="Número de referencia" autoComplete={'off'}
												           value={this.state.cuenta_numero_referencia}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									
									<Grid item xs={12} sm={3} md={3} lg={3} xl={3} align={"right"}>
										<Tooltip
											TransitionComponent={Zoom}
											placement={"top"}
											title="Cancelar edición"
											children={(
												<span>
											<Fab size="small" color="secondary"
											     aria-label="Cancelar edición"
											     onClick={() => this.edit_cancel_cuenta()}
											     style={{marginLeft: "15px"}}
											     disabled={!this.is_edited_product_cuenta()}>
												<CancelOutlined/>
											</Fab>
										</span>
											)}
										/>
										<Tooltip
											TransitionComponent={Zoom}
											placement={"top"}
											title={this.is_edited_product_cuenta() ? "Actualizar" : "Agregar"}
											children={(
												<Fab size="small" color="secondary"
												     aria-label={this.is_edited_product_cuenta() ? "Actualizar" : "Agregar"}
												     onClick={() => this.add_cuenta()}
												     style={{marginLeft: "15px"}}>
													{this.is_edited_product_cuenta() ? (
														<SaveOutlined/>
													) : (
														<AddOutlined/>
													)}
												</Fab>
											)}
										/>
									</Grid>
								
								</Fragment>
							) : ''}
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<table className={'desing-mark'}>
									<thead>
									<tr>
										<th>Titular de la Cta.</th>
										<th>Banco</th>
										<th>Sucursal</th>
										<th>Cuenta</th>
										<th>Clabe</th>
										<th>Nombre de la Cta.</th>
										<th>Núm. de referencia</th>
										{!this.is_view() ? (<th width={100} align={'center'}>Acciones</th>) : null}
									</tr>
									</thead>
									<tbody>
									{this.state.lista_cuentas.map((value, index) => (
											<tr key={index}>
												<td>{value.titular}</td>
												<td>{value.banco}</td>
												<td>{value.sucursal ? value.sucursal : 'Sin registrar'}</td>
												<td>{value.cuenta ? value.cuenta : 'Sin registrar'}</td>
												<td>{value.clabe ? value.clabe : 'Sin registrar'}</td>
												<td>{value.nombre ? value.nombre : 'Sin registrar'}</td>
												<td>{value.numero_referencia ? value.numero_referencia : 'Sin registrar'}</td>
												{!this.is_view() ? (
													<td align={'right'}>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Eliminar"
															children={(
																<Fab
																	size="small"
																	aria-label="Eliminar"
																	onClick={() => this.delete_cuenta(value, index)}
																	style={{
																		marginLeft: "10px",
																		backgroundColor: "transparent",
																		color: "#808080",
																		boxShadow: "none",
																	}}
																	children={(
																		<DeleteOutlined/>
																	)}
																/>
															)}
														/>
														{this.state.key === index ? (
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Producto en edición"
																children={(
																	<Fab
																		size="small"
																		aria-label="Editar"
																		onClick={() => this.edit_cuenta(value, index)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "#f50057",
																			color: "white",
																		}}
																		children={(
																			<EditOutlined/>
																		)}
																	/>
																)}
															/>
														) : (
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Editr producto"
																children={(
																	<Fab
																		size="small"
																		aria-label="Editar"
																		onClick={() => this.edit_cuenta(value, index)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "transparent",
																			color: "#808080",
																			boxShadow: "none",
																		}}
																		children={(
																			<EditOutlined/>
																		)}
																	/>
																)}
															/>
														)}
													</td>
												) : null}
											</tr>
										)
									)}
									{!(this.state.lista_cuentas.length > 0) ? (
										<tr>
											<td colSpan={!this.is_view() ? 8 : 7}
											    align={'center'}>
												<VistaVacia
													numero={null}
													mensaje={'Ninguna cuenta para pago agregada.'}
													image={carrito_de_compra_3}
													padding={'10px'}
													paddingText={'10px'}
													height={'60px'}
													width={'60px'}
												/>
											</td>
										</tr>
									) : null}
									</tbody>
								</table>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<BotonAccionFooterModal
							id={this.state.id_adquisicion}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalAdquisiciones.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalAdquisiciones;
