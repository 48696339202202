import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FieldsJs} from "../../../../settings/General/General";
import TextField from "@material-ui/core/TextField";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {Send} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import icon_email from "../../../../assets/img/icons/email.svg";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {FacturacionService} from "../../../../services/FacturacionService/FacturacionService";

class ModalSendMail extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			
			tipo: props.tipo,
			item: props.item,
			id_facturacion: (props.item || {}).id_facturacion,
			id_facturacion_archivo: (props.item || {}).id_facturacion_archivo,
			id_usuario_email: '',
			check_email: true,
			check_rocket_chat: false,
			text_email: '',
			solicitante: '',
			correo_electronico: '',
			correo_electronico_copia: '',
			text_email_solicitante: '',
			otro_usuario: false,
			
		};
	}
	
	
	openModal = () => {
		const {item} = this.props;
		console.log("ITEM:::", item);
		
		this.setState({
			open: true,
			scroll: 'paper',
			item: item,
			id_usuario_email: '',
			correo_electronico: '',
			correo_electronico_copia: '',
			check_email: true,
			check_rocket_chat: false,
			otro_usuario: false,
			text_email: '',
			text_email_solicitante: '',
			id_facturacion: item.id_facturacion || '',
			id_facturacion_archivo: item.id_facturacion_archivo || '',
		});
	};
	
	closeModal = () => {
		this.setState({open: false});
	};
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	close = () => {
		this.closeModal();
	};
	
	save = () => {
		FacturacionService.SendMail(this.state).then((response) => {
			this.close();
			this.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	saveSolicitante = () => {
		/*TicketsService.SendMail(this.state, true).then((response) => {
			this.close();
			this.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});*/
	};
	
	render() {
		
		return (
			<span>
				
				{this.props.component ? (
					<Fragment>
						<span onClick={() => this.openModal('paper')} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
					</Fragment>
				) : (
					<Fragment>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={() => this.openModal('paper')}>
							<Send className={'margin-5-R px-14'}/>
							Enviar Correo Electrónico
						</Fab>
					</Fragment>
				)}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.closeModal}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal} className={'sendMessage-ticket'}
				>
					
					<DialogTitle style={{color: 'gray'}}>Enviar Correo Electrónico</DialogTitle>
					
					<DialogContent>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={2} className={'h-100'}>
									
									
									
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'text-center px-13'} style={{fontWeight: 'bold'}}>
											Seleccionar el usuario a quien va dirigido el correo electrónico
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginBottom: 20}}>
										{/*<fieldset className={'borde-punteado-lightgray-2 padding-20 padding-10-B'}>*/}
										{/*<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Mensaje</legend>*/}
											<Grid container spacing={2}>
												<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
												<div className={'borde-punteado-lightgray-1 card-0 text-center margin-10-R'} onClick={() => {
													this.setState({
														check_email: !this.state.check_email
													})
												}}>
													<div className={'w-100 text-center padding-10-B padding-10-T'}>
														<span style={{cursor: 'pointer', fontSize: 14}}>
															Correo Electrónico
														</span>
													</div>
													<div className={'w-100 text-center check-notificacion'}>
														<div className={'w-100 margin-10-B'}>
															<img className={'v-center'} src={icon_email} alt="add" width={80} height={80} style={{opacity: 0.7}}/>
														</div>
													</div>
												</div>
											</Grid>
												
												<Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
													<Grid container spacing={2}>
														
													   {/*<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
															<TextField
																select
																fullWidth
																required
																margin="none"
																onChange={this.handleChange}
																disabled={this.is_view()}
																SelectProps={{
																	native: true,
																	MenuProps: {
																		className: '',
																	},
																}}
																name="id_usuario_email"
																label="Usuario"
																value={this.state.id_usuario_email}
															>
																<option key={0} value={''}>&nbsp;</option>
																{this.props.listar_usuarios.map(option => (
																	<option key={option.id_usuario} value={option.id_usuario}>
																		{option.nombre_completo} {option.correo_electronico ? ' - ' + option.correo_electronico : ''}
																	</option>
																))}
																			</TextField>
														</Grid>*/}
														<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
																<TextField
																	multiline
																	required
																	fullWidth
																	variant={"outlined"}
																	name={"correo_electronico"}
																	onChange={this.handleChange}
																	type="text"
																	margin="none"
																	helperText=""
																	label="Correo Electrónico"
																	value={this.state.correo_electronico}
																/>
															</Grid>
														
														<Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
														<FormGroup row>
															<FormControlLabel
																control={
																	<Checkbox
																		type="checkbox"
																		name='otro_usuario'
																		checked={this.state.otro_usuario}
																		onChange={this.handleChange}
																		value="otro_usuario"
																		color="primary"
																		disabled={this.props.tipo === 'view'}
																	/>
																}
																label={'CC'}
															/>
														</FormGroup>
													</Grid>
														
														{this.state.otro_usuario ? (
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<TextField
																	multiline
																	required
																	fullWidth
																	variant={"outlined"}
																	name={"correo_electronico_copia"}
																	onChange={this.handleChange}
																	type="text"
																	margin="none"
																	helperText=""
																	label="Correo Electrónico CC"
																	value={this.state.correo_electronico_copia}
																/>
															</Grid>
														) : null
														}
														
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<TextField
														multiline
														required
														fullWidth
														variant={"outlined"}
														name={"text_email"}
														onChange={this.handleChange}
														type="text"
														margin="none"
														helperText=""
														label="Mensaje"
														value={this.state.text_email}
													/>
												</Grid>
													
													</Grid>
												</Grid>
												
											</Grid>
										{/*</fieldset>*/}
									</Grid>
									
								</Grid>
							</Grid>
						</Grid>

					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={0}>
							<Grid item xs={12} align={'right'}>
								<Button onClick={() => this.close()} color="primary">
									{!this.is_view() ? "Cancelar" : "Cerrar"}
								</Button>
								<Button onClick={() => this.save()} color="primary">
									{'Enviar'}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			
			</span>
		);
	}
}

ModalSendMail.propTypes = {
	tipo: PropTypes.oneOf([
		"add",
		"view",
		"edit",
	]),
	item: PropTypes.object.isRequired,
	listar_usuarios: PropTypes.array.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	component: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
};

export default ModalSendMail;
