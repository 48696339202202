import React, {Component, Fragment} from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer/index';
import Button from '@material-ui/core/Button/index';
import List from '@material-ui/core/List/index';
import Divider from '@material-ui/core/Divider/index';
import ListItem from '@material-ui/core/ListItem/index';
import ListItemIcon from '@material-ui/core/ListItemIcon/index';
import ListItemText from '@material-ui/core/ListItemText/index';
import Menu from '@material-ui/icons/Menu';
import {withRouter} from "react-router-dom";

import {CONFIG} from '../../../settings/Config/Config';
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Icon from "@material-ui/core/Icon";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import {PersonOutlined, Inbox, StarBorder, ExpandLess, ExpandMore} from '@material-ui/icons';
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import {FieldsJs} from "../../../settings/General/General";

import './Menu.css';

/*
import Collapse from "@material-ui/core/Collapse";

import {ExpandLess, ExpandMore, Inbox, StarBorder} from '@material-ui/icons';
*/

class DrawerMenu extends Component {
	
	Usr = {};
	menu = [];
	
	constructor() {
		super();
		
		this.state = {
			top: false,
			left: false,
			bottom: false,
			right: false,
			
			Usr: (ReactLocalStorageService.get('Usr') || {}),
			
			open_collapse: false,
			openindex: 0,
		};
		
		this.toggleDrawer = this.toggleDrawer.bind(this);
		this.renderSwitchIcon = this.renderSwitchIcon.bind(this);
		this.sideList = this.sideList.bind(this);
		this.go = this.go.bind(this);
	}
	
	toggleDrawer = (side, open) => () => {
		this.setState({...this.state, [side]: open});
	};
	
	renderSwitchIcon = (icono) => {
		return <Icon className={'px-22'}>{icono}</Icon>;
	};
	
	checkExiste = (item) => {
		let band = 0;
		for (let i = 0; i < this.state.Usr.menu.length; i++) {
			if (item.menu_pertenece === this.state.Usr.menu[i].menu_pertenece) {
				band++;
			}
		}
		if (band > 1) {
			return false;
		} else {
			return true;
		}
	};
	
	sideList = (tipo) => {
		
		let Usr = ReactLocalStorageService.get('Usr') || {};
		let menu = this.state.Usr.menu || [];
		console.log(menu);
		let foto = '';
		
		if (Usr.foto) {
			foto = CONFIG.src + Usr.foto;
		} else {
			foto = CONFIG.foto_default;
		}
		
		const bg_foto = {
			backgroundColor: `white`,
			backgroundImage: `url(${foto})`,
		};
		
		let MENUS = (
			<div className={(tipo === 1 ? 'mat-list w-60-px' : 'w-300-px')}>
				{tipo === 1 ? (
					<Fragment>
						<div className={'padding-10-B padding-10-T'}>
							<div className={'w-50-px h-50-px card-1 b-r-100'}>
								<div className={'w-50-px h-50-px b-r-100 bg-img-contain-x-center-y-center'}
								     style={bg_foto}>
								</div>
							</div>
						</div>
					</Fragment>
				) : (
					<Fragment>
						<div style={{height: 0, width: '100%', top: '10px', position: 'relative', right: '10px'}} align={'right'}>
							<Tooltip TransitionComponent={Zoom} placement={"right"} title={'Ver perfil'}>
								<IconButton aria-label="Ver perfil" onClick={() => this.go('perfil')}>
									<PersonOutlined style={{color: 'white'}}/>
								</IconButton>
							</Tooltip>
						</div>
						<div className={'w-auto h-auto padding-15'}>
							<div className={'row-flex'}>
								<div className={'w-100'} align={'center'}>
									<div className={'w-110-px h-110-px card-1 b-r-100'}>
										<div className={'w-110-px h-110-px b-r-100 bg-img-contain-x-center-y-center'}
										     style={bg_foto}>
										</div>
									</div>
								</div>
							</div>
							<div className={'row-flex'}>
								<div className={'w-100'}>
									<h2 className={'text-white px-16 text-center margin-14-T margin-7-B'}>
										{Usr.nombre_completo || CONFIG.titulo_alert_confirm}
									</h2>
									{Usr.username ? (
										<h2 className={'text-white px-14 text-center margin-7-T margin-7-B'}>
											{Usr.username}
										</h2>
									) : null}
									{Usr.tipo_usuario ? (
										<h3 className={'text-white px-14 text-center margin-7-T margin-0-B'}>
											{Usr.tipo_usuario}
										</h3>
									) : null}
								</div>
							</div>
						</div>
					</Fragment>
				)}
				
				<Divider className={(tipo === 1 ? 'margin-10-T' : '')}/>
				<div style={{marginBottom: 50}}>
					{(menu || []).length > 0 ? (
						<List>
							{/*
						<ListItem button onClick={() => this.setState({open: !this.state.open})}>
							<ListItemIcon>
								<Inbox />
							</ListItemIcon>
							<ListItemText primary="Inbox" />
							{this.state.open ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={this.state.open} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								<ListItem button>
									<ListItemIcon>
										<StarBorder />
									</ListItemIcon>
									<ListItemText primary="Starred" />
								</ListItem>
							</List>
						</Collapse>
						*/}
							{menu && menu.map((item, index) => (
								<Fragment key={index}>
									{item.menu_mostrar || !item.menu_mostrar && (FieldsJs.Field(item.id_acceso_menu_especifico) && item.id_acceso_menu_especifico > 0) && this.checkExiste(item) ? (
										<Fragment>
											
											<Fragment>
												<Tooltip key={index} TransitionComponent={Zoom} placement={"right"}
												         title={tipo === 1 ? FieldsJs.Field(item.id_cat_menu_pertenece) && item.id_cat_menu_pertenece > 0 ? item.menu_pertenece : item.sub_menu.sub_menu : ''}>
													<ListItem button onClick={() => this.go(FieldsJs.Field(item.id_cat_menu_pertenece) ? null : item.sub_menu.ruta, item.id_cat_menu_pertenece, index)} style={{
														justifyContent: tipo === 1 ? "center" : "",
														paddingTop: '6px',
														paddingBottom: '6px',
														color: 'white'
													}}>
														{tipo === 1 ? (
															<Fragment>
																<ListItemIcon style={{minWidth: '22px', color: 'white'}}>
																	{this.renderSwitchIcon(FieldsJs.Field(item.id_cat_menu_pertenece) ? item.icono : item.sub_menu.icono)}
																</ListItemIcon>
															</Fragment>
														) : (
															<Fragment>
																<ListItemIcon style={{color: 'white'}}>
																	{this.renderSwitchIcon(FieldsJs.Field(item.id_cat_menu_pertenece) ? item.icono : item.sub_menu.icono)}
																</ListItemIcon>
																<ListItemText primary={FieldsJs.Field(item.id_cat_menu_pertenece) ? item.menu_pertenece : item.sub_menu.sub_menu}/>
																{FieldsJs.Field(item.id_cat_menu_pertenece) && item.id_cat_menu_pertenece > 0 ? <Fragment>
																	{this.state.open_collapse && index === this.state.openindex ? <ExpandLess/> : <ExpandMore/>}
																</Fragment> : null}
															</Fragment>
														)}
													</ListItem>
												</Tooltip>
												{this.state.open_collapse && index === this.state.openindex ? (
													<Collapse in={this.state.open_collapse} timeout="auto" unmountOnExit>
														<Fragment>
															{menu.map((item2, index2) => (
																<Fragment key={index2}>
																	{item2.sub_menu.ruta === "recordatorios" && Number(item2.id_cat_menu_pertenece) === Number(item.id_cat_menu_pertenece) ? (
																		<Fragment>
																			{this.state.Usr.isjefe ? (
																				<Fragment>
																					<Tooltip key={index} TransitionComponent={Zoom} placement={"right"}
																					         title={tipo === 1 ? item2.sub_menu.sub_menu : ''}>
																						<ListItem button onClick={() => this.go(item2.sub_menu.ruta, item2.id_cat_menu_pertenece, index)} style={{
																							justifyContent: tipo === 1 ? "center" : "",
																							paddingTop: '6px',
																							paddingBottom: '6px',
																							color: 'white'
																						}}>
																							{tipo === 1 ? (
																								<ListItemIcon style={{minWidth: '22px', color: 'Cornsilk', marginLeft: 15}}>
																									{this.renderSwitchIcon(item2.sub_menu.icono)}
																								</ListItemIcon>
																							) : (
																								<Fragment>
																									<ListItemIcon style={{color: 'Cornsilk', marginLeft: 16}}>
																										{this.renderSwitchIcon(item2.sub_menu.icono)}
																									</ListItemIcon>
																									<ListItemText primary={item2.sub_menu.sub_menu}/>
																								</Fragment>
																							)}
																						</ListItem>
																					</Tooltip>
																				</Fragment>
																			) : null}
																		</Fragment>
																	) : (
																		<Fragment>
																			{Number(item2.id_cat_menu_pertenece) === Number(item.id_cat_menu_pertenece) ? (
																				<Tooltip key={index} TransitionComponent={Zoom} placement={"right"}
																				         title={tipo === 1 ? item2.sub_menu.sub_menu : ''}>
																					<ListItem button onClick={() => this.go(item2.sub_menu.ruta, item2.id_cat_menu_pertenece, index)} style={{
																						justifyContent: tipo === 1 ? "center" : "",
																						paddingTop: '6px',
																						paddingBottom: '6px',
																						color: 'white'
																					}}>
																						{tipo === 1 ? (
																							<ListItemIcon style={{minWidth: '22px', color: 'Cornsilk', marginLeft: 15}}>
																								{this.renderSwitchIcon(item2.sub_menu.icono)}
																							</ListItemIcon>
																						) : (
																							<Fragment>
																								<ListItemIcon style={{color: 'Cornsilk', marginLeft: 16}}>
																									{this.renderSwitchIcon(item2.sub_menu.icono)}
																								</ListItemIcon>
																								<ListItemText primary={item2.sub_menu.sub_menu}/>
																							</Fragment>
																						)}
																					</ListItem>
																				</Tooltip>
																			) : null}
																		</Fragment>
																	)
																	}
																</Fragment>
															))}
														</Fragment>
													</Collapse>
												) : null}
											</Fragment>
										
										</Fragment>
									) : null}
								</Fragment>
							))}
						</List>
					) : (
						<List>
							<Tooltip TransitionComponent={Zoom} placement={"right"}
							         title={tipo === 1 ? 'Iniciar sesión' : ''}>
								<ListItem button onClick={() => this.go('login')} style={{
									justifyContent: tipo === 1 ? "center" : "",
									paddingTop: '6px',
									paddingBottom: '6px',
									color: 'white'
								}}>
									{tipo === 1 ? (
										<Fragment>
											<ListItemIcon style={{minWidth: '22px', color: 'white'}}>
												{this.renderSwitchIcon('input')}
											</ListItemIcon>
										</Fragment>
									) : (
										<Fragment>
											<ListItemIcon style={{color: 'white'}}>
												{this.renderSwitchIcon('input')}
											</ListItemIcon>
											<ListItemText primary={'Iniciar sesión'}/>
										</Fragment>
									)}
								</ListItem>
							</Tooltip>
						</List>
					)}
				</div>
			</div>
		);
		return MENUS;
	}
	;
	
	go = (ruta, id_cat_menu_pertenece, index) => {
		if (FieldsJs.Field(id_cat_menu_pertenece)) {
			if (this.state.open_collapse && FieldsJs.Field(ruta)) {
				this.props.history.push('/' + ruta);
			}
			
			if (index === this.state.openindex) {
				this.setState({
					open_collapse: !this.state.open_collapse,
					id_cat_menu_pertenece: id_cat_menu_pertenece
				})
			} else {
				this.setState({
					open_collapse: true,
					openindex: index,
					id_cat_menu_pertenece: id_cat_menu_pertenece
				})
			}
		} else {
			this.props.history.push('/' + ruta);
		}
	};
	
	render() {
		
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		return (
			<div>
				
				<Button onClick={this.toggleDrawer('left', true)} className={'min-w-24-px'}>
					<Menu className={'color-icon-menu'}/>
				</Button>
				
				{(Cfg.tipo_menu > 0 ? Cfg.tipo_menu : CONFIG.menu) === 2 ? (
					<div className={'Menu-Shadow bg_fondo_menu'} style={{
						position: "fixed",
						left: "0px",
						top: "60px",
						width: "70px",
						height: "calc(100vh - 60px)",
						backgroundColor: "ghostwhite",
					}}>
						{this.sideList(1)}
					</div>
				) : ''}
				
				<SwipeableDrawer open={this.state.left} onClose={this.toggleDrawer('left', false)} onOpen={this.toggleDrawer('left', true)}>
					
					<div className={'bg_fondo_menu'} tabIndex={0} role="button" onKeyDown={this.toggleDrawer('left', false)}>
						{this.sideList()}
					</div>
				
				</SwipeableDrawer>
			
			
			</div>
		);
	}
}

export default withRouter(DrawerMenu);
