import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const PlazaDireccionService = {
	Listar: (id_plaza) => {
		let params = {id_plaza: id_plaza};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Plaza_Direccion_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_plaza: form.id_plaza,
			id_cat_estado: form.id_cat_estado,
			id_cat_municipio: form.id_cat_municipio,
			nombre_plaza: form.nombre_plaza,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			referencia: form.referencia,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Plaza_Direccion_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_plaza_direccion: form.id_plaza_direccion,
			id_plaza: form.id_plaza,
			id_cat_estado: form.id_cat_estado,
			id_cat_municipio: form.id_cat_municipio,
			nombre_plaza: form.nombre_plaza,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			referencia: form.referencia,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Plaza_Direccion_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_plaza_direccion) => {
		let params = {
			id_plaza_direccion: id_plaza_direccion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Plaza_Direccion_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
