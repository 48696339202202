import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {AccountTree, DateRangeOutlined, HdrStrong, MailOutline, PersonOutlined, SearchOutlined, ViewDay} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {CatService as TipoUsuarioServiceService, CatService} from "../../../../../services/_Cat/CatService/CatService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {ModuloService} from "../../../../../services/_Cat/ModuloService/ModuloService";
import {ReactLocalStorageService} from "../../../../../settings/ReactLocalStorageService/ReactLocalStorageService";


class FiltrosModulo extends Component {
	
	Usr = {};
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			id_cat_proyecto: '',
			id_cat_proyecto_modulo: '',
			activo: '',
			
			lista_proyectos: [],
			lista_proyectos_modulos: [],
			
		};
		
		this.ListProyectos();
	}
	
	
	ListProyectos = () => {
		return new Promise((resolve, reject) => {
			CatService.ListProyecto().then((response) => {
				this.setState({
					lista_proyectos: response.data,
					id_cat_proyecto_modulo: ''
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_proyectos: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListProyectoModulos = (id_cat_proyecto) => {
		return new Promise((resolve, reject) => {
			CatService.ListProyectoModulo(id_cat_proyecto).then((response) => {
				this.setState({
					lista_proyectos_modulos: response.data
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_proyectos_modulos: []});
			});
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_cat_proyecto = this.state.id_cat_proyecto || undefined;
		filtro.id_cat_proyecto_modulo = this.state.id_cat_proyecto_modulo || undefined;
		filtro.activo = this.state.activo || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth margin="dense" className={'margin-0'}>
									<InputLabel>Proyecto</InputLabel>
									<Select
										native
										value={this.state.id_cat_proyecto}
										onChange={(e) => {
											this.handleChange(e);
											this.ListProyectoModulos(e.target.value);
										}}
										name="id_cat_proyecto"
										input={<Input/>}
									>
										<option value=""/>
										{this.state.lista_proyectos.map(option => (
											<option key={option.id_cat_proyecto}
											        value={option.id_cat_proyecto}>
												{option.proyecto}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth margin="dense" className={'margin-0'}>
									<InputLabel>Módulo</InputLabel>
									<Select
										native
										value={this.state.id_cat_proyecto_modulo}
										onChange={(e) => {
											this.handleChange(e);
										}}
										name="id_cat_proyecto_modulo"
										input={<Input/>}
										disabled={ !this.state.id_cat_proyecto}
									>
										<option value=""/>
										{this.state.lista_proyectos_modulos.map(option => (
											<option key={option.id_cat_proyecto_modulo}
											        value={option.id_cat_proyecto_modulo}>
												{option.proyecto_modulo}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel htmlFor="age-native-activo">Activo</InputLabel>
									<Select
										native
										value={this.state.activo}
										onChange={this.handleChange}
										inputProps={{
											name: 'activo',
											id: 'age-native-activo',
										}}
									>
										<option value={'all'}>Todos</option>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'left'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosModulo.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosModulo;
