import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const TipoPersonaService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Persona_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cat_tipo_persona: null,
			tipo_persona: form.tipo_persona,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Persona_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_tipo_persona: form.id_cat_tipo_persona,
			tipo_persona: form.tipo_persona,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Persona_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_tipo_persona) => {
		let params = {
			id_cat_tipo_persona: id_cat_tipo_persona
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Persona_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
