import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {FacturacionService} from "../../../../services/FacturacionService/FacturacionService";
import Button from "@material-ui/core/Button";
import {HdrWeakOutlined} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";


class ModalFacturacionCancelar extends Component {
	
	Usr = {};
	state = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo,
			id_facturacion: (props.item || '').id_facturacion,
			id_facturacion_archivo: (props.item || '').id_facturacion_archivo,
			id_facturacion_cfdi: (props.item || '').id_facturacion_cfdi,
			id_facturacion_cfdi_parcial: (props.item || '').id_facturacion_cfdi_parcial,
			cancelaciones: (props.item || '').cancelacion,
			otro_motivo: false,
			motivo: '',
			observacion: '',
			autorizar: null,
			
			cat_facturacion_motivo_cancelacion: []
		};
	}
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		
		let {item} = this.props;
		
		try {
			if (item.timbrados.length > 0) {
				let bandVigente = false;
				for (let i = 0; i < item.timbrados.length; i++) {
					if (Number(item.timbrados[i].id_cat_facturacion_status_sat) === 1) {
						bandVigente = true;
					}
				}
				if (!bandVigente) {
					this.init().then(r => {
						this.ListMotivos();
						setTimeout(() => this.openModal());
					}).catch(e => {
						console.log(e);
					});
				} else {
					throw Object({
						status: false, mensaje: 'No se puede cancelar la solicitud debido a que hay una factura vigente.'
					})
				}
			} else {
				this.init().then(r => {
					this.ListMotivos();
					setTimeout(() => this.openModal());
				}).catch(e => {
					console.log(e);
				});
			}
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	ListMotivos = () => {
		CatService.ListFacturacionMotivoCancelacion().then((response) => {
			this.setState({
				cat_facturacion_motivo_cancelacion: response.data
			});
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		console.log("THIS.STATE: ", this.state);
		
		try {
			
			FacturacionService.Cancelacion(this.state).then((response) => {
				this.closeModal();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	add = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: true,
			id_facturacion: item.id_facturacion || '',
			id_facturacion_archivo: item.id_facturacion_archivo || '',
			id_cat_facturacion_motivo_cancelacion: '',
			cancelaciones: item.cancelacion || [],
			otro_motivo: false,
			motivo: '',
			autorizar: null,
			observacion: '',
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			//abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	autorizarBtn = (st) => {
		this.setState({
			autorizar: st
		});
	};
	
	render() {
		
		const filterOptions = createFilterOptions({
			matchFrom: 'start',
			stringify: option => option.rfc,
		});
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						{'Cancelar Factura'}
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							{!FieldsJs.inArray([4, 9], this.Usr.id_cat_tipo_usuario) ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'margin-15-B'} style={{color: 'gray', opacity: '0.8'}}>
											Agrega un motivo de cancelación para la factura con id: {this.state.id_facturacion}
										</Typography>
									</Grid>
									
									<Grid container spacing={1} alignItems={"flex-end"}>
										<Grid item className={'w-30-px'}>
											<HdrWeakOutlined className={'w-100 text-gray'}/>
										</Grid>
										<Grid item className={'w-100-30-px'}>
											<FormControl fullWidth>
												<InputLabel>Motivo</InputLabel>
												<Select
													native
													value={this.state.id_cat_facturacion_motivo_cancelacion}
													onChange={this.handleChange}
													name="id_cat_facturacion_motivo_cancelacion"
													input={<Input/>}
													disabled={this.props.tipo === 'view'}
												>
													<option value=""/>
													{this.state.cat_facturacion_motivo_cancelacion.map(option => (
														<option key={option.id_cat_facturacion_motivo_cancelacion}
														        value={option.id_cat_facturacion_motivo_cancelacion}>
															{option.facturacion_motivo_cancelacion}
														</option>
													))}
												
												</Select>
											</FormControl>
										</Grid>
									</Grid>
									
									<Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
										
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox type="checkbox" name='otro_motivo' checked={this.state.otro_motivo}
													          onChange={this.handleChange} value="otro_motivo" color="primary"
													          disabled={this.state.tipo === 'view'}/>
												}
												label={'Otro motivo'}
											/>
										</FormGroup>
									
									</Grid>
									
									{this.state.otro_motivo ? (
										<Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
											<TextField
												multiline
												required
												margin="none"
												name="motivo"
												label="Motivo"
												type="text"
												fullWidth
												value={this.state.motivo}
												onChange={this.handleChange}
												disabled={this.is_view()}
											/>
										</Grid>
									) : null}
								</Fragment>
							) : null}
							
							{FieldsJs.inArray([9], this.Usr.id_cat_tipo_usuario) ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'margin-0'} style={{color: 'gray', opacity: '0.8'}}>
											Autorizar la cancelación:
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<ButtonGroup aria-label="button group">
											<Button onClick={() => this.autorizarBtn(false)} variant={this.state.autorizar === false ? "contained":"outlined"} color="primary">
												{'No'}
											</Button>
											<Button onClick={() => this.autorizarBtn(true)} variant={this.state.autorizar ? "contained":"outlined"} color="primary">
												{'Si'}
											</Button>
										</ButtonGroup>
									</Grid>
								</Fragment>
							):null}
							
							{!FieldsJs.inArray([4], this.Usr.id_cat_tipo_usuario) ? (
								<Fragment>
									{FieldsJs.inArray([9], this.Usr.id_cat_tipo_usuario) ? (
									<Fragment>
										{this.state.autorizar === false ? (
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<TextField
													multiline
													rows={3}
													margin="none"
													name="observacion"
													label="Observación"
													type="text"
													fullWidth
													value={this.state.observacion}
													onChange={this.handleChange}
													disabled={this.is_view()}
												/>
											</Grid>
										):null}
									</Fragment>
									):(
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField
												multiline
												rows={3}
												margin="none"
												name="observacion"
												label="Observación"
												type="text"
												fullWidth
												value={this.state.observacion}
												onChange={this.handleChange}
												disabled={this.is_view()}
											/>
										</Grid>
									)}
								</Fragment>
								
							):null}
							
							{this.state.cancelaciones.length > 0 ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'margin-0'} style={{color: 'gray', opacity: '0.8'}}>
											Motivo de cancelación
										</Typography>
									</Grid>
									{this.state.cancelaciones.map((item, key) => {
										return (
											<Fragment key={key}>
												<div className={'margin-15-T'}>
													<div className={'w-100 vertical-inline'}>
														<div className={'v-center'}>
															<Typography className={'margin-0 px-14'} style={{ fontWeight: 'bold', opacity: '0.8'}}>
																{item.tipo_usuario} -
															</Typography>
														</div>
														<div className={'v-center padding-5-L'}>
															<Typography className={'margin-0 px-14'}>
																{item.observacion}
															</Typography>
														</div>
													</div>
													{item.id_cat_facturacion_motivo_cancelacion > 0 ? (
														<div className={'w-100 vertical-inline'}>
															<div className={'v-center'}>
																<Typography className={'margin-0 px-14'} style={{color: 'gray', opacity: '0.8'}}>
																	Motivo -
																</Typography>
															</div>
															<div className={'v-center padding-5-L'}>
																<Typography className={'margin-0 px-14'}>
																	{item.facturacion_motivo_cancelacion}
																</Typography>
															</div>
														</div>
													):null}
												</div>
											</Fragment>
										)
									})}
								</Fragment>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<br/>
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								{'Cerrar'}
							</Button>
							{!FieldsJs.inArray([4], this.Usr.id_cat_tipo_usuario) ? (
								<Button onClick={this.save} color="primary">
									{/*{FieldsJs.inArray([4, 9], this.Usr.id_cat_tipo_usuario) ? 'Autorizar' : 'Guardar'}*/}
									Guardar
								</Button>
							) : null}
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalFacturacionCancelar.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalFacturacionCancelar;
