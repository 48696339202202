import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const FactorRiesgoImssService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Factor_Riesgo_Imss_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			factor_riesgo_imss: form.factor_riesgo_imss,
			descripcion: form.descripcion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Factor_Riesgo_Imss_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_factor_riesgo_imss: form.id_cat_factor_riesgo_imss,
			factor_riesgo_imss: form.factor_riesgo_imss,
			descripcion: form.descripcion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Factor_Riesgo_Imss_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_factor_riesgo_imss) => {
		let params = {
			id_cat_factor_riesgo_imss: id_cat_factor_riesgo_imss
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Factor_Riesgo_Imss_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
