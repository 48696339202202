import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

class BancoSucursalService {
	static Listar = (id_cat_banco) => {
		let params = {
			id_cat_banco: id_cat_banco
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Sucursal_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Agregar = (form) => {
		let params = {
			id_cat_banco_sucursal: null,
			id_cat_banco: form.id_cat_banco,
			banco_sucursal: form.banco_sucursal,
			banco_numero_sucursal: form.banco_numero_sucursal,
			banco_direccion: form.banco_direccion,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Sucursal_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Modificar = (form) => {
		let params = {
			id_cat_banco_sucursal: form.id_cat_banco_sucursal,
			id_cat_banco: form.id_cat_banco,
			banco_sucursal: form.banco_sucursal,
			banco_numero_sucursal: form.banco_numero_sucursal,
			banco_direccion: form.banco_direccion,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Sucursal_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Eliminar = (id_cat_banco_sucursal, id_cat_banco) => {
		let params = {
			id_cat_banco_sucursal: id_cat_banco_sucursal,
			id_cat_banco: id_cat_banco
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Sucursal_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
}

export default BancoSucursalService;
