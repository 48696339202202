import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {CancelPresentation, Description, LocalAtm, Message} from "@material-ui/icons";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import ModalCancelarCFDINomina from "./ModalCancelarCFDINomina";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import ModalObservacionCFDINomina from "./ModalObservacionCFDINomina";
import SubListaTabla from "./SubListaTabla";
import {FieldsJs} from '../../../../settings/General/General';


class ListaTabla extends Component {
	
	Usr = {};
	
	RefreshList = this.props.RefreshList;
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	ver_root = () => {
		return this.Usr.id_usuario === 1;
	};
	
	render() {
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		
		const {lista} = this.props;
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'}> </TableCell>
							<TableCell component="th" align={'left'}>Plaza</TableCell>
							<TableCell component="th" align={'left'}>RFC Emisor</TableCell>
							<TableCell component="th" align={'left'}>Empresa Facturadora</TableCell>
							<TableCell component="th" align={'left'}>Cliente</TableCell>
							<TableCell component="th" align={'left'}>Serie</TableCell>
							<TableCell component="th" align={'left'}>Folio</TableCell>
							<TableCell component="th" align={'left'}>Estatus Sat</TableCell>
							<TableCell component="th" align={'left'}>Fecha Emisión</TableCell>
							<TableCell component="th" align={'left'}>Estatus Pago</TableCell>
							<TableCell component="th" align={'left'}>Fecha Pago</TableCell>
							<TableCell component="th" align={'left'}>SubTotal</TableCell>
							<TableCell component="th" align={'left'}>Iva</TableCell>
							<TableCell component="th" align={'left'}>Total Facturado</TableCell>
							<TableCell component="th" align={'left'}>Concepto de Facturación</TableCell>
							<TableCell component="th" align={'center'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<Fragment key={key}>
								<TableRow>
									<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
										{item.parcialidades.length > 0 ? (
											<div className={'w-100'} align={'center'}>
												<div style={!!item.expandir ? bg_menos : bg_mas}
												     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
												     onClick={() => this.props.SubTabla(item, key)}
												/>
											</div>
										) : '...'}
									</TableCell>
									<TableCell align={'left'}>{item.plaza}</TableCell>
									<TableCell align={'left'}>{item.rfc_emisor}</TableCell>
									<TableCell align={'left'}>{item.empresa_facturadora}</TableCell>
									<TableCell align={'left'}>{item.cliente}</TableCell>
									<TableCell align={'left'}>{item.serie}</TableCell>
									<TableCell align={'left'}>{item.folio}</TableCell>
									<TableCell align={'left'}>{item.facturacion_status_sat}</TableCell>
									<TableCell align={'left'}>{item.fecha ? DateFormat.getFechaText(item.fecha, '/') : 'Sin registrar'}</TableCell>
									<TableCell align={'left'}>{item.facturacion_status_pago}</TableCell>
									<TableCell align={'left'}>{item.fecha_pago ? DateFormat.getFechaText(item.fecha_pago, '/') : 'Sin registrar'}</TableCell>
									<TableCell align={'left'}>${FieldsJs.Currency(item.subtotal)}</TableCell>
									<TableCell align={'left'}>${FieldsJs.Currency(item.iva)}</TableCell>
									<TableCell align={'left'}>${FieldsJs.Currency(item.total)}</TableCell>
									<TableCell align={'left'}>{item.concepto}</TableCell>
									<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
										<div className={'w-auto vertical-inline'}>
											
											<ModalCancelarCFDINomina
												tipo={'add'}
												item={item}
												componente={
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Cancelar">
														<IconButton aria-label="Cancelar" onClick={() => {
														}}>
															<CancelPresentation style={{color: 'red'}}/>
														</IconButton>
													</Tooltip>
												}
												RefreshList={this.RefreshList}
												showSnackBars={this.showSnackBars}
											/>
											
											<ModalObservacionCFDINomina
												tipo={'add'}
												item={item}
												componente={
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Observación">
														<IconButton aria-label="Observación" onClick={() => {
														}}>
															<Message style={{color: item.observacion ? '#3f51b5' : 'rgba(0, 0, 0, 0.54)'}}/>
														</IconButton>
													</Tooltip>
												}
												RefreshList={this.RefreshList}
												showSnackBars={this.showSnackBars}
											/>
										</div>
									</TableCell>
								</TableRow>
								{(
									<Fragment>
										{!!item.expandir ? (
											<TableRow>
												{/*<TableCell align={'center'}
												           className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
												           colSpan={this.ver_root() ? 13 : 13}
												>*/}
												<TableCell align={'center'}
												           className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
												           colSpan={this.ver_root() ? 19 : 19}
												>
													
													<SubListaTabla
														tipo_vista={'add'}
														item={item}
														showSnackBars={this.props.showSnackBars}
														RefreshList={this.props.RefreshList}
													/>
												
												</TableCell>
											</TableRow>
										) : null}
									</Fragment>
								)}
							</Fragment>
						
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	listDropbox: PropTypes.array,
	showSnackBars: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
};

export default ListaTabla;
