import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import moment from "moment";
import IntegrationNotistack from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {AddOutlined, AttachMoney, LibraryAddCheck, CancelOutlined, DeleteOutlined, Description, EditOutlined, SaveOutlined} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import carrito_de_compra_3 from "../../../../assets/img/icons/facturacion.svg";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import {CONFIG} from "../../../../settings/Config/Config";
import {Dropbox} from "dropbox";
import {AdquisicionService} from "../../../../services/AdquisicionService/AdquisicionService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import Fab from "@material-ui/core/Fab";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import ModalConfirmacionPago from "./ModalConfirmacionPago";


class ModalPagos extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			tipo: props.tipo,
			
			id_pago_odontologia: (props.item || '').id_pago_odontologia,
			lista_pagos: (props.item || []).lista_pagos,
			item: (props.item || null),
			id_adquisicion: (props.item || []).id_adquisicion,
			lista_socios: (props.item || []).lista_socios,
			monto: (props.item || '').monto,
			total_pagos: (props.item || '').total_pagos,
			cuenta: (props.item || false).cuenta,
			fecha_pago: null,
			lista_cat_tipo_adquisicion_pago: [],
			id_cat_tipo_adquisicion_pago: [],
			importe: '',
			observacion: '',
			archivo: '',
			
			comprobante_base64: '',
			comprobante_base64Tipo: '',
			comprobante_archivo: '',
			comprobante_formato: '',
			file: '',
			
			activo: true,
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	
	save = () => {
		try {
			
			if (!this.state.importe) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Importe.'
				})
			}
			
			if (!this.state.fecha_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha pago.'
				})
			}
			
			if (!this.state.file && !FieldsJs.Field(this.state.comprobante_archivo)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Comprobante.'
				})
			}
			
			if (this.state.file.length > 0) {
				this.getBase64(this.state.file[0], true).then(response => {
					this.setState({
						archivo: response.path_display
					});
					
					console.log("DESPUES DE GUARDAR:::: ", this.state);
					AdquisicionService.PagoGuardar(this.state).then((response) => {
						this.props.showSnackBars('success', response.mensaje);
						this.props.RefreshList();
						this.modalClose();
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				});
			} else {
				AdquisicionService.PagoGuardar(this.state).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.edit_cancel();
					this.modalClose();
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
			
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	getBase64 = (e, dropzone) => {
		return new Promise((resolve) => {
			const accessToken = CONFIG.DROPBOX_TOKEN;
			let files;
			if (dropzone) {
				files = e;
			} else {
				console.log(e.target);
				files = e.target.files;
			}
			
			const dbx = new Dropbox({
				accessToken,
				fetch
			});
			
			console.log("DROPBOX: ", '/Estados de Cuenta/' + this.state.item.id_adquisicion + this.state.item.propietario + '/' + files.name);
			dbx.filesUpload({path: '/Estados de Cuenta/' + this.state.item.id_adquisicion + '-' + this.state.item.propietario + '/' + files.name, contents: files})
				.then(function (response) {
					console.log("DROPBOX UPLOAD: ", response);
					resolve(response);
				})
				.catch(function (error) {
					console.error(error);
				});
		});
	};
	
	listarCatTipoAdquisicionPago = () => {
		CatService.ListTipoAdquisicionPago().then((response) => {
			this.setState({
				lista_cat_tipo_adquisicion_pago: response.data
			});
		});
	}
	
	add = () => {
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_adquisicion: item.id_adquisicion || '',
			lista_socios: item.lista_socios || [],
			lista_pagos: item.lista_pagos || [],
			cuenta: item.cuenta || false,
			item: item || '',
			id_doctor: '',
			id_paciente: '',
			monto: item.monto,
			total_pagos: item.total_pagos,
			fecha_pago: null,
			id_cat_tipo_adquisicion_pago: '',
			importe: '',
			comprobante_base64: '',
			comprobante_base64Tipo: '',
			comprobante_archivo: '',
			comprobante_formato: '',
			file: '',
			archivo: '',
			observacion: '',
			activo: 1,
		});
		
		this.listarCatTipoAdquisicionPago();
		this.openModal();
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_admin = () => {
		return FieldsJs.inArray([2], this.Usr.id_cat_tipo_usuario);
	};
	
	is_view = () => {
		return this.state.tipo === 'view';
	};
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	edit_pago = (value, key) => {
		try {
			
			this.setState({
				key: key,
				id_adquisicion_pago: value.id_adquisicion_pago || null,
				id_cat_tipo_adquisicion_pago: value.id_cat_tipo_adquisicion_pago || null,
				importe: value.importe || null,
				fecha_pago: value.fecha ? moment(value.fecha) : null,
				comprobante_archivo: value.archivo || '',
				archivo: value.archivo || '',
				comprobante_formato: value.formato || '',
				observacion: value.observacion || ''
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_adquisicion_pago: '',
			id_cat_tipo_adquisicion_pago: '',
			importe: '',
			fecha_pago: null,
			comprobante_archivo: '',
			comprobante_formato: '',
			observacion: ''
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el pago?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_socios = FieldsJs.Copy(this.state.lista_socios);
					
					lista_socios.splice(key, 1);
					
					this.setState({
						lista_socios: lista_socios
					});
				}
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	ListarPagos = () => {
		AdquisicionService.ListarPagos(this.state).then((response) => {
			this.setState({
				lista_pagos: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_pagos: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	}
	
	solicitar_autorizar_edicion = (item) => {
		AdquisicionService.SolicitudEdicion(item).then((response) => {
			this.props.showSnackBars('success', response.mensaje);
			this.ListarPagos();
			this.props.RefreshList();
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	}
	
	render() {
		
		const classes = {};
		
		return (
			<div>
				
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Registro de Pagos">
					<IconButton aria-label="Registro de Pagos" onClick={() => this.add()}>
						<AttachMoney/>
					</IconButton>
				</Tooltip>
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>Registro de Pagos</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							{this.state.item ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={4}>
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={2}>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'borde-punteado-lightgray-2'}>
														<Grid container spacing={2}>
															<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
																<p className={'px-13 margin-0 text-left'}>Tipo adquisición:</p>
																<p className={'px-13 margin-0 text-left'}><strong>{this.state.item.tipo_adquisicion}</strong></p>
															</Grid>
															
															<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
																<p className={'px-13 margin-0 text-left'}>Propietario:</p>
																<p className={'px-13 margin-0 text-left'}><strong>{this.state.item.propietario}</strong></p>
															</Grid>
															
															<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
																<p className={'px-13 margin-0 text-left'}>Broker:</p>
																<p className={'px-13 margin-0 text-left'}><strong>{this.state.item.broker}</strong></p>
															</Grid>
															
															<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
																<p className={'px-13 margin-0 text-left'}>Precio:</p>
																<p className={'px-13 margin-0 text-left'}><strong>${FieldsJs.Currency(this.state.item.precio)}</strong></p>
															</Grid>
															
															<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
																<p className={'px-13 margin-0 text-left'}>Enganche:</p>
																<p className={'px-13 margin-0 text-left'}><strong>${FieldsJs.Currency(this.state.item.enganche)}</strong></p>
															</Grid>
															
															<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
																<p className={'px-13 margin-0 text-left'}>Número de pagos:</p>
																<p className={'px-13 margin-0 text-left'}><strong>{this.state.item.plazo}</strong></p>
															</Grid>
														
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											
											
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={2}>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'borde-punteado-lightgray-2'}>
														<Grid container spacing={2}>
															{this.state.cuenta ? (
																<Fragment>
																	<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
																		<p className={'px-13 margin-0 text-left'}>Socio:</p>
																		<p className={'px-13 margin-0 text-left'}><strong>{this.state.cuenta.nombre}</strong></p>
																	</Grid>
																	
																	<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
																		<p className={'px-13 margin-0 text-left'}>Participación:</p>
																		<p className={'px-13 margin-0 text-left'}><strong>{this.state.cuenta.participacion}%</strong></p>
																	</Grid>
																	
																	<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
																		<p className={'px-13 margin-0 text-left'}>Enganche:</p>
																		<p className={'px-13 margin-0 text-left'}><strong>${this.state.cuenta.enganche ? FieldsJs.Currency(this.state.item.enganche) : '0.00'}</strong></p>
																	</Grid>
																	
																	{/*<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
																		<p className={'px-13 margin-0 text-left'}>Aportacion:</p>
																		<p className={'px-13 margin-0 text-left'}><strong>${this.state.cuenta.aportacion ? this.state.cuenta.aportacion : '0.00'}</strong></p>
																	</Grid>*/}
																	
																	<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
																		<p className={'px-13 margin-0 text-left'}>Enganche individual:</p>
																		<p className={'px-13 margin-0 text-left'}><strong>${this.state.cuenta.enganche_individual ? FieldsJs.Currency(this.state.cuenta.enganche_individual) : '0.00'}</strong></p>
																	</Grid>
																	
																	<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
																		<p className={'px-13 margin-0 text-left'}>Aportación:</p>
																		<p className={'px-13 margin-0 text-left'}><strong>${FieldsJs.Currency(this.state.cuenta.aportacion)}</strong></p>
																	</Grid>
																</Fragment>
															) : (
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																	<VistaVacia
																		numero={null}
																		mensaje={'No se encontro tu usuario como socio.'}
																		image={carrito_de_compra_3}
																		padding={'10px'}
																		paddingText={'10px'}
																		height={'60px'}
																		width={'60px'}
																	/>
																</Grid>
															)}
														
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									
									{this.state.lista_socios.length > 0 && this.Usr.id_usuario === this.state.item.id_usuario ? (
										<Fragment>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<div className={'borde-punteado-gray-1'}>
													<Table className={classes.table + ' desing-acresco'}>
														<TableHead>
															<TableRow>
																<TableCell component="th" align={'left'}>Socio(s)</TableCell>
																<TableCell component="th" align={'left'}>Participación</TableCell>
																<TableCell component="th" align={'left'}>Total pago</TableCell>
																<TableCell component="th" align={'left'}>Saldo</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{this.state.lista_socios.length > 0 ? (
																<Fragment>
																	{this.state.lista_socios.map((item, key) => {
																		return (
																			<TableRow key={key}>
																				<TableCell align={'left'}>{item.nombre_completo}</TableCell>
																				<TableCell align={'left'}>{item.participacion}%</TableCell>
																				<TableCell align={'left'}>${FieldsJs.Currency(item.total_pagos)}</TableCell>
																				<TableCell align={'left'}>${FieldsJs.Currency(item.saldo)}</TableCell>
																			</TableRow>
																		)
																	})}
																</Fragment>
															) : (
																<TableRow>
																	<TableCell colSpan={5} align={'right'} style={{fontWeight: 'bold'}}>
																		<VistaVacia
																			numero={null}
																			mensaje={'Ningún pago agregado.'}
																			image={carrito_de_compra_3}
																			padding={'10px'}
																			paddingText={'10px'}
																			height={'60px'}
																			width={'60px'}
																		/>
																	
																	</TableCell>
																</TableRow>
															)}
														</TableBody>
													</Table>
												</div>
											</Grid>
										</Fragment>
									) : null}
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
									</Grid>
								</Fragment>
							) : null}
							
							{FieldsJs.Field(this.state.cuenta) ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'bg-lightwhite b-r-5 padding-10'}>
									<Grid container spacing={2}>
										
										<Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<Typography className={'px-14 margin-0'} style={{color: '#3e3e3e'}}>Agrega un nuevo pago: </Typography>
												</Grid>
												
												
												<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
													<FormControl required fullWidth>
														<InputLabel>Tipo pago</InputLabel>
														<Select
															native
															value={this.state.id_cat_tipo_adquisicion_pago}
															onChange={this.handleChange}
															name="id_cat_tipo_adquisicion_pago"
															input={<Input/>}
															disabled={this.props.tipo === 'view'}
														>
															<option value=""/>
															{this.state.lista_cat_tipo_adquisicion_pago.map(option => (
																<option key={option.id_cat_tipo_adquisicion_pago}
																        value={option.id_cat_tipo_adquisicion_pago}>
																	{option.tipo_adquisicion_pago}
																</option>
															))}
														</Select>
													</FormControl>
												</Grid>
												
												<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
													<TextField
														type={'text'}
														fullWidth
														required
														name="importe"
														onChange={this.handleChange}
														label="Importe"
														autoComplete={'off'}
														value={this.state.importe}
														onKeyPress={EnteroSolo}
														disabled={this.state.tipo === 'view'}
													/>
												</Grid>
												
												<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
													<KeyboardDatePicker
														fullWidth
														clearable
														format={'dd/MM/yyyy'}
														inputProps={{readOnly: true}}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														label="Fecha Pago"
														value={this.state.fecha_pago}
														onChange={(date) => {
															this.handleChange(null, null, null, date, 'fecha_pago');
														}}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														disabled={this.state.tipo === 'view'}
														required
													/>
												</Grid>
												
												{/*{this.state.id_cat_tipo_adquisicion_pago === 4 ? (
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField
												type={'text'}
												fullWidth
												rows={2}
												name="Tipo"
												onChange={this.handleChange}
												label="Observación"
												autoComplete={'off'}
												value={this.state.observacion}
												disabled={this.state.tipo === 'view'}
											/>
										</Grid>
									):null}*/}
												
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<TextField
														type={'text'}
														fullWidth
														rows={2}
														name="observacion"
														onChange={this.handleChange}
														label="Observación"
														autoComplete={'off'}
														required={Number(this.state.id_cat_tipo_adquisicion_pago) === 4}
														value={this.state.observacion}
														disabled={this.state.tipo === 'view'}
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
											<BoxSelectFile
												MegaByte={5}
												// button={(
												// 	<AddOutlined/>
												// )}
												label={(
													<b className={'vertical-inline v-center text-blue'}>Comprobante</b>
												)}
												style={{
													color: 'black',
													height: '100%'
												}}
												item={{
													base64: this.state.comprobante_base64,
													base64Tipo: this.state.comprobante_base64Tipo,
													archivo: this.state.comprobante_archivo,
													formato: this.state.comprobante_formato,
													file: this.state.file,
												}}
												onChange={(r) => {
													this.handleChange({
														target: {
															name: 'comprobante_base64',
															value: r.base64
														}
													});
													this.handleChange({
														target: {
															name: 'comprobante_base64Tipo',
															value: r.base64Tipo
														}
													});
													this.handleChange({
														target: {
															name: 'comprobante_archivo',
															value: r.archivo
														}
													});
													this.handleChange({
														target: {
															name: 'comprobante_formato',
															value: r.formato
														}
													});
													this.handleChange({
														target: {
															name: 'file',
															value: r.file
														}
													});
													
												}}
												showSnackBars={this.props.showSnackBars}
											/>
										</Grid>
									</Grid>
								</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100 text-center'}>
									<p className={'px-18'} style={{fontWeight: 'bold'}}>Saldo: <strong style={{color: 'red'}}>${this.state.item.saldo ? FieldsJs.Currency(this.state.item.saldo) : '0.00'}</strong></p>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'borde-punteado-gray-1'}>
									<Table className={classes.table + ' desing-pagos'}>
										<TableHead>
											<TableRow>
												<TableCell component="th" align={'left'}>N.P.</TableCell>
												<TableCell component="th" align={'left'}>Tipo pago</TableCell>
												<TableCell component="th" align={'left'}>Importe</TableCell>
												<TableCell component="th" align={'left'}>Fecha Pago</TableCell>
												<TableCell component="th" align={'left'}>Comprobante</TableCell>
												<TableCell component="th" align={'left'}>Estatus</TableCell>
												<TableCell component="th" align={'left'}>Observación</TableCell>
												<TableCell component="th" align={'center'}>Acciones</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.lista_pagos.length > 0 ? (
												<Fragment>
													{this.state.lista_pagos.map((item, key) => {
														return (
															<TableRow key={key}>
																<TableCell align={'left'} className={'px-12'}>{item.id_adquisicion_pago}</TableCell>
																<TableCell align={'left'} className={'px-12'}>{item.tipo_adquisicion_pago}</TableCell>
																<TableCell align={'left'} className={'px-12'}>${FieldsJs.Currency(item.importe)}</TableCell>
																<TableCell align={'left'} className={'px-12'}>{item.fecha}</TableCell>
																<TableCell align={'left'}>
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver comprobante">
																		<IconButton aria-label="Ver comprobante" onClick={() => this.exportar(item.archivo)}>
																			<Description style={{color: '#e18c02'}}/>
																		</IconButton>
																	</Tooltip>
																</TableCell>
																<TableCell align={'left'} className={'px-12'}>{item.estatus}</TableCell>
																<TableCell align={'left'} className={'px-12'}>{item.observacion ? item.observacion : 'Sin observación'}</TableCell>
																<TableCell align={'center'}>
																	<div className={'vertical-inline w-100'}>
																		{Number(this.Usr.id_usuario) === Number(item.id_usuario_adquisicion) ? (
																			<div className={'center'}>
																				<ModalConfirmacionPago
																					tipo={'add'}
																					item={item}
																					componente={
																						<Tooltip
																							TransitionComponent={Zoom}
																							placement={"top"}
																							title="Confirmar pago"
																							children={(
																								<Fab
																									size="small"
																									aria-label="Confirmar pago"
																									style={{
																										marginLeft: "10px",
																										backgroundColor: "#e18c02",
																										color: "white",
																									}}
																									children={(
																										<LibraryAddCheck/>
																									)}
																								/>
																							)}
																						/>
																					}
																					RefreshList={this.ListarPagos}
																					showSnackBars={this.props.showSnackBars}
																				/>
																			</div>
																		) : null}
																		
																		{Number(this.Usr.id_usuario) === Number(item.id_usuario_adquisicion) && Number(item.edicion) === 2 ? (
																			<div className={'center'}>
																				<Tooltip
																					TransitionComponent={Zoom}
																					placement={"top"}
																					title="Autorizar edición"
																					children={(
																						<Fab
																							size="small"
																							aria-label="Autorizar edición"
																							onClick={() => this.solicitar_autorizar_edicion(item)}
																							style={{
																								marginLeft: "10px",
																								backgroundColor: "#303f9f",
																								color: "white",
																							}}
																							children={(
																								<EditOutlined/>
																							)}
																						/>
																					)}
																				/>
																			</div>
																		) : null}
																		
																		{Number(item.edicion) === 1 || Number(this.Usr.id_usuario) === Number(item.id_usuario_adquisicion) ? (
																			<Fragment>
																				<div className={'center'}>
																					<Tooltip
																						TransitionComponent={Zoom}
																						placement={"top"}
																						title="Eliminar"
																						children={(
																							<Fab
																								size="small"
																								aria-label="Eliminar"
																								onClick={() => this.delete(item, key)}
																								style={{
																									marginLeft: "10px",
																									backgroundColor: "transparent",
																									color: "#808080",
																									boxShadow: "none",
																								}}
																								children={(
																									<DeleteOutlined/>
																								)}
																							/>
																						)}
																					/>
																					{this.state.key === key ? (
																						<Tooltip
																							TransitionComponent={Zoom}
																							placement={"top"}
																							title="Cancelar edición"
																							children={(
																								<Fab
																									size="small"
																									aria-label="Cancelar edición"
																									onClick={() => this.edit_cancel(item, key)}
																									style={{
																										marginLeft: "10px",
																										backgroundColor: "#f50057",
																										color: "white",
																									}}
																									children={(
																										<EditOutlined/>
																									)}
																								/>
																							)}
																						/>
																					) : (
																						<Tooltip
																							TransitionComponent={Zoom}
																							placement={"top"}
																							title="Editar pago"
																							children={(
																								<Fab
																									size="small"
																									aria-label="Editar"
																									onClick={() => this.edit_pago(item, key)}
																									style={{
																										marginLeft: "10px",
																										backgroundColor: "transparent",
																										color: "#808080",
																										boxShadow: "none",
																									}}
																									children={(
																										<EditOutlined/>
																									)}
																								/>
																							)}
																						/>)}
																				</div>
																			</Fragment>
																		) : null}
																		
																		{Number(item.edicion) === 0 && Number(this.Usr.id_usuario) !== Number(item.id_usuario_adquisicion) ? (
																			<div className={'center'}>
																				<Tooltip
																					TransitionComponent={Zoom}
																					placement={"top"}
																					title="Solicitar edición"
																					children={(
																						<Fab
																							size="small"
																							aria-label="Solicitar edición"
																							onClick={() => this.solicitar_autorizar_edicion(item)}
																							style={{
																								marginLeft: "10px",
																								backgroundColor: "#303f9f",
																								color: "white",
																							}}
																							children={(
																								<EditOutlined/>
																							)}
																						/>
																					)}
																				/>
																			</div>
																		) : (
																			<Fragment>
																				<div className={'center'}>
																					{Number(item.edicion) < 1 && Number(this.Usr.id_usuario) !== Number(item.id_usuario_adquisicion) ? (
																						<Tooltip
																							TransitionComponent={Zoom}
																							placement={"top"}
																							title="Solicitar edición"
																							children={(
																								<Fab
																									size="small"
																									aria-label="Solicitar edición"
																									onClick={() => this.solicitar_autorizar_edicion(item)}
																									style={{
																										marginLeft: "10px",
																										backgroundColor: "#303f9f",
																										color: "white",
																									}}
																									children={(
																										<EditOutlined/>
																									)}
																								/>
																							)}
																						/>
																					) : (
																						<Fragment>
																							{Number(item.edicion) === 2 && Number(this.Usr.id_usuario) !== Number(item.id_usuario_adquisicion) ? (
																								<div style={{color: '#e18c02'}}>
																									{'Por autorizar'}
																								</div>
																							) : null}
																						
																						</Fragment>
																					)}
																				</div>
																			</Fragment>
																		)}
																	
																	</div>
																
																</TableCell>
															</TableRow>
														)
													})}
													
													<TableRow>
														<TableCell colSpan={5} align={'right'} style={{fontWeight: 'bold'}}>Total</TableCell>
														<TableCell colSpan={5} align={'left'} style={{fontWeight: 'bold'}}>${this.state.item.total_pagos ? FieldsJs.Currency(this.state.item.total_pagos) : '0.00'}</TableCell>
													</TableRow>
												</Fragment>
											) : (
												<TableRow>
													<TableCell colSpan={7} align={'right'} style={{fontWeight: 'bold'}}>
														<VistaVacia
															numero={null}
															mensaje={'Ningún pago agregado.'}
															image={carrito_de_compra_3}
															padding={'10px'}
															paddingText={'10px'}
															height={'60px'}
															width={'60px'}
														/>
													
													</TableCell>
												</TableRow>
											)}
										
										
										</TableBody>
									</Table>
								</div>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							Cerrar
						</Button>
						
						{FieldsJs.Field(this.state.cuenta) ? (
							<Button onClick={() => this.save()} color="primary">
								Agregar
							</Button>
						) : null}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPagos.propTypes = {
	tipo: PropTypes.string.isRequired,
	componente: PropTypes.element,
	/*id_pago_odontologia: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),*/
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default IntegrationNotistack(ModalPagos);
