import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import MaterialTable from 'material-table';
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";

import {DeleteOutlined, EditOutlined, SearchOutlined} from '@material-ui/icons';

class ListaTabla extends Component {
	render() {
		const tabla = {
			columns: [
				{
					title: 'ID',
					field: 'id_cat_giro_cliente'
				},
				{
					title: 'Giro empresa',
					field: 'giro_cliente'
				},
				{
					title: 'Activo / Inactivo',
					field: 'activo',
					render: item => (
						<Fragment>
							{item.activo === 1 ? (
								<div style={{
									width: "50px",
									color: "white",
									borderRadius: "3px",
									padding: "2px 10px",
									background: "darkgreen",
									display: "inline-grid",
								}}>Activo</div>
							) : (
								<div style={{
									width: "50px",
									color: "white",
									borderRadius: "3px",
									padding: "2px 10px",
									background: "#901C30",
									display: "inline-grid",
								}}>Inactivo</div>
							)}
						</Fragment>
					)
				},
				{
					title: 'Acciones',
					field: 'acciones',
					type: 'numeric',
					render: item => (
						<div className={'w-auto vertical-inline'} align={'right'}>
							<div className={'v-center'}>
								<Tooltip TransitionComponent={Zoom} placement={"top"}
								         title="Eliminar">
									<IconButton aria-label="Eliminar"
									            onClick={() => this.props.delete(item)}>
										<DeleteOutlined/>
									</IconButton>
								</Tooltip>
							</div>
							<div className={'v-center'}>
								<Tooltip TransitionComponent={Zoom} placement={"top"}
								         title="Editar">
									<IconButton aria-label="Editar"
									            onClick={() => this.props.edit(item)}>
										<EditOutlined/>
									</IconButton>
								</Tooltip>
							</div>
							<div className={'v-center'}>
								<Tooltip TransitionComponent={Zoom} placement={"top"}
								         title="Detalles">
									<IconButton aria-label="Detalles"
									            onClick={() => this.props.view(item)}>
										<SearchOutlined/>
									</IconButton>
								</Tooltip>
							</div>
						</div>
					)
				},
			],
			data: this.props.lista || [],
		};
		
		return (
			<div className={'desing-table-primary'}>
				<MaterialTable
					title="Giro de empresas"
					columns={tabla.columns}
					data={tabla.data}
					options={{
						exportAllData: true,
						exportButton: true,
						grouping: true,
						pageSize: 5,
						pageSizeOptions: [5, 10, 15, 20, 30, 40, 50]
						
					}}
					localization={{
						body: {
							emptyDataSourceMessage: 'No se encontraron datos'
						},
						toolbar: {
							searchTooltip: 'Buscar orden de venta'
						},
						pagination: {
							labelRowsSelect: 'registros',
							labelDisplayedRows: '{count} registros {from}-{to} registros',
							firstTooltip: 'Inicio',
							previousTooltip: 'Anterior',
							nextTooltip: 'Siguiente',
							lastTooltip: 'Ultimo'
						},
						grouping: {
							placeholder: "Arrastra las columnas para agrupar los datos"
						}
					}}
				/>
			</div>
		);
	}
}

ListaTabla.propTypes = {
	delete: PropTypes.func.isRequired,
	edit: PropTypes.func.isRequired,
	view: PropTypes.func.isRequired,
	lista: PropTypes.array.isRequired,
};

export default ListaTabla;
