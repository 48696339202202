import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {DropzoneArea} from 'material-ui-dropzone';
import {CONFIG} from "../../../../settings/Config/Config";
import {Dropbox} from 'dropbox';
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {CargaCFDIService} from "../../../../services/CargaCFDIService/CargaCFDIService";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import utf8 from "utf8";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";


class ModalPdfCFDI extends Component {
	
	state = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			id_facturacion_cfdi: (props.item || '').id_facturacion_cfdi,
			id_facturacion_cfdi_parcial: (props.item || '').id_facturacion_cfdi_parcial,
			uuid: (props.item || '').uuid,
			archivo: (props.item || '').archivo,
			empresa_facturadora: (props.item || '').empresa_facturadora,
			fecha: (props.item || '').fecha,
			files: [],
			id_cliente: '',
			archivos: [],
			
		};
	}
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	
	guardarDocTimbradoDbx = () => {
		let ftar = this.state.files;
		console.log("1 FTAR::: ", ftar);
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		let files;
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i]) === true) {
					band++;
				}
			}
			let archives = [];
			for (let i = 0; i < ftar.length; i++) {
				console.log("2 FTAR::: ", ftar);
				files = ftar[i];
				
				let years_fechaxml = moment(this.state.fecha).get('years');
				let month_fechaxml = moment(this.state.fecha).get('month');
				month_fechaxml = month_fechaxml + 1;
				if (month_fechaxml < 10) {
					month_fechaxml = '0' + month_fechaxml;
				}
				
				DropboxApi.Upload('/' + this.state.empresa_facturadora +
					'/facturacion/' + years_fechaxml + '/' + month_fechaxml + '/' + ftar[i].name, files).then(function (response) {
					archives.push(response.path_display);
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	
	save = () => {
		console.log("THIS.STATE: ", this.state);
		
		try {
			
			console.log(this.state.archivo, this.state.files[0].name);
			
			
			if (this.state.files.length <= 0) {
				throw Object({
					status: false, mensaje: 'Agrega un archivo.'
				})
			}
			
			let ar = this.state.archivo.split('/');
			let name_xml = ar[ar.length - 1].split('.')[0];
			let name_pdf = this.state.files[0].name.split('.')[0];
			
			if (name_xml !== name_pdf) {
				throw Object({
					status: false, mensaje: 'El nombre del archivo no coincide.'
				})
			}
			
				let guardar = new Promise((resolve, reject) => {
					this.guardarDocTimbradoDbx().then(response => {
						console.log("GUARDARFICHATECNICAARCHIVOELABORACIONPROPUESTADBX::: ", response);
						this.setState({
							archivos: response
						});
						resolve(response);
						console.log("DROPBOX UPLOAD: ", this.state);
						
					}).catch(error => {
						reject(error);
					})
				});
				
				guardar.then(response => {
					CargaCFDIService.Pdf(this.state).then((response) => {
						this.closeModal();
						this.props.RefreshList();
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				})
			
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			activo: true,
			jsonXML: ''
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			//abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	obtenerNombreXML = (archivo) => {
		let ar = 'Archivo xml no encontrado';
		if (archivo) {
			let ch = archivo.split('/');
			ar = ch[ch.length - 1];
		}
		return ar;
	};
	
	render() {
		
		const filterOptions = createFilterOptions({
			matchFrom: 'start',
			stringify: option => option.rfc,
		});
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Carga de CFDI´s'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'margin-15-B'} style={{color: 'gray', opacity: '0.8'}}>
									Seleccionar los archivo PDF del CFDI. Debe coincidir con el nombre del archivo XML llamado:
								</Typography>
								<p><strong>{this.obtenerNombreXML(this.state.archivo)}</strong></p>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<DropzoneArea
									onChange={this.handleChangeDropzoneArea.bind(this)}
									open={this.state.open}
									filesLimit={1}
									dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
									acceptedFiles={['application/pdf']}
									showPreviews={false}
									maxFileSize={5000000}
									showAlerts={false}
									showFileNames={true}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<br/>
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_sexo}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPdfCFDI.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalPdfCFDI;
