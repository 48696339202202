import React, {Component, Fragment} from 'react';

import Typography from "@material-ui/core/Typography/index";
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import EventNote from '@material-ui/icons/EventNote';
import Sync from '@material-ui/icons/Sync';
import ListaTabla from './Includes/ListaTabla';
import ModalUsuarioEnviarReporte from './Includes/ModalUsuarioEnviarReporte';

import {UsuarioEnviarReporteService} from '../../../../services/_Cat/UsuarioEnviarReporteService/UsuarioEnviarReporteService';
import {PopupService} from '../../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Zoom from '@material-ui/core/Zoom/index';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab/index";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import Grid from "@material-ui/core/Grid";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../../settings/Config/Config";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import img_acceso_iniciar_sesion from '../../../../assets/img/icon-catalogo/iniciar-sesion.svg';

class UsuarioEnviarReporte extends Component {
	
	state = {
		modal: {
			open: false,
			title: '',
			tipo: ''
		},
		
		id_usuario_enviar_reporte: '',
		id_empresa: '',
		id_cat_tipo_reporte: '',
		id_usuario: '',
		activo: false,
		
		listar_usuario_enviar_reporte: [],
		cat_tipo_reporte: [],
		cat_usuario_tipo_reporte: [],
		
		filtro: {},
		
	};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.Listar();
		this.ListTipoReporte();
		this.ListUsuarioTipoReporte();
	}
	
	handleChange = (e) => {
		const {value, name, checked, type} = e.target;
		this.setState({
			[name]: type === 'checkbox' ? checked : value
		});
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	
	Listar = () => {
		UsuarioEnviarReporteService.Listar().then((response) => {
			
			let data = response.data || {};
			
			this.setState({
				listar_usuario_enviar_reporte: data.cat_tipo_reporte || []
			});
			
			setTimeout(() => console.log(this.state.listar_usuario_enviar_reporte))
			
		}).catch((error) => {
			
			this.setState({
				listar_usuario_enviar_reporte: []
			});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	ListTipoReporte = () => {
		CatService.ListTipoReporte().then((response) => {
			
			this.setState({cat_tipo_reporte: response.data});
			
		}).catch((error) => {
			
			this.setState({cat_tipo_reporte: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	ListUsuarioTipoReporte = () => {
		CatService.ListUsuarioTipoReporte().then((response) => {
			
			this.setState({cat_usuario_tipo_reporte: response.data});
			
		}).catch((error) => {
			
			this.setState({cat_usuario_tipo_reporte: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	delete = (item) => {
		
		let msg = `¿Deseas eliminar el usuario para enviar el reporte: ${item.nombre_completo}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				UsuarioEnviarReporteService.Eliminar(item.id_usuario_enviar_reporte, item.id_usuario).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	enviarReporteAcceso = () => {
		let msg = `¿Deseas enviar el reporte: Acceso de Usuarios?`;
		
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Aceptar') {
				HttpRequest.get(`_Cron_Jobs_Reporte_Acceso_Diario`, {}, {spinner: true}, null).then((response) => {
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	save = () => {
		if (this.state.id_usuario_enviar_reporte > 0) {
			UsuarioEnviarReporteService.Modificar(this.state).then((response) => {
				this.closeModal();
				this.Listar();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			UsuarioEnviarReporteService.Agregar(this.state).then((response) => {
				this.closeModal();
				this.Listar();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		
		this.setState({
			id_usuario_enviar_reporte: '',
			id_empresa: '',
			id_cat_tipo_reporte: '',
			id_usuario: '',
			activo: true,
		});
		
		this.openModal('add');
		
	};
	
	edit = (item) => {
		
		this.setState({
			id_usuario_enviar_reporte: item.id_usuario_enviar_reporte,
			id_empresa: item.id_empresa,
			id_cat_tipo_reporte: item.id_cat_tipo_reporte,
			id_usuario: item.id_usuario,
			activo: (item.activo === 1)
		});
		
		this.openModal('edit');
		
	};
	
	view = (item) => {
		
		this.setState({
			id_usuario_enviar_reporte: item.id_usuario_enviar_reporte,
			id_empresa: item.id_empresa,
			id_cat_tipo_reporte: item.id_cat_tipo_reporte,
			id_usuario: item.id_usuario,
			activo: (item.activo === 1)
		});
		
		this.openModal('view');
		
	};
	
	openModal = (tipo, form) => {
		let title = "";
		switch (tipo) {
			case 'add':
				title = "Agregar";
				break;
			case 'edit':
				title = "Editar";
				break;
			case 'view':
				title = "Detalle";
				break;
			default:
		}
		this.setState({
			modal: {
				open: true,
				title: title,
				tipo: tipo
			},
			form: form
		});
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false,
				title: '',
				tipo: ''
			}
		})
	};
	
	exportar = (item, archivo, auth) => {
		if (item.id_venta > 0) {
			let tipo = 2;
			HttpRequest.export('cpcxid', ['cuentas_x_cobrar'], {
				id_venta: item.id_venta,
				tipo: tipo,
			}, !(tipo === 3))
		} else {
			let fecha_alta_inicio = (this.state.filtro.fecha_alta_inicio || null) ? DateFormat.FormatSql(this.state.filtro.fecha_alta_inicio) : null;
			let fecha_alta_fin = (this.state.filtro.fecha_alta_fin || null) ? DateFormat.FormatSql(this.state.filtro.fecha_alta_fin) : null;
			let fecha_entrega_inicio = (this.state.filtro.fecha_entrega_inicio || null) ? DateFormat.FormatSql(this.state.filtro.fecha_entrega_inicio) : null;
			let fecha_entrega_fin = (this.state.filtro.fecha_entrega_fin || null) ? DateFormat.FormatSql(this.state.filtro.fecha_entrega_fin) : null;
			let filtro = {
				numero_cotizacion: this.state.filtro.numero_cotizacion || null,
				numero_pedido: this.state.filtro.numero_pedido || null,
				cliente: this.state.filtro.cliente || null,
				fecha_alta_inicio: fecha_alta_inicio,
				fecha_alta_fin: fecha_alta_fin,
				fecha_entrega_inicio: fecha_entrega_inicio,
				fecha_entrega_fin: fecha_entrega_fin,
				status_pago: this.state.filtro.status_pago || null,
				status_proceso: this.state.filtro.status_proceso || null,
				status_tipo_pedido: this.state.filtro.status_tipo_pedido || null,
				id_usuario_disenador: this.state.filtro.id_usuario_disenador || null,
				id_cat_grupo: this.state.filtro.id_cat_grupo || null,
				ordenar_fecha_alta: this.state.filtro.ordenar_fecha_alta || null,
				ordenar_fecha_entrega: this.state.filtro.ordenar_fecha_entrega || null,
				tipo_vista: this.state.tipo_vista || null,
			};
			HttpRequest.export('cpc', ['cuentas_x_cobrar'], {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			}, false, false, false, archivo === "EXCEL")
		}
	};
	
	exportar2 = (item, archivo, auth) => {
		if (item.id_venta > 0) {
			let tipo = 2;
			HttpRequest.export('cfpxid', ['compras_forma_pago'], {
				id_venta: item.id_venta,
				tipo: tipo,
			}, !(tipo === 3))
		} else {
			let fecha_alta_inicio = (this.state.filtro.fecha_alta_inicio || null) ? DateFormat.FormatSql(this.state.filtro.fecha_alta_inicio) : null;
			let fecha_alta_fin = (this.state.filtro.fecha_alta_fin || null) ? DateFormat.FormatSql(this.state.filtro.fecha_alta_fin) : null;
			let fecha_compra_inicio = (this.state.filtro.fecha_compra_inicio || null) ? DateFormat.FormatSql(this.state.filtro.fecha_compra_inicio) : null;
			let fecha_compra_fin = (this.state.filtro.fecha_compra_fin || null) ? DateFormat.FormatSql(this.state.filtro.fecha_compra_fin) : null;
			let filtro = {
				numero_cotizacion: this.state.filtro.numero_cotizacion || null,
				numero_compra: this.state.filtro.numero_pedido || null,
				usuario: this.state.filtro.usuario || null,
				proveedor: this.state.filtro.proveedor || null,
				fecha_alta_inicio: fecha_alta_inicio,
				fecha_alta_fin: fecha_alta_fin,
				fecha_compra_inicio: fecha_compra_inicio,
				fecha_compra_fin: fecha_compra_fin,
				status_pago: this.state.filtro.status_pago || null,
				status_proceso: this.state.filtro.status_proceso || null,
				status_tipo_pedido: this.state.filtro.status_tipo_pedido || null,
				id_usuario_disenador: this.state.filtro.id_usuario_disenador || null,
				id_cat_grupo: this.state.filtro.id_cat_grupo || null,
				ordenar_fecha_alta: this.state.filtro.ordenar_fecha_alta || null,
				ordenar_fecha_compra: this.state.filtro.ordenar_fecha_compra || null,
				tipo_vista: this.state.tipo_vista || null,
			};
			HttpRequest.export('cfp', ['compras_forma_pago'], {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			}, false, false, false, archivo === "EXCEL")
		}
	};
	
	render() {
		
		const img_acceso = {
			backgroundImage: `url(${img_acceso_iniciar_sesion})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain',
			width: 100,
			height: 100,
			opacity: 1
		};
		
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<Fragment>
					
					<ModalUsuarioEnviarReporte modal={this.state.modal} form={this.state} save={this.save}
					                           closeModal={this.closeModal} handleChange={this.handleChange}
					/>
				
				</Fragment>
				
				<div className={'row-flex margin-30-T margin-30-B'}>
					<div className={'w-100-300-px'}>
						<Typography variant={'h5'} className={'text-left'}>
							Usuario enviar reporte
						</Typography>
					</div>
					<div className={'w-300-px text-right'}>
						{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
							<Fab variant="extended" size="small" color="primary" aria-label="PDF"
							     className={'margin-10-L bg-danger'}>
								<PictureAsPdf className={'margin-5-R px-14'}/>
								PDF
							</Fab>
						) : ''}
						{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
							<Fab variant="extended" size="small" color="primary" aria-label="Excel"
							     className={'margin-10-L bg-green'}>
								<EventNote className={'margin-5-R px-14'}/>
								Excel
							</Fab>
						) : ''}
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={this.Listar}>
							<Sync className={'margin-5-R px-14'}/>
							Actualizar
						</Fab>
					</div>
				</div>
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-30-T margin-30-B'}>
					
					</Typography>
					
					<Grid container spacing={2}>
						
						{this.state.listar_usuario_enviar_reporte.map((value, index) => (
							<Fragment key={index}>
								{index === 0 ? (
									<Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
										<ListaTabla
											value={value}
											titulo={value.tipo_reporte}
											lista={value.usuario_enviar_reporte}
											edit={this.edit}
											view={this.view}
											delete={this.delete}
											showSnackBars={this.showSnackBars}
										/>
									</Grid>
								) : null}
							</Fragment>
						))}
						
						<Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
							<div className={'margin-25-T'}>
								<div color="primary" aria-label="Excel" className={'vertical-inline w-100 margin-10-L borde-punteado-gray-1 padding-10 cursor-pointer btn-acceso-usuarios'}
								     onClick={() => this.enviarReporteAcceso()}>
									<div style={img_acceso} className={'v-center w-100-px'}></div>
									<p className={'v-center w-100-100-px'} style={{paddingLeft: '15px', textAlign: 'left', fontWeight: 'bold'}}>Acceso de Usuarios</p>
								</div>
							</div>
							
							{/*<div className={'margin-30-T'}>
								<Fab variant="extended" size="small" color="primary" aria-label="Excel"
								     className={'w-100 margin-10-L bg-green'} onClick={() => this.exportar2({}, 'EXCEL', false)}>
									<EventNote className={'margin-5-R px-14'}/>
									Excel Compras con Forma de Pago
								</Fab>
							</div>*/}
						</Grid>
					</Grid>
				
				</div>
				
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			
			</div>
		);
	}
}

UsuarioEnviarReporte.propTypes = {};

export default IntegrationNotistack(UsuarioEnviarReporte);
