import React, {Component, Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {$cWarn, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import PictureAsXml from "@material-ui/icons/InsertDriveFile";
import Fab from "@material-ui/core/Fab";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import {CloudDownload, EditOutlined, LocalAtm, Send} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import ModalDescargarDoc from "./ModalDescargarDoc";
import ModalEstatusSat from "./ModalEstatusSat";
import ModalSendMail from "./ModalSendMail";
import ModalComprobante from "./ModalComprobante";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import {CONFIG} from "../../../../settings/Config/Config";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 600,
	},
});


class SubTablaFacturacionPago extends Component {
	state = {};
	Usr = {};
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			id_cat_ficha_tecnica_status: props.item.id_cat_ficha_tecnica_status,
			listar_pagos: props.item.pagos || [],
		}
	}
	
	componentDidMount() {
		$cWarn("componentDidMount");
	}
	
	componentWillUnmount() {
		$cWarn("componentWillUnmount");
	}
	
	pasos = () => {
		let curculo = [
			{
				fecha_alta: "12/12/2019",
				serie: '1111111',
				folio: '2222222',
				folio_fiscal: '3333333',
				url_xml_factura: 'http://localhost:3000/facturacion',
				url_pdf_factura: 'http://localhost:3000/facturacion',
			},
			{
				fecha_alta: "12/12/2019",
				serie: '1111111',
				folio: '2222222',
				folio_fiscal: '3333333',
				url_xml_factura: 'http://localhost:3000/facturacion',
				url_pdf_factura: 'http://localhost:3000/facturacion',
			},
		
		];
		return curculo;
	};
	
	exportar = (link, tipo) => {
		if (tipo === 'PDF') {
			this.props.showSnackBars('error', "En desarrollo.");
			/*try {
				let params = {
					archivo: tipo,
					archivo_xml: link
				};
				HttpRequest.exportFacturacion('xmltopdf', ['xmltopdf'], params, false, false, false, true);
			} catch (e) {
				this.showSnackBars('error', e.mensaje);
			}*/
		} else {
			let url = CONFIG.src_facturacion+link;
			window.open(url, '', 'width=900, height=600');
		}
	};
	
	
	descargarZip = (path, zip) => {
		
		try {
			
			if (!FieldsJs.Field(path)) {
				throw Object({
					status: false, mensaje: 'No se encuentra el archivo registrado.'
				})
			} else {
				
				if (zip) {
					console.log("ZIPPP:::", zip);
					let ruta = '';
					let route = path.split('/');
					for (let i = 0; i < route.length; i++) {
						if (i < route.length - 1) {
							ruta = ruta + route[i] + (i < route.length - 2 ? '/' : '');
						}
					}
					path = ruta;
				}
				
				console.log("PATH ::: ", path, zip);
				
				DropboxApi.GenerateZip(path).then(function (data) {
					console.log('Descarga con éxito!');
				}).catch(function (error) {
					console.error("ERROR::: ", error);
				});
			}
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
		
	};
	
	render() {
		
		
		const {classes} = this.props;
		
		return (
			<Fragment>
				<div className={'bg-white card-1 margin-10-T'}>
					
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={'px-14 text-center bg-white padding-5'}>
							Datos de Recibo Electrónico de Pago
						</Typography>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Table className={classes.table + ' desing-integraciones'}>
							<TableHead>
								<TableRow>
									<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Fecha Pago</TableCell>
									<TableCell component="th" align={'left'}>Forma Pago</TableCell>
									<TableCell component="th" align={'center'}>Monto</TableCell>
									<TableCell component="th" align={'left'}>RFC Emisor Cta Ord</TableCell>
									<TableCell component="th" align={'left'}>Banco</TableCell>
									<TableCell component="th" align={'left'}>RFC Emisor Cta Ben</TableCell>
									<TableCell component="th" align={'left'}>Cta Beneficiario</TableCell>
									<TableCell component="th" align={'left'}>No. Parcialidad</TableCell>
									<TableCell component="th" align={'left'}>Imp. Saldo Anterior</TableCell>
									<TableCell component="th" align={'left'}>Imp. Pagado</TableCell>
									<TableCell component="th" align={'left'}>Objeto Impuesto</TableCell>
									<TableCell component="th" align={'left'}>Imp. Saldo Insoluto</TableCell>
									<TableCell component="th" align={'center'}>Acciones</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.state.listar_pagos.map((item, key) => {
									return (
										<Fragment key={key}>
											<TableRow>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''} style={{paddingLeft: '15px', width: 90}}>{DateFormat.FormatText(item.fecha_pago)}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.forma_pago}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.monto}</TableCell>
												<TableCell align={'center'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.rfc_emisor_cuenta_ord}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.banco}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.rfc_emisor_cuenta_ben}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.cuenta_beneficiario}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.numero_parcialidad}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>${FieldsJs.Currency(item.importe_saldo_anterior)}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>${FieldsJs.Currency(item.importe_pagado)}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.objeto_impuesto}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>${FieldsJs.Currency(item.importe_pagado)}</TableCell>
												<TableCell align={'center'} style={{width: '400px'}}>
													<div className={'w-auto vertical-inline'}>
														
														<div className={'v-center'}>
															<Fab variant="extended" size="small" color="default" aria-label="XML" className={'margin-10-L'} onClick={() => this.exportar(item.archivo_xml)}>
																<PictureAsXml className={'margin-5-R px-14'}/>
																XML
															</Fab>
														</div>
														
														<div className={'v-center'}>
															<Fab variant="extended" size="small" color="default" aria-label="PDF" style={{color: 'white', background: '#8E1E32'}} className={'margin-10-L'} onClick={() => this.exportar(item.archivo_xml, 'PDF')}>
																<PictureAsPdf className={'margin-5-R px-14'}/>
																PDF
															</Fab>
														</div>
														
														{/*<div className={'v-center'}>
															<ModalSendMail
																item={item}
																tipo={'add'}
																component={(
																	<Tooltip TransitionComponent={Zoom} placement={"top"}
																	         title="Enviar Correo Electrónico">
																		<IconButton aria-label="Enviar Correo Electrónico">
																			<Send/>
																		</IconButton>
																	</Tooltip>
																)}
																listar_usuarios={[]}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
															/>
														</div>
														
														<div className={'v-center'}>
															<ModalComprobante
																tipo={'add'}
																item={item}
																componente={
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Pagar" >
																		<IconButton aria-label="Pagar" className={'margin-5-L margin-5-R'} style={{color: item.comprobante.length > 0 ? 'green' : 'gray'}} onClick={() => {
																		}}>
																			<LocalAtm/>
																		</IconButton>
																	</Tooltip>
																}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
															/>
														</div>
														
														<div className={'v-center'}>
															<ModalEstatusSat
																id_facturacion={item.id_facturacion}
																item={item}
																tipo={'add'}
																componente={(
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Estatus Sat" className={'margin-10-R'}>
																		<Fab variant="extended" size="small" color="primary" aria-label="" className={'bg-gray'}>
																			E.S.
																		</Fab>
																	</Tooltip>
																)}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
																handleChange={this.handleChange}
															/>
														</div>
														
														<div className={'v-center'}>
															<ModalDescargarDoc
																tipo={'add'}
																item={item}
																componente={
																	<Fab variant="extended" size="small" color="default" aria-label="Descargar">
																		<CloudDownload className={'px-15'}/>
																	</Fab>
																}
																showSnackBars={this.props.showSnackBars}
																RefreshList={this.props.RefreshList}
																descargarZip={this.descargarZip}
															/>
														</div>
														
														<div className={'v-center'}>
															
															<Fab variant="extended" size="small" color="default" aria-label="XML"
															     className={'margin-10-L'} onClick={() => this.exportar(item.url_xml_factura)}
															>
																<PictureAsXml className={'margin-5-R px-14'}/>
																XML
															</Fab>
															
															<Fab variant="extended" size="small" color="default" aria-label="PDF"
															     className={'margin-10-L'} onClick={() => this.exportar(item.url_pdf_factura)}
															>
																<PictureAsPdf className={'margin-5-R px-14'}/>
																PDF
															</Fab>
														</div>*/}
													
													
													</div>
												</TableCell>
											</TableRow>
										</Fragment>
									);
								})
								}
							</TableBody>
						</Table>
					</Grid>
				
				</div>
			</Fragment>
		);
	}
}

SubTablaFacturacionPago.propTypes = {
	// lista: PropTypes.array.isRequired,
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired
};

export default withStyles(styles)(SubTablaFacturacionPago);
