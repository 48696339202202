import React, {Component, Fragment} from 'react';
import {Icono} from "../../../Include/Icono/Icono";
import Button from "@material-ui/core/Button";
import ModalImage from "../../../Include/ModalImage/ModalImage";
import LinearIndeterminate from "../../../Include/Spinners/LinearIndeterminate";
import PropTypes from "prop-types";
import {FieldsJs} from "../../../../settings/General/General";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";

class ArchivosFacturacion extends Component {
	
	
	obtenerFormato = (text) => {
		if (FieldsJs.Field(text) === true) {
			let t = text.split('.');
			return t[t.length -1];
		}
	};
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Open(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	render() {
		
		const {
			index,
			variable,
			texto,
			id_ficha_tecnica_archivo_integracion_expediente,
			id_cat_archivo_integracion_expediente,
			item,
			fileSelect,
			getBase64,
			disabledSeleccionarCambiarArchivo,
			width,
			height,
			padding,
			id_facturacion
		} = this.props;
		
		const lectura = !(!item.archivo);
		
		return (
			<Fragment>
				
				<div className={'card-1 h-100'}>
					
					<div align={'center'} style={{padding: (padding || 15).toString() + 'px'}}>
						
						{item.loading ? (
							<Fragment>
								
								<div className={'h-140-px w-80-px vertical-inline'}>
									<div className={'w-100 v-center'}>
										<LinearIndeterminate/>
									</div>
								</div>
							
							</Fragment>
						) : (
							<Fragment>
								
								<div className={'padding-15-T padding-15-B'}>
									{Icono.get(item.formato || this.obtenerFormato(item.archivo), (width > 0 ? width.toString() + 'px' : '80px'), (height > 0 ? height.toString() + 'px' : '80px'))}
								</div>
								
								<Fragment>
									{!disabledSeleccionarCambiarArchivo ? (
										<Button size="small" color="primary"
										        onClick={() => fileSelect(variable + "_" + index)}>
											{lectura ? 'Cambiar' : 'Seleccionar'}
										</Button>
									) : ''}
									<input type="file" id={variable + "_" + index}
									       onChange={(e) => getBase64(e, variable, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente, id_facturacion)}
									       className={'display-none'}
									/>
								</Fragment>
								
								{lectura ? (
									<Fragment>
										<Button size="small" color="primary" onClick={() => this.exportar(item.archivo)} className={'cursor-pointer'}>
											Ver
										</Button>
										{/*<ModalImage tipo={'add'} desing={this.props.desing || 1} item={item}/>*/}
									</Fragment>
								) : ''}
							
							</Fragment>
						)}
						
						<h5 className={'margin-5'}>{texto}{item.is_required === 1 ? (
							<span className={'text-danger'}> (Requerido)</span>) : ''}</h5>
					
					</div>
				
				</div>
			
			</Fragment>
		)
	}
}

ArchivosFacturacion.propTypes = {
	index: PropTypes.number.isRequired,
	variable: PropTypes.string.isRequired,
	texto: PropTypes.string.isRequired,
	id_ficha_tecnica_archivo_integracion_expediente: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	id_cat_archivo_integracion_expediente: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	item: PropTypes.object.isRequired,
	fileSelect: PropTypes.func.isRequired,
	getBase64: PropTypes.func.isRequired,
	disabledSeleccionarCambiarArchivo: PropTypes.bool.isRequired,
	width: PropTypes.number,
	height: PropTypes.number,
	desing: PropTypes.oneOf([
		1,
		2,
		3,
	]),
	padding: PropTypes.number
};

export default ArchivosFacturacion;
