import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {DropzoneArea} from 'material-ui-dropzone';
import {CONFIG} from "../../../../settings/Config/Config";
import {Dropbox} from 'dropbox';
import {ClienteService} from "../../../../services/ClienteService/ClienteService";
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {CargaCFDIService} from "../../../../services/CargaCFDIService/CargaCFDIService";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import utf8 from "utf8";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import Button from "@material-ui/core/Button";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";


class ModalCargaCFDI extends Component {
	
	state = {};
	Usr = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			modal: {
				open: false,
				open2: false
			},
			id_usuario: this.Usr.id_usuario,
			tipo: props.tipo,
			project: props.project,
			files: [],
			patch: '/prueba1/',
			select_cliente: '',
			
			archivos: [],
			
			listar_cat_cliente: [],
			
			jsonXML: [],
			jsonXMLError: [],
			
			listar_cat_serie_cfdi: [],
			
			response_content: {
				correctos: [],
				erroneos: [],
				repetidos: [],
				existentes: []
			}
			
		};
	}
	
	
	handleChange(files) {
		
		this.setState({
			files: files[0]
		});
	}
	
	
	/*handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	*/
	
	handleChangeDropzoneArea = (files) => {
		//console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
		
		let xmls = [];
		let xmlerror = [];
		if (files.length > 0) {
			showSpinner('spinner');
			
			for (let i = 0; i < files.length; i++) {
				FileBase64.Base64XmlToJson(files[i]).then((response) => {
					
					let xmljson = {
						rfc_emisor: this.searchXmlToJson(response, 'cfdi:Emisor', 'Rfc' || ''),
						empresa_facturadora: this.searchXmlToJson(response, 'cfdi:Emisor', 'Nombre' || ''),
						rfc_receptor: this.searchXmlToJson(response, 'cfdi:Receptor', 'Rfc' || ''),
						cliente: this.searchXmlToJson(response, 'cfdi:Receptor', 'Nombre' || ''),
						iva: this.searchXmlToJson(response, 'cfdi:Impuestos', 'TotalImpuestosTrasladados' || ''),
						total_facturado: this.searchXmlToJson(response, 'cfdi:Emisor', 'Rfc' || ''),
						concepto_facturacion: this.searchXmlToJson(response, 'cfdi:Concepto', 'Descripcion' || ''),
						clave_unidad: this.searchXmlToJson(response, 'cfdi:Concepto', 'ClaveUnidad' || ''),
						clave_concepto: this.searchXmlToJson(response, 'cfdi:Concepto', 'ClaveProdServ' || ''),
						
						version: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Version' || ''),
						serie: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Serie' || ''),
						folio: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Folio' || ''),
						fecha: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Fecha') ? DateFormat.FormatSql(moment(this.searchXmlToJson(response, 'cfdi:Comprobante', 'Fecha'))) : '',
						//sello: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Sello' || ''),
						forma_pago: this.searchXmlToJson(response, 'cfdi:Comprobante', 'FormaPago' || ''),
						numero_certificado: this.searchXmlToJson(response, 'cfdi:Comprobante', 'NoCertificado' || ''),
						//certificado: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Certificado' || ''),
						subtotal: this.searchXmlToJson(response, 'cfdi:Comprobante', 'SubTotal' || ''),
						moneda: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Moneda' || ''),
						tipo_cambio: this.searchXmlToJson(response, 'cfdi:Comprobante', 'TipoCambio' || ''),
						total: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Total' || ''),
						tipo_comprobante: this.searchXmlToJson(response, 'cfdi:Comprobante', 'TipoDeComprobante' || ''),
						metodo_pago: this.searchXmlToJson(response, 'cfdi:Comprobante', 'MetodoPago' || ''),
						lugar_expedicion: this.searchXmlToJson(response, 'cfdi:Comprobante', 'LugarExpedicion' || ''),
						uuid: this.searchXmlToJson(response, 'tfd:TimbreFiscalDigital', 'UUID') || '',
						uuid_relacionado: this.searchXmlToJson(response, 'cfdi:CfdiRelacionado', 'UUID') || '',
						nombre_archivo: files[i].name,
						file: files[i],
						ruta: ''
					};
					
					if (xmljson.tipo_comprobante !== 'I' && xmljson.tipo_comprobante !== 'E') {
						xmlerror.push(xmljson);
					} else {
						xmls.push(xmljson);
					}
					
					this.setState({
						jsonXML: xmls,
						jsonXMLError: xmlerror
					});
					console.log("CONDICION::: ", this.state);
					if (i >= (files.length - 1)) {
						console.log("JSONXML :::: ", xmls);
						hideSpinner('spinner');
					}
				}).catch((error) => {
					console.log("JSONXML ERROR :::: ", error);
					if (i >= (files.length - 1)) {
						console.log("JSONXML :::: ", xmls);
						hideSpinner('spinner');
					}
					this.setState({
						jsonXML: [],
						jsonXMLError: []
					});
				})
			}
		} else {
			this.setState({jsonXML: '', jsonXMLError: ''});
		}
		
	};
	
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	closeModal2 = () => {
		this.setState({
			modal: {
				open2: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	close2 = () => {
		this.closeModal2();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	openModal2 = () => {
		const props = this.props;
		this.setState({
			modal: {
				open2: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	open2 = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal2());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			this.ListaSeries();
			resolve(true);
		});
	};
	
	ListaSeries = () => {
		CatService.ListSeriesCfdi().then((response) => {
			this.setState({
				listar_cat_serie_cfdi: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_serie_cfdi: []
			});
		});
	};
	
	ListaClientes = () => {
		return new Promise((resolve, reject) => {
			ClienteService.ListarTodo(this.state).then((response) => {
				this.setState({
					listar_cat_cliente: response.data,
				});
				resolve(response.data);
			}).catch((error) => {
				this.setState({
					listar_cat_cliente: []
				});
				reject(false);
			});
		});
	};
	
	RefreshListClientes = (id_cliente) => {
		if (id_cliente > 0) {
			this.ListaClientes().then(r => {
				let cliente = '';
				for (let i = 0; i < r.length; i++) {
					if (Number(r[i].id_cliente) === Number(id_cliente)) {
						cliente = r[i];
					}
				}
				
				console.log('CLIENTES:', r, 'ID CLIENTE: ', id_cliente, 'DATOS CLIENTE: ', cliente);
				this.setState({
					select_cliente: cliente,
					nombre_comercial: cliente.nombre_comercial,
					razon_social: cliente.razon_social,
					rfc: cliente.rfc,
					correo_electronico: cliente.correo_electronico,
					telefono: cliente.telefono,
					id_cat_giro_cliente: cliente.id_cat_giro_cliente,
				})
			}).catch(e => {
				console.log(e);
			});
		}
	};
	
	existCompany = (company, listcompany) => {
		
		let comp = false;
		listcompany.map((item, key) => {
			let name = item.name.split('-');
			console.log(name);
			if (company === name[0]) {
				comp = item.name
			}
		});
		
		return comp;
	};
	
	searchXmlToJson = (json, name, elemento) => {
		let valor = '';
		if (json.hasOwnProperty('elements')) {
			for (let a = 0; a < json.elements.length; a++) {
				if (json.elements[a].hasOwnProperty('name') && json.elements[a].hasOwnProperty('attributes')) {
					if (json.elements[a].name === name) {
						valor = json.elements[a].attributes[elemento];
					}
				}
				if (json.elements[a].hasOwnProperty('elements')) {
					for (let b = 0; b < json.elements[a].elements.length; b++) {
						if (json.elements[a].elements[b].hasOwnProperty('name') && json.elements[a].elements[b].hasOwnProperty('attributes')) {
							if (json.elements[a].elements[b].name === name) {
								valor = json.elements[a].elements[b].attributes[elemento];
							}
						}
						if (json.elements[a].elements[b].hasOwnProperty('elements')) {
							for (let c = 0; c < json.elements[a].elements[b].elements.length; c++) {
								if (json.elements[a].elements[b].elements[c].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].hasOwnProperty('attributes')) {
									if (json.elements[a].elements[b].elements[c].name === name) {
										valor = json.elements[a].elements[b].elements[c].attributes[elemento];
									}
								}
								if (json.elements[a].elements[b].elements[c].hasOwnProperty('elements')) {
									for (let d = 0; d < json.elements[a].elements[b].elements[c].elements.length; d++) {
										if (json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('attributes')) {
											if (json.elements[a].elements[b].elements[c].elements[d].name === name) {
												valor = json.elements[a].elements[b].elements[c].elements[d].attributes[elemento];
											}
										}
										if (json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('elements')) {
											for (let f = 0; f < json.elements[a].elements[b].elements[c].elements[d].elements.length; f++) {
												if (json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('attributes')) {
													if (json.elements[a].elements[b].elements[c].elements[d].elements[f].name === name) {
														valor = json.elements[a].elements[b].elements[c].elements[d].elements[f].attributes[elemento];
													}
												}
												if (json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('elements')) {
													for (let g = 0; g < json.elements[a].elements[b].elements[c].elements[d].elements[f].elements.length; g++) {
														if (json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].hasOwnProperty('attributes')) {
															if (json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].name === name) {
																valor = json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].attributes[elemento];
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		
		if (name === 'cfdi:Emisor' && elemento === 'Nombre' ||
			name === 'cfdi:Receptor' && elemento === 'Nombre')
			valor = utf8.decode(valor);
		
		return valor;
	};
	
	guardarDocTimbradoDbx = () => {
		let ftar = this.state.files;
		console.log("1 FTAR::: ", ftar);
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		//const dbx = new Dropbox({accessToken, fetch});
		const dbx = new Dropbox({accessToken});
		let files;
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			
			band = this.state.jsonXML.length;
			
			let archives = [];
			console.log("2 FTAR 22::: ", ftar, this.state.jsonXML);
			
			
			for (let x = 0; x < this.state.jsonXML.length; x++) {
				files = this.state.jsonXML[x].file;
				//if (files.name === this.state.jsonXML[x].nombre_archivo) {
				let years_fechaxml = moment(this.state.jsonXML[x].fecha).get('years');
				let month_fechaxml = moment(this.state.jsonXML[x].fecha).get('month');
				month_fechaxml = month_fechaxml + 1;
				if (month_fechaxml < 10) {
					month_fechaxml = '0' + month_fechaxml;
				}
				
				let empresa = this.existCompany(this.state.jsonXML[x].rfc_emisor, this.props.listDropbox);
				let ruta = '';
				let plaza_cfdi = null;
				let series_cfdi = FieldsJs.Copy(this.state.listar_cat_serie_cfdi);
				
				for (let i = 0; i < series_cfdi.length; i++) {
					if (series_cfdi[i].serie === this.state.jsonXML[x].serie) {
						plaza_cfdi = series_cfdi[i].facturacion_plaza;
					}
				}
				
				if (plaza_cfdi === null) {
					plaza_cfdi = this.state.jsonXML[x].serie;
				}
				
				if (!empresa) {
					ruta = '/' + this.state.jsonXML[x].rfc_emisor + '-' + this.state.jsonXML[x].empresa_facturadora
						+ '/facturacion/' + years_fechaxml + '/' + month_fechaxml + '/' + plaza_cfdi + '/' +
						this.state.jsonXML[x].nombre_archivo;
				} else {
					ruta = '/' + empresa + '/facturacion/' + years_fechaxml + '/' + month_fechaxml + '/' + plaza_cfdi + '/' +
						this.state.jsonXML[x].nombre_archivo;
				}
				
				
				let displayDropbox = new Promise((resol, reje) => {
					DropboxApi.Upload(ruta, files).then(function (response) {
						
						archives.push(response.path_display);
						console.log("DROPBOX UPLOAD RESPONSE: ", response, band, (x + 1));
						
						resol(response.path_display);
						
					}).catch(function (error) {
						console.log("DROPBOX UPLOAD ERROR: ", error);
						reje(null);
					});
					
				});
				
				displayDropbox.then((response) => {
					this.state.jsonXML[x].ruta = response;
					band2++;
					if (band2 >= band) {
						resolve(true);
						//hideSpinner('spinner');
					}
				}).catch((error) => {
					band2++;
					this.state.jsonXML[x].ruta = null;
					if (band2 >= band) {
						resolve(true);
						//hideSpinner('spinner');
					}
				});
				
			}
		});
	};
	
	save = () => {
		console.log("THIS.STATE: ", this.state);
		
		try {
			
			/*if (!this.state.select_cliente) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cliente.'
				})
			}*/
			
			if (this.state.files.length <= 0) {
				throw Object({
					status: false, mensaje: 'Agrega un archivo.'
				})
			}
			showSpinner('spinner');
			let guardar = new Promise((resolve, reject) => {
				this.guardarDocTimbradoDbx().then(response => {
					console.log("GUARDARFICHATECNICAARCHIVOELABORACIONPROPUESTADBX::: ", response);
					this.setState({
						archivos: response
					});
					resolve(response);
					console.log("DROPBOX UPLOAD: ", this.state);
					
				}).catch(error => {
					reject(error);
				})
			});
			console.log("GUARDARRR::", guardar);
			guardar.then(response => {
				hideSpinner('spinner');
				
				CargaCFDIService.Guardar(this.state).then((response) => {
					if (response.data.status) {
						this.closeModal();
						this.props.RefreshList();
						this.props.showSnackBars('success', response.mensaje);
						this.setState({
							response_content: response.data
						});
						if (response.data.existentes.length > 0 ) {
							this.open2();
						}
					} else {
						
						this.setState({
							response_content: response.data
						});
					}
					
					console.log("RESPONSE.DATA ::: ", response.data);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			})
			
		} catch (e) {
			hideSpinner('spinner');
			console.log("ERROR", e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	
	save2 = () => {
		console.log("THIS.STATE: ", this.state);
		
		try {
			
			/*let erroneos = [];
			
			for (let i = 0; i < this.state.jsonXML.length; i++) {
				for (let j = 0; j < this.state.response_content.erroneos.length; j++) {
					if(this.state.jsonXML[i].nombre_archivo === this.state.response_content.erroneos[j]) {
						erroneos.push(this.state.jsonXML[i]);
					}
				}
			}*/
			
			/*this.setState({
				jsonXML: erroneos
			});*/
			
			//setTimeout(() => {
			this.save();
			//console.log("THIS.STATE: ", this.state);
			
			//},1500);
			
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			activo: true,
			jsonXML: [],
			jsonXMLError: []
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			//abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	getBase64 = (e, dropzone) => {
		return new Promise((resolve) => {
			const accessToken = CONFIG.DROPBOX_TOKEN;
			let files;
			if (dropzone) {
				files = e;
			} else {
				console.log(e.target);
				files = e.target.files;
			}
			
			const dbx = new Dropbox({
				accessToken,
				fetch
			});
			console.log("DROPBOX: ", dbx, files[0], files[0].name);
			dbx.filesUpload({path: '/' + files[0].name, contents: files[0]})
				.then(function (response) {
					console.log("DROPBOX UPLOAD: ", response);
					resolve(true);
				})
				.catch(function (error) {
					console.error(error);
				});
		});
	};
	
	
	onTagsChange = (event, value) => {
		let select = FieldsJs.Copy(value);
		
		if (select) {
			try {
				
				this.setState({
					select_cliente: select,
					nombre_comercial: select.nombre_comercial,
					razon_social: select.razon_social,
					rfc: select.rfc,
					correo_electronico: select.correo_electronico,
					telefono: select.telefono,
					id_cat_giro_cliente: select.id_cat_giro_cliente,
				}, () => console.log("STATE", select, this.state));
				
			} catch (error) {
				this.showSnackBars('error', error.mensaje);
			}
		} else {
			try {
				
				this.setState({
					select_cliente: '',
					nombre_comercial: '',
					razon_social: '',
					rfc: '',
					correo_electronico: '',
					telefono: '',
					id_cat_giro_cliente: '',
				});
				
			} catch (error) {
				this.showSnackBars('error', error.mensaje);
			}
		}
	};
	
	render() {
		
		const filterOptions = createFilterOptions({
			matchFrom: 'start',
			stringify: option => option.rfc,
		});
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Carga de CFDI´s'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'margin-15-B'} style={{color: 'gray', opacity: '0.8'}}>
									Selecciona los archivos XML´s de los CFDI´s que desea registrar.
								</Typography>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<DropzoneArea
									onChange={this.handleChangeDropzoneArea.bind(this)}
									open={this.state.open}
									filesLimit={100}
									dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
									acceptedFiles={['text/xml']}
									showPreviews={false}
									maxFileSize={5000000}
									showAlerts={false}
									showFileNames={true}
									showFileNamesInPreview={true}
								/>
							</Grid>
							
							{this.state.jsonXMLError.length > 0 ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-14 text-center bg-white padding-5'} style={{color: '#901C30', fontSize: 'bold'}}>
											XML´s no válidos:
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Table className={'desing-acresco'}>
											<TableHead>
												<TableRow>
													<TableCell component="th" align={'left'}>RFC Emisor</TableCell>
													<TableCell component="th" align={'left'}>Empresa Facturadora</TableCell>
													<TableCell component="th" align={'left'}>Archivo</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												
												{this.state.jsonXMLError.map((item, key) => (
													<Fragment key={key}>
														<TableRow>
															<TableCell align={'left'}>{item.rfc_emisor}</TableCell>
															<TableCell align={'left'}>{item.empresa_facturadora}</TableCell>
															<TableCell align={'left'}>{item.nombre_archivo}</TableCell>
														</TableRow>
													</Fragment>
												))
												}
											</TableBody>
										</Table>
									</Grid>
								</Fragment>
							) : null}
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_sexo}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
				
				<Dialog open={this.state.modal.open2} onClose={() => this.close2()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						{/*<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Carga de CFDI´s'}
						/>*/}
						Carga de CFDI´s
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'px-14 text-center bg-white padding-5'} style={{color: '#901C30', fontSize: 'bold'}}>
									Los siguientes xml´s ya se encuentran registrados:
								</Typography>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Table className={'desing-acresco'}>
									<TableHead>
										<TableRow>
											<TableCell component="th" align={'left'}>Archivo</TableCell>
											<TableCell component="th" align={'left'}>Nombre registrado</TableCell>
											<TableCell component="th" align={'left'}>Pdf registrado</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										
										{this.state.response_content.existentes.map((item, key) => (
											<Fragment key={key}>
												<TableRow>
													<TableCell align={'left'}>{item.nombre_archivo}</TableCell>
													<TableCell align={'left'}>{item.archivo}</TableCell>
													<TableCell align={'left'}>{item.archivo_pdf}</TableCell>
												</TableRow>
											</Fragment>
										))
										}
									</TableBody>
								</Table>
							</Grid>
							
							{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'vertical-inline w-100'}>
									<Typography className={'v-center px-14 text-center bg-white padding-5 padding-20-R'} style={{color: '#901C30', fontSize: 'bold'}}>
										No registrados: {this.state.response_content.erroneos.length}
									</Typography>
								</Typography>*!/
									<Typography className={'v-center px-14 text-center bg-white padding-5'} style={{color: '#009018', fontSize: 'bold'}}>
										Registrados: {this.state.response_content.correctos.length}
									</Typography>
								</div>
								<Divider/>
							</Grid>
							
							{this.state.response_content.erroneos.length > 0 ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-14 text-center bg-white padding-5'} style={{color: '#901C30', fontSize: 'bold'}}>
											Los siguientes XML´s no se registraron correctamente, favor de volver a intentar:
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Table className={'desing-acresco'}>
											<TableHead>
												<TableRow>
													<TableCell component="th" align={'left'}>Archivo</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												
												{this.state.response_content.erroneos.map((item, key) => (
													<Fragment key={key}>
														<TableRow>
															<TableCell align={'left'}>{item}</TableCell>
														</TableRow>
													</Fragment>
												))
												}
											</TableBody>
										</Table>
									</Grid>
								</Fragment>
							) : null}*/}
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={this.close2} color="primary">
							Cerrar
						</Button>
						
						{/*<BotonAccionFooterModal
							id={this.state.id_cat_sexo}
							tipo={this.state.tipo}
							close={this.close2}
							save={this.save2}
						/>*/}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCargaCFDI.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	project: PropTypes.bool,
	listDropbox: PropTypes.array,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCargaCFDI;
