import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const CargaCFDINominaService = {
	Listar: (filtro, paginacion) => {
		console.log('PAGINACION', paginacion);
		console.log('FILTRO', filtro);
		let fecha = (filtro.fecha || null) ? DateFormat.FormatSql(filtro.fecha) : null;
		let fecha_inicio = (filtro.fecha_inicio || null) ? DateFormat.FormatSql(filtro.fecha_inicio) : null;
		let fecha_fin = (filtro.fecha_fin || null) ? DateFormat.FormatSql(filtro.fecha_fin) : null;
		
		let params = {
			filtro: {
				empresa: filtro.empresa || null,
				cliente: filtro.cliente || null,
				id_cat_facturacion_status_sat: filtro.id_cat_facturacion_status_sat || null,
				id_cat_facturacion_status_pago: filtro.id_cat_facturacion_status_pago || null,
				id_cat_facturacion_plaza: filtro.id_cat_facturacion_plaza || null,
				anio: filtro.anio || null,
				periodo: filtro.periodo || null,
				serie: filtro.serie || null,
				folio: filtro.folio || null,
				fecha_inicio: fecha_inicio || null,
				fecha_fin: fecha_fin || null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Carga_CFDI_Nomina_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Excel: (filtro, paginacion, archivo) => {
		console.log('PAGINACION', paginacion);
		let params = {
			empresa: filtro.empresa || null,
			cliente: filtro.cliente || null,
			id_cat_facturacion_status_sat: filtro.id_cat_facturacion_status_sat || null,
			id_cat_facturacion_status_pago: filtro.id_cat_facturacion_status_pago || null,
			id_cat_facturacion_plaza: filtro.id_cat_facturacion_plaza || null,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.export('_Carga_CFDI_Nomina_Datos_Excel', ['Cargo', 'CFDI'], params, false, false, false, archivo === "EXCEL", (response) => {
				resolve(response);
				this.setState({
					to: '',
					cc: '',
					to_name: '',
					subject: '',
					mensaje: '',
				});
			}, (error) => {
				reject(error);
			});
		});
	},
	ListarTodo: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Carga_CFDI_Nomina_Datos_All', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalles: (id_facturacion) => {
		let params = {
			id_facturacion: id_facturacion,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Carga_CFDI_Nomina_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Guardar: (form) => {
		let params = {
			id_cliente: form.select_cliente.id_cliente,
			archivos: form.archivos,
			xmls: form.jsonXML,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Carga_CFDI_Nomina_Agregar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarX: (form) => {
		let params = {
			id_cliente: form.select_cliente.id_cliente,
			archivos: form.archivos,
			fecha_pago: form.fecha_pago,
			xmls: form.jsonXML,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Carga_CFDI_Nomina_Agregar_X', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarPDF: (form) => {
		let params = {
			archivos: form.archivos,
			xmls: form.jsonXML,
			activo: form.activo ? 1 : 0,
			pdf: form.pdf ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Carga_CFDI_Nomina_Agregar_PDF', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_facturacion_cfdi) => {
		let params = {
			id_facturacion_cfdi: id_facturacion_cfdi
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Carga_CFDI_Nomina_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Cancelar: (form) => {
		let fecha_cancelacion = form.fecha_cancelacion && form.fecha_cancelacion !== 'NaN-NaN-NaN' ? DateFormat.FormatSql(form.fecha_cancelacion) : '';
		let params = {
			id_cliente: form.id_cliente,
			id_facturacion_cfdi: form.id_facturacion_cfdi,
			id_facturacion_cfdi_parcial: form.id_facturacion_cfdi_parcial || '',
			fecha_cancelacion: fecha_cancelacion,
			folio_fiscal_sustituye: form.folio_fiscal_sustituye,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Carga_CFDI_Nomina_Cancelar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Pdf: (form) => {
		let params = {
			id_facturacion_cfdi: form.id_facturacion_cfdi || '',
			id_facturacion_cfdi_parcial: form.id_facturacion_cfdi_parcial || '',
			archivo_pdf: form.archivos[0],
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Carga_CFDI_Nomina_PDF', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Observacion: (form) => {
		let params = {
			id_cliente: form.id_cliente,
			id_facturacion_cfdi: form.id_facturacion_cfdi,
			id_facturacion_cfdi_parcial: form.id_facturacion_cfdi_parcial || '',
			observacion: form.observacion,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Carga_CFDI_Nomina_Observacion', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	
};
