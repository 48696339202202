import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const EmpresaService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				nombre_comercial: filtro.nombre_comercial || null,
				razon_social: filtro.razon_social || null,
				rfc: filtro.rfc || null,
				correo_electronico: filtro.correo_electronico || null,
				estatus: filtro.estatus || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Empresa_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_empresa: form.id_empresa || '',
			nombre_comercial: form.nombre_comercial || '',
			razon_social: form.razon_social || '',
			rfc: form.rfc || '',
			correo_electronico: form.correo_electronico || '',
			telefono: form.telefono || '',
			id_cat_giro_cliente: form.id_cat_giro_cliente || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Empresa_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_empresa: form.id_empresa || '',
			nombre_comercial: form.nombre_comercial || '',
			razon_social: form.razon_social || '',
			rfc: form.rfc || '',
			correo_electronico: form.correo_electronico || '',
			telefono: form.telefono || '',
			id_cat_giro_cliente: form.id_cat_giro_cliente || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Empresa_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_empresa) => {
		let params = {
			id_empresa: id_empresa
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Empresa_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
