import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import ModalEmpresaFacturacion from "./ModalEmpresaFacturacion";
import {CONFIG} from "../../../../settings/Config/Config";
import Avatar from "@material-ui/core/Avatar";
import {AttachFile, AttachMoney, Business, Description, Lock, ClearAll, HdrStrong, InsertDriveFile} from "@material-ui/icons";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import ModalEmpresaSucursal from "./ModalEmpresaSucursal";
import empresa_default from "../../../../assets/img/icons/business.svg";
import ModalEmpresaSucursalSerie from "./ModalEmpresaSucursalSerie";
import ModalEmpresaSucursalCertificado from "./ModalEmpresaSucursalCertificado";
import ModalEmpresaCatalogos from "./ModalEmpresaCatalogos";

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar la empresa facturación ${item.nombre_razon_social}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				EmpresaFacturacionExtService.Eliminar(item.id_empresa).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	verDocumento = (path) => {
		console.log("PATH ::: ", path);
		DropboxApi.Open(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
	};
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'} className={'w-10-px padding-20-L padding-10-R'}>
								ID
							</TableCell>
							<TableCell component="th" align={'center'} className={'w-60-px'}>Logo</TableCell>
							<TableCell component="th" align={'left'}>Nombre o Razón Social</TableCell>
							<TableCell component="th" align={'center'}>RFC</TableCell>
							<TableCell component="th" align={'left'}>Tipo Persona</TableCell>
							<TableCell component="th" align={'left'}>Correo electronico</TableCell>
							<TableCell component="th" align={'center'}>Telefono</TableCell>
							<TableCell component="th" align={'center'}>Activo</TableCell>
							<TableCell component="th" align={'center'} className={'w-70-px'}>Sucursales</TableCell>
							<TableCell component="th" align={'center'} className={'w-70-px'}>Certificados</TableCell>
							<TableCell component="th" align={'center'} className={'w-70-px'}>Series</TableCell>
							<TableCell component="th" align={'center'} className={'w-70-px'}>Catálogos</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<TableRow key={key}>
								<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
									{item.id_empresa}
								</TableCell>
								<TableCell align={'center'}>
									<div className={'margin-10-L'}>
										<div className={'card-0 b-r-100 w-40-px h-40-px'}>
											<Avatar
												style={{
													width: 40,
													height: 40
												}}
												alt={item.archivo_logo}
												src={item.archivo_logo ? CONFIG.src_facturacion + item.archivo_logo : empresa_default}
											/>
										</div>
									</div>
								</TableCell>
								<TableCell align={'left'}>{item.nombre_razon_social}</TableCell>
								<TableCell align={'center'}>{item.rfc}</TableCell>
								<TableCell align={'left'}>{item.tipo_persona}</TableCell>
								<TableCell align={'left'}>{item.correo_electronico}</TableCell>
								<TableCell align={'center'}>{item.telefono || '-'}</TableCell>
								
								<TableCell align={'center'}>
									{item.activo === 1 ? (
										<div style={{
											width: "50px",
											color: "white",
											borderRadius: "3px",
											padding: "2px 10px",
											background: "darkgreen",
											display: "inline-grid",
										}}>Activo</div>
									) : (
										<div style={{
											width: "50px",
											color: "white",
											borderRadius: "3px",
											padding: "2px 10px",
											background: "#901C30",
											display: "inline-grid",
										}}>Inactivo</div>
									)}
								</TableCell>
								
								<TableCell align={'center'}>
									<ModalEmpresaSucursal
										tipo={'add'}
										item={item}
										componente={(
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Sucursales SAT">
												<IconButton aria-label="Sucursales SAT"><Business/></IconButton>
											</Tooltip>
										)}
										RefreshList={this.props.RefreshList}
										showSnackBars={this.props.showSnackBars}
									/>
								</TableCell>
								
								
								<TableCell align={'center'}>
									<ModalEmpresaSucursalCertificado
										tipo={'add'}
										item={item}
										componente={(
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Certificados">
												<IconButton aria-label="Certificados"><Lock/></IconButton>
											</Tooltip>
										)}
										RefreshList={this.props.RefreshList}
										showSnackBars={this.props.showSnackBars}
									/>
								</TableCell>
								
								
								<TableCell align={'center'}>
									<ModalEmpresaSucursalSerie
										tipo={'add'}
										item={item}
										componente={(
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Series">
												<IconButton aria-label="Series"><ClearAll/></IconButton>
											</Tooltip>
										)}
										RefreshList={this.props.RefreshList}
										showSnackBars={this.props.showSnackBars}
									/>
								</TableCell>
								
								<TableCell align={'center'}>
									<ModalEmpresaCatalogos
										tipo={'add'}
										item={item}
										componente={(
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Catálogos">
												<IconButton aria-label="Catálogos"><HdrStrong/></IconButton>
											</Tooltip>
										)}
										RefreshList={this.props.RefreshList}
										showSnackBars={this.props.showSnackBars}
									/>
								</TableCell>
								
								<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
									<div className={'w-auto vertical-inline'}>
										<div className={'v-center'}>
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
												<IconButton aria-label="Eliminar"
												            onClick={() => this.eliminar(item)}>
													<DeleteOutlined/>
												</IconButton>
											</Tooltip>
										</div>
										<div className={'v-center'}>
											<ModalEmpresaFacturacion
												tipo={'edit'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
										<div className={'v-center'}>
											<ModalEmpresaFacturacion
												tipo={'view'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ListaTabla;
