import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import {CargaCFDIService} from "../../../../services/CargaCFDIService/CargaCFDIService";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";


class ModalVerificacionCFDI extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			comprobante_base64: '',
			comprobante_base64Tipo: '',
			comprobante_archivo: '',
			comprobante_formato: '',
			
			facturados: [],
			no_facturados: []
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.setState({
			comprobante_base64: '',
			comprobante_base64Tipo: '',
			comprobante_archivo: '',
			comprobante_formato: '',
		});
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	
	subir_comprobante = () => {
		try {
			
			if (!this.state.comprobante_formato) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Selecciona el comprobante.'
				})
			}
			
			if (!this.state.comprobante_archivo) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Selecciona un comprobante.'
				})
			}
			
			try {
				CargaCFDIService.SubirArchivoVerificacion(this.state).then((response) => {
					this.setState({
						comprobante_base64: '',
						comprobante_base64Tipo: '',
						comprobante_archivo: '',
						comprobante_formato: '',
					
						facturados: response.data.facturados,
						no_facturados: response.data.no_facturados
					});
					
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			} catch (e) {
				this.props.showSnackBars('error', e.mensaje);
			}
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	
	render() {
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => this.open()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_empresa}
						tipo={this.state.tipo}
						onClick={() => this.open()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Verificación de CFDI´s
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BoxSelectFile
									MegaByte={5}
									label={(
										<b className={'vertical-inline v-center text-blue'}>Archivo Excel</b>
									)}
									style={{
										color: 'black',
										height: '115px'
									}}
									item={{
										base64: this.state.comprobante_base64,
										base64Tipo: this.state.comprobante_base64Tipo,
										archivo: this.state.comprobante_archivo,
										formato: this.state.comprobante_formato,
									}}
									onChange={(r) => {
										this.handleChange({
											target: {
												name: 'comprobante_base64',
												value: r.base64
											}
										});
										this.handleChange({
											target: {
												name: 'comprobante_base64Tipo',
												value: r.base64Tipo
											}
										});
										this.handleChange({
											target: {
												name: 'comprobante_archivo',
												value: r.archivo
											}
										});
										this.handleChange({
											target: {
												name: 'comprobante_formato',
												value: r.formato
											}
										});
									}}
									showSnackBars={this.props.showSnackBars}
								/>
							</Grid>
							
							{this.state.facturados.length > 0 ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-14 text-center bg-white padding-5'} style={{color: '#2884a5', fontSize: 'bold'}}>
											Depósitos que no se han facturado:
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Table className={'desing-acresco'}>
											<TableHead>
												<TableRow>
													<TableCell component="th" align={'left'}>Fecha</TableCell>
													<TableCell component="th" align={'left'}>Cliente</TableCell>
													<TableCell component="th" align={'left'}>Forma pago</TableCell>
													<TableCell component="th" align={'left'}>Total</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												
												{this.state.facturados.map((item, key) => (
													<Fragment key={key}>
														<TableRow>
															<TableCell align={'left'}>{item.fecha}</TableCell>
															<TableCell align={'left'}>{item.cliente}</TableCell>
															<TableCell align={'left'}>{item.forma_pago}</TableCell>
															<TableCell align={'left'}>{item.total}</TableCell>
														</TableRow>
													</Fragment>
												))
												}
											</TableBody>
										</Table>
									</Grid>
								</Fragment>
							) : null}
							
							{this.state.no_facturados.length > 0 ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-14 text-center bg-white padding-5'} style={{color: '#901C30', fontSize: 'bold'}}>
											Facturas sin registros de depósitos:
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Table className={'desing-acresco'}>
											<TableHead>
												<TableRow>
													<TableCell component="th" align={'left'}>Fecha</TableCell>
													<TableCell component="th" align={'left'}>Cliente</TableCell>
													<TableCell component="th" align={'left'}>Forma pago</TableCell>
													<TableCell component="th" align={'left'}>Total</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												
												{this.state.no_facturados.map((item, key) => (
													<Fragment key={key}>
														<TableRow>
															<TableCell align={'left'}>{item.fecha}</TableCell>
															<TableCell align={'left'}>{item.cliente}</TableCell>
															<TableCell align={'left'}>{item.forma_pago}</TableCell>
															<TableCell align={'left'}>{item.total}</TableCell>
														</TableRow>
													</Fragment>
												))
												}
											</TableBody>
										</Table>
									</Grid>
								</Fragment>
							) : null}
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button onClick={() => this.subir_comprobante()} color="primary" variant={"contained"}>
									Verificar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ModalVerificacionCFDI.propTypes = {
	item: PropTypes.object.isRequired,
	componente: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalVerificacionCFDI;
