import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const FichaTecnicaStatusService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Ficha_Tecnica_Status_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			quien_realiza: form.quien_realiza,
			ficha_tecnica_status: form.ficha_tecnica_status,
			descripcion: form.descripcion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Ficha_Tecnica_Status_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_ficha_tecnica_status: form.id_cat_ficha_tecnica_status,
			quien_realiza: form.quien_realiza,
			ficha_tecnica_status: form.ficha_tecnica_status,
			descripcion: form.descripcion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Ficha_Tecnica_Status_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_ficha_tecnica_status) => {
		let params = {
			id_cat_ficha_tecnica_status: id_cat_ficha_tecnica_status
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Ficha_Tecnica_Status_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
