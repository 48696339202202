import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {HdrStrong, PersonOutlined, SearchOutlined, ViewList, ViewModule} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {MetodoPagoService} from "../../../../services/_Cat/MetodoPagoService/MetodoPagoService";
import {UsuariosService} from "../../../../services/_Sis/UsuariosService/UsuariosService";
import {TipoUsuarioService} from "../../../../services/_Cat/TipoUsuarioService/TipoUsuarioService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";


class FiltrosFacturacion extends Component {
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			id_usuario: '',
			nombre_usuario: '',
			id_cat_tipo_usuario: '',
			fecha_alta: false,
			activo: '',
			list_cat_tipo_usuario: [],
			view: true
		};
		
		this.ListaTipoUsuario();
	}
	
	ListaTipoUsuario = () => {
		TipoUsuarioService.Listar().then((response) => {
			this.setState({
				list_cat_tipo_usuario: response.data,
			});
		}).catch((error) => {
			this.setState({
				list_cat_tipo_usuario: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_usuario = this.state.id_usuario || undefined;
		filtro.nombre_usuario = this.state.nombre_usuario || undefined;
		filtro.id_cat_tipo_usuario = this.state.id_cat_tipo_usuario || undefined;
		filtro.fecha_alta = this.state.fecha_alta || undefined;
		filtro.activo = this.state.activo || undefined;
		filtro.view = this.state.view || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					{/*<Grid item xs={6} sm={6} md={4} lg={2} xl={2} align={'left'}>
						<TextField
							type={'text'}
							fullWidth
							name="id_usuario"
							onChange={this.handleChange}
							label="ID"
							autoComplete={'off'}
							value={this.state.id_usuario}
						/>
					</Grid>*/}
					
					<Grid item xs={6} sm={6} md={4} lg={2} xl={2} align={'left'}>
						<TextField
							type={'text'}
							fullWidth
							name="nombre_usuario"
							onChange={this.handleChange}
							label="Nombre de usuario"
							autoComplete={'off'}
							value={this.state.nombre_usuario}
						/>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_tipo_usuario"
									label="Tipo de Usuario"
									value={this.state.id_cat_tipo_usuario}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.list_cat_tipo_usuario.map(option => (
										<option key={option.id_cat_tipo_usuario}
										        value={option.id_cat_tipo_usuario}>
											{option.tipo_usuario}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel htmlFor="age-native-activo">Activo</InputLabel>
									<Select
										native
										value={this.state.activo}
										onChange={this.handleChange}
										inputProps={{
											name: 'activo',
											id: 'age-native-activo',
										}}
									>
										<option value={'all'}>Todos</option>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					
					<Grid item xs={6} sm={6} md={4} lg={1} xl={1} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
					
					<Grid item xs={12} sm={12} md={6} lg={4} xl={4} align={'right'}>
						<div className={'w-auto vertical-inline'}>
							{ this.state.view ?
								<div className={'v-center'}>
									<Tooltip TransitionComponent={Zoom} placement={"top"} style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}} title="Cambio lista">
										<IconButton aria-label="Cambio lista" onClick={() => {
											this.setState({view: !this.state.view}); this.HandleFiltro(); }}><ViewModule/></IconButton>
									</Tooltip>
								</div>
								:
								<div className={'v-center'}>
									<Tooltip TransitionComponent={Zoom} placement={"top"} style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}} title="Cambio lista">
										<IconButton aria-label="Cambio lista" onClick={() => {
											this.setState({view: !this.state.view}); this.HandleFiltro(); }}><ViewList/></IconButton>
									</Tooltip>
								</div>
							}
						</div>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosFacturacion.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosFacturacion;
