import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import {
	BotonAccionAbrirModal,
	BotonAccionFooterModal,
	TituloHeaderModal
} from "../../../Include/MiniComponents/GlobalComponent";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import {HdrWeakOutlined, Description} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {CatService} from "../../../../services/_Cat/CatService/CatService";

class ModalEmpresaFacturacion extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_empresa: (props.item || {}).id_empresa,
			id_cat_tipo_persona: '',
			nombre_razon_social: '',
			rfc: '',
			sucursal: '',
			correo_electronico: '',
			telefono: '',
			pagina_web: '',
			id_cat_pais: '',
			codigo_postal: '',
			password: '',
			serie: '',
			folio_inicial: '',
			
			logo_base64Tipo: '',
			logo_base64: '',
			logo_archivo: '',
			logo_formato: '',
			
			cer_base64Tipo: '',
			cer_base64: '',
			cer_archivo: '',
			cer_formato: '',
			
			key_base64Tipo: '',
			key_base64: '',
			key_archivo: '',
			key_formato: '',
			
			terminos_condiciones: false,
			activo: true,
			
			cat_pais: [],
		};
		
		console.log("MODALCLIENTERECEPTORAPAGO::: ", this.state.id_cliente);
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			this.ListPais();
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	ListPais = () => {
		CatService.ListPais().then((response) => {
			this.setState({
				cat_pais: response.data,
				id_cat_pais: 401
			});
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		if (this.state.id_empresa > 0) {
			EmpresaFacturacionExtService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			EmpresaFacturacionExtService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_empresa: (this.props.item || {}).id_empresa,
			id_cat_tipo_persona: '',
			nombre_razon_social: '',
			rfc: '',
			sucursal: '',
			correo_electronico: '',
			telefono: '',
			id_cat_pais: '',
			codigo_postal: '',
			password: '',
			pagina_web: '',
			serie: '',
			folio_inicial: '',
			
			logo_base64Tipo: '',
			logo_base64: '',
			logo_archivo: '',
			logo_formato: '',
			
			cer_base64Tipo: '',
			cer_base64: '',
			cer_archivo: '',
			cer_formato: '',
			
			key_base64Tipo: '',
			key_base64: '',
			key_archivo: '',
			key_formato: '',
			
			terminos_condiciones: false,
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.detalle(item.id_empresa);
		
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		
		this.detalle(item.id_empresa);
		
		this.open();
	};
	
	detalle = (id_empresa) => {
		EmpresaFacturacionExtService.Detalle(id_empresa).then((response) => {
			const {item} = this.props;
			console.log(this.props);
			this.setState({
				id_empresa: response.data.id_empresa,
				id_cat_tipo_persona: response.data.id_cat_tipo_persona || '',
				sucursal: response.data.sucursal || '',
				nombre_razon_social: response.data.nombre_razon_social || '',
				rfc: response.data.rfc || '',
				correo_electronico: response.data.correo_electronico || '',
				telefono: response.data.telefono || '',
				id_cat_pais: response.data.id_cat_pais || '',
				codigo_postal: response.data.codigo_postal || '',
				password: response.data.password || '',
				pagina_web: response.data.pagina_web || '',
				serie: response.data.serie || '',
				folio_inicial: response.data.folio_inicial || '',
				logo_base64Tipo: response.data.logo_base64Tipo || '',
				logo_base64: response.data.logo_base64 || '',
				logo_archivo: response.data.logo_archivo || '',
				logo_formato: response.data.logo_formato || '',
				terminos_condiciones: (response.data.terminos_condiciones === 1),
				activo: (response.data.activo === 1),
			});
		}).catch((error) => {
			const {item} = this.props;
			console.log(this.props);
			this.setState({
				id_empresa: '',
				id_cat_tipo_persona: '',
				sucursal: '',
				nombre_razon_social: '',
				rfc: '',
				correo_electronico: '',
				telefono: '',
				id_cat_pais: '',
				codigo_postal: '',
				password: '',
				pagina_web: '',
				serie: '',
				folio_inicial: '',
				logo_base64Tipo: '',
				logo_base64: '',
				logo_archivo: '',
				logo_formato: '',
				terminos_condiciones: false,
				activo: false,
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	clienteRFC = () => {
		
		EmpresaFacturacionExtService.EmpresaRFC(this.state.rfc).then((response) => {
			let data = response.data;
			this.setState({
				id_cat_tipo_persona: data.id_cat_tipo_persona || '',
				nombre_razon_social: data.id_cat_tipo_persona === 2 ? data.razon_social : data.nombre,
				correo_electronico: data.correo_electronico || '',
				codigo_postal: ''
			});
		}).catch((error) => {
			this.setState({
				nombre_razon_social: '',
				correo_electronico: '',
				codigo_postal: '',
				id_cat_tipo_persona: this.state.rfc.length < 13 ? '2':'1',
			});
		});
	}
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_empresa}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Empresa Facturación'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<fieldset style={{border: 'none', padding: 0}}>
									<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Empresa</legend>
									
									<Grid container spacing={2} alignItems={"flex-end"}>
										
										<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
											
											<TextField
												type={'text'}
												fullWidth
												name="rfc"
												onChange={this.handleChange}
												onBlur={this.clienteRFC}
												inputProps={{maxLength: 13}}
												label="R.F.C." autoComplete={'off'}
												value={this.state.rfc}
												required
												disabled={this.props.tipo !== 'add'}
											/>
										
										</Grid>
										
										<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<HdrWeakOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<FormControl fullWidth required>
														<InputLabel>Tipo de persona</InputLabel>
														<Select
															native
															margin="dense"
															value={this.state.id_cat_tipo_persona}
															onChange={this.handleChange}
															name="id_cat_tipo_persona"
															input={<Input/>}
															disabled
														>
															<option value=""/>
															<option key={"1"} value={"1"}> {"Física"} </option>
															<option key={"2"} value={"2"}> {"Moral"} </option>
														</Select>
													</FormControl>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												required
												name="nombre_razon_social"
												label="Nombre o Razón Social"
												type="text"
												fullWidth
												value={this.state.nombre_razon_social}
												onChange={this.handleChange}
												disabled={this.state.tipo === 'view'}
											/>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
											
											<TextField
												required
												name="correo_electronico"
												label="Correo electronico"
												type="text"
												fullWidth
												value={this.state.correo_electronico}
												onChange={this.handleChange}
												disabled={this.state.tipo === 'view'}
											/>
										
										</Grid>
									</Grid>
								</fieldset>
							</Grid>
							
							{this.state.tipo === 'add' ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<fieldset style={{border: 'none', padding: 0}}>
											<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Sucursal</legend>
											
											<Grid container spacing={2}>
												
												<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
													<Grid container spacing={2}>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															
															<TextField
																required
																name="sucursal"
																label="Nombre Sucursal"
																type="text"
																fullWidth
																value={this.state.sucursal}
																onChange={this.handleChange}
																disabled={this.state.tipo === 'view'}
															/>
														
														</Grid>
														
														<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
															<Grid container spacing={1} alignItems={"flex-end"}>
																<Grid item className={'w-30-px'}>
																	<HdrWeakOutlined className={'w-100 text-gray'}/>
																</Grid>
																<Grid item className={'w-100-30-px'}>
																	<FormControl fullWidth required>
																		<InputLabel>País</InputLabel>
																		<Select
																			native
																			value={this.state.id_cat_pais}
																			onChange={this.handleChange}
																			name="id_cat_pais"
																			input={<Input/>}
																			disabled={this.props.tipo === 'view'}
																		>
																			<option value=""/>
																			{this.state.cat_pais.map(option => (
																				<option key={option.id_cat_pais}
																				        value={option.id_cat_pais}>
																					{option.pais}
																				</option>
																			))}
																		
																		</Select>
																	</FormControl>
																</Grid>
															</Grid>
														</Grid>
														
														<Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
															
															<TextField
																required
																name="codigo_postal"
																label="Código Postal"
																type="text"
																autocomplete="off"
																fullWidth
																value={this.state.codigo_postal}
																onChange={this.handleChange}
																disabled={this.state.tipo === 'view'}
																onKeyPress={EnteroSolo}
																inputProps={{
																	maxLength: 5,
																}}
															/>
														
														</Grid>
													
													
													</Grid>
												</Grid>
												
												<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
													<Grid container spacing={2}>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															
															<BoxSelectFile
																FormatAccepted={["image/png", "image/jpeg", "image/svg"]}
																MegaByte={5}
																// button={(
																// 	<AddOutlined/>
																// )}
																label={(
																	<b className={'vertical-inline v-center text-blue'}>Logo</b>
																)}
																style={{
																	color: 'black',
																	height: '115px'
																}}
																item={{
																	base64: this.state.logo_base64,
																	base64Tipo: this.state.logo_base64Tipo,
																	archivo: this.state.logo_archivo,
																	formato: this.state.logo_formato,
																}}
																onChange={(r) => {
																	this.handleChange({
																		target: {
																			name: 'logo_base64',
																			value: r.base64
																		}
																	});
																	this.handleChange({
																		target: {
																			name: 'logo_base64Tipo',
																			value: r.base64Tipo
																		}
																	});
																	this.handleChange({
																		target: {
																			name: 'logo_archivo',
																			value: r.archivo
																		}
																	});
																	this.handleChange({
																		target: {
																			name: 'logo_formato',
																			value: r.formato
																		}
																	});
																}}
																showSnackBars={this.props.showSnackBars}
																disabled={this.state.tipo === 'view'}
															/>
														
														</Grid>
													</Grid>
												</Grid>
												
												<Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
													<fieldset className={'borde-punteado-lightgray-2'}>
														<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Certificado</legend>
														
														<Grid container spacing={2} alignItems={"flex-end"}>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																
																<TextField
																	required
																	name="password"
																	label="Contraseña del Certificado"
																	type="password"
																	fullWidth
																	autocomplete="off"
																	value={this.state.password}
																	onChange={this.handleChange}
																	disabled={this.state.tipo === 'view'}
																	/*onKeyPress={EnteroSolo}
																	inputProps={{
																		maxLength: 6,
																	}}*/
																/>
															
															</Grid>
															
															<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
																<Grid container spacing={2}>
																	<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																		
																		<BoxSelectFile
																			FormatAccepted={["image/png", "image/jpeg", "image/svg"]}
																			MegaByte={5}
																			// button={(
																			// 	<AddOutlined/>
																			// )}
																			label={(
																				<b className={'vertical-inline v-center text-blue'}>Archivo del Certificado (.CER)</b>
																			)}
																			style={{
																				color: 'black',
																				height: '115px'
																			}}
																			item={{
																				base64: this.state.cer_base64,
																				base64Tipo: this.state.cer_base64Tipo,
																				archivo: this.state.cer_archivo,
																				formato: this.state.cer_formato,
																			}}
																			onChange={(r) => {
																				this.handleChange({
																					target: {
																						name: 'cer_base64',
																						value: r.base64
																					}
																				});
																				this.handleChange({
																					target: {
																						name: 'cer_base64Tipo',
																						value: r.base64Tipo
																					}
																				});
																				this.handleChange({
																					target: {
																						name: 'cer_archivo',
																						value: r.archivo
																					}
																				});
																				this.handleChange({
																					target: {
																						name: 'cer_formato',
																						value: r.formato
																					}
																				});
																			}}
																			showSnackBars={this.props.showSnackBars}
																			disabled={this.state.tipo === 'view'}
																		/>
																	
																	</Grid>
																</Grid>
															</Grid>
															
															<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
																<Grid container spacing={2}>
																	<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																		
																		<BoxSelectFile
																			FormatAccepted={["image/png", "image/jpeg", "image/svg"]}
																			MegaByte={5}
																			// button={(
																			// 	<AddOutlined/>
																			// )}
																			label={(
																				<b className={'vertical-inline v-center text-blue'}>Archivo del Certificado (.KEY)</b>
																			)}
																			style={{
																				color: 'black',
																				height: '115px'
																			}}
																			item={{
																				base64: this.state.key_base64,
																				base64Tipo: this.state.key_base64Tipo,
																				archivo: this.state.key_archivo,
																				formato: this.state.key_formato,
																			}}
																			onChange={(r) => {
																				this.handleChange({
																					target: {
																						name: 'key_base64',
																						value: r.base64
																					}
																				});
																				this.handleChange({
																					target: {
																						name: 'key_base64Tipo',
																						value: r.base64Tipo
																					}
																				});
																				this.handleChange({
																					target: {
																						name: 'key_archivo',
																						value: r.archivo
																					}
																				});
																				this.handleChange({
																					target: {
																						name: 'key_formato',
																						value: r.formato
																					}
																				});
																			}}
																			showSnackBars={this.props.showSnackBars}
																			disabled={this.state.tipo === 'view'}
																		/>
																	
																	</Grid>
																</Grid>
															</Grid>
														
														</Grid>
													</fieldset>
												</Grid>
												
												<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
													<fieldset className={'borde-punteado-lightgray-2 h-91'}>
														<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Serie</legend>
														
														<Grid container spacing={2} alignItems={"flex-end"}>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																
																<TextField
																	required
																	name="serie"
																	label="Serie"
																	type="serie"
																	fullWidth
																	value={this.state.serie}
																	onChange={this.handleChange}
																	disabled={this.state.tipo === 'view'}
																	/*onKeyPress={EnteroSolo}
																	inputProps={{
																		maxLength: 6,
																	}}*/
																/>
															
															</Grid>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																
																<TextField
																	required
																	name="folio_inicial"
																	label="Folio Inicial"
																	type="folio_inicial"
																	fullWidth
																	value={this.state.folio_inicial}
																	onChange={this.handleChange}
																	disabled={this.state.tipo === 'view'}
																	/*onKeyPress={EnteroSolo}
																	inputProps={{
																		maxLength: 6,
																	}}*/
																/>
															
															</Grid>
														
														</Grid>
													</fieldset>
												</Grid>
											
											</Grid>
										</fieldset>
									</Grid>
								</Fragment>
							) : null
							}
							
							
							<Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
								<div className={'vertical-inline margin-15-T'}>
									<div className={'v-center'}>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox"
														disabled={this.state.tipo === 'view'}
														onChange={this.handleChange}
														color="primary"
														name='terminos_condiciones'
														checked={this.state.terminos_condiciones}
														value="terminos_condiciones"
													/>
												}
												label={'Términos y Condiciones'}
											/>
										</FormGroup>
									</div>
									<div className={'v-center'}>
										<span style={{cursor: 'pointer'}} children={
											<Description className={'w-100 text-gray'}/>
										} onClick={() => {
										}}/>
									</div>
								</div>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_empresa}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEmpresaFacturacion.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalEmpresaFacturacion;
