import React, {Component, Fragment} from 'react';

import Typography from "@material-ui/core/Typography/index";
import Sync from '@material-ui/icons/Sync';
import ListaTabla from './Includes/ListaTabla';
import ModalTimbrado from './Includes/ModalTimbrado';
import ModalTimbradoPromotor from './Includes/ModalTimbradoPromotor';
import {TimbradoService} from '../../../services/TimbradoService/TimbradoService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {EnteroSolo, FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Zoom from '@material-ui/core/Zoom/index';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab/index";
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import carrito_de_compra_1 from "../../../assets/img/icons/facturacion.svg";
import Paginacion from "../../Include/Paginacion/Paginacion";
import FiltrosTimbrado from "./Includes/FiltrosTimbrado";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {CONFIG} from "../../../settings/Config/Config";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import {AddOutlined, BarChart, DeleteOutlined, Edit, HdrStrong, PlaylistAddOutlined, Visibility} from "@material-ui/icons";
import ModalEstadisticas from "./Includes/ModalEstadisticas";
import {DropboxApi} from "../../../settings/Dropbox/DropboxApi";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import Grid from "@material-ui/core/Grid";
import TabContext from "@material-ui/lab/TabContext";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import {TabPanel} from "@material-ui/lab";
import ModalEmpresa from "../EmpresaFacturacion/Includes/ModalEmpresaFacturacion";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import ModalCliente from "../ClienteFacturacion/Includes/ModalClienteFacturacion";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import carrito_de_compra_3 from "../../../assets/img/icons/facturacion.svg";
import {EmpresaFacturacionExtService} from "../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import {ClaveProductoServicioService} from "../../../services/_Cat/ClaveProductoServicioService/ClaveProductoServicioService";
import {ClaveUnidadMedidaService} from "../../../services/_Cat/ClaveUnidadMedidaService/ClaveUnidadMedidaService";
import {Card} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import icon_mas from "../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../assets/img/icons/expandir-menos.svg";
import {TimbradoFacturacionExtService} from "../../../services/TimbradoFacturacionExtService/TimbradoFacturacionExtService";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import PropTypes, {array, number} from "prop-types";
import AutoCompleteEmpresa from "./Includes/AutoCompleteEmpresa";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ModalPrevisualizarTimbrado from "./Includes/ModalPrevisualizarTimbrado";
import ModalDescargaTimbrado from "./Includes/ModalDescargaTimbrado";
import {TemporalFacturacionExtService} from "../../../services/TemporalFacturacionExtService/TemporalFacturacionExtService";
import Chip from "@material-ui/core/Chip";
import ModalOpcionesTimbrado from "../TimbradoFacturacion/Includes/ModalOpcionesTimbrado";
import IconButton from "@material-ui/core/IconButton";
import ModalEmpresaCatalogos from "../EmpresaFacturacion/Includes/ModalEmpresaCatalogos";
import {ReactMultiEmail} from 'react-multi-email';
import "react-multi-email/dist/style.css";


class Timbrado extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		const params = props.params || {};
		
		let ids_expandido = ReactLocalStorageService.get('ids_expandido');
		
		this.state = {
			id_cat_clave_producto_servicio: null,
			id_cat_tipo_comprobante: '',
			id_facturacion_cfdi_temporal: params.id_facturacion_cfdi_temporal,
			autocomplete_empresa: [],
			numero_identificacion: '',
			descripcion_concepto: '',
			cantidad: 1.0,
			precio_unitario: '',
			id_cat_exportacion: '',
			total: '',
			iva_incluido: false,
			iva_aplicado: false,
			iva_exento: false,
			informacion_global: false,
			
			listar_cliente: [],
			listar_empresa: [],
			listar_empresa_sucursal: [],
			listar_empresa_regimen_fiscal: [],
			
			listar_forma_pago: [],
			listar_clave_producto_servicio: [],
			listar_clave_unidad_medida: [],
			listar_tipo_comprobante: [],
			
			listar_metodo_pago: [],
			listar_periodo_pago: [],
			listar_cat_impuesto: [],
			listar_cat_tasa: [],
			listar_concepto: [],
			listar_unidad_medida: [],
			listar_cat_exportacion: [],
			
			listar_cat_impuestos: [],
			listar_cat_impuestos_retenidos: [],
			listar_cat_impuestos_trasladados: [],
			listar_cat_tipo_factor: [],
			listar_cat_tipo_cfdi: [],
			listar_cat_uso_cfdi: [],
			listar_cat_metodo_pago: [],
			listar_cat_forma_pago: [],
			listar_cat_moneda: [],
			listar_cat_objeto_impuesto: [],
			listar_empresa_sucursal_serie: [],
			listar_periodicidad: [],
			
			
			id_empresa: '',
			empresa: null,
			cliente: null,
			nombre_razon_social: '',
			id_cliente: '',
			id_empresa_sucursal: '',
			id_cat_regimen_fiscal: '',
			lugar_expedicion: '',
			cliente_regimen_fiscal: '',
			cliente_numero_registro_if: '',
			cliente_codigo_postal: '',
			id_empresa_regimen_fiscal: '',
			id_cat_uso_cfdi: 3,
			
			id_cat_tipo_cfdi: '',
			id_cat_metodo_pago: 1,
			id_cat_forma_pago: '',
			id_cat_moneda: '',
			fecha: '1',
			folio: '',
			condiciones_pago: '',
			clave_confirmacion: '',
			tipo_cambio: '1',
			id_empresa_sucursal_serie: '1',
			serie: '',
		
			id_cat_objeto_impuesto: '',
			id_cat_impuesto_trasladado: '',
			id_cat_tipo_factor_trasladado: '',
			
			concepto: '',
			clave_concepto: '',
			tipo_descuento: '1',
			descuento: '',
			id_empresa_clave_unidad: '',
			numero_pedimento: '',
			numero_predial: '',
			
			id_cat_impuesto: '',
			impuestos_traladados: [],
			
			id_cat_impuesto_retenido: '',
			id_cat_tipo_factor_retenido: '',
			tasa_cuota_retenido: '',
			impuestos_retenidos: [],
			
			partidas: [],
			
			cat_regimen_fiscal: [],
			valuetab: '1',
			
			desglose: '',
			archivo_xml: '',
			open_modal_xml: false,
			open_modal_result: false,
			
			rfc_receptor: '',
			rfc_emisor: '',
			sucursal_emisor: '',
			certificado_emisor: '',
			observaciones_emisor: '',
			enviar_a: [],
			enviar_cc: [],
			enviar_cco: [],
			
			id_cat_periodicidad: '',
			id_cat_meses_global: '',
			anio_global: '',
			
			folios_fiscales: false,
			id_cat_tipo_relacion: '',
			listar_tipo_relacion: [],
			folios_relacionados: [],
			folios: [],
			listar_folios: [],
			folio_fiscal: '',
			
			produccion: false,
			editar_partida: 0,
			autocomplete_concepto: {},
			
			btnTimbrado: false
		};
		
		console.log("PARAMS.ID_FACTURACION_CFDI_TEMPORAL::: ", params.id_facturacion_cfdi_temporal);
		
		this.RefreshList();
		
	}
	
	
	Temporal = () => {
		TemporalFacturacionExtService.CFDITemporalFac(this.state, {}).then((response) => {
			let item = response.data;
			let empresa = '';
			
			let emp = FieldsJs.Copy(this.state.listar_empresa);
			for (let i = 0; i < emp.length; i++) {
				if (Number(emp[i].id_empresa) === Number(item.id_empresa)) {
					empresa = emp[i];
				}
			}
			
			let cliente = '';
			let cli = FieldsJs.Copy(this.state.listar_cliente);
			for (let i = 0; i < cli.length; i++) {
				if (Number(cli[i].id_cliente) === Number(item.id_cliente)) {
					cliente = cli[i];
				}
			}
			
			if (item.partidas.length > 0) {
				for (let i = 0; i < item.partidas.length; i++) {
					if (item.partidas[i].trasladados <= 0) {
						item.partidas[i].impuestos_trasladados = [];
					}
					if (item.partidas[i].retenidos <= 0) {
						item.partidas[i].impuestos_retenidos = [];
					}
				}
			}
			console.log("TEMPORAL CFDI::: ", response, item.id_empresa, empresa);
			
			this.setState({
				id_cliente: item.id_cliente,
				id_empresa: item.id_empresa,
				empresa: empresa,
				cliente: cliente,
				rfc_emisor: item.rfc_emisor || '',
				sucursal_emisor: item.sucursal_emisor || '',
				certificado_emisor: item.certificado_emisor || '',
				observaciones_emisor: item.observaciones_emisor || '',
				enviar_a: item.enviar_a || [],
				enviar_cc: item.enviar_cc || [],
				enviar_cco: item.enviar_cco || [],
				id_empresa_sucursal: item.id_empresa_sucursal || '',
				lugar_expedicion: item.lugar_expedicion || '',
				cliente_regimen_fiscal: item.cliente_regimen_fiscal || '',
				cliente_numero_registro_if: item.cliente_numero_registro_if || '',
				partidas: item.partidas || [],
				id_cat_tipo_cfdi: item.id_cat_tipo_cfdi || '',
				id_cat_metodo_pago: item.id_cat_metodo_pago || '',
				id_cat_forma_pago: item.id_cat_forma_pago || '',
				id_cat_moneda: item.id_cat_moneda || '',
				id_cat_uso_cfdi: item.id_cat_uso_cfdi || '',
				id_cat_exportacion: item.id_cat_exportacion || '',
				id_empresa_sucursal_serie: item.id_empresa_sucursal_serie || '',
				fecha: item.fecha || '',
				condiciones_pago: item.condiciones_pago || '',
				clave_confirmacion: item.clave_confirmacion || '',
				tipo_cambio: item.tipo_cambio || '',
				produccion: item.produccion ? 1 : 0,
				activo: item.activo ? 1 : 0
			});
			
			setTimeout(() => {
				let desglose = this.calcularDesgoseTotales();
				this.setState({
					desglose: desglose
				});
			}, 1000);
			
			if (empresa != '') {
				this.handleChangeEmpresa(item.id_empresa, empresa);
			}
			if (cliente != '') {
				this.handleChangeCliente(item.id_cliente, cliente);
			}
		}).catch((error) => {
			this.setState({
				listar_cat_tipo_cfdi: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshTimbradoOtro = () => {
		this.setState({
			id_cliente: '',
			//id_empresa: '',
			//empresa: null,
			cliente: null,
			//rfc_emisor: '',
			//sucursal_emisor: '',
			//certificado_emisor: '',
			//observaciones_emisor: '',
			//enviar_a: '',
			//enviar_cc: '',
			//enviar_cco: '',
			//id_empresa_sucursal: '',
			//lugar_expedicion: '',
			cliente_regimen_fiscal: '',
			cliente_numero_registro_if: '',
			cliente_codigo_postal: '',
			//id_empresa_regimen_fiscal: '',
			partidas: [],
			//id_cat_tipo_cfdi: '',
			//id_cat_metodo_pago: '',
			id_cat_forma_pago: '',
			//id_cat_moneda: '',
			//id_cat_uso_cfdi: '',
			//id_empresa_sucursal_serie: '',
			fecha: '1',
			condiciones_pago: '',
			clave_confirmacion: '',
			tipo_cambio: '',
			produccion: 1,
			valuetab: '1',
		});
		
		this.handleChangeSerie(this.state.id_empresa_sucursal_serie);
		
		//this.RefreshList();
	}
	
	RefreshTimbrado = () => {
		this.setState({
			id_cliente: '',
			id_empresa: '',
			empresa: null,
			cliente: null,
			rfc_emisor: '',
			sucursal_emisor: '',
			certificado_emisor: '',
			observaciones_emisor: '',
			enviar_a: [],
			enviar_cc: [],
			enviar_cco: [],
			id_empresa_sucursal: '',
			lugar_expedicion: '',
			cliente_regimen_fiscal: '',
			cliente_numero_registro_if: '',
			cliente_codigo_postal: '',
			id_empresa_regimen_fiscal: '',
			partidas: [],
			id_cat_tipo_cfdi: '',
			id_cat_exportacion: '',
			//id_cat_metodo_pago: '',
			id_cat_forma_pago: '',
			id_cat_moneda: '',
			//id_cat_uso_cfdi: '',
			id_empresa_sucursal_serie: '',
			fecha: '1',
			condiciones_pago: '',
			clave_confirmacion: '',
			tipo_cambio: '',
			produccion: 1,
			valuetab: '1',
		});
		
		this.RefreshList();
	}
	
	RefreshList = () => {
		
		//this.Listar();
		this.ListaTipoFactor();
		this.ListaTipoCFDI();
		this.ListaUsoCFDI();
		this.ListaMetodoPago();
		this.ListaFormaPago();
		this.ListaObjetoImpuesto();
		this.ListaImpuestos();
		this.ListaImpuestosRetenidos();
		this.ListaImpuestosTraslados();
		this.ListaCliente();
		this.ListaClaveProductoServicio();
		this.ListaClaveUnidadMedida();
		this.ListarPeriodicidad();
		this.ListarTipoRelacion();
		this.ListarExportacion();
		
		
		if (Number(this.state.id_facturacion_cfdi_temporal) > 0) {
			this.ListaEmpresa().then(() => {
				this.Temporal();
			})
		} else {
			this.ListaEmpresa();
		}
		
	};
	
	
	listar_foliosAgregarRelacionados = () => {
		
		try {
			
			if (!this.state.id_cat_tipo_relacion) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Tipo Relación.'
				})
			}
			
			if (this.state.folios.length <= 0) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Folio(s) Relacionado(s).'
				})
			}
			
			/*if (!this.state.id_cat_tipo_factor_trasladado) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Tipo Factor.'
				})
			}*/
			
			let impuestos = FieldsJs.Copy(this.state.folios_relacionados);
			
			/*let band = false;
			for (let i = 0; i < impuestos.length; i++) {
				if (Number(this.state.id_cat_tipo_relacion) === Number(impuestos[i].id_cat_tipo_relacion)) {
					band = true;
				}
			}
			if (band) {
				throw Object({
					status: false, mensaje: 'El impuesto trasladado ya se encuentra agregado.'
				})
			}*/
			
			let cat_impuesto_t = FieldsJs.Copy(this.state.listar_tipo_relacion);
			
			let it = '';
			let de = '';
			
			for (let i = 0; i < cat_impuesto_t.length; i++) {
				if (Number(this.state.id_cat_tipo_relacion) === Number(cat_impuesto_t[i].id_cat_tipo_relacion)) {
					it = cat_impuesto_t[i].tipo_relacion;
					de = cat_impuesto_t[i].descripcion;
				}
			}
			
			let item = {
				id_cat_tipo_relacion: this.state.id_cat_tipo_relacion,
				tipo_relacion: it,
				descripcion: de,
				folios: this.state.folios,
			};
			
			impuestos.push(item);
			
			console.log("ITEM::: ADD::: ", item);
			
			this.setState({
				folios_relacionados: impuestos,
				id_cat_tipo_relacion: '',
				tipo_relacion: '',
				listar_folios: [],
				folios: [],
			});
			
		} catch (e) {
			console.error(e);
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	AgregarImpuestoTrasladado = () => {
		
		try {
			
			/*if (!this.state.id_cat_impuesto_trasladado) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Impuestos.'
				})
			}*/
			
			if (!FieldsJs.Field(this.state.id_cat_tipo_factor_trasladado)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Tipo Factor.'
				})
			}
			
			if (!FieldsJs.Field(this.state.id_cat_impuesto) && Number(this.state.id_cat_tipo_factor_trasladado) != 2) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Tasa o Cuota.'
				})
			}
			
			let impuestos = FieldsJs.Copy(this.state.impuestos_traladados);
			let band = false;
			for (let i = 0; i < impuestos.length; i++) {
				if (Number(this.state.id_cat_impuesto_trasladado) === Number(impuestos[i].id_cat_impuesto_trasladado)) {
					band = true;
				}
			}
			if (band) {
				throw Object({
					status: false, mensaje: 'El impuesto trasladado ya se encuentra agregado.'
				})
			}
			
			let cat_impuesto_t = FieldsJs.Copy(this.state.listar_cat_impuestos_trasladados);
			let cat_tipo_factor = FieldsJs.Copy(this.state.listar_cat_tipo_factor);
			let cat_impuesto = FieldsJs.Copy(this.state.listar_cat_impuestos);
			
			let it = '';
			let ifc = '';
			let im = '';
			
			for (let i = 0; i < cat_impuesto_t.length; i++) {
				if (Number(this.state.id_cat_impuesto_trasladado) === Number(cat_impuesto_t[i].id_cat_impuesto_trasladado)) {
					it = cat_impuesto_t[i].impuesto_trasladado;
				}
			}
			for (let i = 0; i < cat_tipo_factor.length; i++) {
				if (Number(this.state.id_cat_tipo_factor_trasladado) === Number(cat_tipo_factor[i].id_cat_tipo_factor)) {
					ifc = cat_tipo_factor[i].tipo_factor;
				}
			}
			for (let i = 0; i < cat_impuesto.length; i++) {
				if (Number(this.state.id_cat_tipo_factor_trasladado) === 1 && Number(this.state.id_cat_impuesto) === Number(cat_impuesto[i].id_cat_impuesto)) {
					im = cat_impuesto[i].impuesto;
				}
			}
			
			let item = {
				base: Number(this.state.precio_unitario) * Number(this.state.cantidad),
				id_cat_impuesto_trasladado: this.state.id_cat_impuesto_trasladado,
				impuesto_trasladado: it,
				tipo_impuesto: this.state.tipo_impuesto,
				tipo_factor: ifc,
				id_cat_impuesto: this.state.id_cat_impuesto,
				tasa_cuota: im,
				importe: (Number(this.state.precio_unitario) * Number(this.state.cantidad)) * Number(im)
			};
			
			impuestos.push(item);
			
			console.log(this.state.id_cat_impuesto_retenido, this.state.id_cat_tipo_factor_retenido, this.state.tasa_cuota_retenido, impuestos);
			
			this.setState({
				id_cat_impuesto_trasladado: '',
				id_cat_tipo_factor_trasladado: '',
				id_cat_impuesto: '',
				impuestos_traladados: impuestos,
			});
			
		} catch (e) {
			console.error(e);
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	AgregarImpuestoRetenido = () => {
		try {
			
			if (!this.state.id_cat_impuesto_retenido) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Impuestos Retenido.'
				})
			}
			
			if (!this.state.id_cat_tipo_factor_retenido) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Tipo Factor.'
				})
			}
			
			if (!this.state.tasa_cuota_retenido) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Tasa o Couta.'
				})
			}
			var str = this.state.tasa_cuota_retenido.toString();
			var numarray = str.split('.');
			
			if (Number.isInteger(Number(this.state.tasa_cuota_retenido)) || numarray[numarray.length - 1].length > 6) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Tasa o Couta debe tener decimales y no mayor a 6 decimales.'
				})
			}
			
			
			let impuestos = FieldsJs.Copy(this.state.impuestos_retenidos);
			let band = false;
			for (let i = 0; i < impuestos.length; i++) {
				if (Number(this.state.id_cat_impuesto_retenido) === Number(impuestos[i].id_cat_impuesto_retenido)) {
					band = true;
				}
			}
			if (band) {
				throw Object({
					status: false, mensaje: 'El impuesto retenido ya se encuentra agregado.'
				})
			}
			
			let cat_impuesto_r = FieldsJs.Copy(this.state.listar_cat_impuestos_retenidos);
			let cat_tipo_factor = FieldsJs.Copy(this.state.listar_cat_tipo_factor);
			
			let ir = '';
			let ifc = '';
			let tc = this.state.tasa_cuota_retenido;
			
			for (let i = 0; i < cat_impuesto_r.length; i++) {
				if (Number(this.state.id_cat_impuesto_retenido) === Number(cat_impuesto_r[i].id_cat_impuesto_retenido)) {
					ir = cat_impuesto_r[i].impuesto_retenido;
				}
			}
			for (let i = 0; i < cat_tipo_factor.length; i++) {
				if (Number(this.state.id_cat_tipo_factor_retenido) === Number(cat_tipo_factor[i].id_cat_tipo_factor)) {
					ifc = cat_tipo_factor[i].tipo_factor;
				}
			}
			
			let item = {
				base: Number(this.state.precio_unitario) * Number(this.state.cantidad),
				id_cat_impuesto_retenido: this.state.id_cat_impuesto_retenido,
				impuesto_retenido: ir,
				id_cat_tipo_factor_retenido: this.state.id_cat_tipo_factor_retenido,
				tipo_factor: ifc,
				tasa_cuota: tc,
				importe: (Number(this.state.precio_unitario) * Number(this.state.cantidad)) * Number(tc)
			};
			
			impuestos.push(item);
			
			console.log(this.state.id_cat_impuesto_retenido, this.state.id_cat_tipo_factor_retenido, this.state.tasa_cuota_retenido, impuestos);
			
			this.setState({
				id_cat_impuesto_retenido: '',
				id_cat_tipo_factor_retenido: '',
				tasa_cuota_retenido: '',
				impuestos_retenidos: impuestos
			});
			
		} catch (e) {
			console.error(e);
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	addPartida = () => {
		try {
			
			if (!this.state.clave_concepto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Clave Concepto.'
				})
			}
			
			if (!this.state.id_empresa_clave_producto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Concepto.'
				})
			}
			
			if (!this.state.id_empresa_clave_unidad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Unidad de Medida.'
				})
			}
			
			if (!this.state.cantidad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cantidad.'
				})
			}
			
			if (!this.state.precio_unitario) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Valor Unitario.'
				})
			}
			
			if (FieldsJs.inArray([2, 3], Number(this.state.tipo_descuento)) && !this.state.descuento) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Descuento.'
				})
			}
			
			if (!this.state.id_cat_objeto_impuesto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Objeto Impuesto.'
				})
			}
			
			let impuestos_t = FieldsJs.Field(this.state.impuestos_traladados) ? FieldsJs.Copy(this.state.impuestos_traladados) : [];
			
			if (Number(this.state.id_cat_objeto_impuesto) === 2 && !this.state.iva_incluido && impuestos_t.length <= 0) {
				throw Object({
					status: false, mensaje: 'Debe agregar un impuesto.'
				})
			}
			
			let partidas = FieldsJs.Copy(this.state.partidas);
			let productos = FieldsJs.Copy(this.state.listar_concepto);
			let unidades_medida = FieldsJs.Copy(this.state.listar_unidad_medida);
			
			let impuestos_r = FieldsJs.Copy(this.state.impuestos_retenidos);
			let impuestos_trasladados_total = 0;
			let impuestos_retenidos_total = 0;
			let descripcion = '';
			let clave = '';
			let unidad_medida = '';
			let clave_unidad = '';
			let unidad = '';
			let importe = 0;
			if (this.state.iva_incluido) {
				importe = (Number(this.state.precio_unitario) / 1.16) * Number(this.state.cantidad);
				let cat_impuesto_t = FieldsJs.Copy(this.state.listar_cat_impuestos_trasladados);
				let cat_tipo_factor = FieldsJs.Copy(this.state.listar_cat_tipo_factor);
				let cat_impuesto = FieldsJs.Copy(this.state.listar_cat_impuestos);
				
				let it = '';
				let ifc = '';
				let im = '';
				
				for (let i = 0; i < cat_impuesto_t.length; i++) {
					if (Number(2) === Number(cat_impuesto_t[i].id_cat_impuesto_trasladado)) {
						it = cat_impuesto_t[i].impuesto_trasladado;
					}
				}
				for (let i = 0; i < cat_tipo_factor.length; i++) {
					if (Number(1) === Number(cat_tipo_factor[i].id_cat_tipo_factor)) {
						ifc = cat_tipo_factor[i].tipo_factor;
					}
				}
				for (let i = 0; i < cat_impuesto.length; i++) {
					if (Number(3) === Number(cat_impuesto[i].id_cat_impuesto)) {
						im = cat_impuesto[i].impuesto;
					}
				}
				
				let item = {
					id_cat_impuesto_trasladado: 2,
					impuesto_trasladado: it,
					tipo_impuesto: 1,
					tipo_factor: ifc,
					id_cat_impuesto: 3,
					tasa_cuota: im,
				};
				
				impuestos_t.push(item);
			} else if (this.state.iva_aplicado) {
				importe = Number(this.state.cantidad) * Number(this.state.precio_unitario);
			} else {
				importe = Number(this.state.cantidad) * Number(this.state.precio_unitario);
				
			}
			let descuento = Number(this.state.tipo_descuento) === 2 ? importe * (Number(this.state.descuento) / 100) : Number(this.state.descuento);
			
			for (let i = 0; i < impuestos_t.length; i++) {
				impuestos_t[i].base = importe - descuento;
				impuestos_t[i].importe = (importe - descuento) * (impuestos_t[i].tasa_cuota);
				impuestos_trasladados_total = impuestos_trasladados_total + ((importe - descuento) * (impuestos_t[i].tasa_cuota));
				
			}
			for (let i = 0; i < impuestos_r.length; i++) {
				impuestos_r[i].base = importe - descuento;
				impuestos_r[i].importe = (importe - descuento) * (impuestos_r[i].tasa_cuota);
				impuestos_retenidos_total = impuestos_retenidos_total + ((importe - descuento) * (impuestos_r[i].tasa_cuota));
			}
			
			for (let i = 0; i < productos.length; i++) {
				if (Number(this.state.id_empresa_clave_producto) === Number(productos[i].id_empresa_clave_producto)) {
					clave = productos[i].clave_producto_servicio;
					descripcion = productos[i].descripcion;
				}
			}
			
			for (let i = 0; i < unidades_medida.length; i++) {
				if (Number(this.state.id_empresa_clave_unidad) === Number(unidades_medida[i].id_empresa_clave_unidad)) {
					unidad_medida = unidades_medida[i].concepto ? unidades_medida[i].clave_unidad + ' - ' + unidades_medida[i].concepto : unidades_medida[i].concepto;
					clave_unidad = unidades_medida[i].clave_unidad;
					unidad = unidades_medida[i].concepto;
				}
			}
			
			let item = {
				id_empresa_clave_producto: this.state.id_empresa_clave_producto,
				numero_identificacion: this.state.numero_identificacion,
				id_empresa_clave_unidad: this.state.id_empresa_clave_unidad,
				unidad_medida: unidad_medida,
				clave_unidad: clave_unidad,
				unidad: unidad,
				descripcion: descripcion,
				cantidad: this.state.cantidad,
				precio_unitario: this.state.iva_incluido ? importe : this.state.precio_unitario,
				importe: importe,
				concepto: this.state.concepto,
				clave_concepto: this.state.clave_concepto,
				tipo_descuento: this.state.tipo_descuento,
				descuento: descuento,
				numero_pedimento: this.state.numero_pedimento,
				numero_predial: this.state.numero_predial,
				id_cat_objeto_impuesto: this.state.id_cat_objeto_impuesto,
				trasladados: impuestos_trasladados_total,
				retenidos: impuestos_retenidos_total,
				impuestos_trasladados: impuestos_t,
				impuestos_retenidos: impuestos_r,
				iva_incluido: this.state.iva_incluido ? 1 : 0,
				iva_aplicado: this.state.iva_aplicado ? 1 : 0,
				iva_exento: this.state.iva_exento ? 1 : 0,
			};
			
			partidas.push(item);
			
			this.setState({
				
				id_cat_clave_producto_servicio: null,
				id_empresa_clave_unidad: '',
				id_empresa_clave_producto: '',
				descripcion_concepto: '',
				numero_identificacion: '',
				cantidad: 1.0,
				precio_unitario: '',
				concepto: '',
				clave_concepto: '',
				tipo_descuento: '1',
				descuento: '',
				id_cat_impuesto_trasladado: '',
				id_cat_tipo_factor_trasladado: '',
				id_cat_impuesto: '',
				impuestos_trasladados: [],
				numero_pedimento: '',
				numero_predial: '',
				id_cat_objeto_impuesto: '',
				iva_incluido: false,
				iva_aplicado: false,
				iva_exento: false,
				
				id_cat_impuesto_retenido: '',
				id_cat_tipo_factor_retenido: '',
				tasa_cuota_retenido: '',
				impuestos_traladados: [],
				impuestos_retenidos: [],
				partidas: partidas,
				autocomplete_concepto: {},
			});
			
			setTimeout(() => {
				let desglose = this.calcularDesgoseTotales();
				this.setState({
					desglose: desglose
				});
			}, 800);
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	modificarPartida = () => {
		try {
			
			if (!this.state.clave_concepto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Clave Concepto.'
				})
			}
			
			if (!this.state.id_empresa_clave_producto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Concepto.'
				})
			}
			
			if (!this.state.id_empresa_clave_unidad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Unidad de Medida.'
				})
			}
			
			if (!this.state.cantidad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cantidad.'
				})
			}
			
			if (!this.state.precio_unitario) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Valor Unitario.'
				})
			}
			
			if (FieldsJs.inArray([2, 3], Number(this.state.tipo_descuento)) && !this.state.descuento) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Descuento.'
				})
			}
			
			if (!this.state.id_cat_objeto_impuesto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Objeto Impuesto.'
				})
			}
			
			let impuestos_t = FieldsJs.Field(this.state.impuestos_traladados) ? FieldsJs.Copy(this.state.impuestos_traladados) : [];
			
			if (Number(this.state.id_cat_objeto_impuesto) === 2 && !this.state.iva_incluido && impuestos_t.length <= 0) {
				throw Object({
					status: false, mensaje: 'Debe agregar un impuesto.'
				})
			}
			
			let partidas = FieldsJs.Copy(this.state.partidas);
			let productos = FieldsJs.Copy(this.state.listar_concepto);
			let unidades_medida = FieldsJs.Copy(this.state.listar_unidad_medida);
			
			let impuestos_r = FieldsJs.Copy(this.state.impuestos_retenidos);
			let impuestos_trasladados_total = 0;
			let impuestos_retenidos_total = 0;
			let descripcion = '';
			let clave = '';
			let unidad_medida = '';
			let clave_unidad = '';
			let unidad = '';
			let importe = 0;
			if (this.state.iva_incluido) {
				importe = (Number(this.state.precio_unitario) / 1.16) * Number(this.state.cantidad);
				let cat_impuesto_t = FieldsJs.Copy(this.state.listar_cat_impuestos_trasladados);
				let cat_tipo_factor = FieldsJs.Copy(this.state.listar_cat_tipo_factor);
				let cat_impuesto = FieldsJs.Copy(this.state.listar_cat_impuestos);
				
				let it = '';
				let ifc = '';
				let im = '';
				
				for (let i = 0; i < cat_impuesto_t.length; i++) {
					if (Number(2) === Number(cat_impuesto_t[i].id_cat_impuesto_trasladado)) {
						it = cat_impuesto_t[i].impuesto_trasladado;
					}
				}
				for (let i = 0; i < cat_tipo_factor.length; i++) {
					if (Number(1) === Number(cat_tipo_factor[i].id_cat_tipo_factor)) {
						ifc = cat_tipo_factor[i].tipo_factor;
					}
				}
				for (let i = 0; i < cat_impuesto.length; i++) {
					if (Number(3) === Number(cat_impuesto[i].id_cat_impuesto)) {
						im = cat_impuesto[i].impuesto;
					}
				}
				
				let item = {
					id_cat_impuesto_trasladado: 2,
					impuesto_trasladado: it,
					tipo_impuesto: 1,
					tipo_factor: ifc,
					id_cat_impuesto: 3,
					tasa_cuota: im,
				};
				
				impuestos_t.push(item);
			} else if (this.state.iva_aplicado) {
				importe = Number(this.state.cantidad) * Number(this.state.precio_unitario);
			} else {
				importe = Number(this.state.cantidad) * Number(this.state.precio_unitario);
				
			}
			let descuento = Number(this.state.tipo_descuento) === 2 ? importe * (Number(this.state.descuento) / 100) : Number(this.state.descuento);
			
			for (let i = 0; i < impuestos_t.length; i++) {
				impuestos_t[i].base = importe - descuento;
				impuestos_t[i].importe = (importe - descuento) * (impuestos_t[i].tasa_cuota);
				impuestos_trasladados_total = impuestos_trasladados_total + ((importe - descuento) * (impuestos_t[i].tasa_cuota));
				
			}
			for (let i = 0; i < impuestos_r.length; i++) {
				impuestos_r[i].base = importe - descuento;
				impuestos_r[i].importe = (importe - descuento) * (impuestos_r[i].tasa_cuota);
				impuestos_retenidos_total = impuestos_retenidos_total + ((importe - descuento) * (impuestos_r[i].tasa_cuota));
			}
			
			for (let i = 0; i < productos.length; i++) {
				if (Number(this.state.id_empresa_clave_producto) === Number(productos[i].id_empresa_clave_producto)) {
					clave = productos[i].clave_producto_servicio;
					descripcion = productos[i].descripcion;
				}
			}
			
			for (let i = 0; i < unidades_medida.length; i++) {
				if (Number(this.state.id_empresa_clave_unidad) === Number(unidades_medida[i].id_empresa_clave_unidad)) {
					unidad_medida = unidades_medida[i].concepto ? unidades_medida[i].clave_unidad + ' - ' + unidades_medida[i].concepto : unidades_medida[i].concepto;
					clave_unidad = unidades_medida[i].clave_unidad;
					unidad = unidades_medida[i].concepto;
				}
			}
			
			let item = {
				id_empresa_clave_producto: this.state.id_empresa_clave_producto,
				numero_identificacion: this.state.numero_identificacion,
				id_empresa_clave_unidad: this.state.id_empresa_clave_unidad,
				unidad_medida: unidad_medida,
				clave_unidad: clave_unidad,
				unidad: unidad,
				descripcion: descripcion,
				cantidad: this.state.cantidad,
				precio_unitario: this.state.iva_incluido ? importe : this.state.precio_unitario,
				importe: importe,
				concepto: this.state.concepto,
				clave_concepto: this.state.clave_concepto,
				tipo_descuento: this.state.tipo_descuento,
				descuento: descuento,
				numero_pedimento: this.state.numero_pedimento,
				numero_predial: this.state.numero_predial,
				id_cat_objeto_impuesto: this.state.id_cat_objeto_impuesto,
				trasladados: impuestos_trasladados_total,
				retenidos: impuestos_retenidos_total,
				impuestos_trasladados: impuestos_t,
				impuestos_retenidos: impuestos_r,
				iva_incluido: this.state.iva_incluido ? 1 : 0,
				iva_aplicado: this.state.iva_aplicado ? 1 : 0,
			};
			
			partidas[Number(this.state.editar_partida) - 1] = item;
			
			this.setState({
				
				id_cat_clave_producto_servicio: null,
				id_empresa_clave_unidad: '',
				id_empresa_clave_producto: '',
				descripcion_concepto: '',
				numero_identificacion: '',
				cantidad: 1.0,
				precio_unitario: '',
				concepto: '',
				clave_concepto: '',
				tipo_descuento: '1',
				descuento: '',
				id_cat_impuesto_trasladado: '',
				id_cat_tipo_factor_trasladado: '',
				id_cat_impuesto: '',
				impuestos_trasladados: [],
				numero_pedimento: '',
				numero_predial: '',
				id_cat_objeto_impuesto: '',
				iva_incluido: false,
				iva_aplicado: false,
				iva_exento: false,
				editar_partida: 0,
				
				id_cat_impuesto_retenido: '',
				id_cat_tipo_factor_retenido: '',
				tasa_cuota_retenido: '',
				impuestos_traladados: [],
				impuestos_retenidos: [],
				partidas: partidas,
				autocomplete_concepto: {},
			}, () => {
			});
			
			setTimeout(() => {
				let desglose = this.calcularDesgoseTotales();
				this.setState({
					desglose: desglose
				});
			}, 800);
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	editarPartida = (value, key) => {
		try {
			console.log(value, key);
			this.setState({
				editar_partida: key + 1,
				id_empresa_clave_producto: value.id_empresa_clave_producto,
				numero_identificacion: value.numero_identificacion,
				id_empresa_clave_unidad: value.id_empresa_clave_unidad,
				unidad_medida: value.unidad_medida,
				clave_unidad: value.clave_unidad,
				unidad: value.unidad,
				descripcion: value.descripcion,
				cantidad: value.cantidad,
				precio_unitario: value.precio_unitario,
				importe: value.importe,
				concepto: value.concepto,
				clave_concepto: value.clave_concepto,
				tipo_descuento: value.tipo_descuento,
				descuento: value.descuento,
				numero_pedimento: value.numero_pedimento,
				numero_predial: value.numero_predial,
				id_cat_objeto_impuesto: value.id_cat_objeto_impuesto,
				trasladados: value.trasladados,
				retenidos: value.retenidos,
				impuestos_trasladados: value.impuestos_trasladados,
				impuestos_retenidos: value.impuestos_retenidos,
				iva_incluido: value.iva_incluido ? 1 : 0,
				iva_aplicado: value.iva_aplicado ? 1 : 0,
				iva_exento: value.iva_exento ? 1 : 0,
			}, () => {
				this.obtenerDatosConcepto();
			});
			
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	deletePartida = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar la partida?').then((r) => {
				if (r.button === 'Aceptar') {
					let partidas = FieldsJs.Copy(this.state.partidas);
					
					partidas.splice(key, 1);
					
					this.setState({
						partidas: partidas
					});
					
					setTimeout(() => {
						let desglose = this.calcularDesgoseTotales();
						this.setState({
							desglose: desglose
						});
					}, 800);
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	deleteRelacionados = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el folio relacionado?').then((r) => {
				if (r.button === 'Aceptar') {
					let folios_relacionados = FieldsJs.Copy(this.state.folios_relacionados);
					
					folios_relacionados.splice(key, 1);
					
					this.setState({
						folios_relacionados: folios_relacionados
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	deleteImpuestoTrasladado = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el impuesto trasladado?').then((r) => {
				if (r.button === 'Aceptar') {
					let impuestos_traladados = FieldsJs.Copy(this.state.impuestos_traladados);
					
					impuestos_traladados.splice(key, 1);
					
					this.setState({
						impuestos_traladados: impuestos_traladados
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	deleteImpuestoRetenido = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el impuesto retenido?').then((r) => {
				if (r.button === 'Aceptar') {
					let impuestos_retenidos = FieldsJs.Copy(this.state.impuestos_retenidos);
					
					impuestos_retenidos.splice(key, 1);
					
					this.setState({
						impuestos_retenidos: impuestos_retenidos
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	ListarExportacion = () => {
		CatService.ListExportacionFac({}, {}).then((response) => {
			this.setState({
				listar_cat_exportacion: response.data,
				id_cat_exportacion: 1,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_exportacion: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaTipoCFDI = () => {
		CatService.ListTipoCFDIFac({}, {}).then((response) => {
			this.setState({
				listar_cat_tipo_cfdi: response.data,
				id_cat_tipo_cfdi: 1,
				tipo_decimales_redondear: 2,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_tipo_cfdi: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaUsoCFDI = () => {
		CatService.ListUsoCFDIFac({}, {}).then((response) => {
			this.setState({
				listar_cat_uso_cfdi: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_uso_cfdi: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaFormaPago = () => {
		CatService.ListFormaPagoFac({}, {}).then((response) => {
			this.setState({
				listar_cat_forma_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_forma_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaMetodoPago = () => {
		CatService.ListMetodoPagoFac({}, {}).then((response) => {
			this.setState({
				listar_cat_metodo_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_metodo_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaMoneda = (id_empresa) => {
		if (FieldsJs.Field(id_empresa)) {
			CatService.ListMonedaFac(id_empresa, {}).then((response) => {
				this.setState({
					listar_cat_moneda: response.data,
					id_cat_moneda: response.data[0].id_cat_moneda,
				});
			}).catch((error) => {
				this.setState({
					listar_cat_moneda: []
				});
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			this.setState({
				listar_cat_moneda: []
			});
		}
	};
	
	ListaEmpresaSucursalSerie = (id_empresa_sucursal) => {
		EmpresaFacturacionExtService.Series(id_empresa_sucursal, {}).then((response) => {
			if (response.data.length > 0) {
				this.setState({
					listar_empresa_sucursal_serie: response.data,
					id_empresa_sucursal_serie: response.data[0].id_empresa_sucursal_serie,
					serie: response.data[0].serie
				});
				this.handleChangeSerie(response.data[0].id_empresa_sucursal_serie);
			} else {
				this.setState({
					listar_empresa_sucursal_serie: response.data,
					id_empresa_sucursal_serie: '',
				});
			}
		}).catch((error) => {
			this.setState({
				listar_cat_moneda: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaObjetoImpuesto = () => {
		CatService.ListObjetoImpuestoFac({}, {}).then((response) => {
			this.setState({
				listar_cat_objeto_impuesto: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_objeto_impuesto: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaEmpresa = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpresaFac(1, this.Usr.id_usuario, this.Usr.id_plaza).then((response) => {
				this.setState({
					listar_empresa: response.data,
				});
				resolve(response.data);
			}).catch((error) => {
				this.setState({
					listar_empresa: []
				});
				reject(error);
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListaEmpresaSucursal = (id_empresa) => {
		
		let empresas = FieldsJs.Copy(this.state.listar_empresa);
		for (let i = 0; i < empresas.length; i++) {
			if (Number(id_empresa) === Number(empresas[i].id_empresa)) {
				this.setState({
					rfc_emisor: empresas[i].rfc,
				});
			}
		}
		
		if (FieldsJs.Field(id_empresa)) {
			CatService.ListEmpresaSucursalFac(id_empresa, {}).then((response) => {
				if (response.data.length > 0) {
					this.setState({
						listar_empresa_sucursal: response.data,
						id_empresa_sucursal: response.data[0].id_empresa_sucursal,
						lugar_expedicion: response.data[0].codigo_postal,
						sucursal_emisor: response.data[0].nombre,
					});
					this.ListaEmpresaSucursalSerie(response.data[0].id_empresa_sucursal);
				} else {
					this.setState({
						listar_empresa_sucursal: response.data,
					});
				}
			}).catch((error) => {
				this.setState({
					listar_empresa_sucursal: []
				});
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			this.setState({
				listar_empresa_sucursal: []
			});
		}
	};
	
	ListaEmpresaRegimenFiscal = (id_empresa) => {
		if (FieldsJs.Field(id_empresa)) {
			CatService.ListEmpresaRegimenFiscalFac(id_empresa, {}).then((response) => {
				if (response.data.length > 0) {
					this.setState({
						listar_empresa_regimen_fiscal: response.data,
						id_empresa_regimen_fiscal: response.data[0].id_empresa_regimen_fiscal,
						id_cat_regimen_fiscal: response.data[0].id_cat_regimen_fiscal,
						
					});
				} else {
					this.setState({
						listar_empresa_regimen_fiscal: response.data,
					});
				}
			}).catch((error) => {
				this.setState({
					listar_empresa_regimen_fiscal: []
				});
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			this.setState({
				listar_empresa_regimen_fiscal: []
			});
		}
	};
	
	ListaCliente = () => {
		CatService.ListClienteFac(null).then((response) => {
			this.setState({
				listar_cliente: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cliente: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	lugarExpedicion = (id_empresa_sucursal) => {
		for (let i = 0; i < this.state.listar_empresa_sucursal.length; i++) {
			if (Number(id_empresa_sucursal) === Number(this.state.listar_empresa_sucursal[i].id_empresa_sucursal)) {
				this.setState({
					lugar_expedicion: this.state.listar_empresa_sucursal[i].codigo_postal,
					sucursal_emisor: this.state.listar_empresa_sucursal[i].nombre
				});
			}
		}
		
		this.ListaEmpresaSucursalSerie(id_empresa_sucursal);
	}
	
	clienteRecidenciaRegistro = (id_cliente) => {
		for (let i = 0; i < this.state.listar_cliente.length; i++) {
			if (Number(id_cliente) === Number(this.state.listar_cliente[i].id_cliente)) {
				this.setState({
					cliente_regimen_fiscal: this.state.listar_cliente[i].descripcion ? this.state.listar_cliente[i].regimen_fiscal + ' - ' + this.state.listar_cliente[i].descripcion : this.state.listar_cliente[i].regimen_fiscal,
					cliente_numero_registro_if: this.state.listar_cliente[i].numero_registro_if,
					cliente_codigo_postal: this.state.listar_cliente[i].codigo_postal,
					enviar_a: this.state.listar_cliente[i].correo_electronico ?? [],
					enviar_cc: this.state.listar_cliente[i].correo_electronico_copia ?? [],
					rfc_receptor: this.state.listar_cliente[i].rfc,
					nombre_razon_social: this.state.listar_cliente[i].nombre_razon_social
					
				});
			}
		}
	}
	
	ListaUnidadMedida = (id_empresa) => {
		return new Promise((resolve, reject) => {
			let params = {
				id_empresa: id_empresa,
				valuetab: '2'
			};
			EmpresaFacturacionExtService.Catalogos(params).then((response) => {
				this.setState({
					listar_unidad_medida: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					listar_unidad_medida: []
				});
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	ListaConcepto = (id_empresa) => {
		return new Promise((resolve, reject) => {
			let params = {
				id_empresa: id_empresa,
				valuetab: '3'
			};
			EmpresaFacturacionExtService.Catalogos(params).then((response) => {
				this.setState({
					listar_concepto: response.data,
				});
				resolve(response.data);
			}).catch((error) => {
				this.setState({
					listar_concepto: []
				});
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	ConceptoSelect = (concepto) => {
		let con = FieldsJs.Copy(this.state.listar_concepto);
		let lum = FieldsJs.Copy(this.state.listar_unidad_medida);
		
		
		let concep = '';
		for (let i = 0; i < con.length; i++) {
			if (concepto === (con[i].concepto)) {
				concep = con[i].id_empresa_clave_unidad;
				this.setState({
					id_empresa_clave_producto: con[i].id_empresa_clave_producto,
					clave_concepto: con[i].clave_producto_servicio,
					precio_unitario: con[i].precio_unitario,
					numero_identificacion: con[i].numero_identificacion,
					tipo_descuento: con[i].tipo_descuento.toString(),
					descuento: con[i].descuento,
				});
			}
		}
		
		console.log(concepto, lum, concep);
		let band = false;
		for (let i = 0; i < lum.length; i++) {
			if (concep === (lum[i].id_empresa_clave_unidad)) {
				band = true;
				this.setState({
					id_empresa_clave_unidad: lum[i].id_empresa_clave_unidad,
				});
			}
		}
		if (!band) {
			if (concep === '') {
				this.setState({
					id_empresa_clave_unidad: '',
				});
			} else {
				this.setState({
					id_empresa_clave_unidad: lum[0].id_empresa_clave_unidad,
				});
			}
		}
	}
	
	obtenerDatosConcepto = () => {
		let con = FieldsJs.Field(this.state.editar_partida) ? [] : FieldsJs.Copy(this.state.listar_concepto);
		let band = false;
		let conceptos = [];
		for (let i = 0; i < con.length; i++) {
			if (this.state.clave_concepto === con[i].clave_producto_servicio) {
				band = true;
				conceptos.push(con[i]);
			}
		}
		console.log("obtenerDatosConcepto", band, this.state.editar_partida, this.state.concepto);
		
		if (!band) {
			this.ListaConcepto(this.state.id_empresa).then((response) => {
				if (!FieldsJs.Field(this.state.editar_partida)) {
					this.setState({
						id_empresa_clave_producto: '',
						precio_unitario: '',
						id_empresa_clave_unidad: '',
						numero_identificacion: '',
						tipo_descuento: '',
						descuento: '',
						listar_concepto: response
					});
				} else {
					this.setState({autocomplete_concepto: this.state.concepto});
					this.ConceptoSelect(this.state.concepto);
				}
			});
			
		} else {
			this.setState({
				clave_concepto: con[0].clave_producto_servicio,
				id_empresa_clave_producto: con[0].id_empresa_clave_producto,
				precio_unitario: con[0].precio_unitario,
				id_empresa_clave_unidad: con[0].id_empresa_clave_unidad,
				numero_identificacion: con[0].numero_identificacion,
				tipo_descuento: con[0].tipo_descuento.toString(),
				descuento: con[0].descuento,
				listar_concepto: conceptos
			});
		}
	}
	
	ListaImpuestos = () => {
		CatService.ListImpuestoFac().then((response) => {
			this.setState({
				listar_cat_impuestos: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_impuestos: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaImpuestosRetenidos = () => {
		CatService.ListImpuestoRetenidoFac().then((response) => {
			this.setState({
				listar_cat_impuestos_retenidos: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_impuestos_retenidos: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaImpuestosTraslados = () => {
		CatService.ListImpuestoTrasladoFac().then((response) => {
			this.setState({
				listar_cat_impuestos_trasladados: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_impuestos_trasladados: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaTipoFactor = () => {
		CatService.ListTipoFactorFac().then((response) => {
			this.setState({
				listar_cat_tipo_factor: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_tipo_factor: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	ListaClaveProductoServicio = () => {
		ClaveProductoServicioService.Listar({}, {}).then((response) => {
			this.setState({
				listar_clave_producto_servicio: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_clave_producto_servicio: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaClaveUnidadMedida = () => {
		ClaveUnidadMedidaService.Listar({}, {}).then((response) => {
			this.setState({
				listar_clave_unidad_medida: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_clave_unidad_medida: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	Listar = () => {
		TimbradoService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			let listar_facturacion = response.data;
			
			this.setState({
				listar_facturacion: listar_facturacion,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
		}).catch((error) => {
			
			this.setState({listar_facturacion: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	timbradoFacturacion = () => {
		this.setState({btnTimbrado: true});
		TimbradoFacturacionExtService.Agregar(this.state).then((response) => {
			
			if (Number(response.data.code) === 200) {
				setTimeout(() => {
					this.handleChangeModalOpciones(response.ruta);
				}, 1000);
			} else {
				let msg = `${response.data.code} - ${response.data.message}`;
				PopupService.Confirm(['Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
					if (r.button === 'Aceptar') {
						this.setState({
							ruta: response.ruta,
							open_modal_xml: true
						});
					}
				});
			}
			this.setState({btnTimbrado: false});
		}).catch((error) => {
			this.setState({listar_facturacion: []});
			this.showSnackBars('error', error.mensaje);
			this.setState({btnTimbrado: false});
		});
	};
	
	temporalFacturacion = () => {
		let msg = `¿Seguro que deseas guardar temporalmente el timbrado?`;
		
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Aceptar') {
				TemporalFacturacionExtService.Agregar(this.state).then((response) => {
					this.props.history.push('/temporalfacturacion');
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	onTagsChangeEmpresa = (event, values) => {
		this.setState({
			tags_Empresa: values
		}, () => {
			// This will output an array of objects
			// given by Autocompelte options property.
			console.log(this.state.tags);
		});
	}
	handleChangeEmpresa = (id_empresa, valor) => {
		console.log(id_empresa.tarjet, valor);
		this.setState({
			empresa: valor,
			id_empresa: valor.id_empresa,
			impuestos_traladados: []
		});
		this.ListaEmpresaSucursal(valor.id_empresa);
		this.ListaEmpresaRegimenFiscal(valor.id_empresa);
		this.ListaConcepto(valor.id_empresa);
		this.ListaUnidadMedida(valor.id_empresa);
		this.ListaMoneda(valor.id_empresa);
	}
	
	handleChangeFolios = (id_cliente, valor) => {
		console.log(id_cliente.tarjet, valor);
		this.setState({
			folios: valor,
		});
	}
	
	handleChangeCliente = (id_cliente, valor) => {
		console.log(id_cliente.tarjet, valor);
		this.setState({
			cliente: valor,
			id_cliente: valor.id_cliente
		});
		this.clienteRecidenciaRegistro(valor.id_cliente);
	}
	
	handleChangeConcepto = (id_empresa_clave_producto, valor) => {
		console.log(id_empresa_clave_producto, valor);
		this.setState({
			concepto: valor
		});
		this.ConceptoSelect(valor);
	}
	
	handleChangeSerie = (id_empresa_sucursal_serie, valor) => {
		let series = FieldsJs.Copy(this.state.listar_empresa_sucursal_serie);
		for (let i = 0; i < series.length; i++) {
			if (Number(id_empresa_sucursal_serie) === Number(series[i].id_empresa_sucursal_serie)) {
				this.setState({
					serie: series[i].serie
				});
			}
		}
		
		EmpresaFacturacionExtService.SerieFolio(id_empresa_sucursal_serie).then((response) => {
			this.setState({
				folio: response.data
			});
		}).catch((error) => {
			this.setState({listar_facturacion: []});
			this.showSnackBars('error', error.mensaje);
		});
	}
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
		}
	};
	
	save = () => {
		try {
			if (!this.state.id_empresa) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Empresa.'
				})
			}
			
			if (!this.state.id_cliente) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cliente.'
				})
			}
			
			try {
				TimbradoService.Guardar(this.state).then((response) => {
					this.closeModal();
					this.props.RefreshList();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			} catch (e) {
				this.showSnackBars('error', e.mensaje);
			}
		} catch (e) {
			console.error(e);
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	RefreshListEmpresa = (id_empresa) => {
		if (id_empresa > 0) {
			this.setState({
				id_empresa: id_empresa
			})
		}
		this.ListaEmpresa();
	};
	
	RefreshListFacturacion = (id_cliente) => {
		if (id_cliente > 0) {
			this.setState({
				id_cliente: id_cliente
			})
		}
		this.ListaCliente();
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	getClaveProductoServicio = (id_clave_ps) => {
		let clave;
		
		for (let i = 0; i < this.state.listar_clave_producto_servicio.length; i++) {
			if (Number(id_clave_ps) === Number(this.state.listar_clave_producto_servicio[i].id_cat_clave_producto_servicio)) {
				clave = this.state.listar_clave_producto_servicio[i].clave_producto_servicio;
			}
		}
		return clave;
	};
	
	handleChangeModalOpciones = (archivo_xml) => {
		this.setState({
			open_modal_result: true,
			archivo_xml: archivo_xml
		}, () => {
		
		});
	};
	
	closeModalOpciones = () => {
		this.setState({
			open_modal_result: false,
			archivo_xml: ''
		});
	};
	
	handleChangeTabs = (e, variable, campo, date, input) => {
		console.log(e, variable);
		
		try {
			if (Number(variable) === 2) {
				if (!this.state.id_empresa) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Empresa.'
					})
				}
				
				if (!this.state.id_empresa_sucursal) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Sucursal.'
					})
				}
				
				if (!this.state.id_empresa_regimen_fiscal) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Régimen Fiscal.'
					})
				}
				
				if (!this.state.id_cliente) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Receptor.'
					})
				}
			}
			
			if (Number(variable) === 3) {
				
				
				if (this.state.rfc_receptor === "XAXX010101000" && this.state.nombre_razon_social === "PUBLICO EN GENERAL" && !this.state.informacion_global) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Información Global.'
					})
				}
				
				if (!FieldsJs.Field(this.state.id_cat_forma_pago)) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Forma de pago.'
					})
				}
				
				if (this.state.informacion_global && !FieldsJs.Field(this.state.id_cat_periodicidad)) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Periodicidad.'
					})
				}
				
				if (this.state.informacion_global && !FieldsJs.Field(this.state.id_cat_meses_global)) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Meses.'
					})
				}
				
				if (this.state.informacion_global && !FieldsJs.Field(this.state.anio_global)) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Año.'
					})
				}
			}
			
			
			this.setState({
				valuetab: variable
			});
			console.log(this.state);
			
		} catch (e) {
			console.log(this.state);
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	redondearDecimales = (numero, decimales) => {
		let numeroRegexp = new RegExp('\\d\\.(\\d){' + decimales + ',}');   // Expresion regular para numeros con un cierto numero de decimales o mas
		if (numeroRegexp.test(numero)) {         // Ya que el numero tiene el numero de decimales requeridos o mas, se realiza el redondeo
			return Number(numero.toFixed(decimales));
		} else {
			return Number(numero.toFixed(decimales)) === 0 ? 0 : numero;  // En valores muy bajos, se comprueba si el numero es 0 (con el redondeo deseado), si no lo es se devuelve el numero otra vez.
		}
	}
	
	calcularDesgoseTotales = () => {
		let desglose = {
			descuento: 0,
			subtotal: 0,
			trasladados: 0,
			retenidos: 0,
			total: 0,
		};
		let partidas = FieldsJs.Copy(this.state.partidas);
		
		for (let i = 0; i < partidas.length; i++) {
			let item = partidas[i];
			desglose.descuento += Number(item.descuento);
			desglose.subtotal += Number(item.importe);
			desglose.trasladados += Number(item.trasladados);
			desglose.retenidos += Number(item.retenidos);
		}
		
		desglose.total += ((this.redondearDecimales(desglose.subtotal, 2) - this.redondearDecimales(desglose.descuento, 2)) + this.redondearDecimales(desglose.trasladados, 2)) - this.redondearDecimales(desglose.retenidos, 2);
		
		return desglose;
	};
	
	SubTabla = (item, key) => {
		let partidas = FieldsJs.Copy(this.state.partidas);
		for (let i = 0; i < partidas.length; i++) {
			if (i === key) {
				partidas[i].expandir = !partidas[i].expandir;
			}
		}
		
		this.setState({
			partidas: partidas
		})
	}
	
	changeTipoMoneda = (id_cat_moneda) => {
		let moneda = FieldsJs.Copy(this.state.listar_cat_moneda);
		let m = 2;
		for (let i = 0; i < moneda.length; i++) {
			if (Number(id_cat_moneda) === Number(moneda[i].id_cat_moneda)) {
				m = moneda[i].decimales;
			}
		}
		this.setState({
			tipo_decimales_redondear: m
		});
	}
	
	handleChangeSingle = (e, name) => {
		console.log(name, e);
		
		let form = FieldsJs.Copy(this.state);
		
		for (let i = 0; i < this.state.listar_empresa.length; i++) {
			if (this.state.listar_empresa[i].id_empresa === e.value) {
				//for (let key in this.state) {
				//form[key] = this.state.listar_empresa[i].id_empresa;
				//}
			}
		}
		
		/*this.setState({
			[name]: e.value,
			form: FieldsJs.Copy(form),
			form_seleccionado: FieldsJs.Copy(form)
		});*/
	};
	
	ivaIncluidoAplicado = (iva, tipo) => {
		this.setState({
			impuestos_traladados: []
		})
		if (iva) {
			if (Number(tipo) === 2) {
				this.setState({
					iva_incluido: false,
					iva_aplicado: false,
					id_cat_objeto_impuesto: 2,
					id_cat_impuesto_trasladado: 2,
					id_cat_tipo_factor_trasladado: 2
				}, () => {
						this.AgregarImpuestoTrasladado();
				});
			} else if (Number(tipo)) {
				this.setState({
					iva_incluido: false,
					iva_exento: false,
					id_cat_impuesto_trasladado: '',
					id_cat_objeto_impuesto: 1,
					id_cat_tipo_factor_trasladado: ''
				});
			} else {
				this.setState({
					iva_aplicado: false,
					iva_exento: false,
					id_cat_objeto_impuesto: 2,
					id_cat_impuesto_trasladado: '',
					id_cat_tipo_factor_trasladado: ''
				});
			}
		}
	}
	
	setEmails = (emails) => {
		this.setState({enviar_a: emails});
	}
	
	setEmailsCC = (emails) => {
		this.setState({enviar_cc: emails});
	}
	
	setEmailsCCO = (emails) => {
		this.setState({enviar_cco: emails});
	}
	
	visualizacionPDF = () => {
		try {
			console.log("Generar pdf");
			
			let params = {
				rfc_emisor: this.state.rfc_emisor || '',
				sucursal_emisor: this.state.sucursal_emisor || '',
				certificado_emisor: this.state.certificado_emisor || '',
				observaciones_emisor: this.state.observaciones_emisor || '',
				enviar_a: this.state.enviar_a || [],
				enviar_cc: this.state.enviar_cc || [],
				enviar_cco: this.state.enviar_cco || [],
				id_empresa: this.state.id_empresa || '',
				id_cliente: this.state.id_cliente || '',
				id_empresa_sucursal: this.state.id_empresa_sucursal || '',
				id_cat_regimen_fiscal: this.state.id_cat_regimen_fiscal || '',
				lugar_expedicion: this.state.lugar_expedicion || '',
				cliente_regimen_fiscal: this.state.cliente_regimen_fiscal || '',
				cliente_numero_registro_if: this.state.cliente_numero_registro_if || '',
				partidas: this.state.partidas || [],
				desglose: this.state.desglose || '',
				id_cat_tipo_cfdi: this.state.id_cat_tipo_cfdi || '',
				id_cat_metodo_pago: this.state.id_cat_metodo_pago || '',
				id_cat_forma_pago: this.state.id_cat_forma_pago || '',
				id_cat_moneda: this.state.id_cat_moneda || '',
				id_cat_uso_cfdi: this.state.id_cat_uso_cfdi || '',
				id_cat_exportacion: this.state.id_cat_exportacion || '',
				id_empresa_sucursal_serie: this.state.id_empresa_sucursal_serie || '',
				fecha: this.state.fecha || '',
				serie: this.state.serie || '',
				folio: this.state.folio || '',
				condiciones_pago: this.state.condiciones_pago || '',
				clave_confirmacion: this.state.clave_confirmacion || '',
				tipo_cambio: this.state.tipo_cambio || '',
				produccion: this.state.produccion ? 1 : 0,
				folios_relacionados: this.state.folios_relacionados || [],
				activo: this.state.activo ? 1 : 0,
			};
			
			HttpRequest.exportFacturacion('pdfvisualizacion', ['pdfvisualizacion'], params, true, 800, 1000, true);
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	ListarPeriodicidad = () => {
		CatService.ListPeriodicidadCFDI(this.state).then((response) => {
			this.setState({
				listar_periodicidad: response.data,
				id_cat_periodicidad: '01',
			})
		});
	};
	
	ListarTipoRelacion = () => {
		CatService.ListTipoRelacionFac(this.state).then((response) => {
			this.setState({
				listar_tipo_relacion: response.data,
			})
		});
	};
	
	handleChangeFormaPago = (value) => {
		console.log(value);
		if (Number(value) === 2) {
			this.setState({
				id_cat_forma_pago: 22
			})
		}
	}
	
	handleChangeObjetoImpuesto = (value) => {
		console.log(value);
		if (Number(value) === 2) {
			this.setState({
				id_cat_impuesto_trasladado: 2,
				id_cat_tipo_factor_trasladado: 1,
				id_cat_impuesto: 3,
			})
		}
	}
	
	emails = (value) => {
		console.log(value);
	}
	
	render() {
		
		const desglose = this.calcularDesgoseTotales();
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		let meses = [
			{"mes": "01", "descripcion": "Enero"},
			{"mes": "02", "descripcion": "Febrero"},
			{"mes": "03", "descripcion": "Marzo"},
			{"mes": "04", "descripcion": "Abril"},
			{"mes": "05", "descripcion": "Mayo"},
			{"mes": "06", "descripcion": "Junio"},
			{"mes": "07", "descripcion": "Julio"},
			{"mes": "08", "descripcion": "Agosto"},
			{"mes": "09", "descripcion": "Septiembre"},
			{"mes": "10", "descripcion": "Octubre"},
			{"mes": "11", "descripcion": "Nombiembre"},
			{"mes": "12", "descripcion": "Diciembre"}
		];
		
		return (
			<div className='Catalogos ContaineViewComponet timbrado_facturacion'>
				<div className={'row-flex margin-30-T margin-30-B'}>
					<div className={'w-100-250-px'}>
						<Typography variant={'h5'} className={'text-left'}>
							Timbrado
						</Typography>
					</div>
					<div className={'w-250-px text-right vertical-inline'}>
						{/*{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
							<FormGroup row className={'margin-3-L'}>
								<FormControlLabel
									control={
										<Checkbox type="checkbox" name='produccion'
										          checked={this.state.produccion}
										          onChange={this.handleChange} value="produccion"
										          color="primary"
										          disabled={this.props.tipo === 'view'}/>
									}
									label={'Para Producción:'}
								/>
							</FormGroup>
						) : null}*/}
					</div>
				</div>
				
				
				<ModalDescargaTimbrado item={this.state} open={this.state.open_modal_xml} showSnackBars={this.showSnackBars} RefreshList={this.RefreshList}/>
				
				<ModalOpcionesTimbrado
					item={this.state}
					open={this.state.open_modal_result}
					archivo_xml={this.state.archivo_xml}
					showSnackBars={this.showSnackBars}
					RefreshList={this.RefreshTimbradoOtro}
					handleChangeModalOpciones={this.handleChangeModalOpciones}
					closeModalOpciones={this.closeModalOpciones}
				/>
				
				<div className={'form margin-20'}>
					<div className={'w-100'} align={'center'}>
						
						<Card style={{maxWidth: '1400px', width: '100%'}}>
							
							<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<TabContext value={this.state.valuetab}>
										<AppBar position="static">
											<TabList variant="fullWidth" onChange={this.handleChangeTabs} aria-label="simple tabs example">
												<Tab label={<Fragment>
													<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>Datos Fiscales</p>
													{/*<p className={'margin-0 padding-0 px-13'}>{this.props.titulo}</p>*/}
												</Fragment>} value="1"/>
												<Tab label={<Fragment>
													<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>Comprobante</p>
													{/*<p className={'margin-0 padding-0 px-13'}>Contratos individuales firmados</p>*/}
												</Fragment>} value="2"/>
												<Tab label={<Fragment>
													<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>Generar</p>
													{/*<p className={'margin-0 padding-0 px-13'}>Contratos individuales firmados</p>*/}
												</Fragment>} value="3"/>
											</TabList>
										</AppBar>
										
										<TabPanel value="1" className={''}>
											<Grid container spacing={2} alignContent={"flex-start"} alignItems={"stretch"}>
												<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
													
													<fieldset className={'borde-punteado-gray-2 text-left'}>
														<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Emisor</legend>
														
														<Grid container spacing={2}>
															
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
																	{!this.is_view() ? (
																		<Grid item style={{width: '50px'}}>
																			<ModalEmpresa
																				componente={(
																					<Tooltip
																						TransitionComponent={Zoom}
																						placement={"right"}
																						title="Agregar nueva empresa"
																						children={(
																							<Fab
																								color="default"
																								size="small"
																								aria-label="Agregar nueva empresa"
																								children={(
																									<PlaylistAddOutlined/>
																								)}
																							/>
																						)}
																					/>
																				)}
																				id_empresa={null}
																				tipo={'add'}
																				item={{}}
																				RefreshList={this.RefreshListEmpresa}
																				showSnackBars={this.showSnackBars}
																			/>
																		</Grid>
																	) : null}
																	{!this.is_view() && FieldsJs.Field(this.state.id_empresa) ? (
																		<Grid item style={{width: '50px'}}>
																			<ModalEmpresa
																				componente={(
																					<Tooltip
																						TransitionComponent={Zoom}
																						placement={"right"}
																						title="Editar empresa"
																						children={(
																							<Fab
																								color="default"
																								size="small"
																								aria-label="Editar empresa"
																								children={(
																									<Edit/>
																								)}
																							/>
																						)}
																					/>
																				)}
																				id_empresa={null}
																				tipo={'edit'}
																				item={{id_empresa: this.state.id_empresa}}
																				RefreshList={this.RefreshListEmpresa}
																				showSnackBars={this.showSnackBars}
																			/>
																		</Grid>
																	) : null}
																	<Grid item style={{width: FieldsJs.Field(this.state.id_empresa) ? 'calc(100% - 100px)' : 'calc(100% - 50px)'}}>
																		
																		<Autocomplete
																			id="autocomplete_empresa"
																			options={this.state.listar_empresa}
																			value={this.state.empresa}
																			//inputValue={this.state.empresa ? this.state.empresa.rfc + ' - ' + this.state.empresa.nombre_razon_social : ''}
																			getOptionLabel={(option) => option.rfc + ' - ' + option.nombre_razon_social}
																			noOptionsText={'No se encontron datos.'}
																			renderInput={(params) => <TextField {...params} label="Empresa  *" margin="none"/>}
																			onChange={(e, value) => {
																				//this.onTagsChangeEmpresa(e);
																				this.handleChangeEmpresa(e, value);
																			}}
																		/>
																		{/*<AutoCompleteEmpresa
																			handleChangeSingle={this.handleChangeSingle}
																			suggestions={this.state.listar_empresa}
																			value={this.state.id_empresa}
																			name={'id_empresa'} id={'id_empresa'}
																			label={'nombre_razon_social'}
																			required={true}
																		/>*/}
																		
																		{/*<TextField
																			select
																			fullWidth
																			required
																			margin="none"
																			onChange={(e) => {
																				this.handleChange(e);
																				this.ListaEmpresaSucursal(e.target.value);
																				this.ListaEmpresaRegimenFiscal(e.target.value);
																				this.ListaConcepto(e.target.value);
																				this.ListaUnidadMedida(e.target.value);
																				this.ListaMoneda(e.target.value);
																			}}
																			disabled={this.is_view()}
																			SelectProps={{
																				native: true,
																				MenuProps: {
																					className: '',
																				},
																			}}
																			name="id_empresa"
																			label="Empresa"
																			value={this.state.id_empresa}
																		>
																			<option key={0} value={''}>&nbsp;</option>
																			{this.state.listar_empresa.map(option => (
																				<option key={option.id_empresa} value={option.id_empresa}>
																					{option.nombre_razon_social}
																				</option>
																			))}
																		</TextField>*/}
																	</Grid>
																</Grid>
															</Grid>
															
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																<FormControl fullWidth required>
																	<InputLabel>Sucursal</InputLabel>
																	<Select
																		native
																		value={this.state.id_empresa_sucursal}
																		onChange={(e) => {
																			this.handleChange(e);
																			this.lugarExpedicion(e.target.value);
																		}}
																		name="id_empresa_sucursal"
																		input={<Input/>}
																		disabled={this.props.tipo === 'view'}
																	>
																		<option value=""/>
																		{this.state.listar_empresa_sucursal.map(option => (
																			<option key={option.id_empresa_sucursal}
																			        value={option.id_empresa_sucursal}>
																				{option.nombre}
																			</option>
																		))}
																	</Select>
																</FormControl>
															</Grid>
															
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																<FormControl fullWidth required>
																	<InputLabel>Régimen Fiscal</InputLabel>
																	<Select
																		native
																		value={this.state.id_empresa_regimen_fiscal}
																		onChange={(e) => {
																			this.handleChange(e);
																		}}
																		name="id_empresa_regimen_fiscal"
																		input={<Input/>}
																		disabled={this.props.tipo === 'view'}
																	>
																		<option value=""/>
																		{this.state.listar_empresa_regimen_fiscal.map(option => (
																			<option key={option.id_empresa_regimen_fiscal}
																			        value={option.id_empresa_regimen_fiscal}>
																				{option.descripcion ? option.regimen_fiscal + ' - ' + option.descripcion : option.regimen_fiscal}
																			</option>
																		))}
																	</Select>
																</FormControl>
															</Grid>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																
																<TextField
																	required
																	name="lugar_expedicion"
																	label="Lugar de Expedición"
																	type="text"
																	fullWidth
																	value={this.state.lugar_expedicion}
																	onChange={this.handleChange}
																	disabled
																/>
															
															</Grid>
														
														</Grid>
													
													</fieldset>
												
												</Grid>
												
												<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
													
													<fieldset className={'borde-punteado-gray-2 text-left h-93'}>
														<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Receptor</legend>
														
														<Grid container spacing={2}>
															
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
																	{!this.is_view() ? (
																		<Grid item style={{width: '50px'}}>
																			<ModalCliente
																				componente={(
																					<Tooltip
																						TransitionComponent={Zoom}
																						placement={"right"}
																						title="Agregar nuevo cliente"
																						children={(
																							<Fab
																								color="default"
																								size="small"
																								aria-label="Agregar nuevo cliente"
																								children={(
																									<PlaylistAddOutlined/>
																								)}
																							/>
																						)}
																					/>
																				)}
																				id_cliente={null}
																				tipo={'add'}
																				item={{}}
																				RefreshList={this.RefreshListFacturacion}
																				showSnackBars={this.showSnackBars}
																			/>
																		</Grid>
																	) : null}
																	{!this.is_view() && FieldsJs.Field(this.state.id_cliente) ? (
																		<Grid item style={{width: '50px'}}>
																			<ModalCliente
																				componente={(
																					<Tooltip
																						TransitionComponent={Zoom}
																						placement={"right"}
																						title="Editar cliente"
																						children={(
																							<Fab
																								color="default"
																								size="small"
																								aria-label="Editar cliente"
																								children={(
																									<Edit/>
																								)}
																							/>
																						)}
																					/>
																				)}
																				id_cliente={null}
																				tipo={'edit'}
																				item={{id_cliente: this.state.id_cliente}}
																				RefreshList={this.RefreshListFacturacion}
																				showSnackBars={this.showSnackBars}
																			/>
																		</Grid>
																	) : null}
																	<Grid item style={{width: FieldsJs.Field(this.state.id_cliente) ? 'calc(100% - 100px)' : 'calc(100% - 50px)'}}>
																		<Autocomplete
																			id="autocomplete_receptor"
																			options={this.state.listar_cliente}
																			value={this.state.cliente}
																			//inputValue={this.state.cliente ? this.state.cliente.rfc + ' - ' + this.state.cliente.nombre_razon_social : ''}
																			getOptionLabel={(option) => option.rfc + ' - ' + option.nombre_razon_social}
																			noOptionsText={'No se encontron datos.'}
																			renderInput={(params) => <TextField {...params} label="RFC Receptor  *" margin="none"/>}
																			onChange={(e, value) => {
																				this.handleChangeCliente(e, value);
																			}}
																		/>
																		{/*<TextField
																			select
																			fullWidth
																			required
																			margin="none"
																			onChange={(e) => {
																				this.handleChange(e);
																				this.clienteRecidenciaRegistro(e.target.value);
																			}}
																			disabled={this.is_view()}
																			SelectProps={{
																				native: true,
																				MenuProps: {
																					className: '',
																				},
																			}}
																			name="id_cliente"
																			label="RFC Receptor"
																			value={this.state.id_cliente}
																		>
																			<option key={0} value={''}>&nbsp;</option>
																			{this.state.listar_cliente.map(option => (
																				<option key={option.id_cliente} value={option.id_cliente}>
																					{option.rfc + ' - ' + option.nombre_razon_social}
																				</option>
																			))}
																		</TextField>*/}
																	</Grid>
																</Grid>
															</Grid>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																
																<TextField
																	name="cliente_regimen_fiscal"
																	label="Régimen fiscal"
																	type="text"
																	fullWidth
																	value={this.state.cliente_regimen_fiscal}
																	onChange={this.handleChange}
																	disabled
																/>
															
															</Grid>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																
																<TextField
																	name="cliente_codigo_postal"
																	label="Domicilio Fiscal"
																	type="text"
																	fullWidth
																	value={this.state.cliente_codigo_postal}
																	onChange={this.handleChange}
																	disabled
																/>
															
															</Grid>
														
														
														</Grid>
													
													</fieldset>
												
												</Grid>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
													<Divider style={{marginTop: "10px", marginBottom: "15px"}}/>
													<div className={'text-right'}>
														<div className={'vertical-inline'}>
															<Button color="primary" size={'small'} variant="contained" onClick={() => this.handleChangeTabs(null, '2')}>
																{'Siguiente'}
															</Button>
														</div>
													</div>
												</Grid>
											</Grid>
										</TabPanel>
										
										<TabPanel value="2" className={''}>
											<Grid container spacing={2} alignContent={"flex-start"} alignItems={"stretch"}>
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
													<Typography className={'text-center px-16'} style={{opacity: 0.8, color: '#3f51b5', fontWeight: 'bold'}}>
														{this.state.rfc_receptor + ' - ' + this.state.nombre_razon_social}
													</Typography>
												</Grid>
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
													<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
													<Typography className={'text-left px-16'} style={{opacity: 0.8, color: 'black'}}>
														{"Datos del comprobante"}
													</Typography>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														select
														fullWidth
														required
														margin="none"
														onChange={this.handleChange}
														disabled={this.is_view()}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														name="id_cat_tipo_cfdi"
														label="Tipo de CDFI"
														value={this.state.id_cat_tipo_cfdi}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_tipo_cfdi.map(option => (
															<option key={option.id_cat_tipo_cfdi} value={option.id_cat_tipo_cfdi}>
																{option.tipo_cfdi}
															</option>
														))}
													</TextField>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														select
														fullWidth
														required
														margin="none"
														onChange={this.handleChange}
														disabled={this.is_view()}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														name="id_cat_uso_cfdi"
														label="Uso CFDI"
														value={this.state.id_cat_uso_cfdi}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_uso_cfdi.map(option => (
															<option key={option.id_cat_uso_cfdi} value={option.id_cat_uso_cfdi}>
																{option.descripcion ? option.uso_cfdi + " - " + option.descripcion : option.uso_cfdi}
															</option>
														))}
													</TextField>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														select
														fullWidth
														required
														margin="none"
														onChange={(e) => {
															this.handleChange(e);
															this.handleChangeFormaPago(e.target.value);
														}}
														disabled={this.is_view()}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														name="id_cat_metodo_pago"
														label="Método de Pago"
														value={this.state.id_cat_metodo_pago}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_metodo_pago.map(option => (
															<option key={option.id_cat_metodo_pago} value={option.id_cat_metodo_pago}>
																{option.descripcion ? option.metodo_pago + " - " + option.descripcion : option.metodo_pago}
															</option>
														))}
													</TextField>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														select
														fullWidth
														required
														margin="none"
														onChange={this.handleChange}
														disabled={this.is_view() || Number(this.state.id_cat_metodo_pago) === 2 && Number(this.state.id_cat_forma_pago) === 22}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														name="id_cat_forma_pago"
														label="Forma de Pago"
														value={this.state.id_cat_forma_pago}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_forma_pago.map(option => (
															<option key={option.id_cat_forma_pago} value={option.id_cat_forma_pago}>
																{option.descripcion ? option.forma_pago + " - " + option.descripcion : option.forma_pago}
															</option>
														))}
													</TextField>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														select
														fullWidth
														required
														margin="none"
														onChange={(e) => {
															this.handleChange(e);
															this.changeTipoMoneda(e.target.value);
														}}
														disabled={this.is_view()}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														name="id_cat_moneda"
														label="Tipo Moneda"
														value={this.state.id_cat_moneda}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_moneda.map(option => (
															<option key={option.id_cat_moneda} value={option.id_cat_moneda}>
																{option.descripcion ? option.moneda + " - " + option.descripcion : option.moneda}
															</option>
														))}
													</TextField>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<Grid container spacing={2} alignContent={"flex-start"} alignItems={"stretch"}>
														<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
															<TextField
																margin="none"
																name="tipo_cambio"
																label="Tipo Cambio"
																type="text"
																fullWidth
																value={this.state.tipo_cambio}
																onChange={this.handleChange}
															/>
														</Grid>
														
														<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
															<TextField
																select
																fullWidth
																required
																margin="none"
																onChange={(e) => {
																	this.handleChange(e);
																}}
																disabled={this.is_view()}
																SelectProps={{
																	native: true,
																	MenuProps: {
																		className: '',
																	},
																}}
																name="id_cat_exportacion"
																label="Exportación"
																value={this.state.id_cat_exportacion}
															>
																<option key={0} value={''}>&nbsp;</option>
																{this.state.listar_cat_exportacion.map(option => (
																	<option key={option.id_cat_exportacion} value={option.id_cat_exportacion}>
																		{option.descripcion ? option.exportacion + " - " + option.descripcion : option.exportacion}
																	</option>
																))}
															</TextField>
														</Grid>
													</Grid>
												</Grid>
												
												{/*<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="tipo_decimales_redondear"
														label="Total de Decimales a Redondear"
														type="text"
														fullWidth
														value={this.state.tipo_decimales_redondear}
														onChange={(e) => {
															this.handleChange(e);
														}}
														onKeyPress={EnteroSolo}
														//inputProps={{maxLength: 15}}
													/>
												</Grid>*/}
												
												<Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
													{/*<TextField
														margin="none"
														name="serie"
														label="Serie"
														type="text"
														fullWidth
														value={this.state.serie}
														onChange={this.handleChange}
													/>*/}
													<TextField
														select
														fullWidth
														margin="none"
														onChange={(e) => {
															this.handleChange(e);
															this.handleChangeSerie(e.target.value);
														}}
														disabled={this.is_view()}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														name="id_empresa_sucursal_serie"
														label="Serie"
														value={this.state.id_empresa_sucursal_serie}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_empresa_sucursal_serie.map(option => (
															<option key={option.id_empresa_sucursal_serie} value={option.id_empresa_sucursal_serie}>
																{option.serie}
															</option>
														))}
													</TextField>
												</Grid>
												
												<Grid item xs={12} sm={6} md={1} lg={1} xl={1}>
													<TextField
														margin="none"
														name="folio"
														label="Folio"
														type="text"
														fullWidth
														value={this.state.folio}
														onChange={this.handleChange}
														disabled
													/>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														select
														fullWidth
														margin="none"
														onChange={(e) => {
															this.handleChange(e);
														}}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														name="fecha"
														label="Fecha de CFDI"
														value={this.state.fecha}
													>
														<option value={'1'}>Timbrar con fecha actual</option>
														<option value={'2'}>Fecha de ayer</option>
														<option value={'3'}>Fecha de hace dos días</option>
														<option value={'4'}>Fecha de hace tres días</option>
													</TextField>
												</Grid>
												
												{/*<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="fecha"
														label="Fecha"
														type="text"
														fullWidth
														value={this.state.fecha}
														onChange={this.handleChange}
														disabled
													/>
												</Grid>*/}
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="condiciones_pago"
														label="Condiciones de Pago"
														type="text"
														fullWidth
														value={this.state.condiciones_pago}
														onChange={this.handleChange}
													/>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="clave_confirmacion"
														label="Clave de Confirmación"
														type="text"
														fullWidth
														value={this.state.clave_confirmacion}
														onChange={this.handleChange}
														disabled
													/>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<FormGroup row>
														<FormControlLabel
															control={
																<Checkbox
																	type="checkbox"
																	name='folios_fiscales'
																	checked={this.state.folios_fiscales}
																	onChange={(e) => {
																		this.handleChange(e);
																	}}
																	value="folios_fiscales"
																	color="primary"
																	disabled={this.props.tipo === 'view'}
																/>
															}
															label={'Folios Fiscales Relacionados'}
														/>
													</FormGroup>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<FormGroup row>
														<FormControlLabel
															control={
																<Checkbox
																	type="checkbox"
																	name='informacion_global'
																	checked={this.state.informacion_global}
																	onChange={(e) => {
																		this.handleChange(e);
																	}}
																	value="informacion_global"
																	color="primary"
																	disabled={this.props.tipo === 'view'}
																/>
															}
															label={'Información Global'}
														/>
													</FormGroup>
												</Grid>
												
												{this.state.folios_fiscales ? (
													<Fragment>
														<Grid item xs={12} sm={6} md={4} lg={5} xl={5}>
															<TextField
																select
																fullWidth
																required
																margin="none"
																onChange={this.handleChange}
																SelectProps={{
																	native: true,
																	MenuProps: {
																		className: '',
																	},
																}}
																name="id_cat_tipo_relacion"
																label="Tipo Relación"
																value={this.state.id_cat_tipo_relacion}
															>
																<option key={0} value={''}>&nbsp;</option>
																{this.state.listar_tipo_relacion.map(option => (
																	<option key={option.id_cat_tipo_relacion}
																	        value={option.id_cat_tipo_relacion}>
																		{option.tipo_relacion ? option.tipo_relacion + ' - ' + option.descripcion : option.descripcion}
																	</option>
																))}
															</TextField>
														</Grid>
														
														<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
															{/*<Autocomplete
																multiple
																id="tags-standard"
																options={this.state.folios}
																getOptionLabel={(option) => option.folio}
																//defaultValue={[top100Films[13]]}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		variant="standard"
																		label="Folio Fiscal"
																		placeholder="Folio Fiscal"
																	/>
																)}
															/>*/}
															<Autocomplete
																multiple
																id="autocomplete_folios"
																options={this.state.listar_folios}
																getOptionLabel={(option) => option}
																//value={this.state.listar_folios}
																freeSolo
																clearText
																renderTags={(value, getTagProps) =>
																	value.map((option, index) => (
																		<Chip variant="outlined" label={option} {...getTagProps({index})} />
																	))
																}
																renderInput={(params) => (
																	<TextField {...params} variant="outlined" label="" placeholder="Folio Fiscal" margin="none"/>
																)}
																onChange={(e, value) => {
																	this.handleChangeFolios(e, value);
																}}
															/>
															{/*<Autocomplete
																id="autocomplete_receptor"
																options={this.state.listar_cliente}
																value={this.state.cliente}
																//inputValue={this.state.cliente ? this.state.cliente.rfc + ' - ' + this.state.cliente.nombre_razon_social : ''}
																getOptionLabel={(option) => option.rfc + ' - ' + option.nombre_razon_social}
																noOptionsText={'No se encontron datos.'}
																renderInput={(params) => <TextField {...params} label="RFC Receptor  *" margin="none"/>}
																onChange={(e, value) => {
																	this.handleChangeCliente(e, value);
																}}
															/>*/}
															{/*<TextField
																margin="none"
																name="folio_fiscal"
																label="Folio Fiscal"
																type="text"
																fullWidth
																value={this.state.folio_fiscal}
																onChange={this.handleChange}
																required
															/>*/}
														</Grid>
														
														<Grid item xs={12} sm={6} md={2} lg={1} xl={1} align={'right'}>
															<div className={'w-auto vertical-inline h-100'}>
																<div className={'v-center'}>
																	<Tooltip
																		TransitionComponent={Zoom}
																		placement={"top"}
																		title={"Agregar"}
																		children={(
																			<Fab size="small" color="secondary"
																			     aria-label={"Agregar"}
																			     onClick={this.listar_foliosAgregarRelacionados}
																			     style={{marginLeft: "15px"}}>
																				<AddOutlined/>
																			</Fab>
																		)}
																	/>
																</div>
															</div>
														</Grid>
														
														{this.state.folios_relacionados.length > 0 ? (
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<table className={'desing-mark'}>
																	<thead>
																	<tr>
																		<th align={"center"}>Tipo Relación</th>
																		<th align={"center"}>Folios</th>
																		<th align={"right"}>Acciones</th>
																	</tr>
																	</thead>
																	<tbody>
																	{this.state.folios_relacionados.map((item, index) => (
																			<tr key={index}>
																				<td align={"center"}>{item.tipo_relacion + ' - ' + item.descripcion}</td>
																				<td align={"center"}>
																					{item.folios.map((item2, index2) => (
																						<p className={'margin-0'} key={index2}>{item2}</p>
																					))
																					}
																				</td>
																				<td align={'right'}>
																					<Tooltip
																						TransitionComponent={Zoom}
																						placement={"top"}
																						title="Eliminar"
																						children={(
																							<Fab
																								size="small"
																								aria-label="Eliminar"
																								onClick={() => this.deleteRelacionados(item, index)}
																								style={{
																									marginLeft: "10px",
																									backgroundColor: "transparent",
																									color: "#808080",
																									boxShadow: "none",
																								}}
																								children={(
																									<DeleteOutlined/>
																								)}
																							/>
																						)}
																					/>
																				</td>
																			</tr>
																		)
																	)}
																	</tbody>
																</table>
															</Grid>
														) : null}
													
													</Fragment>
												) : null}
												
												{this.state.informacion_global ? (
													<Fragment>
														<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
															<TextField
																select
																fullWidth
																required
																margin="none"
																onChange={this.handleChange}
																SelectProps={{
																	native: true,
																	MenuProps: {
																		className: '',
																	},
																}}
																name="id_cat_periodicidad"
																label="Periodicidad"
																value={this.state.id_cat_periodicidad}
															>
																<option key={0} value={''}>&nbsp;</option>
																{this.state.listar_periodicidad.map(option => (
																	<option key={option.periodicidad}
																	        value={option.periodicidad}>
																		{option.periodicidad + ' - ' + option.descripcion}
																	</option>
																))}
															</TextField>
														</Grid>
														
														<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
															<TextField
																select
																fullWidth
																required
																margin="none"
																onChange={this.handleChange}
																SelectProps={{
																	native: true,
																	MenuProps: {
																		className: '',
																	},
																}}
																name="id_cat_meses_global"
																label="Meses"
																value={this.state.id_cat_meses_global}
															>
																<option key={0} value={''}>&nbsp;</option>
																{meses.map(option => (
																	<option key={option.mes}
																	        value={option.mes}>
																		{option.mes + ' - ' + option.descripcion}
																	</option>
																))}
															</TextField>
														</Grid>
														
														<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
															<TextField
																margin="none"
																name="anio_global"
																label="Año"
																type="text"
																fullWidth
																value={this.state.anio_global}
																onChange={this.handleChange}
																required
															/>
														</Grid>
													</Fragment>
												) : null}
												
												{/*<Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
													<TextField
														margin="none"
														name="clave_concepto"
														label="Clave de Confirmación"
														type="text"
														fullWidth
														value={this.state.clave_concepto}
														onChange={this.handleChange}
														required
														onKeyDown={this.obtenerDatosConcepto}
													/>
												</Grid>

												<Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
													<TextField
														margin="none"
														name="clave_concepto"
														label="Clave de Confirmación"
														type="text"
														fullWidth
														value={this.state.clave_concepto}
														onChange={this.handleChange}
														required
														onKeyDown={this.obtenerDatosConcepto}
													/>
												</Grid>*/}
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
													<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
													<Typography className={'text-left px-16'} style={{opacity: 0.8, color: 'black'}}>
														{"Conceptos"}
													</Typography>
												</Grid>
												
												<Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
													<div className={'w-100 vertical-inline'}>
														<div className={'w-50-px v-center'}>
															<ModalEmpresaCatalogos
																tipo={'add'}
																item={{"id_empresa": this.state.id_empresa}}
																componente={(
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Catálogos">
																		<IconButton aria-label="Catálogos"><HdrStrong/></IconButton>
																	</Tooltip>
																)}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
															/>
														</div>
														<div className={'w-100-50-px v-center'}>
															<TextField
																margin="none"
																name="clave_concepto"
																label="Clave Concepto"
																type="text"
																fullWidth
																value={this.state.clave_concepto}
																onChange={this.handleChange}
																required
																onKeyDown={this.obtenerDatosConcepto}
															/>
														</div>
													</div>
												</Grid>
												
												<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
													<Autocomplete
														id="listar_concepto"
														freeSolo
														options={this.state.listar_concepto.map((option) => option.concepto)}
														renderInput={(params) => (
															<TextField {...params} label="Concepto  *" margin="none" name="concepto"
															           type="text"
															           fullWidth
															           value={this.state.concepto}
															           onChange={this.handleChange}/>
														)}
														value={this.state.autocomplete_concepto}
														onChange={(e, value) => {
															this.handleChangeConcepto(e, value);
														}}
													/>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
														<Grid item style={{width: 'calc(100%)'}}>
															<TextField
																select
																fullWidth
																required
																margin="none"
																onChange={this.handleChange}
																disabled={this.is_view()}
																SelectProps={{
																	native: true,
																	MenuProps: {
																		className: '',
																	},
																}}
																name="id_empresa_clave_unidad"
																label="Unidad de Medida"
																value={this.state.id_empresa_clave_unidad}
															>
																<option key={0} value={''}>&nbsp;</option>
																{this.state.listar_unidad_medida.map(option => (
																	<option key={option.id_empresa_clave_unidad} value={option.id_empresa_clave_unidad}>
																		{option.concepto ? option.clave_unidad + ' - ' + option.concepto : option.clave_unidad}
																	</option>
																))}
															</TextField>
														</Grid>
													</Grid>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="numero_identificacion"
														label="No. Identificación"
														type="text"
														fullWidth
														value={this.state.numero_identificacion}
														onChange={this.handleChange}
													/>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="cantidad"
														label="Cantidad"
														type="text"
														fullWidth
														value={this.state.cantidad}
														onChange={(e) => {
															this.handleChange(e);
														}}
														onKeyPress={EnteroSolo}
														//inputProps={{maxLength: 15}}
														required
													/>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="precio_unitario"
														label="Valor Unitario"
														type="text"
														fullWidth
														value={this.state.precio_unitario}
														onChange={(e) => {
															this.handleChange(e);
														}}
														onKeyPress={EnteroSolo}
														//inputProps={{maxLength: 15}}
														required
													/>
												</Grid>
												
												<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
													<Grid container spacing={0} justify="flex-start" alignItems="flex-end">
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'borde-punteado-gray-2-opacity'}>
															<Typography className={'w-100 px-16'} style={{opacity: 0.8, color: 'black', textAlign: 'center', fontWeight: 'bold'}}>
																{"IVA"}
															</Typography>
														</Grid>
														<Grid item xs={6} sm={6} md={4} lg={4} xl={4} style={{borderWidth: '2px', borderStyle: 'none none dotted dotted', borderColor: 'rgba(128, 128, 128, 0.6)'}}>
															<FormGroup row>
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			name='iva_incluido'
																			checked={this.state.iva_incluido}
																			onChange={(e) => {
																				this.handleChange(e);
																				this.ivaIncluidoAplicado(e.target.value, 0);
																			}}
																			value="iva_incluido"
																			color="primary"
																			disabled={this.props.tipo === 'view'}
																		/>
																	}
																	label={'Incluido'}
																/>
															</FormGroup>
														</Grid>
														
														<Grid item xs={6} sm={6} md={4} lg={4} xl={4} style={{borderWidth: '2px', borderStyle: 'none dotted dotted dotted', borderColor: 'rgba(128, 128, 128, 0.6)'}}>
															<FormGroup row>
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			name='iva_exento'
																			checked={this.state.iva_exento}
																			onChange={(e) => {
																				this.handleChange(e);
																				this.ivaIncluidoAplicado(e.target.value, 2);
																			}}
																			value="iva_exento"
																			color="primary"
																			disabled={this.props.tipo === 'view'}
																		/>
																	}
																	label={'Exento'}
																/>
															</FormGroup>
														</Grid>
														
														<Grid item xs={6} sm={6} md={4} lg={4} xl={4} style={{borderWidth: '2px', borderStyle: 'none dotted dotted none', borderColor: 'rgba(128, 128, 128, 0.6)'}}>
															<FormGroup row>
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			name='iva_aplicado'
																			checked={this.state.iva_aplicado}
																			onChange={(e) => {
																				this.handleChange(e);
																				this.ivaIncluidoAplicado(e.target.value, 1);
																			}}
																			value="iva_aplicado"
																			color="primary"
																			disabled={this.props.tipo === 'view'}
																		/>
																	}
																	label={'No Aplica'}
																/>
															</FormGroup>
														</Grid>
													</Grid>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<span className={'text-left text-black px-13'}>Descuento por:</span>
													{/*<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span>*/}
													<Grid item className={'text-left ticketfrecuencia'} xs={12} sm={12} md={12} lg={12} xl={12}>
														<RadioGroup
															row aria-label="position"
															name="tipo_descuento"
															value={this.state.tipo_descuento}
															onChange={this.handleChange}>
															
															<FormControlLabel
																style={{color: 'gray', fontSize: '13px'}}
																labelPlacement="end"
																control={<Radio color="primary"/>}
																value={"1"}
																label={"Sin descuento"}
																disabled={this.props.tipo === 'view'}
															/>
															<FormControlLabel
																style={{color: 'gray', fontSize: '13px'}}
																labelPlacement="end"
																control={<Radio color="primary"/>}
																value={"2"}
																label={"Porcentaje"}
																disabled={this.props.tipo === 'view'}
															/>
															<FormControlLabel
																style={{color: 'gray', fontSize: '13px'}}
																labelPlacement="end"
																control={<Radio color="primary"/>}
																value={"3"}
																label={"Monto"}
																disabled={this.props.tipo === 'view'}
															/>
														</RadioGroup>
													</Grid>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="descuento"
														label="Descuento"
														type="text"
														fullWidth
														value={this.state.descuento}
														onChange={(e) => {
															this.handleChange(e);
														}}
														onKeyPress={EnteroSolo}
														//inputProps={{maxLength: 15}}
													/>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="numero_pedimento"
														label="Número de pedimento"
														type="text"
														fullWidth
														value={this.state.numero_pedimento}
														onChange={(e) => {
															this.handleChange(e);
														}}
														onKeyPress={EnteroSolo}
														//inputProps={{maxLength: 15}}
													/>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="numero_predial"
														label="Número Cuenta Predial"
														type="text"
														fullWidth
														value={this.state.numero_predial}
														onChange={(e) => {
															this.handleChange(e);
														}}
														onKeyPress={EnteroSolo}
														//inputProps={{maxLength: 15}}
													/>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														select
														fullWidth
														required
														margin="none"
														onChange={(e) => {
															this.handleChange(e);
															this.handleChangeObjetoImpuesto(e.target.value);
														}}
														disabled={this.is_view() || this.state.iva_incluido || this.state.iva_aplicado}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														name="id_cat_objeto_impuesto"
														label="Objeto de Impuesto"
														value={this.state.id_cat_objeto_impuesto}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_objeto_impuesto.map(option => (
															<option key={option.id_cat_objeto_impuesto} value={option.id_cat_objeto_impuesto}>
																{option.descripcion ? option.objeto_impuesto + ' - ' + option.descripcion : option.objeto_impuesto}
															</option>
														))}
													</TextField>
												</Grid>
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
													<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
													<Typography className={'text-left px-16'} style={{opacity: 0.8, color: 'black'}}>
														{"Impuestos trasladados del concepto"}
													</Typography>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														select
														fullWidth
														required
														margin="none"
														onChange={this.handleChange}
														disabled={this.is_view() || (!this.state.iva_incluido && !this.state.iva_aplicado && (!this.state.id_cat_objeto_impuesto || Number(this.state.id_cat_objeto_impuesto) === 1)) || this.state.iva_incluido || this.state.iva_aplicado}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														name="id_cat_impuesto_trasladado"
														label="Impuestos"
														value={this.state.id_cat_impuesto_trasladado}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_impuestos_trasladados.map(option => (
															<option key={option.id_cat_impuesto_trasladado} value={option.id_cat_impuesto_trasladado}>
																{option.impuesto_trasladado}
															</option>
														))}
													</TextField>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														select
														fullWidth
														required
														margin="none"
														onChange={(e) => {
															this.handleChange(e);
														}}
														disabled={this.is_view() || (!this.state.iva_incluido && !this.state.iva_aplicado && (!this.state.id_cat_objeto_impuesto || Number(this.state.id_cat_objeto_impuesto) === 1)) || this.state.iva_incluido || this.state.iva_aplicado || Number(this.state.id_cat_objeto_impuesto) === 1}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														name="id_cat_
														_trasladado"
														label="Tipo Factor"
														value={this.state.id_cat_tipo_factor_trasladado}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_tipo_factor.map((option, key) => (
															<Fragment key={key}>
																{Number(option.tipo_impuesto) === 1 ? (
																	<option key={option.id_cat_tipo_factor} value={option.id_cat_tipo_factor}>
																		{option.tipo_factor}
																	</option>
																) : null}
															</Fragment>
														))}
													</TextField>
												</Grid>
												
												{Number(this.state.id_cat_tipo_factor_trasladado) !== 2 ? (
													<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
														<TextField
															select
															fullWidth
															required
															margin="none"
															onChange={this.handleChange}
															disabled={this.is_view() || (!this.state.iva_incluido && !this.state.iva_aplicado && (!this.state.id_cat_objeto_impuesto || Number(this.state.id_cat_objeto_impuesto) === 1)) || this.state.iva_incluido || this.state.iva_aplicado || Number(this.state.id_cat_objeto_impuesto) === 1}
															SelectProps={{
																native: true,
																MenuProps: {
																	className: '',
																},
															}}
															name="id_cat_impuesto"
															label="Tasa o Couta"
															value={this.state.id_cat_impuesto}
														>
															<option key={0} value={''}>&nbsp;</option>
															{this.state.listar_cat_impuestos.map(option => (
																<option key={option.id_cat_impuesto} value={option.id_cat_impuesto}>
																	{option.impuesto}
																</option>
															))}
														</TextField>
													</Grid>
												) : null}
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3} align={'right'}>
													<div className={'w-auto vertical-inline h-100'}>
														<div className={'v-center'}>
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title={"Agregar"}
																children={(
																	<Fab size="small" color="secondary"
																	     aria-label={"Agregar"}
																	     onClick={this.AgregarImpuestoTrasladado}
																	     style={{marginLeft: "15px"}}>
																		<AddOutlined/>
																	</Fab>
																)}
															/>
														</div>
													</div>
												</Grid>
												
												{this.state.impuestos_traladados.length > 0 ? (
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<table className={'desing-mark'}>
															<thead>
															<tr>
																<th align={"center"}>Base</th>
																<th align={"center"}>Impuesto</th>
																<th align={"center"}>Tipo Factor</th>
																<th align={"center"}>Tasa o Cuota</th>
																<th align={"center"}>Importe</th>
																<th align={"right"}>Acciones</th>
															</tr>
															</thead>
															<tbody>
															{this.state.impuestos_traladados.map((item, index) => (
																	<tr key={index}>
																		<td align={"center"}>{item.base}</td>
																		<td align={"center"}>{item.impuesto_trasladado}</td>
																		<td align={"center"}>{item.tipo_factor}</td>
																		<td align={"center"}>{item.tasa_cuota}</td>
																		<td align={"center"}>{FieldsJs.Currency(item.importe)}</td>
																		<td align={'right'}>
																			<Tooltip
																				TransitionComponent={Zoom}
																				placement={"top"}
																				title="Eliminar"
																				children={(
																					<Fab
																						size="small"
																						aria-label="Eliminar"
																						onClick={() => this.deleteImpuestoTrasladado(item, index)}
																						style={{
																							marginLeft: "10px",
																							backgroundColor: "transparent",
																							color: "#808080",
																							boxShadow: "none",
																						}}
																						children={(
																							<DeleteOutlined/>
																						)}
																					/>
																				)}
																			/>
																		</td>
																	</tr>
																)
															)}
															</tbody>
														</table>
													</Grid>
												) : null}
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
													<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
													<Typography className={'text-left px-16'} style={{opacity: 0.8, color: 'black'}}>
														{"Impuestos retenidos del concepto"}
													</Typography>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														select
														fullWidth
														required
														margin="none"
														onChange={this.handleChange}
														disabled={this.is_view() || this.state.iva_aplicado}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														name="id_cat_impuesto_retenido"
														label="Impuestos"
														value={this.state.id_cat_impuesto_retenido}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_impuestos_retenidos.map(option => (
															<option key={option.id_cat_impuesto_retenido} value={option.id_cat_impuesto_retenido}>
																{option.impuesto_retenido}
															</option>
														))}
													</TextField>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														select
														fullWidth
														required
														margin="none"
														onChange={this.handleChange}
														disabled={this.is_view() || this.state.iva_aplicado}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														name="id_cat_tipo_factor_retenido"
														label="Tipo Factor"
														value={this.state.id_cat_tipo_factor_retenido}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_cat_tipo_factor.map((option, key) => (
															<Fragment key={key}>
																{Number(option.tipo_impuesto) === 2 ? (
																	<option key={option.id_cat_tipo_factor} value={option.id_cat_tipo_factor}>
																		{option.tipo_factor}
																	</option>
																) : null}
															</Fragment>
														))}
													</TextField>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="tasa_cuota_retenido"
														label="Tasa o Cuota"
														type="text"
														fullWidth
														value={this.state.tasa_cuota_retenido}
														onChange={(e) => {
															this.handleChange(e);
														}}
														onKeyPress={EnteroSolo}
														//inputProps={{maxLength: 15}}
														disabled={this.is_view() || this.state.iva_aplicado}
													/>
												</Grid>
												
												<Grid item xs={12} sm={6} md={3} lg={3} xl={3} align={'right'}>
													<div className={'w-auto vertical-inline h-100'}>
														<div className={'v-center'}>
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title={"Agregar"}
																children={(
																	<Fab size="small" color="secondary"
																	     aria-label={"Agregar"}
																	     onClick={this.AgregarImpuestoRetenido}
																	     style={{marginLeft: "15px"}}>
																		<AddOutlined/>
																	</Fab>
																)}
															/>
														</div>
													</div>
												</Grid>
												
												{this.state.impuestos_retenidos.length > 0 ? (
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<table className={'desing-mark'}>
															<thead>
															<tr>
																<th align={"center"}>Base</th>
																<th align={"center"}>Impuesto</th>
																<th align={"center"}>Tipo Factor</th>
																<th align={"center"}>Tasa o Cuota</th>
																<th align={"center"}>Importe</th>
																<th align={"right"}>Acciones</th>
															</tr>
															</thead>
															<tbody>
															{this.state.impuestos_retenidos.map((item, index) => (
																	<tr key={index}>
																		<td align={"center"}>{item.base}</td>
																		<td align={"center"}>{item.impuesto_retenido}</td>
																		<td align={"center"}>{item.tipo_factor}</td>
																		<td align={"center"}>{item.tasa_cuota}</td>
																		<td align={"center"}>{FieldsJs.Currency(item.importe)}</td>
																		<td align={'right'}>
																			<Tooltip
																				TransitionComponent={Zoom}
																				placement={"top"}
																				title="Eliminar"
																				children={(
																					<Fab
																						size="small"
																						aria-label="Eliminar"
																						onClick={() => this.deleteImpuestoRetenido(item, index)}
																						style={{
																							marginLeft: "10px",
																							backgroundColor: "transparent",
																							color: "#808080",
																							boxShadow: "none",
																						}}
																						children={(
																							<DeleteOutlined/>
																						)}
																					/>
																				)}
																			/>
																		</td>
																	</tr>
																)
															)}
															</tbody>
														</table>
													</Grid>
												) : null}
												
												{this.state.editar_partida > 0 ? (
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
														<Divider style={{marginTop: "10px", marginBottom: "15px"}}/>
														<Button style={{background: '#f50057'}} color="primary" size={'small'} variant="contained" onClick={this.modificarPartida}>
															{'Modificar Partida'}
														</Button>
													</Grid>
												) : (
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
														<Divider style={{marginTop: "10px", marginBottom: "15px"}}/>
														<Button color="primary" size={'small'} variant="contained" onClick={this.addPartida}>
															{'Agregar Partida'}
														</Button>
													</Grid>
												)}
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
													<Divider style={{marginTop: "0px", marginBottom: "10px"}}/>
													<Typography className={'text-left px-16'} style={{opacity: 0.8, color: 'black'}}>
														{"Detalle de Partidas"}
													</Typography>
												</Grid>
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<table className={'desing-mark'}>
														<thead>
														<tr>
															<th align={"center"}>...</th>
															<th align={"center"}>Clave Prod/Serv</th>
															<th align={"center"}>Cantidad</th>
															<th align={"center"}>Unidad</th>
															<th align={"center"}>Descripción</th>
															<th align={"right"}>Valor Unitario</th>
															<th align={"right"}>Importe</th>
															<th align={"right"}>Trasladados</th>
															<th align={"right"}>Retenidos</th>
															<th align={"right"}>Descuentos</th>
															{/*<th align={"right"}>Partes</th>
															<th align={"right"}>Complemento Concepto</th>*/}
															<th align={"right"}>Acciones</th>
														</tr>
														</thead>
														<tbody>
														{this.state.partidas.map((item, index) => (
															<Fragment key={index}>
																<tr>
																	<td align={'center'} className={'padding-20-L padding-20-R'}>
																		<div className={'w-100'} align={'center'}>
																			<div style={!!item.expandir ? bg_menos : bg_mas}
																			     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
																			     onClick={() => this.SubTabla(item, index)}
																			/>
																		</div>
																	</td>
																	<td>{item.clave_concepto}</td>
																	<td align={"center"}>{item.cantidad}</td>
																	<td align={"center"}>{item.unidad_medida}</td>
																	<td align={"center"}>{item.concepto}</td>
																	<td align={"center"}>${FieldsJs.Currency(item.precio_unitario)}</td>
																	<td align={"center"}>${FieldsJs.Currency(item.importe)}</td>
																	<td align={"center"}>${FieldsJs.Currency(item.trasladados)}</td>
																	<td align={"center"}>${FieldsJs.Currency(item.retenidos)}</td>
																	<td align={"center"}>${FieldsJs.Currency(item.descuento)}</td>
																	{/*<td align={"center"}>{item.partes}</td>
																<td align={"center"}>{item.complemento}</td>*/}
																	<td align={'right'}>
																		<Tooltip
																			TransitionComponent={Zoom}
																			placement={"top"}
																			title="Eliminar"
																			children={(
																				<Fab
																					size="small"
																					aria-label="Eliminar"
																					onClick={() => this.deletePartida(item, index)}
																					style={{
																						marginLeft: "10px",
																						backgroundColor: "transparent",
																						color: "#808080",
																						boxShadow: "none",
																					}}
																					children={(
																						<DeleteOutlined/>
																					)}
																				/>
																			)}
																		/>
																		<Tooltip
																			TransitionComponent={Zoom}
																			placement={"top"}
																			title="Editar"
																			children={(
																				<Fab
																					size="small"
																					aria-label="Editar"
																					onClick={() => this.editarPartida(item, index)}
																					style={{
																						marginLeft: "10px",
																						backgroundColor: "transparent",
																						color: this.state.editar_partida > 0 ? '#f50057' : "#808080",
																						boxShadow: "none",
																					}}
																					children={(
																						<Edit/>
																					)}
																				/>
																			)}
																		/>
																	</td>
																</tr>
																{item.expandir ? (
																	<Fragment>
																		<tr>
																			<td></td>
																			<td colSpan={10}>
																				<Typography className={'text-left px-14'} style={{opacity: 0.8, color: 'black'}}>
																					{item.impuestos_trasladados.length > 0 ? "Detalle de Impuestos Trasladados" : "La partida no tiene impuestos trasladados."}
																				</Typography>
																				{item.impuestos_trasladados.length > 0 ? (
																					<table className={'desing-mark'}>
																						<thead>
																						<tr>
																							<th align={"center"}># Base</th>
																							<th align={"center"}>Impuesto</th>
																							<th align={"center"}>Tipo Factor</th>
																							<th align={"center"}>Tasa Cuota</th>
																							<th align={"right"}>Importe</th>
																						</tr>
																						</thead>
																						<tbody>
																						{item.impuestos_trasladados.map((itemt, keyt) => (
																							<tr key={keyt}>
																								<td align={"center"}>${FieldsJs.Currency(itemt.base)}</td>
																								<td align={"center"}>{itemt.impuesto_trasladado}</td>
																								<td align={"center"}>{itemt.tipo_factor}</td>
																								<td align={"center"}>{itemt.tasa_cuota}</td>
																								<td align={"right"}>${FieldsJs.Currency(itemt.importe)}</td>
																							</tr>
																						))}
																						
																						</tbody>
																					</table>
																				) : null}
																			</td>
																		</tr>
																		<tr>
																			<td></td>
																			<td colSpan={10}>
																				<Typography className={'text-left px-14'} style={{opacity: 0.8, color: 'black'}}>
																					{item.impuestos_retenidos.length > 0 ? "Detalle de Impuestos Retenidos" : "La partida no tiene impuestos retenidos."}
																				</Typography>
																				{item.impuestos_retenidos.length > 0 ? (
																					<table className={'desing-mark'}>
																						<thead>
																						<tr>
																							<th align={"center"}># Base</th>
																							<th align={"center"}>Impuesto</th>
																							<th align={"center"}>Tipo Factor</th>
																							<th align={"center"}>Tasa Cuota</th>
																							<th align={"right"}>Importe</th>
																						</tr>
																						</thead>
																						<tbody>
																						{item.impuestos_retenidos.map((itemr, keyr) => (
																							<tr key={keyr}>
																								<td>${FieldsJs.Currency(itemr.base)}</td>
																								<td>{itemr.impuesto_retenido}</td>
																								<td>{itemr.tipo_factor}</td>
																								<td>{itemr.tasa_cuota}</td>
																								<td>${FieldsJs.Currency(itemr.importe)}</td>
																							</tr>
																						))}
																						</tbody>
																					</table>
																				) : null}
																			</td>
																		</tr>
																	</Fragment>
																) : null}
															</Fragment>
														
														))}
														
														
														{!(this.state.partidas.length > 0) ? (
															<tr>
																<td colSpan={12}
																    align={'center'}>
																	<VistaVacia
																		numero={null}
																		mensaje={'Ningún concepto agregado.'}
																		image={carrito_de_compra_3}
																		padding={'20px'}
																		paddingText={'20px'}
																		height={'80px'}
																		width={'80px'}
																	/>
																</td>
															</tr>
														) : null}
														<tr>
															<td colSpan={7}/>
															<td colSpan={2} align={"right"}>Subtotal</td>
															<td colSpan={2} align={"right"}>${FieldsJs.Currency(desglose.subtotal)}</td>
														</tr>
														<tr>
															<td colSpan={7}/>
															<td colSpan={2} align={"right"}>Descuentos</td>
															<td colSpan={2} align={"right"}>${FieldsJs.Currency(desglose.descuento)}</td>
														</tr>
														{desglose.trasladados > 0 ? (
															<tr>
																<td colSpan={7}/>
																<td colSpan={2} align={"right"}>Impuestos Trasladados</td>
																<td colSpan={2} align={"right"}>${FieldsJs.Currency(desglose.trasladados)}</td>
															</tr>
														) : null}
														{desglose.retenidos > 0 ? (
															<tr>
																<td colSpan={7}/>
																<td colSpan={2} align={"right"}>Impuestos Retenidos</td>
																<td colSpan={2} align={"right"}>${FieldsJs.Currency(desglose.retenidos)}</td>
															</tr>
														) : null}
														<tr>
															<td colSpan={7}/>
															<td colSpan={2} align={"right"}>Total</td>
															<td colSpan={2} align={"right"}>${FieldsJs.Currency(desglose.total)}</td>
														</tr>
														</tbody>
													</table>
												</Grid>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
													<Divider style={{marginTop: "10px", marginBottom: "15px"}}/>
													<div className={'text-right'}>
														<div className={'vertical-inline'}>
															<Button className={'v-center margin-15-R'} color="default" size={'small'} variant="contained" onClick={() => this.handleChangeTabs(null, '1')}>
																{'Regresar'}
															</Button>
															<Button className={'v-center'} color="primary" size={'small'} variant="contained" onClick={() => this.handleChangeTabs(null, '3')}>
																{'Siguiente'}
															</Button>
														</div>
													</div>
												</Grid>
											</Grid>
										</TabPanel>
										
										<TabPanel value="3" className={''}>
											<Grid container spacing={2} alignContent={"flex-start"} alignItems={"stretch"}>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
													<Typography className={'text-center px-16'} style={{opacity: 0.8, color: '#3f51b5', fontWeight: 'bold'}}>
														{this.state.rfc_receptor + ' - ' + this.state.nombre_razon_social}
													</Typography>
												</Grid>
												
												<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
													<fieldset className={'borde-punteado-gray-2 text-left h-92'}>
														<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Verificación</legend>
														
														<Grid container spacing={2}>
															
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																<TextField
																	required
																	name="rfc_emisor"
																	label="RFC Emisor"
																	type="text"
																	fullWidth
																	value={this.state.rfc_emisor}
																	onChange={this.handleChange}
																	disabled
																/>
															</Grid>
															
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																<TextField
																	required
																	name="sucursal_emisor"
																	label="Sucursal"
																	type="text"
																	fullWidth
																	value={this.state.sucursal_emisor}
																	onChange={this.handleChange}
																	disabled
																/>
															</Grid>
															
															{/*<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																<TextField
																	required
																	name="certificado_emisor"
																	label="Certificado"
																	type="text"
																	fullWidth
																	value={this.state.certificado_emisor}
																	onChange={this.handleChange}
																	disabled
																/>
															</Grid>*/}
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																
																<TextField
																	multiline
																	name="observaciones_emisor"
																	label="Observaciones"
																	type="observaciones_emisor"
																	fullWidth
																	rows={3}
																	value={this.state.observaciones_emisor}
																	onChange={this.handleChange}
																/>
															
															</Grid>
														
														</Grid>
													
													</fieldset>
												
												</Grid>
												
												<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
													
													<fieldset className={'borde-punteado-gray-2 text-left'}>
														<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Datos de envío</legend>
														
														<Grid container spacing={2}>
															
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																<ReactMultiEmail
																	placeholder='Enviar a: '
																	emails={this.state.enviar_a}
																	onChange={(_emails) => {
																		this.setEmails(_emails);
																	}}
																	autoFocus={false}
																	getLabel={(email, index, removeEmail) => {
																		return (
																			<div data-tag key={index}>
																				<div data-tag-item>{email}</div>
																				<span data-tag-handle onClick={() => removeEmail(index)}>
															                ×
															              </span>
																			</div>
																		);
																	}}
																/>
																{/*<TextField
																	required
																	name="enviar_a"
																	label="Enviar a: "
																	type="text"
																	fullWidth
																	value={this.state.enviar_a}
																	onChange={this.handleChange}
																/>*/}
															</Grid>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<ReactMultiEmail
																	placeholder='Cc:'
																	emails={this.state.enviar_cc}
																	onChange={(_emails) => {
																		this.setEmailsCC(_emails);
																	}}
																	autoFocus={false}
																	getLabel={(email, index, removeEmail) => {
																		return (
																			<div data-tag key={index}>
																				<div data-tag-item>{email}</div>
																				<span data-tag-handle onClick={() => removeEmail(index)}>
															                ×
															              </span>
																			</div>
																		);
																	}}
																/>
																{/*<TextField
																	required
																	name="enviar_cc"
																	label="Cc:"
																	type="text"
																	fullWidth
																	value={this.state.enviar_cc}
																	onChange={this.handleChange}
																/>*/}
															
															</Grid>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<ReactMultiEmail
																	placeholder='Cco:'
																	emails={this.state.enviar_cco}
																	onChange={(_emails) => {
																		this.setEmailsCCO(_emails);
																	}}
																	autoFocus={false}
																	getLabel={(email, index, removeEmail) => {
																		return (
																			<div data-tag key={index}>
																				<div data-tag-item>{email}</div>
																				<span data-tag-handle onClick={() => removeEmail(index)}>
															                ×
															              </span>
																			</div>
																		);
																	}}
																/>
																{/*<TextField
																	required
																	name="enviar_cco"
																	label="Cco:"
																	type="text"
																	fullWidth
																	value={this.state.enviar_cco}
																	onChange={this.handleChange}
																/>*/}
															
															</Grid>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'right'}>
																
																<Button onClick={() => this.visualizacionPDF()} color="primary" size={'small'} variant="contained" startIcon={<Visibility/>}>
																	{'Previsualizar'}
																</Button>
																
																{/*<ModalPrevisualizarTimbrado
																	component={
																		<Button onClick={() => {
																		}} color="primary" size={'small'} variant="contained" startIcon={<Visibility/>}>
																			{'Previsualizar'}
																		</Button>
																	}
																	item={this.state}
																/>*/}
															</Grid>
														
														
														</Grid>
													
													</fieldset>
												
												</Grid>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
													<Divider style={{marginTop: "10px", marginBottom: "15px"}}/>
													<div className={'text-right'}>
														<div className={'vertical-inline'}>
															<Button className={'v-center margin-15-R'} color="default" size={'small'} variant="contained" onClick={() => this.handleChangeTabs(null, '2')}>
																{'Regresar'}
															</Button>
															<Button className={'v-center margin-15-R'} style={{background: '#db8e37'}} color="primary" size={'small'} variant="contained" onClick={() => this.temporalFacturacion()}>
																{'Guardar Pre-CFDI'}
															</Button>
															<Button disabled={this.state.btnTimbrado} className={'v-center'} color="primary" size={'small'} variant="contained" onClick={() => this.timbradoFacturacion()}>
																{'Generar Timbrado'}
															</Button>
														</div>
													</div>
												</Grid>
											</Grid>
										</TabPanel>
									</TabContext>
								</Grid>
							
							</Grid>
						
						</Card>
					</div>
				</div>
			
			</div>
		);
	}
}

Timbrado.propTypes = {
	params: PropTypes.object,
};

export default IntegrationNotistack(Timbrado);
