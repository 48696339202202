import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {ClaveProductoServicioService} from "../../../../../services/_Cat/ClaveProductoServicioService/ClaveProductoServicioService";
import {
	BotonAccionAbrirModal,
	BotonAccionFooterModal,
	TituloHeaderModal
} from "../../../../Include/MiniComponents/GlobalComponent";
import {ClaveUnidadMedidaService} from "../../../../../services/_Cat/ClaveUnidadMedidaService/ClaveUnidadMedidaService";
import ModalClaveUnidadMedida from "../../ClaveUnidadMedida/Includes/ModalClaveUnidadMedida";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import {PlaylistAddOutlined} from "@material-ui/icons";
import {ReactLocalStorageService} from "../../../../../settings/ReactLocalStorageService/ReactLocalStorageService";

class ModalClaveProductoServicio extends Component {
	
	state = {};
	Usr = {};
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_cat_clave_producto_servicio: (props.item || {}).id_cat_clave_producto_servicio,
			id_cat_clave_unidad_medida: '',
			clave_producto_servicio: '',
			producto_servicio: '',
			numero_identificacion: '',
			activo: true,
			
			listar_clave_unidad_medida: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.ListaClaveUnidadMedida();
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		if (this.state.id_cat_clave_producto_servicio > 0) {
			ClaveProductoServicioService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			ClaveProductoServicioService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_cat_clave_producto_servicio: '',
			id_cat_clave_unidad_medida: '',
			clave_producto_servicio: '',
			producto_servicio: '',
			numero_identificacion: '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_clave_producto_servicio: item.id_cat_clave_producto_servicio || '',
			id_cat_clave_unidad_medida: item.id_cat_clave_unidad_medida || '',
			clave_producto_servicio: item.clave_producto_servicio || '',
			producto_servicio: item.producto_servicio || '',
			numero_identificacion: item.numero_identificacion || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_clave_producto_servicio: item.id_cat_clave_producto_servicio || '',
			id_cat_clave_unidad_medida: item.id_cat_clave_unidad_medida || '',
			clave_producto_servicio: item.clave_producto_servicio || '',
			producto_servicio: item.producto_servicio || '',
			numero_identificacion: item.numero_identificacion || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	RefreshListClaveUnidadMedida = (id_cat_clave_unidad_medida) => {
		if (id_cat_clave_unidad_medida > 0) {
			this.setState({
				id_cat_clave_unidad_medida: id_cat_clave_unidad_medida
			})
		}
		this.ListaClaveUnidadMedida();
	};
	
	ListaClaveUnidadMedida = () => {
		ClaveUnidadMedidaService.Listar().then((response) => {
			this.setState({
				listar_clave_unidad_medida: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_clave_unidad_medida: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	is_view = () => {
		return this.state.tipo === 'view';
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_clave_producto_servicio}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'clave producto servicio'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									margin="dense"
									name="clave_producto_servicio"
									label="Clave Producto Servicio"
									type="text"
									fullWidth
									onKeyPress={EnteroSolo}
									inputProps={{
										maxLength: 8
									}}
									defaultValue={this.state.clave_producto_servicio}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									margin="dense"
									name="producto_servicio"
									label="Descripción"
									type="text"
									fullWidth
									defaultValue={this.state.producto_servicio}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									margin="dense"
									name="numero_identificacion"
									label="Número de identificación"
									type="text"
									fullWidth
									defaultValue={this.state.numero_identificacion}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									{!this.is_view() ? (
										<Grid item style={{width: '50px'}}>
											{FieldsJs.inArray([1, 2, 4], this.Usr.id_cat_tipo_usuario) ? (
												<ModalClaveUnidadMedida
													componente={(
														<Tooltip
															TransitionComponent={Zoom}
															placement={"right"}
															title="Clave unidad medida"
															children={(
																<Fab
																	color="default"
																	size="small"
																	aria-label="Agregar nueva clave unidad medida"
																	children={(
																		<PlaylistAddOutlined/>
																	)}
																/>
															)}
														/>
													)}
													id_cliente={null}
													tipo={'add'}
													item={{}}
													RefreshList={this.RefreshListClaveUnidadMedida}
													showSnackBars={this.props.showSnackBars}
												/>
											) : ''}
										</Grid>
									) : null}
									<Grid item style={{width: 'calc(100% - 50px)'}}>
										<TextField
											select
											fullWidth
											required
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											
											name="id_cat_clave_unidad_medida"
											label="Clave unidad medida"
											value={this.state.id_cat_clave_unidad_medida}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.listar_clave_unidad_medida.map(option => (
												<option key={option.id_cat_clave_unidad_medida} value={option.id_cat_clave_unidad_medida}>
													{option.clave_unidad_medida} - {option.unidad_medida}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_clave_producto_servicio}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalClaveProductoServicio.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalClaveProductoServicio;
