import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import {Edit, SearchOutlined} from "@material-ui/icons";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {CatService} from "../../../../services/_Cat/CatService/CatService";

class ModalEmpresaUnidadMedida extends Component {
	
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_empresa: props.id_empresa || '',
			tipo: props.tipo,
			id_cat_clave_unidad: '',
			id_empresa_clave_unidad: '',
			clave_unidad: '',
			concepto: '',
			nombre: '',
			descripcion: '',
			cat_clave_unidad: []
		};
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.claveUnidad();
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	claveUnidad = () => {
		return new Promise((resolve, reject) => {
			CatService.ListClaveUnidad().then((response) => {
				this.setState({
					cat_clave_unidad: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					cat_clave_unidad: []
				});
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	save = () => {
		
		try {
			if(Number(this.state.id_empresa_clave_unidad) > 0) {
				EmpresaFacturacionExtService.ModificarUnidadMedida(this.state).then((response) => {
					this.props.RefreshList();
					hideSpinner('spinner', 500);
					this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			} else {
				EmpresaFacturacionExtService.AgregarUnidadMedida(this.state).then((response) => {
					this.props.RefreshList();
					hideSpinner('spinner', 500);
					this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		const {id_empresa, item} = this.props;
		console.log("ITEM:::", item);
		
		this.claveUnidad().then((response) => {
			this.setState({
				id_cat_clave_unidad: item.id_cat_clave_unidad || '',
			});
		});
		
		this.setState({
			id_empresa_clave_unidad: '',
			id_empresa: id_empresa || '',
			nombre: item.nombre || '',
			concepto: item.concepto || '',
			descripcion: item.descripcion || '',
			activo: true,
		});
		
		this.openModal();
		
	};
	
	view = () => {
		
		const {id_empresa, item} = this.props;
		this.claveUnidad().then((response) => {
			this.setState({
				id_cat_clave_unidad: item.id_cat_clave_unidad || '',
			});
		});
		
		this.setState({
			id_empresa_clave_unidad: item.id_empresa_clave_unidad || '',
			id_empresa: id_empresa || '',
			nombre: item.nombre || '',
			concepto: item.concepto || '',
			descripcion: item.descripcion || '',
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.tipo === 'edit') {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
					<IconButton aria-label="Editar" onClick={() => this.edit()}>
						<Edit/>
					</IconButton>
				</Tooltip>
			);
		} else if (this.state.tipo === 'view') {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
					<IconButton aria-label="Detalles" onClick={() => this.view()}>
						<SearchOutlined/>
					</IconButton>
				</Tooltip>
			);
		} else {
			BTN_ACTION = (
				<Button color="primary" size={'small'} variant="contained" onClick={() => this.add()}>
					{'Agregar'}
				</Button>
				/*<Tooltip TransitionComponent={Zoom} placement={"top"} title="Sucursales">
					<IconButton aria-label="Sucursales" onClick={() => this.add()}><Business/></IconButton>
				</Tooltip>*/
			);
		}
	
		
		
		return BTN_ACTION;
	};
	
	edit = (value, key) => {
		const {id_empresa, item} = this.props;
		
		this.claveUnidad().then((response) => {
			this.setState({
				id_cat_clave_unidad: item.id_cat_clave_unidad || '',
			});
		});
		
		this.setState({
			id_empresa_clave_unidad: item.id_empresa_clave_unidad || '',
			id_empresa: id_empresa || '',
			concepto: item.concepto || '',
			descripcion: item.descripcion || '',
			activo: item.activo ? 1 : 0
		});
		
		this.openModal();
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{'Unidad de Medida'}</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									required
									name="concepto"
									label="Unidad de Medida"
									type="text"
									fullWidth
									value={this.state.concepto}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormControl fullWidth required>
									<InputLabel>Clave Unidad de Medida</InputLabel>
									<Select
										native
										value={this.state.id_cat_clave_unidad}
										onChange={this.handleChange}
										name="id_cat_clave_unidad"
										input={<Input/>}
										disabled
									>
										<option value=""/>
										{this.state.cat_clave_unidad.map(option => (
											<option key={option.id_cat_clave_unidad}
											        value={option.id_cat_clave_unidad}>
												{option.nombre ? option.clave_unidad + ' - ' + option.nombre : option.clave_unidad}
											</option>
										))}
									
									</Select>
								</FormControl>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.tipo === 'add' ? 'Agregar':'Modificar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEmpresaUnidadMedida.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	id_empresa: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalEmpresaUnidadMedida);
