import React, {Component, Fragment} from 'react';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {AddOutlined, AttachMoney, BubbleChart, CancelOutlined, DeleteOutlined, EditOutlined, Person, SaveOutlined} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import carrito_de_compra_3 from "../../../../assets/img/icons/contacto_tarjeta.svg";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import * as PropTypes from "prop-types";
import {AdquisicionService} from "../../../../services/AdquisicionService/AdquisicionService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import ModalUsuarios from "./ModalUsuarios";
import Button from "@material-ui/core/Button";

class ModalSocio extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_adquisicion: (props.item || {}).id_adquisicion,
			item: (props.item || null),
			
			nombre: '',
			id_usuario: '',
			rfc: '',
			direccion: '',
			correo_electronico: '',
			telefono: '',
			celular: '',
			participacion: '',
			aportacion: '',
			
			lista_usuario: [],
			lista_socios: (props.item || []).lista_socios,
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	listaUsuarioSocio = () => {
		CatService.ListUsuarioSocio().then((response) => {
			this.setState({
				lista_usuario: response.data
			});
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
		AdquisicionService.SocioGuardar(this.state).then((response) => {
			this.close();
			this.props.RefreshList();
			this.props.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	add = () => {
		const {item} = this.props;
		console.log("this.props ADD::", this.props);
		this.setState({
			id_adquisicion: item.id_adquisicion || '',
			item: item || null,
			nombre: '',
			id_usuario: '',
			direccion: '',
			correo_electronico: '',
			telefono: '',
			celular: '',
			participacion: '',
			aportacion: '',
			activo: true,
			
			lista_socios: item.lista_socios || [],
		});
		this.listaUsuarioSocio();
		
		if (Number(this.state.item.socio || 0) > 0) {
			this.open();
		} else {
			this.props.showSnackBars('error', "Agrega por lo menos 1 socio en la operación.");
		}
		
	};
	
	edit = () => {
		const {item} = this.props;
		
		this.setState({
			id_adquisicion: item.id_adquisicion || '',
		});
		this.listaUsuarioSocio();
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_adquisicion: item.id_adquisicion || '',
			
			lista_socios: item.socios || [],
		});
		this.open();
	};
	
	edit_socio = (value, key) => {
		try {
			
			this.setState({
				key: key,
				nombre: value.nombre,
				id_usuario: value.id_usuario,
				telefono: value.telefono,
				celular: value.celular,
				correo_electronico: value.correo_electronico,
				aportacion: value.aportacion,
				participacion: value.participacion
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			nombre: '',
			id_usuario: '',
			telefono: '',
			celular: '',
			correo_electronico: '',
			participacion: '',
			aportacion: ''
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el socio?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_socios = FieldsJs.Copy(this.state.lista_socios);
					
					lista_socios.splice(key, 1);
					
					this.setState({
						lista_socios: lista_socios
					});
				}
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	add_socio = () => {
		
		try {
			
			if (!this.state.id_usuario) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Usuario.'
				})
			}
			
			if (!(this.state.participacion)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Participación.'
				})
			}
			
			if (this.state.participacion <= 0 || this.state.participacion > 100) {
				throw Object({
					status: false, mensaje: 'Participación incorrecta.'
				})
			}
			
			if (!(this.state.aportacion)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Aportación.'
				})
			}
			
			if (!(this.state.aportacion)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Aportación.'
				})
			}
			
			
			let lista_socios = FieldsJs.Copy(this.state.lista_socios || []);
			
			let cont_participacion = Number(this.state.participacion);
			for (let i = 0; i < lista_socios.length; i++) {
				if (this.is_edited_product()) {
					if (this.state.key !== i) {
						cont_participacion = cont_participacion + Number(lista_socios[i].participacion)
					}
				} else {
					cont_participacion = cont_participacion + Number(lista_socios[i].participacion)
				}
			}
			
			if (cont_participacion > 100) {
				throw Object({
					status: false, mensaje: 'Participación excede del 100%.'
				})
			}
			
			if (this.is_edited_product()) {
				for (let i = 0; i < lista_socios.length; i++) {
					let item = lista_socios[i];
					if (this.state.key === i) {
						item.nombre = this.state.nombre || null;
						item.id_usuario = this.state.id_usuario || null;
						item.telefono = this.state.telefono || null;
						item.celular = this.state.celular || '';
						item.correo_electronico = this.state.correo_electronico || '';
						item.participacion = this.state.participacion || '';
						item.aportacion = this.state.aportacion || '';
					}
				}
			} else {
				let item;
				item = {
					nombre: this.state.nombre || null,
					id_usuario: this.state.id_usuario || null,
					telefono: this.state.telefono || null,
					celular: this.state.celular || '',
					correo_electronico: this.state.correo_electronico || '',
					participacion: this.state.participacion || '',
					aportacion: this.state.aportacion || ''
				};
				lista_socios.push(item);
			}
			
			this.setState({
				key: null,
				nombre: '',
				id_usuario: '',
				telefono: '',
				celular: '',
				correo_electronico: '',
				participacion: '',
				aportacion: '',
				
				lista_socios: lista_socios
			});
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	
	clacularAportacion = (participacion) => {
		
		let aportacion = ((((Number(this.state.item.precio) - Number(this.state.item.enganche)) * Number(participacion)) / 100) / Number(this.state.item.plazo)).toFixed(2);
		
		this.setState({
			aportacion: aportacion
		});
	};
	
	getNombre = (id_usuario) => {
		if (id_usuario) {
			let lista = FieldsJs.Copy(this.state.lista_usuario);
			for (let i = 0; i < lista.length; i++) {
				if (Number(id_usuario) === lista[i].id_usuario) {
					this.setState({
						nombre: lista[i].nombre_completo,
						telefono: lista[i].telefono,
						celular: lista[i].celular,
						correo_electronico: lista[i].correo_electronico,
					});
				}
			}
		}
	};
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Socio'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
							</Grid>
							
							{this.state.item ? (
								<Fragment>
									<Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
										<p className={'px-13 margin-0 text-center'}>Tipo adquisición:</p>
										<p className={'px-13 margin-0 text-center'}><strong>{this.state.item.tipo_adquisicion}</strong></p>
									</Grid>
									
									<Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
										<p className={'px-13 margin-0 text-center'}>Precio:</p>
										<p className={'px-13 margin-0 text-center'}><strong>${FieldsJs.Currency(this.state.item.precio)}</strong></p>
									</Grid>
									
									<Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
										<p className={'px-13 margin-0 text-center'}>Enganche:</p>
										<p className={'px-13 margin-0 text-center'}><strong>{this.state.item.enganche}</strong></p>
									</Grid>
									
									<Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
										<p className={'px-13 margin-0 text-center'}>Número de pagos:</p>
										<p className={'px-13 margin-0 text-center'}><strong>{this.state.item.plazo}</strong></p>
									</Grid>
									
									<Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
										<p className={'px-13 margin-0 text-center'}>Propietario:</p>
										<p className={'px-13 margin-0 text-center'}><strong>{this.state.item.propietario}</strong></p>
									</Grid>
									
									<Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
										<p className={'px-13 margin-0 text-center'}>Socio(s):</p>
										<p className={'px-13 margin-0 text-center'}><strong>{this.state.item.socio}</strong></p>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<p className={'px-13 margin-0 text-left'}>Dirección: <strong>{this.state.item.direccion ? this.state.item.direccion : 'Sin registrar'}</strong></p>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
									</Grid>
								</Fragment>
							) : null}
							
							{!this.is_view() ? (
								<Fragment>
									{/*<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="nombre"
												           onChange={this.handleChange}
												           label="Nombre" autoComplete={'off'}
												           value={this.state.nombre}
												           required
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>*/}
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Person className={'w-100 text-gray'}/>
											</Grid>
											
											<Grid item className={'w-100-90-px'}>
												<FormControl required fullWidth>
													<InputLabel>Usuario</InputLabel>
													<Select
														native
														value={this.state.id_usuario}
														onChange={(e) => {
															this.handleChange(e);
															this.getNombre(e.target.value);
														}}
														name="id_usuario"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.lista_usuario.map(option => (
															<option key={option.id_usuario}
															        value={option.id_usuario}>
																{option.nombre + ' ' + option.apellido_paterno + ' ' + option.apellido_materno}
															</option>
														))}
													</Select>
												</FormControl>
											</Grid>
											
											<Grid item className={'w-60-px'}>
												<ModalUsuarios tipo={'add'} RefreshList={this.props.RefreshList} showSnackBars={this.props.showSnackBars} listaUsuarioSocio={this.listaUsuarioSocio}/>
											</Grid>
										</Grid>
									</Grid>
									
									{/*<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HowToVoteOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="rfc"
												           onChange={this.handleChange}
												           inputProps={{maxLength: 13}}
												           label="R.F.C." autoComplete={'off'}
												           value={this.state.rfc}
												           required
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>*/}
									
									{/*<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Phone className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="telefono"
												           onChange={this.handleChange}
												           inputProps={{maxLength: 10}}
												           onKeyPress={EnteroSolo}
												           label="Teléfono" autoComplete={'off'}
												           value={this.state.telefono}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<PhoneIphoneOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="celular"
												           onChange={this.handleChange}
												           inputProps={{maxLength: 10}}
												           onKeyPress={EnteroSolo}
												           required
												           label="Celular" autoComplete={'off'}
												           value={this.state.celular}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AlternateEmailOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="correo_electronico"
												           onChange={this.handleChange}
												           required
												           label="Correo electrónico" autoComplete={'off'}
												           value={this.state.correo_electronico}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>*/}
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<BubbleChart className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="participacion"
												           onChange={(e) => {
													           this.handleChange(e);
													           this.clacularAportacion(e.target.value);
												           }}
													//inputProps={{maxLength: 10}}
													        onKeyPress={EnteroSolo}
													        required
													        label="Participación del bien" autoComplete={'off'}
													        value={this.state.participacion}
													        disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AttachMoney className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="aportacion"
												           onChange={this.handleChange}
													//inputProps={{maxLength: 10}}
													        onKeyPress={EnteroSolo}
													        required
													        label="Aportación" autoComplete={'off'}
													        value={this.state.aportacion}
													        disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={3} md={3} lg={2} xl={2} align={"right"}>
										<Tooltip
											TransitionComponent={Zoom}
											placement={"top"}
											title="Cancelar edición"
											children={(
												<span>
											<Fab size="small" color="secondary"
											     aria-label="Cancelar edición"
											     onClick={() => this.edit_cancel()}
											     style={{marginLeft: "15px"}}
											     disabled={!this.is_edited_product()}>
												<CancelOutlined/>
											</Fab>
										</span>
											)}
										/>
										<Tooltip
											TransitionComponent={Zoom}
											placement={"top"}
											title={this.is_edited_product() ? "Actualizar" : "Agregar"}
											children={(
												<Fab size="small" color="secondary"
												     aria-label={this.is_edited_product() ? "Actualizar" : "Agregar"}
												     onClick={() => this.add_socio()}
												     style={{marginLeft: "15px"}}>
													{this.is_edited_product() ? (
														<SaveOutlined/>
													) : (
														<AddOutlined/>
													)}
												</Fab>
											)}
										/>
									</Grid>
								
								</Fragment>
							) : ''}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<table className={'desing-mark'}>
									<thead>
									<tr>
										<th>Nombre</th>
										{/*<th>RFC</th>*/}
										<th>Teléfono</th>
										<th>Celular</th>
										<th>Correo electrónico</th>
										<th>Participación</th>
										<th>Aportación</th>
										{!this.is_view() ? (<th width={100} align={"center"}>Acciones</th>) : null}
									</tr>
									</thead>
									<tbody>
									{this.state.lista_socios.map((value, index) => (
											<tr key={index}>
												<td>{value.nombre}</td>
												{/*<td>{value.rfc}</td>*/}
												<td>{value.telefono ? value.telefono : 'Sin registrar'}</td>
												<td>{value.celular ? value.celular : 'Sin registrar'}</td>
												<td>{value.correo_electronico ? value.correo_electronico : 'Sin registrar'}</td>
												<td>{value.participacion}%</td>
												<td>${FieldsJs.Currency(value.aportacion)}</td>
												
												{!this.is_view() ? (
													<td align={'right'}>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Eliminar"
															children={(
																<Fab
																	size="small"
																	aria-label="Eliminar"
																	onClick={() => this.delete(value, index)}
																	style={{
																		marginLeft: "10px",
																		backgroundColor: "transparent",
																		color: "#808080",
																		boxShadow: "none",
																	}}
																	children={(
																		<DeleteOutlined/>
																	)}
																/>
															)}
														/>
														{this.state.key === index ? (
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Producto en edición"
																children={(
																	<Fab
																		size="small"
																		aria-label="Editar"
																		onClick={() => this.edit_socio(value, index)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "#f50057",
																			color: "white",
																		}}
																		children={(
																			<EditOutlined/>
																		)}
																	/>
																)}
															/>
														) : (
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Editr producto"
																children={(
																	<Fab
																		size="small"
																		aria-label="Editar"
																		onClick={() => this.edit_socio(value, index)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "transparent",
																			color: "#808080",
																			boxShadow: "none",
																		}}
																		children={(
																			<EditOutlined/>
																		)}
																	/>
																)}
															/>
														)}
													</td>
												) : null}
											</tr>
										)
									)}
									{!(this.state.lista_socios.length > 0) ? (
										<tr>
											<td colSpan={!this.is_view() ? (is_root ? 13 : 6) : (is_root ? 12 : 6)}
											    align={'center'}>
												<VistaVacia
													numero={null}
													mensaje={'Ningún contacto agregado.'}
													image={carrito_de_compra_3}
													padding={'10px'}
													paddingText={'10px'}
													height={'60px'}
													width={'60px'}
												/>
											</td>
										</tr>
									) : null}
									
									</tbody>
								</table>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								{'Cancelar'}
							</Button>
							<Button onClick={this.save} color="primary">
								{'Guardar'}
							</Button>
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalSocio.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalSocio;
