import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const AccesoService = {
	Listar: (filtro, paginacion) => {
		console.log('PAGINACION', paginacion);
		let fecha_alta = (filtro.fecha_alta || null) ? DateFormat.FormatSql(filtro.fecha_alta) : null;
		
		let params = {
			filtro: {
				id_usuario: filtro.id_usuario || null,
				nombre_usuario: filtro.nombre_usuario || null,
				id_cat_tipo_usuario: filtro.id_cat_tipo_usuario || null,
				activo: filtro.activo || 1,
				fecha_alta: fecha_alta,
				ordenar_fecha_alta: filtro.ordenar_fecha_alta || null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Acceso_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalles: (id_facturacion) => {
		let params = {
			id_facturacion: id_facturacion,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Acceso_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Guardar: (form) => {
		let ws = '_Acceso_Agregar';
		let id_facturacion = null;
		if (form.id_facturacion > 0) {
			ws = '_Acceso_Modificar';
			id_facturacion = form.id_facturacion;
		}
		let fecha_emitir_comprobante = DateFormat.FormatSql(form.fecha_emitir_comprobante);
		let params = {
			id_facturacion: id_facturacion,
			id_empresa: form.id_empresa,
			serie: form.serie,
			folio: form.folio,
			id_cat_tipo_comprobante: form.id_cat_tipo_comprobante,
			id_cliente: form.id_cliente,
			id_cat_facturacion_estatus: form.id_cat_facturacion_estatus,
			id_cat_forma_pago: form.id_cat_forma_pago,
			id_cat_metodo_pago: form.id_cat_metodo_pago,
			id_cat_periodo_pago: form.id_cat_periodo_pago,
			id_cat_uso_cfdi: form.id_cat_uso_cfdi,
			observacion_cfdi: form.observacion_cfdi || null,
			observacion_interna: form.observacion_interna || null,
			fecha_emitir_comprobante: fecha_emitir_comprobante || null,
			total: form.facturacion_total || null,
			facturacion_producto: form.lista_facturacion_producto || [],
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post(ws, params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_facturacion) => {
		let params = {
			id_facturacion: id_facturacion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Acceso_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Cerrar: (id_facturacion) => {
		let params = {
			id_facturacion: id_facturacion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Acceso_Cerrar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	
};
