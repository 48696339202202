import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

class BancoService {
	static Listar = () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Agregar = (form) => {
		let params = {
			id_cat_banco: null,
			clave_banco: form.clave_banco,
			banco: form.banco,
			razon_social: form.razon_social,
			logo_archivo: form.logo_archivo,
			logo_formato: form.logo_formato,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Modificar = (form) => {
		let params = {
			id_cat_banco: form.id_cat_banco,
			clave_banco: form.clave_banco,
			banco: form.banco,
			razon_social: form.razon_social,
			logo_archivo: form.logo_archivo,
			logo_formato: form.logo_formato,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Eliminar = (id_cat_banco) => {
		let params = {
			id_cat_banco: id_cat_banco
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Banco_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
}

export default BancoService;
