import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Slide from "@material-ui/core/Slide/index";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import {
	AccountBalanceOutlined,
	AccountTreeOutlined,
	BusinessOutlined,
	CommentOutlined,
	ConfirmationNumberOutlined,
	SyncOutlined
} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import BancoService from "../../../../services/_Cat/BancoService/BancoService";
import BancoSucursalService from "../../../../services/_Cat/BancoSucursalService/BancoSucursalService";
import BancoTipoCuentaService from "../../../../services/_Cat/BancoTipoCuentaService/BancoTipoCuentaService";
import UsuarioBancoService from "../../../../services/_Sis/UsuarioBancoService/UsuarioBancoService";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import Fab from "@material-ui/core/Fab";
import ModalBanco from "../../Cat/Banco/Includes/ModalBanco";
import ModalBancoSucursal from "../../Cat/BancoSucursal/Includes/ModalBancoSucursal";
import ModalBancoTipoCuenta from "../../Cat/BancoTipoCuenta/Includes/ModalBancoTipoCuenta";


class ModalDatosBancarios extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		this.state = {
			modal: {
				open: false
			},
			
			id_usuario_banco: '',
			id_usuario: '',
			id_cat_banco: '',
			id_cat_banco_sucursal: '',
			id_cat_banco_tipo_cuenta: '',
			numero_cliente: '',
			numero_cuenta: '',
			clabe_interbancaria: '',
			observaciones: '',
			activo: true,
			
			cat_banco: [],
			cat_banco_sucursal: [],
			cat_banco_tipo_cuenta: [],
			
			usuario_banco: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({
			modal: {
				open: true
			},
			
			id_usuario_banco: '',
			id_usuario: '',
			id_cat_banco: '',
			id_cat_banco_sucursal: '',
			id_cat_banco_tipo_cuenta: '',
			numero_cliente: '',
			numero_cuenta: '',
			clabe_interbancaria: '',
			observaciones: '',
			activo: true,
			
			cat_banco: [],
			cat_banco_sucursal: [],
			cat_banco_tipo_cuenta: [],
			
			usuario_banco: [],
		});
		this.InitComponent();
		this.ListBanco();
		this.ListBancoTipoCuenta();
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			},
			
			id_usuario_banco: '',
			id_usuario: '',
			id_cat_banco: '',
			id_cat_banco_sucursal: '',
			id_cat_banco_tipo_cuenta: '',
			numero_cliente: '',
			numero_cuenta: '',
			clabe_interbancaria: '',
			observaciones: '',
			activo: true,
			
			cat_banco: [],
			cat_banco_sucursal: [],
			cat_banco_tipo_cuenta: [],
			
			usuario_banco: [],
		});
	};
	
	InitComponent = () => {
		UsuarioBancoService.Listar().then((response) => {
			this.setState({
				usuario_banco: response.data
			});
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListBanco = () => {
		BancoService.Listar().then((response) => {
			this.setState({
				cat_banco: response.data
			});
		});
	};
	
	ListBancoSucursal = (e) => {
		let id_cat_banco = e.target.value;
		BancoSucursalService.Listar(id_cat_banco).then((response) => {
			this.setState({
				cat_banco_sucursal: response.data
			});
		});
	};
	
	ListBancoTipoCuenta = () => {
		BancoTipoCuentaService.Listar().then((response) => {
			this.setState({
				cat_banco_tipo_cuenta: response.data
			});
		});
	};
	
	agregar = () => {
		UsuarioBancoService.Agregar(this.state).then((response) => {
			this.props.showSnackBars('success', response.mensaje);
			this.InitComponent();
			this.setState({
				id_usuario: '',
				id_cat_banco: '',
				id_cat_banco_sucursal: '',
				id_cat_banco_tipo_cuenta: '',
				numero_cliente: '',
				numero_cuenta: '',
				clabe_interbancaria: '',
				observaciones: '',
				activo: true,
			});
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	render() {
		
		return (
			<div>
				
				<div onClick={() => this.openModal()}>
					{this.props.component}
				</div>
				
				<Dialog open={this.state.modal.open} onClose={() => this.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								
								<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography variant={'h5'} className={'text-left'}>
											<Fab size="small" color="secondary" aria-label="Actualizar"
											     onClick={() => this.InitComponent()} style={{marginRight: '15px'}}>
												<SyncOutlined/>
											</Fab>
											Datos bancarios
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-50-px'}>
												<ModalBanco
													componente={(
														<Fab size="small" color="secondary">
															<AccountBalanceOutlined/>
														</Fab>
													)}
													tipo={'add'}
													item={{}}
													RefreshList={this.ListBanco}
													showSnackBars={this.props.showSnackBars}
												/>
											</Grid>
											<Grid item className={'w-100-50-px'}>
												<TextField
													select
													fullWidth
													onChange={(e) => {
														this.handleChange(e);
														this.ListBancoSucursal(e);
													}}
													SelectProps={{
														native: true,
														MenuProps: {},
													}}
													
													name="id_cat_banco"
													label="Banco"
													value={this.state.id_cat_banco}
													required
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.cat_banco.map(option => (
														<option key={option.id_cat_banco} value={option.id_cat_banco}>
															{option.banco}
														</option>
													))}
												</TextField>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-50-px'}>
												<ModalBancoSucursal
													componente={(
														<Fab size="small" color="secondary">
															<BusinessOutlined/>
														</Fab>
													)}
													tipo={'add'}
													item={{
														id_cat_banco_sucursal: null,
														id_cat_banco: this.state.id_cat_banco
													}}
													RefreshList={() => {
														this.ListBancoSucursal({target: {value: this.state.id_cat_banco}});
													}}
													showSnackBars={this.props.showSnackBars}
												/>
											</Grid>
											<Grid item className={'w-100-50-px'}>
												<TextField
													select
													fullWidth
													onChange={this.handleChange}
													SelectProps={{
														native: true,
														MenuProps: {},
													}}
													
													name="id_cat_banco_sucursal"
													label="Sucursal"
													value={this.state.id_cat_banco_sucursal}
													required
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.cat_banco_sucursal.map(option => (
														<option key={option.id_cat_banco_sucursal}
														        value={option.id_cat_banco_sucursal}>
															{option.banco_sucursal}
														</option>
													))}
												</TextField>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-50-px'}>
												<ModalBancoTipoCuenta
													componente={(
														<Fab size="small" color="secondary">
															<AccountTreeOutlined/>
														</Fab>
													)}
													tipo={'add'}
													item={{}}
													RefreshList={() => {
														this.ListBancoTipoCuenta();
													}}
													showSnackBars={this.props.showSnackBars}
												/>
											</Grid>
											<Grid item className={'w-100-50-px'}>
												<TextField
													select
													fullWidth
													onChange={this.handleChange}
													SelectProps={{
														native: true,
														MenuProps: {},
													}}
													
													name="id_cat_banco_tipo_cuenta"
													label="Tipo de cuenta"
													value={this.state.id_cat_banco_tipo_cuenta}
													required
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.cat_banco_tipo_cuenta.map(option => (
														<option key={option.id_cat_banco_tipo_cuenta}
														        value={option.id_cat_banco_tipo_cuenta}>
															{option.banco_tipo_cuenta}
														</option>
													))}
												</TextField>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<ConfirmationNumberOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="numero_cliente"
												           onChange={this.handleChange}
												           label="No. cliente" autoComplete={'off'}
												           value={this.state.numero_cliente}
												           required
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<ConfirmationNumberOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="numero_cuenta"
												           onChange={this.handleChange}
												           label="No. cuenta" autoComplete={'off'}
												           value={this.state.numero_cuenta}
												           required
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<ConfirmationNumberOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="clabe_interbancaria"
												           onChange={this.handleChange}
												           label="Clabe interbancaria" autoComplete={'off'}
												           value={this.state.clabe_interbancaria}
												           required
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<CommentOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} multiline fullWidth name="observaciones"
												           onChange={this.handleChange}
												           label="Observaciones" autoComplete={'off'}
												           value={this.state.observaciones}
												           required
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<Button onClick={() => this.agregar()} color="secondary" variant={"contained"}
										        fullWidth>
											Agregar banco
										</Button>
									</Grid>
								
								
								</Grid>
							
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<div style={{background: "#ddfffc", padding: 15}}>
									
									{this.state.usuario_banco.map((value, index) => (
										<div key={index} style={{width: "100%", marginBottom: 15}}>
											<Typography style={{fontSize: 18}}>
												{value.banco}
											</Typography>
											<Typography style={{fontSize: 16}}>
												Sucursal: {value.banco_sucursal}
											</Typography>
											<Typography style={{fontSize: 14}}>
												Tipo de cuenta: {value.banco_tipo_cuenta}
											</Typography>
											<Typography style={{fontSize: 14}}>
												No. cliente: {value.numero_cliente}
											</Typography>
											<Typography style={{fontSize: 14}}>
												Numero de cuenta: {value.numero_cuenta}
											</Typography>
											<Typography style={{fontSize: 14}}>
												Clabe interbancaria: {value.clabe_interbancaria}
											</Typography>
										</div>
									))}
									
									{this.state.usuario_banco.length <= 0 ? (
										<VistaVacia
											width={50}
											height={50}
											numero={0}
											mensaje={'No se encontraron cuentas bancarias.'}
										/>
									) : null}
								
								</div>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.closeModal()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
							
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}


ModalDatosBancarios.propTypes = {
	form: PropTypes.object.isRequired,
	component: PropTypes.element.isRequired,
	refresh: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalDatosBancarios;
