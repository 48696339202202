import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import {AttachFile, AttachMoney, CheckCircle, ClearAll, DeleteOutlined, Description, Edit, EditOutlined, HdrStrong, HdrWeakOutlined, HomeWork, House, Lock, SearchOutlined} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import EmpresaFacturacionService from "../../../../services/EmpresaFacturacionService/EmpresaFacturacionService";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import {CONFIG} from "../../../../settings/Config/Config";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import empresa_default from "../../../../assets/img/icons/business.svg";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import TabContext from "@material-ui/lab/TabContext";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import {TabPanel} from "@material-ui/lab";
import Card from "@material-ui/core/Card";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {ClienteService} from "../../../../services/ClienteService/ClienteService";
import Paginacion from "../../../Include/Paginacion/Paginacion";
import ModalEmpresaClaveProducto from "./ModalEmpresaClaveProducto";
import ModalEmpresaUnidadMedida from "./ModalEmpresaUnidadMedida";

class ModalEmpresaCatalogos extends Component {
	
	state = {};
	Usr = {};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			
			filtro: {},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			open: false,
			scroll: 'paper',
			id_empresa: (props.item || '').id_empresa,
			id_cat_tipo_persona: (props.item || '').id_cat_tipo_persona,
			tipo: props.tipo,
			
			id_cat_regimen_fiscal: '',
			id_cat_clave_unidad: '',
			id_cat_clave_producto_servicio: '',
			unidad_medida: '',
			producto: '',
			id_cat_moneda: '',
			
			cat_regimen_fiscal: [],
			cat_clave_unidad: [],
			cat_clave_producto_servicio: [],
			cat_moneda: [],
			
			regimen_fiscales: [],
			unidades_medidas: [],
			productos: [],
			listar_productos: [],
			monedas: [],
			
			
			valuetab: '1',
			activo: 1,
			
		};
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	handleChangeTabs = (e, variable, campo, date, input) => {
		console.log(e, variable);
		this.setState({
			valuetab: variable
		});
		
		setTimeout(() => {
			this.RefreshList();
		}, 800);
	};
	
	SearchProducto = (producto) => {
		let filtro = {};
		
		filtro.producto = this.state.producto || undefined;
		filtro.activo = this.state.activo || undefined;
		
		this.HandleFiltro(filtro);
		setTimeout(() => {
			this.AplicarFiltros();
		}, 600)
	};
	
	SearchUnidadMedida = (unidad_medida) => {
		let filtro = {};
		
		filtro.unidad_medida = this.state.unidad_medida || undefined;
		filtro.activo = this.state.activo || undefined;
		
		this.HandleFiltro(filtro);
		setTimeout(() => {
			this.AplicarFiltrosUnidadMedida();
		}, 600)
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	ListarProductos = () => {
		EmpresaFacturacionExtService.ListarProducto(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				listar_productos: response.data,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({
				listar_productos: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.ListarProductos());
	};
	
	AplicarPaginacionUnidad = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.ListarUnidadMedida());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.ListarProductos());
	};
	
	AplicarFiltrosUnidadMedida = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.ListarUnidadMedida());
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	RefreshList = () => {
		return new Promise((resolve, reject) => {
			EmpresaFacturacionExtService.Catalogos(this.state).then((response) => {
				switch (Number(this.state.valuetab)) {
					case 1:
						this.setState({
							regimen_fiscales: response.data,
							unidades_medidas: [],
							productos: [],
							monedas: [],
						});
						break;
					case 2:
						this.setState({
							regimen_fiscales: [],
							unidades_medidas: response.data,
							productos: [],
							monedas: [],
						});
						break;
					case 3:
						this.setState({
							regimen_fiscales: [],
							unidades_medidas: [],
							productos: response.data,
							monedas: [],
						});
						break;
					case 4:
						this.setState({
							regimen_fiscales: [],
							unidades_medidas: [],
							productos: [],
							monedas: response.data,
						});
						break;
				}
				resolve(response);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	regimenFiscal = () => {
		CatService.ListRegimenFiscalFac(this.state.id_cat_tipo_persona).then((response) => {
			this.setState({
				cat_regimen_fiscal: response.data,
			});
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	}
	
	ListarUnidadMedida = () => {
		EmpresaFacturacionExtService.ListUnidadMedida(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				cat_clave_unidad: response.data,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({
				listar_productos: []
			});
			this.showSnackBars('error', error.mensaje);
		});
		/*CatService.ListClaveUnidad(this.state).then((response) => {
			this.setState({
				cat_clave_unidad: response.data,
			});
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});*/
	}
	
	claveProductoServicio = () => {
		CatService.ListClaveProductoServicio(this.state).then((response) => {
			this.setState({
				cat_clave_producto_servicio: response.data,
			});
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	}
	
	moneda = () => {
		CatService.ListMoneda(this.state).then((response) => {
			this.setState({
				cat_moneda: response.data,
			});
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	}
	
	openModal = () => {
		this.regimenFiscal();
		this.ListarUnidadMedida();
		this.claveProductoServicio();
		this.moneda();
		this.ListarProductos();
		this.RefreshList().then((response) => {
			this.setState({open: true, scroll: 'paper'});
		});
		
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	clear = () => {
		this.setState({
			id_cat_regimen_fiscal: '',
			id_cat_clave_unidad: '',
			id_cat_clave_producto_servicio: '',
			id_cat_moneda: '',
		});
	};
	
	saveProducto = (item) => {
		this.setState({
			id_cat_clave_producto_servicio: item.id_cat_clave_producto_servicio
		}, () => {
			this.save();
		});
	}
	
	save = () => {
		
		try {
			EmpresaFacturacionExtService.CatalogosAgregar(this.state).then((response) => {
				this.clear();
				this.RefreshList();
				hideSpinner('spinner', 500);
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	}
	
	add = () => {
		
		console.log(this.props);
		const {item} = this.props;
		
		this.setState({
			id_cat_tipo_persona: item.id_cat_tipo_persona || '',
			id_empresa_archivo: item.id_empresa_archivo || '',
			id_empresa: item.id_empresa || '',
			key: null,
			id_empresa_sucursal: '',
			serie: '',
			folio_inicial: '',
			activo: true,
		});
		
		this.openModal();
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_empresa_archivo > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Estatus">
						<IconButton aria-label="Estatus" onClick={() => this.edit()}>
							<HdrStrong/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Catálogos">
					<IconButton aria-label="Catálogos" onClick={() => this.add()}><HdrStrong/></IconButton>
				</Tooltip>
			);
		}
		return BTN_ACTION;
	};
	
	obtenerTabText = () => {
		let text = '';
		switch (this.state.valuetab) {
			case '1':
				text = "el regimen fiscal";
				break;
			case '2':
				text = "la unidad de medida";
				break;
			case '3':
				text = "el producto";
				break;
			case '4':
				text = "la moneda";
				break;
			
		}
		return text;
	}
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar ' + this.obtenerTabText() + ' con Id: ' + key + '?').then((r) => {
				if (r.button === 'Aceptar') {
					EmpresaFacturacionExtService.CatalogosDelete(value, this.state.valuetab).then((response) => {
						this.RefreshList();
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
						
					});
				}
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	productoAgregado = (item) => {
		let band = false;
		for (let i = 0; i < this.state.productos.length; i++) {
			if (Number(item.id_cat_clave_producto_servicio) === Number(this.state.productos[i].id_cat_clave_producto_servicio)) {
				band = true;
			}
		}
		return band;
	}
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{'Catálogos'}
					</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
								<Card>
									<fieldset className={'borde-punteado-gray-2 text-center h-88'}>
										<legend align={'left'}> Empresa</legend>
										
										<Grid container spacing={2}>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2} align={"center"}>
												<div className={'text-center w-70-px'}>
													<div className={'card-0 w-70-px h-70-px'}>
														<img style={{width: 70, height: 70}} alt={'Logo'} src={this.props.item.archivo_logo ? CONFIG.src_facturacion + this.props.item.archivo_logo : empresa_default}/>
													</div>
												</div>
											</Grid>
											<Grid item xs={12} sm={12} md={10} lg={10} xl={10} align={'left'}>
												<Typography variant={'h5'} className={'px-20 text-uppercase'} style={{fontWeight: 'bold'}}>
													{this.props.item.nombre_razon_social}
												</Typography>
												<Typography variant={'h5'} className={'px-16 margin-0-T margin-0-B'}>
													{this.props.item.rfc}
												</Typography>
												<Typography variant={'h5'} className={'px-14'}>
													{this.props.item.tipo_persona}
												</Typography>
											</Grid>
										
										</Grid>
									
									</fieldset>
								</Card>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TabContext value={this.state.valuetab}>
									<AppBar position="static">
										<TabList variant="fullWidth" onChange={this.handleChangeTabs} aria-label="simple tabs example">
											<Tab label={<Fragment>
												<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>Régimen Fiscal</p>
												{/*<p className={'margin-0 padding-0 px-13'}>{this.props.titulo}</p>*/}
											</Fragment>} value="1"/>
											<Tab label={<Fragment>
												<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>Unidades de Medida</p>
												{/*<p className={'margin-0 padding-0 px-13'}>Contratos individuales firmados</p>*/}
											</Fragment>} value="2"/>
											<Tab label={<Fragment>
												<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>Productos</p>
												{/*<p className={'margin-0 padding-0 px-13'}>Contratos individuales firmados</p>*/}
											</Fragment>} value="3"/>
											<Tab label={<Fragment>
												<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>Monedas</p>
												{/*<p className={'margin-0 padding-0 px-13'}>Contratos individuales firmados</p>*/}
											</Fragment>} value="4"/>
										</TabList>
									</AppBar>
									
									<TabPanel value="1" className={'padding-0-L padding-0-R'}>
										<Grid container spacing={2} alignContent={"flex-end"} alignItems={"stretch"}>
											<Grid item xs={10} sm={10} md={10} lg={10} xl={10} align={'center'}>
												<FormControl fullWidth required>
													<InputLabel>Régimen Fiscal</InputLabel>
													<Select
														native
														value={this.state.id_cat_regimen_fiscal}
														onChange={(e) => {
															this.handleChange(e);
														}}
														name="id_cat_regimen_fiscal"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_regimen_fiscal.map(option => (
															<option key={option.id_cat_regimen_fiscal}
															        value={option.id_cat_regimen_fiscal}>
																{option.regimen_fiscal + ' - ' + option.descripcion}
															</option>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={2} sm={2} md={2} lg={2} xl={2} align={'center'}>
												<Button onClick={() => this.save()} color="primary" variant="contained">
													{this.state.key !== null ? 'Actualizar' : 'Agregar'}
												</Button>
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Paper style={{overflowX: "auto"}}>
													<Table className={'desing-acresco'}>
														<TableHead>
															<TableRow>
																<TableCell component="th" align={'center'} className={'w-10-px padding-20-L padding-10-R'}>
																	ID
																</TableCell>
																<TableCell component="th" align={'center'}>Empresa</TableCell>
																<TableCell component="th" align={'center'}>Régimen Fiscal</TableCell>
																<TableCell component="th" align={'center'}>Descripción</TableCell>
																<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{this.state.regimen_fiscales.map((item, key) => (
																<TableRow key={key}>
																	<TableCell align={'center'} className={'padding-20-L padding-20-R'}> {key + 1} </TableCell>
																	<TableCell align={'center'}>{this.props.item.nombre_razon_social}</TableCell>
																	<TableCell align={'center'}>{item.regimen_fiscal}</TableCell>
																	<TableCell align={'center'}>{item.descripcion}</TableCell>
																	<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
																		<div className={'center'}>
																			<Tooltip
																				TransitionComponent={Zoom}
																				placement={"top"}
																				title="Eliminar"
																				children={(
																					<Fab
																						size="small"
																						aria-label="Eliminar"
																						onClick={() => this.delete(item, key + 1)}
																						style={{
																							marginLeft: "10px",
																							backgroundColor: "transparent",
																							color: "#808080",
																							boxShadow: "none",
																						}}
																						children={(
																							<DeleteOutlined/>
																						)}
																					/>
																				)}
																			/>
																		</div>
																	</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
												</Paper>
												{this.state.regimen_fiscales.length <= 0 ? (
													<div className={'w-100'}>
														<VistaVacia
															width={100}
															height={100}
															numero={0}
															mensaje={'Aun no hay regimen fiscal registrado.'}
														/>
													</div>
												) : null}
											</Grid>
										</Grid>
									</TabPanel>
									
									<TabPanel value="2" className={'padding-0-L padding-0-R'}>
										<Grid container spacing={2} alignContent={"flex-end"} alignItems={"stretch"}>
											{/*<Grid item xs={10} sm={10} md={10} lg={10} xl={10} align={'center'}>
												<FormControl fullWidth required>
													<InputLabel>Unidad de Medida</InputLabel>
													<Select
														native
														value={this.state.id_cat_clave_unidad}
														onChange={(e) => {
															this.handleChange(e);
														}}
														name="id_cat_clave_unidad"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_clave_unidad.map(option => (
															<option key={option.id_cat_clave_unidad}
															        value={option.id_cat_clave_unidad}>
																{option.descripcion ? option.clave_unidad + ' - ' + option.descripcion : option.clave_unidad}
															</option>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={2} sm={2} md={2} lg={2} xl={2} align={'center'}>
												<Button onClick={() => this.save()} color="primary" variant="contained">
													{this.state.key !== null ? 'Actualizar' : 'Agregar'}
												</Button>
											</Grid>*/}
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Paper style={{overflowX: "auto"}}>
													<Table className={'desing-acresco'}>
														<TableHead>
															<TableRow>
																<TableCell component="th" align={'center'} className={'w-10-px padding-20-L padding-10-R'}>
																	ID
																</TableCell>
																<TableCell component="th" align={'center'}>Empresa</TableCell>
																<TableCell component="th" align={'center'}>Clave</TableCell>
																<TableCell component="th" align={'center'}>Concepto</TableCell>
																<TableCell component="th" align={'center'}>Unidad Medida</TableCell>
																<TableCell component="th" align={'center'}>Descripción</TableCell>
																<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{this.state.unidades_medidas.map((item, key) => (
																<TableRow key={key}>
																	<TableCell align={'center'} className={'padding-20-L padding-20-R'}> {key + 1} </TableCell>
																	<TableCell align={'center'}>{this.props.item.nombre_razon_social}</TableCell>
																	<TableCell align={'center'}>{item.clave_unidad}</TableCell>
																	<TableCell align={'center'}>{item.concepto}</TableCell>
																	<TableCell align={'center'}>{item.nombre}</TableCell>
																	<TableCell align={'center'}>{item.descripcion}</TableCell>
																	<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
																		<div className={'w-100 h-100 vertical-inline'}>
																			<div className={'center'}>
																				<Tooltip
																					TransitionComponent={Zoom}
																					placement={"top"}
																					title="Eliminar"
																					children={(
																						<IconButton aria-label="Eliminar" onClick={() => this.delete(item, key + 1)}>
																							<DeleteOutlined/>
																						</IconButton>
																					)}
																				/>
																			</div>
																			<div className={'center'}>
																				<ModalEmpresaUnidadMedida
																					tipo={'edit'}
																					item={item}
																					id_empresa={this.state.id_empresa}
																					componente={(
																						<Button color="primary" size={'small'} variant="contained">
																							{'Editar'}
																						</Button>
																					)}
																					RefreshList={this.RefreshList}
																					showSnackBars={this.props.showSnackBars}
																				/>
																			</div>
																		</div>
																	</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
												</Paper>
												{this.state.unidades_medidas.length <= 0 ? (
													<div className={'w-100'}>
														<VistaVacia
															width={100}
															height={100}
															numero={0}
															mensaje={'Aun no hay unidad medida registrada.'}
														/>
													</div>
												) : null}
											</Grid>
											
											<Grid item xs={10} sm={10} md={10} lg={10} xl={10} align={'center'}>
												<TextField
													margin="none"
													name="unidad_medida"
													label="Unidad de Medida"
													type="text"
													fullWidth
													value={this.state.unidad_medida}
													onChange={this.handleChange}
												/>
											</Grid>
											
											<Grid item xs={2} sm={2} md={2} lg={2} xl={2} align={'center'}>
												<Button onClick={() => this.SearchUnidadMedida()} color="primary" variant="contained">
													{'Buscar'}
												</Button>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Paper style={{overflowX: "auto"}}>
													<Table className={'desing-acresco'}>
														<TableHead>
															<TableRow>
																<TableCell component="th" align={'center'} className={'w-10-px padding-20-L padding-10-R'}>
																	ID
																</TableCell>
																<TableCell component="th" align={'center'}>Clave Unidad</TableCell>
																<TableCell component="th" align={'center'}>Nombre</TableCell>
																<TableCell component="th" align={'center'}>Descripción</TableCell>
																<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{this.state.cat_clave_unidad.map((item, key) => (
																<TableRow key={key}>
																	<TableCell align={'center'} className={'padding-20-L padding-20-R'}> {item.id_cat_clave_unidad} </TableCell>
																	<TableCell align={'center'}>{item.clave_unidad}</TableCell>
																	<TableCell align={'center'}>{item.nombre}</TableCell>
																	<TableCell align={'center'}>{item.descripcion ? item.descripcion : ' - '}</TableCell>
																	<TableCell align={'center'} className={'padding-5-L padding-5-R vertical-inline'}>
																		<div className={'center'}>
																			<ModalEmpresaUnidadMedida
																				tipo={'add'}
																				item={item}
																				id_empresa={this.state.id_empresa}
																				componente={(
																					<Button color="primary" size={'small'} variant="contained">
																						{'Agregar'}
																					</Button>
																				)}
																				RefreshList={this.RefreshList}
																				showSnackBars={this.props.showSnackBars}
																			/>
																		</div>
																	</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
												</Paper>
												<Paginacion
													total={this.state.paginacion.total}
													page={this.state.paginacion.page}
													limit={this.state.paginacion.limit}
													rangos={this.state.paginacion.rangos}
													onClick={(data) => this.AplicarPaginacionUnidad(data)}
												/>
											</Grid>
										
										</Grid>
									</TabPanel>
									
									<TabPanel value="3" className={'padding-0-L padding-0-R'}>
										<Grid container spacing={2} alignContent={"flex-end"} alignItems={"stretch"}>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Paper style={{overflowX: "auto"}}>
													<Table className={'desing-acresco'}>
														<TableHead>
															<TableRow>
																<TableCell component="th" align={'center'} className={'w-10-px padding-20-L padding-10-R'}>
																	ID
																</TableCell>
																<TableCell component="th" align={'center'}>Clave</TableCell>
																<TableCell component="th" align={'center'}>Producto / Servicio</TableCell>
																<TableCell component="th" align={'center'}>Concepto</TableCell>
																<TableCell component="th" align={'center'}>Precio Unitario</TableCell>
																<TableCell component="th" align={'center'}>Tipo Descuento</TableCell>
																<TableCell component="th" align={'center'}>Descuento</TableCell>
																<TableCell component="th" align={'center'}>Unidad de Medida</TableCell>
																<TableCell component="th" align={'center'}>Número de Identificación</TableCell>
																<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{this.state.productos.map((item, key) => (
																<TableRow key={key}>
																	<TableCell align={'center'} className={'padding-20-L padding-20-R'}> {key + 1} </TableCell>
																	<TableCell align={'center'}>{item.clave_producto_servicio}</TableCell>
																	<TableCell align={'center'}>{item.descripcion}</TableCell>
																	<TableCell align={'center'}>{item.concepto}</TableCell>
																	<TableCell align={'center'}>{item.precio_unitario}</TableCell>
																	<TableCell align={'center'}>{item.tipo_descuento_texto}</TableCell>
																	<TableCell align={'center'}>{item.descuento}</TableCell>
																	<TableCell align={'center'}>{item.concepto_unidad ? item.clave_unidad + ' - ' + item.concepto_unidad : item.clave_unidad}</TableCell>
																	<TableCell align={'center'}>{item.numero_identificacion || "-"}</TableCell>
																	<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
																		<div className={'w-100 vertical-inline'}>
																			<div className={'v-center'}>
																				<ModalEmpresaClaveProducto
																					tipo={'edit'}
																					item={item}
																					id_empresa={this.state.id_empresa}
																					RefreshList={this.RefreshList}
																					showSnackBars={this.props.showSnackBars}
																				/>
																			</div>
																			<div className={'center'}>
																				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
																					<IconButton aria-label="Eliminar" onClick={() => this.delete(item, key + 1)}>
																						<DeleteOutlined/>
																					</IconButton>
																				</Tooltip>
																				{/*<Tooltip
																					TransitionComponent={Zoom}
																					placement={"top"}
																					title="Eliminar"
																					children={(
																						<Fab
																							size="small"
																							aria-label="Eliminar"
																							onClick={() => this.delete(item, key + 1)}
																							style={{
																								marginLeft: "10px",
																								backgroundColor: "transparent",
																								color: "#808080",
																								boxShadow: "none",
																							}}
																							children={(
																								<DeleteOutlined/>
																							)}
																						/>
																					)}
																				/>*/}
																			</div>
																		</div>
																	</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
												</Paper>
												{this.state.productos.length <= 0 ? (
													<div className={'w-100'}>
														<VistaVacia
															width={100}
															height={100}
															numero={0}
															mensaje={'Aun no hay producto registrado.'}
														/>
													</div>
												) : null}
											</Grid>
											
											<Grid item xs={10} sm={10} md={10} lg={10} xl={10} align={'center'}>
												<TextField
													margin="none"
													name="producto"
													label="Producto"
													type="text"
													fullWidth
													value={this.state.producto}
													onChange={this.handleChange}
												/>
											</Grid>
											
											<Grid item xs={2} sm={2} md={2} lg={2} xl={2} align={'center'}>
												<Button onClick={() => this.SearchProducto()} color="primary" variant="contained">
													{'Buscar'}
												</Button>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Paper style={{overflowX: "auto"}}>
													<Table className={'desing-acresco'}>
														<TableHead>
															<TableRow>
																<TableCell component="th" align={'center'} className={'w-10-px padding-20-L padding-10-R'}>
																	ID
																</TableCell>
																<TableCell component="th" align={'center'}>Producto</TableCell>
																<TableCell component="th" align={'center'}>Descripción</TableCell>
																<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{this.state.listar_productos.map((item, key) => (
																<TableRow key={key}>
																	<TableCell align={'center'} className={'padding-20-L padding-20-R'}> {item.id_cat_clave_producto_servicio} </TableCell>
																	<TableCell align={'center'}>{item.clave_producto_servicio}</TableCell>
																	<TableCell align={'center'}>{item.descripcion}</TableCell>
																	<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
																		<div className={'w-100 vertical-inline h-100'}>
																			<div className={'v-center'}>
																				<ModalEmpresaClaveProducto
																					tipo={'add'}
																					item={item}
																					id_empresa={this.state.id_empresa}
																					componente={(
																						<Button color="primary" size={'small'} variant="contained">
																							{'Agregar'}
																						</Button>
																					)}
																					RefreshList={this.RefreshList}
																					showSnackBars={this.props.showSnackBars}
																				/>
																			</div>
																		</div>
																	</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
												</Paper>
												<Paginacion
													total={this.state.paginacion.total}
													page={this.state.paginacion.page}
													limit={this.state.paginacion.limit}
													rangos={this.state.paginacion.rangos}
													onClick={(data) => this.AplicarPaginacion(data)}
												/>
											</Grid>
										</Grid>
									</TabPanel>
									
									<TabPanel value="4" className={'padding-0-L padding-0-R'}>
										<Grid container spacing={2} alignContent={"flex-end"} alignItems={"stretch"}>
											<Grid item xs={10} sm={10} md={10} lg={10} xl={10} align={'center'}>
												<FormControl fullWidth required>
													<InputLabel>Moneda</InputLabel>
													<Select
														native
														value={this.state.id_cat_moneda}
														onChange={(e) => {
															this.handleChange(e);
														}}
														name="id_cat_moneda"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_moneda.map(option => (
															<option key={option.id_cat_moneda}
															        value={option.id_cat_moneda}>
																{option.descripcion ? option.moneda + ' - ' + option.descripcion : option.moneda}
															</option>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={2} sm={2} md={2} lg={2} xl={2} align={'center'}>
												<Button onClick={() => this.save()} color="primary" variant="contained">
													{this.state.key !== null ? 'Actualizar' : 'Agregar'}
												</Button>
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Paper style={{overflowX: "auto"}}>
													<Table className={'desing-acresco'}>
														<TableHead>
															<TableRow>
																<TableCell component="th" align={'center'} className={'w-10-px padding-20-L padding-10-R'}>
																	ID
																</TableCell>
																<TableCell component="th" align={'center'}>Empresa</TableCell>
																<TableCell component="th" align={'center'}>Moneda</TableCell>
																<TableCell component="th" align={'center'}>Descripción</TableCell>
																<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{this.state.monedas.map((item, key) => (
																<TableRow key={key}>
																	<TableCell align={'center'} className={'padding-20-L padding-20-R'}> {key + 1} </TableCell>
																	<TableCell align={'center'}>{this.props.item.nombre_razon_social}</TableCell>
																	<TableCell align={'center'}>{item.moneda}</TableCell>
																	<TableCell align={'center'}>{item.descripcion}</TableCell>
																	<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
																		<div className={'center'}>
																			<Tooltip
																				TransitionComponent={Zoom}
																				placement={"top"}
																				title="Eliminar"
																				children={(
																					<Fab
																						size="small"
																						aria-label="Eliminar"
																						onClick={() => this.delete(item, key + 1)}
																						style={{
																							marginLeft: "10px",
																							backgroundColor: "transparent",
																							color: "#808080",
																							boxShadow: "none",
																						}}
																						children={(
																							<DeleteOutlined/>
																						)}
																					/>
																				)}
																			/>
																		</div>
																	</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
												</Paper>
												{this.state.monedas.length <= 0 ? (
													<div className={'w-100'}>
														<VistaVacia
															width={100}
															height={100}
															numero={0}
															mensaje={'Aun no hay moneda registrada.'}
														/>
													</div>
												) : null}
											</Grid>
										</Grid>
									</TabPanel>
								
								
								</TabContext>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{/*	{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.key !== null ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}*/}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEmpresaCatalogos.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalEmpresaCatalogos);
