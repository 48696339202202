import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {ClienteService} from "../../../../services/ClienteService/ClienteService";
import ModalAdquisiciones from "./ModalAdquisiciones";
import {CONFIG} from "../../../../settings/Config/Config";
import ModalPagos from "./ModalPagos";
import ModalSocio from "./ModalSocio";
import {GetApp, PeopleAlt} from "@material-ui/icons";
import {AdquisicionService} from "../../../../services/AdquisicionService/AdquisicionService";
import {FieldsJs} from "../../../../settings/General/General";
import {BotonExportarListaExcel} from "../../../Include/MiniComponents/GlobalComponent";

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar la adquisición: ${item.id_adquisicion}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				AdquisicionService.Eliminar(item.id_adquisicion).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	validacionBrinca = (item) => {
		let val = false;
		
		if (FieldsJs.Field(item)) {
			let cont_participacion = 0;
			if (Number(item.socio) > item.lista_socios.length && Number(item.socio) > 0) {
				val = true;
			}
			
			for (let i = 0; i < item.lista_socios.length; i++) {
				cont_participacion = cont_participacion + Number(item.lista_socios[i].participacion)
			}
			
			if (cont_participacion !== 100) {
				val = true;
			}
		}
		return val;
	}
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'}>ID</TableCell>
							<TableCell component="th" align={'left'}>Tipo de Adquisición</TableCell>
							<TableCell component="th" align={'left'}>Propietario</TableCell>
							<TableCell component="th" align={'left'}>Broker</TableCell>
							<TableCell component="th" align={'center'}>Socios</TableCell>
							<TableCell component="th" align={'left'}>Precio</TableCell>
							<TableCell component="th" align={'left'}>Enganche/Depósito</TableCell>
							<TableCell component="th" align={'left'}>Número de Pagos</TableCell>
							<TableCell component="th" align={'left'}>Saldo</TableCell>
							<TableCell component="th" align={'left'}>Dirección</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<TableRow key={key}>
								<TableCell align={'center'}>{item.id_adquisicion}</TableCell>
								{/*<TableCell align={'left'} className={'text-uppercase'}>{item.rfc}</TableCell>*/}
								<TableCell align={'left'}>{item.tipo_adquisicion}</TableCell>
								<TableCell align={'left'}>{item.propietario}</TableCell>
								<TableCell align={'left'}>{item.broker}</TableCell>
								<TableCell align={'center'}>{item.lista_socios.length + ' / ' + item.socio}</TableCell>
								<TableCell align={'left'}>${FieldsJs.Currency(item.precio)}</TableCell>
								<TableCell align={'left'}>${FieldsJs.Currency(item.enganche)}</TableCell>
								<TableCell align={'left'}>{item.plazo}</TableCell>
								<TableCell align={'left'}><strong>${FieldsJs.Currency(item.saldo)}</strong></TableCell>
								<TableCell align={'left'}>{item.direccion}</TableCell>
								<TableCell align={'right'} className={'padding-5-L padding-5-R'}>
									<div className={'w-auto vertical-inline'}>
										
										{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (
											<div className={'v-center'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Edo Cta General">
													<IconButton aria-label="Edo Cta General" onClick={() => this.props.exportar(item, 'EXCEL', true)}>
														<GetApp/>
													</IconButton>
												</Tooltip>
											</div>
										) : ''}
										
										{/*{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (
											<div className={'v-center'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Edo Cta Individual">
													<IconButton aria-label="Edo Cta Individual" onClick={() => this.props.exportar(item, 'EXCEL', true)}>
														<GetApp/>
													</IconButton>
												</Tooltip>
											</div>
										) : ''}*/}
										
										{/*{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (
											<div className={'v-center'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Edo Cta">
													<IconButton aria-label="Edo Cta Individual" onClick={() => this.props.exportar(item, 'EXCEL', true)}>
														<GetApp/>
													</IconButton>
												</Tooltip>
											</div>
										) : ''}*/}
										
										
										<div className={'v-center'}>
											<ModalPagos
												tipo={'add'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
										
										{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) || Number(this.Usr.id_usuario) === Number(item.id_usuario) ? (
											<div className={'v-center'}>
												<ModalSocio
													tipo={'add'}
													item={item}
													componente={
														<div className={this.validacionBrinca(item) ? 'animate__animated animate__bounce animate__delay-2s animate__infinite infinite' : ''}>
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Socios">
																<IconButton aria-label="Socios">
																	<PeopleAlt style={{color: !this.validacionBrinca(item) ? 'rgb(0, 99, 0)' : 'rgba(0, 0, 0, 0.54)'}}/>
																</IconButton>
															</Tooltip>
														</div>
													}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											</div>
										) : null}
										
										{Number(this.Usr.id_usuario) === Number(item.id_usuario) ? (
											<div className={'v-center'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
													<IconButton aria-label="Eliminar"
													            onClick={() => this.eliminar(item)}>
														<DeleteOutlined/>
													</IconButton>
												</Tooltip>
											</div>
										) : null}
										
										{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) || Number(this.Usr.id_usuario) === Number(item.id_usuario) ? (
											<Fragment>
												<div className={'v-center'}>
													<ModalAdquisiciones
														tipo={'edit'}
														item={item}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
												
												<div className={'v-center'}>
													<ModalAdquisiciones
														tipo={'view'}
														item={item}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
											</Fragment>
										) : null}
									
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
	
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired
	,
	RefreshList: PropTypes.func.isRequired
	,
	showSnackBars: PropTypes.func.isRequired
	,
	exportar: PropTypes.func.isRequired
	,
}

;

export default ListaTabla;
